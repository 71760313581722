import React, {memo, useMemo} from "react";
import {
    ArticleRowFragmentFragment,
    ShipmentStatus,
} from "../generated/graphql";
import ColumnId from "../common/data-grid/columns/ColumnId";
import {Link} from "react-router-dom";
import ColumnStatus from "../common/data-grid/columns/ColumnStatus";
import {ShipmentStatusExt} from "../shipment/model/ShipmentStatusExt";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";

const TABLE_KEY = "TABLE_KEY_ORDER_SHIPMENT"

type PackageWithShipment = {
    packageItems?: Array<{
        id: string,
        package: {
            id: string,
            shipment?: {
                id: string,
                number: string,
            } | null
        }
    }> | null
};


export const getLinkedShipments = (articleRows: PackageWithShipment[]) =>
{
    const shipments = articleRows.flatMap((ar) =>
    {
        return ar.packageItems?.map((e) => e?.package?.shipment)
    }).filter((e) => e)

    const uniqueShipments = new Map(shipments.map(shipment => [shipment?.id, shipment]));
    return Array.from(uniqueShipments.values())
}

const OrderShipmentPanel = ({articleRows}: { articleRows: ArticleRowFragmentFragment[] }) =>
{
    const linkedShipments = useMemo(() =>
    {
        return getLinkedShipments(articleRows)
    }, [articleRows])

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'number',
                searchable: true,
                renderCell: (params) =>
                {
                    return <Link to={`/shipment-detail/${params.id}`}>{params.value}</Link>
                }
            },
            ColumnStatus(Object.values(ShipmentStatus).map((e) => ({
                id: e,
                name: ShipmentStatusExt.label(e),
                color: ShipmentStatusExt.color(e)
            }))),
            ColumnDate("pickupDate", "Pickup date"),
            ColumnCreatedAt(),
            ColumnId(),
        ]
    }, [])

    return <>
        <h4>Shipments</h4>
        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: columns,
        }} data={linkedShipments}/>
    </>
}

export default memo(OrderShipmentPanel)