import {Invoice, InvoiceStatus, InvoiceType, TermsOfPaymentType} from "../generated/graphql";
import {sumBy} from "lodash";


export enum PaymentRequirementStatusEnum
{
    COMPLETE,
    NOT_YET_FULFILLED
}

export namespace PaymentRequirementStatus
{
    export const label = (status: PaymentRequirementStatusEnum): string =>
    {
        switch (status)
        {
            case PaymentRequirementStatusEnum.COMPLETE:
                return 'Prepayment complete';
            case PaymentRequirementStatusEnum.NOT_YET_FULFILLED:
                return 'Prepayment not yet fulfilled';
        }
    }

    export const color = (status: PaymentRequirementStatusEnum): string =>
    {
        switch (status)
        {
            case PaymentRequirementStatusEnum.COMPLETE:
                return 'success';
            case PaymentRequirementStatusEnum.NOT_YET_FULFILLED:
                return 'danger';
        }
    }
}

export const getPaymentRequirementStatus = (invoices: Invoice[] | null,
                                            orderSum: number | null,
                                            termsOfPaymentType: TermsOfPaymentType | null): PaymentRequirementStatusEnum | null =>
{
    if (invoices === null || invoices.length === 0 || orderSum === null)
    {
        return null
    }

    switch (termsOfPaymentType)
    {
        case TermsOfPaymentType.CashOnDelivery:
            return PaymentRequirementStatusEnum.COMPLETE;
        case TermsOfPaymentType.Day10:
        case TermsOfPaymentType.Day14:
        case TermsOfPaymentType.Day20:
        case TermsOfPaymentType.Day30:
        case TermsOfPaymentType.Day45:
        case TermsOfPaymentType.Day60:
        case TermsOfPaymentType.Day90:
        case null:
            return null
        case TermsOfPaymentType.ImmediatePayment:
            return orderSum <= sumAmount(invoices) ? PaymentRequirementStatusEnum.COMPLETE : PaymentRequirementStatusEnum.NOT_YET_FULFILLED
        case TermsOfPaymentType.Prepayment50:
            return (orderSum / 2) <= sumAmount(invoices) ? PaymentRequirementStatusEnum.COMPLETE : PaymentRequirementStatusEnum.NOT_YET_FULFILLED
    }
}

const sumAmount = (invoices: Invoice[]) =>
{
    return sumBy(invoices.filter((v) => v.type === InvoiceType.Invoice && v.status === InvoiceStatus.Closed), (v) => v.amountInCurrency)
}