import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {Link} from "react-router-dom";
import Paragraph from "../common/Paragraph";
import {
    Button, Badge, Spinner, UncontrolledPopover,
    PopoverBody, UncontrolledTooltip
} from "reactstrap";
import {downloadFile, FORMAT_DATE, formatToSwedishTime} from "../UTIL";
import Wood from "../common/icons/Wood";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import {
    GetSpiralStairsInProductionV2Query,
    SpiralStairStatus, useGetSpiralStairsInProductionV2Query
} from "../generated/graphql";
import BomInSpiralStairModal from "../spiralStair/bom/BomInSpiralStairModal";
import {getSpiralStairStatusColor} from "../spiralStair/SpiralStairStatusExt";
import {MultiSelectFilterOperators} from "../common/data-grid/MultiSelectInput";
import {ArrayElement} from "../UTILmore";
import ProductionProgressBar, {calculateProgress} from "../common/ProductionProgressBar";
import ColumnPurchaseOrderPrioritisation from "../common/data-grid/columns/ColumnPurchaseOrderPrioritisation";
import ColumnExtra, {Extra} from "../common/data-grid/columns/spiral-stair/ColumnExtra";
import ColumnRadius, {Radius} from "../common/data-grid/columns/spiral-stair/ColumnRadius";
import ColumnDirection from "../common/data-grid/columns/spiral-stair/ColumnDirection";
import ColumnSurfaceTreatmentOverview from "../common/data-grid/columns/spiral-stair/ColumnSurfaceTreatmentOverview";
import ColumnRalColors from "../common/data-grid/columns/spiral-stair/ColumnRalColors";
import ColumnDrawings from "../common/data-grid/columns/spiral-stair/ColumnDrawings";

const TABLE_KEY = 'SPIRAL_STAIRS'

type SpiralStairElement = ArrayElement<GetSpiralStairsInProductionV2Query['spiralStairsInProductionV2']>;

export type SpiralStairsInProductionType = SpiralStairElement['spiralStair'] & {
    purchaseOrder: SpiralStairElement['purchaseOrder'];
    articleRow: SpiralStairElement['articleRow'];
}

const defaultSort = {sortModel: [{field: 'purchaseOrder.order.preferredDeliveryDate', sort: 'asc'}]}

const buttons = [
    {
        name: "Pending",
        filters: {items:[{field: "status", operator: "in", value: ["pending"]}]},
    },
    {
        name: "Prepackaged",
        filters: {items:[{field: "status", operator: "in", value: ["prepackaged"]}]},
    },
    {
        name: "Finished",
        filters: {items:[{field: "status", operator: "in", value: ["finished"]}]},
    },
]

const visibilityModel = {
    columnVisibilityModel: {
        id: false,
        direction: false,
        extra: false,
        stepType: false,
        platingType: false,
        segments: false
    }
}

export const DrawingsOverlay = (props) => {
    let drawings = props.files.filter(file => file.fileCategory.name === 'Production');
    if (drawings.length === 0) {
        return null;
    }
    drawings = drawings.reverse();
    const target = `drawings-${props.stairId}`
    return <React.Fragment>
        <div style={{textAlign: "center"}}><span id={`drawings-${props.stairId}`} role="img" title="Show drawings"
                                                 aria-label="Show drawings">📐</span></div>
        <UncontrolledPopover
            placement="left"
            target={target}
            trigger="hover">
            <PopoverBody>
                <table style={{borderCollapse: "collapse"}}>
                    <thead>
                    <tr>
                        <th>File</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        drawings.map((drawing, i) => {
                            return <tr key={i} style={{border: "1px solid #000"}}>
                                <td className='link' onClick={async () => {
                                    await downloadFile(drawing.id, drawing.filename);
                                }}>{drawing.filename}</td>
                                <td align="right">{formatToSwedishTime(drawing.createdAt, FORMAT_DATE)}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </PopoverBody>
        </UncontrolledPopover>
    </React.Fragment>;
}

export default () =>
{
    const [currentBomId, setCurrentBomId] = useState<string | null>()
    const {data: response, loading, error} = useGetSpiralStairsInProductionV2Query();

    const [spiralStairsInProduction, setSpiralStairsInProduction] = useState<SpiralStairsInProductionType[]>([]);

    useEffect(() =>
    {
        if (!response?.spiralStairsInProductionV2)
        {
            return
        }

        setSpiralStairsInProduction(response.spiralStairsInProductionV2.map((e) => ({
            ...e.spiralStair,
            purchaseOrder: e.purchaseOrder,
            articleRow: e.articleRow
        })))
    }, [response]);

    const onBomClick = useCallback((id) =>
    {
        setCurrentBomId(id)
    }, [])

    const bomModalData = useMemo(() =>
    {
        if (!currentBomId || !spiralStairsInProduction)
        {
            return null
        }
        return spiralStairsInProduction.find((e) => e.id === currentBomId)
    }, [currentBomId, spiralStairsInProduction])

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'purchaseOrder.order.number',
                headerName: 'Order number',
                width: 120,
                renderCell: (params) =>
                {
                    const orderId = params.row.purchaseOrder.order.id
                    return <Link to={`/orderDetail/${orderId}`}>{params.value}</Link>
                },
            },
            ColumnPurchaseOrderPrioritisation(),
            {
                field: 'artNo',
                headerName: 'Art no.',
                width: 120,
                renderCell: (params) =>
                {
                    const {id, stepType, innerRailingType} = params.row
                    const hasWoodSteps = stepType === "oak30mm" || innerRailingType === "oak";
                    const woodId = `wood-${id}`;
                    return <>
                        <Link to={`/spiralStairDetail/${id}`}>{params.row?.articleRow?.article?.artNo}</Link>
                        {hasWoodSteps && <><UncontrolledTooltip target={woodId}>Wooden steps</UncontrolledTooltip><span
                            id={woodId} style={{textDecoration: "underline", fontSize: "5px"}}><Wood/></span></>}
                    </>;
                },
            },
            {
                field: 'details',
                headerName: 'Details',
                sortable: false,
                filterable: false,
                renderCell: (params) =>
                {
                    const {id, direction, description, segments, extra} = params.row

                    return <div>
                        <div style={{textAlign: 'center', width: "100%"}}>
                            <Button style={{border: 0}} id={"openToolTip" + id} outline>show details</Button>
                        </div>
                        <UncontrolledTooltip
                            style={{
                                backgroundColor: "#fff", border: "1px solid #999", color: "#333",
                                opacity: 1, padding: "15px", textAlign: "left"
                            }}
                            placement="left"
                            target={"openToolTip" + id}
                            autohide={false}
                            flip={"true"}
                            trigger="hover">
                            <h6>Direction</h6>
                            <Paragraph style={{marginLeft: "7px"}}>
                                {direction}
                            </Paragraph>
                            {description && <>
                                <h6><br/>Comment</h6>
                                <Paragraph style={{marginLeft: "7px"}}>
                                    {description}
                                </Paragraph>
                            </>}
                            {segments && <h6><br/>Radius</h6>}
                            {Radius(segments)}
                            {extra.length > 0 && <h6><br/>Extra</h6>}
                            {Extra(extra)}
                        </UncontrolledTooltip>
                    </div>
                }
            },
            ColumnSurfaceTreatmentOverview((params) => ({
                surfaceTreatmentOverview: params.value,
                platingType: params.row.platingType
            })),
            ColumnDate('purchaseOrder.order.preferredDeliveryDate', 'Preferred delivery date'),
            ColumnRalColors(),
            ColumnDrawings((p)=> p.row.purchaseOrder.order.files),
            {
                field: 'progress',
                headerName: 'Progress',
                type: 'number',
                valueGetter: (params) =>
                {
                    const articleRow = params.row.articleRow
                    return calculateProgress(articleRow)
                },
                renderCell: (params) =>
                {
                    const articleRow = params.row.articleRow
                    const progress = params.value
                    return <ProductionProgressBar id={articleRow?.id} progress={progress}
                                                  productionStatus={articleRow?.productionStatus}/>
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                type: 'singleSelect',
                customFilterOperators: MultiSelectFilterOperators(Object.entries(SpiralStairStatus)
                        .map(([key, value]) => ({id: value, name: key})),
                    "Status"),
                renderCell: (params) =>
                {
                    const value = params.value
                    return <Badge color={getSpiralStairStatusColor(value)}>{value}</Badge>
                }
            },
            {
                field: 'bom',
                headerName: 'Bill of material',
                renderCell: (params) =>
                {
                    return <Button size="sm" color="primary"
                                   onClick={() => onBomClick(params.id)}>
                        BoM
                    </Button>
                }
            },
            ColumnDirection(),
            ColumnRadius((params)=> params.row.segments),
            ColumnExtra(),
            {
                field: 'stepType',
            },
        ]
    }, [onBomClick])

    if(loading){
        return <Spinner color={"warning"}/>;
    }

    if(loading){
        return <Spinner color={"warning"}/>;
    }

    return <div>
        {error && <p style={{color: "red"}}>Error: {error.message}</p>}

        {spiralStairsInProduction.length > 0 && <>
            <NonPaginatedDataGrid
                definition={{
                    tableKey: TABLE_KEY,
                    columns: columns,
                    initState: {
                        sorting: defaultSort,
                        columns: visibilityModel
                    },
                    pageSize: 20,
                    buttons: buttons
                }}
                data={spiralStairsInProduction}/>
        </>}

        {
            bomModalData && <BomInSpiralStairModal spiralStair={bomModalData}
                                                   toggle={() => setCurrentBomId(null)}
                                                   setSpiralStairs={setSpiralStairsInProduction}/>
        }
    </div>
}
