import SurfaceTreatmentTag, {getSurfaceTreatmentType, SurfaceTreatmentType} from "../../../SurfaceTreatmentTag";
import {GridFilterInputSingleSelect} from "@mui/x-data-grid";
import React from "react";

type ValueGetter = (params: any) => { surfaceTreatmentOverview: string, platingType: string } | null

const ColumnSurfaceTreatmentOverview = (valueGetter: ValueGetter) =>
{
    return {
        field: 'surfaceTreatmentOverview',
        headerName: 'Surface treatment',
        valueGetter: valueGetter,
        renderCell: (params) =>
        {
            return <SurfaceTreatmentTag spiralStair={params.value}></SurfaceTreatmentTag>
        },
        sortComparator: (v1, v2) =>
        {
            const spiralStair1 = getSurfaceTreatmentType(v1)
            const spiralStair2 = getSurfaceTreatmentType(v2)
            return Object.keys(SurfaceTreatmentType).indexOf(spiralStair1) > Object.keys(SurfaceTreatmentType).indexOf(spiralStair2)
        },
        type: 'singleSelect',
        valueOptions: ['vfz', 'vfz_fe_zn', 'vfz_lack', 'pulverlack', 'obehandlad'],
        customFilterOperators: [
            {
                label: 'is',
                value: '=',
                getApplyFilterFn: (filterItem) =>
                {
                    if (!filterItem.field || !filterItem.value || !filterItem.operator)
                    {
                        return null;
                    }
                    return (params) =>
                    {
                        const surfaceTreatmentValue = getSurfaceTreatmentType(params.value)
                        return filterItem.value === surfaceTreatmentValue;
                    };
                },
                InputComponent: GridFilterInputSingleSelect
            },
        ],
    }
}

export default ColumnSurfaceTreatmentOverview;