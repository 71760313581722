import {
    ImageFragmentFragment, ImageTagFragmentFragment,
    useGetImageGalleryLazyQuery, useGetImageTagsLazyQuery,
} from "../generated/graphql";
import {useCallback, useEffect, useState} from "react";


type Tag = ImageTagFragmentFragment & {
    disabled: boolean
}

const useImageGalleryHook = () =>
{
    const [getTagsQuery] = useGetImageTagsLazyQuery()
    const [getImagesQuery] = useGetImageGalleryLazyQuery()

    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [images, setImages] = useState<ImageFragmentFragment[]>([])
    const [openImage, setOpenImage] = useState<ImageFragmentFragment | null>(null)

    const onTagClicked = useCallback((tagId: string) =>
    {
        setSelectedTags((prevState) =>
        {
            const index = prevState.indexOf(tagId)
            if (index !== -1)
            {
                prevState.splice(index, 1)
            } else
            {
                prevState.push(tagId)
            }
            return [...prevState]
        })
    }, [])

    const clearSelectedTag = useCallback(()=> {
        setSelectedTags([])
    }, [])

    const getImages = useCallback(async (selectedTags: string[]) =>
    {
        const response = await getImagesQuery({variables: {tags: selectedTags}})
        const images = response?.data?.imageGallery
        setImages(images ?? [])

        const allTagsId = images?.flatMap((e) => e.tags.map(tag => tag.id))
        const allTagsIdSet = new Set(allTagsId ?? [])

        setTags(prevState =>
        {
            prevState.forEach((e) =>
            {
                e.disabled = !allTagsIdSet.has(e.id)
            })

            return [...prevState]
        })
    }, [getImagesQuery])

    const getTags = useCallback(async () =>
    {
        const response = await getTagsQuery()
        setTags(response.data?.imageTags.map((e) => ({...e, disabled: false})) ?? [])
    }, [getTagsQuery])

    useEffect(() =>
    {
        getImages(selectedTags).then()
    }, [selectedTags, getImages])

    useEffect(() =>
    {
        getTags().then(() => getImages([]))
    }, [getTags, getImages])

    return {
        tags, selectedTags, onTagClicked, clearSelectedTag,
        images, openImage, setOpenImage
    }
}

export default useImageGalleryHook;