import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Table} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import gql from "graphql-tag";

export const articleDetailLink = (article,label,target="_blank") =>
{
    if(!label)
    {
        label = article.artNo;
    }

    if(article.spiralStair)
    {
        return <Link to={`/spiralStairDetail/${article.id}`} target={target}>{label}</Link>
    }
    else if(article.straightStair)
    {
        return <Link to={`/straightStairDetail/${article.id}`} target={target}>{label}</Link>
    }
    else if(article.straightStairV2)
    {
        return <Link to={`/straightStairV2Detail/${article.id}`} target={target}>{label}</Link>
    }
    else if(article.moduleRamp)
    {
        return <Link to={`/moduleRampDetail/${article.moduleRamp.id}`} target={target}>{label}</Link>
    }
    else
    {
        return <Link to={`/articleDetail/${article.id}`} target={target}>{label}</Link>;
    }
};

export const articleDetailURL = (article) =>
{
    if(article.spiralStair)
    {
        return `/spiralStairDetail/${article.id}`;
    }
    else if(article.straightStair)
    {
        return `/straightStairDetail/${article.id}`
    }
    else if(article.straightStairV2)
    {
        return `/straightStairV2Detail/${article.id}`
    }
    else if(article.moduleRamp)
    {
        return `/moduleRampDetail/${article.moduleRamp.id}`
    }
    else
    {
        return `/articleDetail/${article.id}`
    }
};

const getOrderArticlesName = gql`
    query getOrderArticlesName($id:ID!)
    {
        order(id:$id)
        {
            articleRows {
                article {
                    id
                }
                name
            }
        }
    }
`;
export default function ArticleList ({articles, orderId}){
    const [getData] = useLazyQuery(getOrderArticlesName);
    const [newNameArticles, setNewNameArticles] = useState(articles);

    useEffect(()=>{
        const init = async () => {
            const res = await getData({variables:{id:orderId} });

            if(res.data.order)
            {
                const newNameArticles = articles.map(thisArticle => {
                    let name = thisArticle.name;
                    res.data.order.articleRows.forEach(articleRow => {
                        if(articleRow.article && articleRow.article.id === thisArticle.id){
                            name = articleRow.name;
                        }
                    });
                    return {...thisArticle, name};
                });
                setNewNameArticles(newNameArticles);
            }
        }

        init().then();
    }, [articles, orderId, getData, setNewNameArticles]);

    return  <Table>
        <thead>
        <tr>
            <th>Art No</th>
            <th>Name</th>
        </tr>
        </thead>
        <tbody>
        {newNameArticles && newNameArticles.map(article => <tr key={article.id}>
            <td>
                {articleDetailLink(article)}
            </td>
            <td>{article.name}</td>
        </tr>)}
        </tbody>

    </Table>
}
