import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc'
import gql from 'graphql-tag';
import Select from 'react-select';
import _ from 'lodash';
import PropTypes from 'prop-types'

class SelectUser extends Component
{
    constructor(props)
    {
        super();
        this.state = {
            userId:props.userId || null
        }
    }

    onChange(selected)
    {
        this.setState({userId:selected?selected.value:null});
        this.props.onChange(selected)
    }

    render()
    {
        const data = this.props.data;
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error) {
            return (<p>Error loading user dropdown..</p>);
        }
        const options = _.map(data.users,user => ({value:user.username,label:user.displayName}));
        return (
        <div>
            <Select options={options} value={_.find(options,usr=>usr.value ===this.state.userId)} onChange={this.onChange.bind(this)}
                    isClearable={this.props.isClearable}/>
        </div>

        )
    }
}

SelectUser.propTypes =
{
    onChange:PropTypes.func
};


const AllUserQuery = gql`
    query {
        users
        {
            username
            displayName
        }
    }
`;


export default graphql(AllUserQuery)(SelectUser);



