import inherits from 'inherits';
import Viewer from 'bpmn-js/lib/Viewer';

import KeyboardMoveModule from 'diagram-js/lib/navigation/keyboard-move';
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';
import ZoomScrollModule from './custom-zoomscroll';

/**
 * A viewer that includes mouse navigation facilities
 *
 * @param {Object} options
 */
export default function MyViewer(options) {
    Viewer.call(this, options);
}

inherits(MyViewer, Viewer);

MyViewer.prototype._navigationModules = [
    KeyboardMoveModule,
    MoveCanvasModule,
    ZoomScrollModule
];

MyViewer.prototype._modules = [].concat(
MyViewer.prototype._modules,
MyViewer.prototype._navigationModules);