import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {BetweenRamp, Ramp, StartingRamp, TrianglePlatform} from "./Module/Ramp";
import {IndustrialPole, IndustrialRailing, IndustrialRailingCurvePiece} from "./Module/IndustrialPole";
import {ChildSafeRailingPiece, ChildSafeRailingPlasticStrip, ChildSafeSection} from "./Module/ChildSafeSection";
import {ScrewLeg, SupportLeg} from "./Module/SupportLeg";
import {ModuleObject} from "./ShpaeObject";
import {Scene} from "@babylonjs/core/scene";
import {ModuleRampType, IndustrialRailingPieceType, ChildSafeRailingPieceType} from "../generated/graphql";
import {WoodPole} from "./Module/WoodPole";
import {RampToPlatform} from "./Module/RampToPlatform";
import {KickPlate} from "./Module/KickPlate";
import {StairStep, StairStringer} from "./Module/Stair";

export const scaleRatio = 0.01;
export const pairNodeDistance = 0.5;

export interface ModuleData {
    name?:string;
    lengthMM?:number;
    widthMM?:number;
    heightMM?:number;
    diameterMM?:number;
    slopeDegree?:number;
    [others: string]: any;
}

export default class ModuleTemplate {
    type:ModuleRampType;
    name:string;
    moduleId:number;
    artNos:string[];
    origin:Vector3;
    rotationY:number;
    width:number;
    height:number;
    length:number;
    diameter:number;
    slopeDegree:number;
    otherProps:any;

    constructor(data: {type:ModuleRampType} & ModuleData) {
        const {
            type,
            name="",
            moduleId=0,
            artNos=[],
            origin=Vector3.Zero(),
            rotationY=0,
            lengthMM=0,
            widthMM=0,
            heightMM=0,
            diameterMM=0,
            slopeDegree=0,
            ...otherProps
        } = data;

        this.type = type;
        this.name = name;
        this.moduleId = moduleId;
        this.artNos = artNos;
        this.origin = origin;
        this.rotationY = rotationY;
        this.width = widthMM*scaleRatio;
        this.height = heightMM*scaleRatio;
        this.length = lengthMM*scaleRatio;
        this.diameter = diameterMM*scaleRatio;
        this.slopeDegree = slopeDegree;
        this.otherProps = otherProps;
    }

    clone(){
        return new ModuleTemplate({
            type: this.type,
            name: this.name,
            artNos: [...this.artNos],
            origin: this.origin.clone(),
            rotationY: this.rotationY,
            widthMM: this.width/scaleRatio,
            heightMM: this.height/scaleRatio,
            lengthMM: this.length/scaleRatio,
            diameterMM: this.diameter/scaleRatio,
            slopeDegree: this.slopeDegree,
            ...this.otherProps
        });
    }
}

export const store:{[key in ModuleRampType]:ModuleData[]} = {
    [ModuleRampType.StartRamp]: [
        {name:"Startramp 900 x 300", artNos:["RAM0903"], lengthMM:300, widthMM:900, heightMM:60},
        {name:"Startramp 900 x 600", artNos:["RAM0906"], lengthMM:600, widthMM:900, heightMM:60},
        {name:"Startramp 1200 x 300", artNos:["RAM0906"], lengthMM:300, widthMM:1200, heightMM:60},
        {name:"Startramp 1200 x 600", artNos:["RAM0906"], lengthMM:600, widthMM:1200, heightMM:60},
        {name:"Startramp 1500 x 300", artNos:["RAM1506"], lengthMM:300, widthMM:1500, heightMM:60},
        {name:"Startramp 1500 x 600", artNos:["RAM1506"], lengthMM:600, widthMM:1500, heightMM:60},
    ],
    [ModuleRampType.Ramp]: [
        {name:"Ramp  900 x  900", artNos:["RAM0909"], lengthMM:900, widthMM:900, heightMM:60},
        {name:"Ramp  900 x 1200", artNos:["RAM0912"], lengthMM:1200, widthMM:900, heightMM:60},
        {name:"Ramp  900 x 1500", artNos:["RAM0915"], lengthMM:1500, widthMM:900, heightMM:60},
        {name:"Ramp  900 x 1800", artNos:["RAM0918"], lengthMM:1800, widthMM:900, heightMM:60},
        {name:"Ramp  900 x 2100", artNos:["RAM0921"], lengthMM:2100, widthMM:900, heightMM:60},
        {name:"Ramp 1200 x  900", artNos:["RAM1209"], lengthMM:900, widthMM:1200, heightMM:60},
        {name:"Ramp 1200 x 1200", artNos:["RAM1212"], lengthMM:1200, widthMM:1200, heightMM:60},
        {name:"Ramp 1200 x 1500", artNos:["RAM1215"], lengthMM:1500, widthMM:1200, heightMM:60},
        {name:"Ramp 1200 x 1800", artNos:["RAM1218"], lengthMM:1800, widthMM:1200, heightMM:60},
        {name:"Ramp 1200 x 2100", artNos:["RAM1221"], lengthMM:2100, widthMM:1200, heightMM:60},
        {name:"Ramp 1500 x 1500", artNos:["RAM1515"], lengthMM:1500, widthMM:1500, heightMM:60},
    ],
    [ModuleRampType.BetweenRamp]: [
        {name:"Between ramp", artNos:["RAX5003"], lengthMM:0, widthMM:0, heightMM:0},
    ],
    [ModuleRampType.TrianglePlatform]: [
        // lengthMM in this module refers to the length of short side of the triangle, the real length(triangle's height) is widthMM * Math.tan(angle)
        {name:"Platform 900mm, 19°∠", artNos:["RAX0919"], lengthMM:300, widthMM:900, heightMM:60, angle:19},
        {name:"Platform 900mm, 38°∠", artNos:["RAX0938"], lengthMM:600, widthMM:900, heightMM:60, angle:38},
        {name:"Platform 900mm, 60°∠", artNos:["RAX0960"], lengthMM:900, widthMM:900, heightMM:60, angle:60},
        {name:"Platform 1200mm, 14°∠", artNos:["RAX1214"], lengthMM:300, widthMM:1200, heightMM:60, angle:14},
        {name:"Platform 1200mm, 29°∠", artNos:["RAX1229"], lengthMM:600, widthMM:1200, heightMM:60, angle:29},
        {name:"Platform 1200mm, 44°∠", artNos:["RAX1244"], lengthMM:900, widthMM:1200, heightMM:60, angle:44},
        {name:"Platform 1200mm, 60°∠", artNos:["RAX1260"], lengthMM:1200, widthMM:1200, heightMM:60, angle:60},
    ],
    [ModuleRampType.RampToPlatform]: [
        {name: " 900mm", artNos: ["RAX2090"], lengthMM: 110, widthMM: 900, heightMM: 60},
        {name: " 900x250 mm", artNos: ["RAX2090S"], lengthMM: 250, widthMM: 900, heightMM: 60},
        {name: "1200 mm", artNos: ["RAX2120"], lengthMM: 110, widthMM: 1200, heightMM: 60},
        {name: "1200x250 mm", artNos: ["RAX2120S"], lengthMM: 250, widthMM: 1200, heightMM: 60},
        {name: "1500 mm", artNos: ["RAX2150"], lengthMM: 110, widthMM: 1500, heightMM: 60},
    ],
    [ModuleRampType.IndustrialPole]: [
        {name:"Industrial pole", artNos:["RAR9100"], lengthMM:20, widthMM:20, heightMM:900, offsetMM:50},
    ],
    [ModuleRampType.IndustrialRailing]: [
        {name:"Railing 300mm", artNos:["RAR9203", "RAR9203"], lengthMM:300, diameterMM:50},
        {name:"Railing 600mm", artNos:["RAR9206", "RAR9206"], lengthMM:600, diameterMM:50},
        {name:"Railing 900mm", artNos:["RAR9209", "RAR9209"], lengthMM:900, diameterMM:50},
        {name:"Railing 1200mm", artNos:["RAR9212", "RAR9212"], lengthMM:1200, diameterMM:50},
        {name:"Railing 1500mm", artNos:["RAR9215", "RAR9215"], lengthMM:1500, diameterMM:50},
        {name:"Railing 1800mm", artNos:["RAR9218", "RAR9218"], lengthMM:1800, diameterMM:50},
        {name:"Railing 2100mm", artNos:["RAR9221", "RAR9221"], lengthMM:2100, diameterMM:50},
    ],
    [ModuleRampType.IndustrialRailingPiece]: [
        {name:"Railing end front", artNos:["RAR9104", "RAR9105"], pieceType: IndustrialRailingPieceType.EndFront, lengthMM:100, curveRadiusMM:100, extensionLengthMM:70, diameterMM:50},
        {name:"Railing end plug", artNos:["RAR9105", "RAR9105"], pieceType: IndustrialRailingPieceType.EndPlug, curveRadiusMM:200, diameterMM:50},
        {name:"Railing joint 90 degree", artNos:["RAR9101"], pieceType: IndustrialRailingPieceType.Joint90degree, curveRadiusMM:100, extensionLengthMM:50, diameterMM:50},
        {name:"Railing joint between plane and ramp", artNos:["RAR9102"], pieceType: IndustrialRailingPieceType.JointPlaneRamp, lengthMM:150, diameterMM:50, jointTargetSlopeDegree:0},
    ],
    [ModuleRampType.ChildSafeSection]: [
        {name:"Section 300mm", artNos:["RAR0300"], lengthMM:300, widthMM:30, heightMM:5},
        {name:"Section 900mm", artNos:["RAR0900"], lengthMM:900, widthMM:30, heightMM:5},
        {name:"Section 1200mm", artNos:["RAR1200"], lengthMM:1200, widthMM:30, heightMM:5},
        {name:"Section 1500mm", artNos:["RAR1500"], lengthMM:1500, widthMM:30, heightMM:5},
        {name:"Section 1800mm", artNos:["RAR1800"], lengthMM:1800, widthMM:30, heightMM:5},
        {name:"Section 2100mm", artNos:["RAR2100"], lengthMM:2100, widthMM:30, heightMM:5},
    ],
    [ModuleRampType.ChildSafeRailingPiece]: [
        {name:"Railing joint, inner, 90°∠", artNos:["RAX9002"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:103, widthMM:30, heightMM:5, angle:90},
        {name:"Railing joint, outer, 90°∠", artNos:["RAX9003"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:142, widthMM:30, heightMM:5, angle:90},
        {name:"Railing joint, inner, 19°∠", artNos:["RAX9011"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:115, widthMM:30, heightMM:5, angle:19},
        {name:"Railing joint, outer, 19°∠", artNos:["RAX9012"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:122, widthMM:30, heightMM:5, angle:10},
        {name:"Railing joint, inner, 36°∠", artNos:["RAX9013"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:110, widthMM:30, heightMM:5, angle:38},
        {name:"Railing joint, outer, 36°∠", artNos:["RAX9014"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:118, widthMM:30, heightMM:5, angle:19},
        {name:"Railing joint, inner, 60°∠", artNos:["RAX9013"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:107, widthMM:30, heightMM:5, angle:60},
        {name:"Railing joint, outer, 60°∠", artNos:["RAX9014"], pieceType: ChildSafeRailingPieceType.Joint, lengthMM:123, widthMM:30, heightMM:5, angle:30},
        {name:"Railing straight joint", artNos:["RAX9001"], pieceType: ChildSafeRailingPieceType.StraightJoint, lengthMM:116, widthMM:30, heightMM:5},
        {name:"Railing wall end", artNos:["RAX9004"], pieceType: ChildSafeRailingPieceType.WallEnd, lengthMM:50, widthMM:30, heightMM:5},
        {name:"Railing end", artNos:["RAX9005"], pieceType: ChildSafeRailingPieceType.End, lengthMM:400, widthMM:30, heightMM:5},
    ],
    [ModuleRampType.ChildSafeRailingPlasticStrip]: [
        {name:"Plastic strip per 40mm", artNos:["RAX0140"], lengthMM:1000, widthMM:0, heightMM:0},
    ],
    [ModuleRampType.WoodPole]: [
        {name: "Wood pole", artNos: ["RAX0100"], lengthMM: 20, widthMM: 20, heightMM: 900, offsetMM: 50},
    ],
    [ModuleRampType.KickPlate]: [
        {name: "kick plate 300mm",  artNos: ["RAV0003"], lengthMM: 300, widthMM: 20, heightMM: 150},
        {name: "kick plate 900mm",  artNos: ["RAV0009"], lengthMM: 900, widthMM: 20, heightMM: 150},
        {name: "kick plate 1200mm", artNos: ["RAV0012"], lengthMM: 1200, widthMM: 20, heightMM: 150},
        {name: "kick plate 1500mm", artNos: ["RAV0015"], lengthMM: 1500, widthMM: 20, heightMM: 150},
        {name: "kick plate 1800mm", artNos: ["RAV0018"], lengthMM: 1800, widthMM: 20, heightMM: 150},
        {name: "kick plate 2100mm", artNos: ["RAV0021"], lengthMM: 2100, widthMM: 20, heightMM: 150},
    ],
    [ModuleRampType.StairStep]: [
        {name:"Step 570mm", artNos:["RAT0570"], lengthMM:570, widthMM:300, heightMM:60},
        {name:"Step 870mm", artNos:["RAT0870"], lengthMM:870, widthMM:300, heightMM:60},
        {name:"Step 1170mm", artNos:["RAT1170"], lengthMM:1170, widthMM:300, heightMM:60},
        {name:"Step 1470mm", artNos:["RAT1470"], lengthMM:1470, widthMM:300, heightMM:60},
    ],
    [ModuleRampType.StairStringer]: [
        {name:`Stair side for 1 step, no railing, right`, artNos:["RAT0001"], lengthMM:300, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 2 step, no railing, right", artNos:["RAT0002"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 3 step, no railing, right", artNos:["RAT0003"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 4 step, no railing, right", artNos:["RAT0004"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 5 step, no railing, right", artNos:["RAT0005"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 6 step, no railing, right", artNos:["RAT0006"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:"Stair side for 7 step, no railing, right", artNos:["RAT0007"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'right'},
        {name:`Stair side for 1 step, no railing, left`,  artNos:["RAT1001"], lengthMM:300, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 2 step, no railing, left",  artNos:["RAT1002"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 3 step, no railing, left",  artNos:["RAT1003"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 4 step, no railing, left",  artNos:["RAT1004"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 5 step, no railing, left",  artNos:["RAT1005"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 6 step, no railing, left",  artNos:["RAT1006"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},
        {name:"Stair side for 7 step, no railing, left",  artNos:["RAT1007"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'left'},

        {name:"Stair side for 1 step, child safe, right", artNos:["RAT0011"], lengthMM:300, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 2 step, child safe, right", artNos:["RAT0012"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 3 step, child safe, right", artNos:["RAT0013"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 4 step, child safe, right", artNos:["RAT0014"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 5 step, child safe, right", artNos:["RAT0015"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 6 step, child safe, right", artNos:["RAT0016"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:"Stair side for 7 step, child safe, right", artNos:["RAT0017"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'childSafe'},
        {name:`Stair side for 1 step, child safe, left`,  artNos:["RAT1011"], lengthMM:300, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 2 step, child safe, left",  artNos:["RAT1012"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 3 step, child safe, left",  artNos:["RAT1013"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 4 step, child safe, left",  artNos:["RAT1014"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 5 step, child safe, left",  artNos:["RAT1015"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 6 step, child safe, left",  artNos:["RAT1016"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},
        {name:"Stair side for 7 step, child safe, left",  artNos:["RAT1017"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'childSafe'},

        {name:"Stair side for 2 step, industrial, right", artNos:["RAT2012"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 3 step, industrial, right", artNos:["RAT2013"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 4 step, industrial, right", artNos:["RAT2014"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 5 step, industrial, right", artNos:["RAT2015"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 6 step, industrial, right", artNos:["RAT2016"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 7 step, industrial, right", artNos:["RAT2017"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'right', railing:'industrial'},
        {name:"Stair side for 2 step, industrial, left",  artNos:["RAT2022"], lengthMM:600, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
        {name:"Stair side for 3 step, industrial, left",  artNos:["RAT2023"], lengthMM:900, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
        {name:"Stair side for 4 step, industrial, left",  artNos:["RAT2024"], lengthMM:1200, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
        {name:"Stair side for 5 step, industrial, left",  artNos:["RAT2025"], lengthMM:1500, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
        {name:"Stair side for 6 step, industrial, left",  artNos:["RAT2026"], lengthMM:1800, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
        {name:"Stair side for 7 step, industrial, left",  artNos:["RAT2027"], lengthMM:2100, widthMM:5, heightMM:120, slopeDegree:30, side:'left', railing:'industrial'},
    ],
    [ModuleRampType.SupportLeg]: [
        {name:"Leg 168-253 mm", artNos:["RAS8103"], diameterMM:100, minHeightMM:168, maxHeightMM:253},
        {name:"Leg 258-458 mm", artNos:["RAS8104"], diameterMM:100, minHeightMM:258, maxHeightMM:458},
        {name:"Leg 348-548 mm", artNos:["RAS8105"], diameterMM:100, minHeightMM:348, maxHeightMM:548},
        {name:"Leg 510-710 mm", artNos:["RAS8106"], diameterMM:100, minHeightMM:510, maxHeightMM:710},
        {name:"Leg 640-850 mm", artNos:["RAS8107"], diameterMM:100, minHeightMM:640, maxHeightMM:850},
        {name:"Leg 780-990 mm", artNos:["RAS8108"], diameterMM:100, minHeightMM:780, maxHeightMM:990},
        {name:"Leg 920-1130 mm", artNos:["RAS8114"], diameterMM:100, minHeightMM:920, maxHeightMM:1130},
        {name:"Leg 1060-1270 mm", artNos:["RAS8115"], diameterMM:100, minHeightMM:1060, maxHeightMM:1270},
    ],
    [ModuleRampType.ScrewLeg]: [
        {name:"Screw leg 0-64 mm",  artNos:["RAS8116"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:64},
        {name:"Screw leg 0-84 mm",  artNos:["RAS8118"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:84},
        {name:"Screw leg 0-124 mm", artNos:["RAS8124"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:124},
        {name:"Screw leg 0-164 mm", artNos:["RAS8164"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:164},
        {name:"Screw leg 0-204 mm", artNos:["RAS8204"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:204},
        {name:"Screw leg 0-244 mm", artNos:["RAS8244"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:244},
        {name:"Screw leg 0-284 mm", artNos:["RAS8284"], diameterMM:15, footDiameterMM:100, footHeightMM:10, minHeightMM:0, maxHeightMM:284},
    ],
}

export const childSafePole = {
    centerPoleWidth:20 * scaleRatio,
    centerPoleDepth:20 * scaleRatio,
    centerPoleHeight:900 * scaleRatio,
    subPoleHeight:700 * scaleRatio,
    horizontalPoleHeight:200 * scaleRatio,
    poleSpacing:300 * scaleRatio, // distance between two center poles.
    railingPadding:60 * scaleRatio, // approximately poleSpacing/5
}

export const stairConst = {
    stairStringerGap: 15 * scaleRatio,
    offsetWithRamp: 100 * scaleRatio,
}

export const supportLegConst = {
    margin: 60 * scaleRatio,
}

export const getModuleTemplate = (props:{type:ModuleRampType, id:number, [others: string]: any}):ModuleTemplate => {
    const {
        type,
        id,
        ...otherProps
    } = props;

    const data = {type, ...otherProps, ...store[type][id]};

    switch (type) {
        // case ModuleType.supportLeg:
        //     const { height:heightMM } = otherProps;
        //     return new ModuleTemplate({...data, heightMM});
        default:
            return new ModuleTemplate(data);
    }
}

export const getModule = (moduleTemplate:ModuleTemplate, scene:Scene):ModuleObject =>{
    switch (moduleTemplate.type) {
        case ModuleRampType.StartRamp:
            return new StartingRamp(moduleTemplate, scene);
        case ModuleRampType.Ramp:
            return new Ramp(moduleTemplate, scene);
        case ModuleRampType.BetweenRamp:
            return new BetweenRamp(moduleTemplate, scene);
        case ModuleRampType.TrianglePlatform:
            return new TrianglePlatform(moduleTemplate, scene);
        case ModuleRampType.RampToPlatform:
            return new RampToPlatform(moduleTemplate, scene);
        case ModuleRampType.IndustrialPole:
            return new IndustrialPole(moduleTemplate, scene);
        case ModuleRampType.IndustrialRailing:
            return new IndustrialRailing(moduleTemplate, scene);
        case ModuleRampType.IndustrialRailingPiece:
            return new IndustrialRailingCurvePiece(moduleTemplate, scene);
        case ModuleRampType.ChildSafeSection:
            return new ChildSafeSection(moduleTemplate, scene);
        case ModuleRampType.ChildSafeRailingPiece:
            return new ChildSafeRailingPiece(moduleTemplate, scene);
        case ModuleRampType.ChildSafeRailingPlasticStrip:
            return new ChildSafeRailingPlasticStrip(moduleTemplate, scene);
        case ModuleRampType.SupportLeg:
            return new SupportLeg(moduleTemplate, scene);
        case ModuleRampType.ScrewLeg:
            return new ScrewLeg(moduleTemplate, scene);
        case ModuleRampType.WoodPole:
            return new WoodPole(moduleTemplate, scene);
        case ModuleRampType.KickPlate:
            return new KickPlate(moduleTemplate, scene);
        case ModuleRampType.StairStep:
            return new StairStep(moduleTemplate, scene);
        case ModuleRampType.StairStringer:
            return new StairStringer(moduleTemplate, scene);
    }
}