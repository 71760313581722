import {BomCategory, BomFragmentFragment} from "../../../generated/graphql";
import React, {useCallback, useMemo} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input, Row
} from "reactstrap";
import {camelToTitle, formatToSwedishTime} from "../../../UTIL";
import Picker from "../../../common/Picker";
import gql from "graphql-tag";
import Trash from "../../../common/icons/Trash";
import Box from "../../../common/icons/Box";
import UnBox from "../../../common/icons/UnBox";
import WhiteUncontrolledTooltip from "../../../common/WhiteUncontrolledTooltip";

type OnBomClicked = (bom: BomFragmentFragment) => void

type BomListPanelProps = {
    bomList: BomFragmentFragment[],
    setBomList: any,
    isEditable: boolean,
    onRemoveBomClick: OnBomClicked,
    onCheckedBomClick: OnBomClicked,
    onUnCheckedBomClick: OnBomClicked
    onUpdateBom: OnBomClicked
}

const pickerQuery = gql`
    query($term:String!)
    {
        Inventory_searchArticle(term: $term){
            id:artNo
            name
            category
        }
    }`;

const EmptyBom: BomFragmentFragment = {
    id: '',
    artNo: null,
    name: null,
    quantity: 0,
    unit: null,
    isChecked: false,
    checkedAt: null,
    checkedUser: null
}

const BomRow = ({row,setRow,deleteRow,isEditable, onCheckBoxClicked, onBlur})=>
{
    const isRowEditable = useMemo(()=> {
        return isEditable && !row.isChecked
    }, [isEditable, row.isChecked]);

    const onQuantityChange = useCallback((e) =>
    {
        const value = e.target.value
        setRow({...row, quantity: value ? parseInt(value) : null})
    }, [setRow, row])

    return <Row style={{"margin": "10px 0"}} key={row?.artNo}>
        <Col xs={8}>
            <Picker startValue={row?.artNo?{label:row.artNo +" - " +row.name}:""}
                    types={["stockArticle"]}
                    mapFn={({name, id, category}) => ({
                        value: id,
                        name: name,
                        category: category,
                        artNo: id,
                        label: `${id} - ${name}`
                    })}
                    onChange={(selected) =>
                    {
                        setRow({...row, artNo: selected.artNo, name: selected.name, category: selected.category})
                    }}
                    query={pickerQuery}
                    isDisabled={!isRowEditable}
            />
        </Col>
        <Col xs={2}>
            <Input type={"number"} style={{"width":"100%"}}
                   value={row?.quantity}
                   onChange={onQuantityChange}
                   onBlur={onBlur}
                   disabled={!isRowEditable}/>
            {row?.unit}
        </Col>
        <Col xs={1}>
            <Button color={"danger"} onClick={deleteRow} disabled={!isRowEditable}>
                <Trash/>
            </Button>
        </Col>
        <Col xs={1}>
            <Button id={'bom' +row?.artNo}
                    color={row.isChecked ? "success" : "warning"}
                    disabled={!isEditable}
                    onClick={onCheckBoxClicked}>
                {row.isChecked ? <Box fill={'white'}/> : <UnBox fill={'white'}/>}
            </Button>

            {row.isChecked && <WhiteUncontrolledTooltip trigger="hover" placement="right"
                                                        target={'bom' + row?.artNo}>
                <p style={{margin: 0}}>Date: {formatToSwedishTime(row.checkedAt)}</p>
                <p style={{margin: 0}}>User: {row.checkedUser.displayName}</p>
            </WhiteUncontrolledTooltip>}
        </Col>
    </Row>
}

export const BomListPanel = ({
                                 bomList, setBomList, isEditable, onRemoveBomClick,
                                 onCheckedBomClick, onUnCheckedBomClick, onUpdateBom
                             }: BomListPanelProps) =>
{
    const addArticle = useCallback(() =>
    {
        setBomList(bomList.concat([EmptyBom]));
    }, [setBomList, bomList]);

    const groupedBom = useMemo(() =>
    {
        if (!bomList)
        {
            return null
        }

        const categoryIndexMap = {}
        const groupList: { category: string, items: BomFragmentFragment[] }[] = Object.values(BomCategory)
            .sort()
            .map((e, index) =>
            {
                categoryIndexMap[e] = index
                return {category: e, items: []}
            })
        const noCategoryItems: BomFragmentFragment[] = []

        bomList.forEach((e) =>
        {
            if (e.category && categoryIndexMap[e.category] != null)
            {
                const index = categoryIndexMap[e.category]
                groupList[index].items.push(e)
                return
            }

            noCategoryItems.push(e)
        })

        if (noCategoryItems.length > 0)
        {
            groupList.push({category: "noCategory", items: noCategoryItems})
        }
        return groupList
    }, [bomList])

    const setRow = useCallback((bom) =>
    {
        const newBomList = bomList.filter((e) => e.artNo)
        const index = newBomList.findIndex((e) => e.artNo === bom.artNo)
        if (index !== -1)
        {
            newBomList[index] = bom
        } else
        {
            newBomList.push(bom)
        }

        setBomList(newBomList)
    }, [setBomList, bomList])

    const onCheckBoxClicked = useCallback((bom: BomFragmentFragment) =>
    {
        if (bom.isChecked)
        {
            onUnCheckedBomClick(bom)
            return
        }
        onCheckedBomClick(bom)
    }, [onCheckedBomClick, onUnCheckedBomClick])

    return <div>
        {
            groupedBom && groupedBom.map((e,index) =>
            {
                return <Card style={{margin: "0", borderColor: "transparent"}} key={`bomCategory${index}`}>
                    <CardHeader style={{fontWeight: "bold"}}>
                        {camelToTitle(e.category)}
                        <Badge pill style={{marginLeft: "4px"}}>{e.items.length}</Badge>
                    </CardHeader>
                    <CardBody style={{padding: "0"}}>
                        {
                            e.items.map((bom, index) =>
                            {
                                return <BomRow key={index} row={bom}
                                               setRow={setRow}
                                               deleteRow={() => onRemoveBomClick(bom)}
                                               onCheckBoxClicked={() => onCheckBoxClicked(bom)}
                                               onBlur={()=> onUpdateBom(bom)}
                                               isEditable={isEditable}/>
                            })}
                    </CardBody>
                </Card>
            })
        }
        <div style={{"margin": "10px"}}>
            <Button onClick={addArticle} disabled={!isEditable}>Add</Button>
        </div>
    </div>
}