import React, {useCallback, useEffect, useMemo, useState} from "react";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import {
    Comparator, PackageFragmentFragment,
    useGetPackagesQuery
} from "../../generated/graphql";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {getPackageColumns} from "../../package/PackageListPage";
import {Col, Row} from "reactstrap";
import Collapser from "../../common/Collapser";
import DeliveryInfoCard from "./DeliveryInfoCard";
import {getUniqueOrders} from "../ShipmentUtils";

const TABLE_KEY_PACKAGE = "SELECT_PACKAGE_FOR_SHIPMENT"

type Props = {
    selectedPackages?: PackageFragmentFragment[] | null
    setSelectedPackages: (selectedPackages: PackageFragmentFragment[]) => void
    children
}

const SelectPackagesForShipmentPanel = ({selectedPackages, setSelectedPackages, children}: Props) =>
{
    const [selectedRowIdList, setSelectedRowIdList] = useState<GridRowSelectionModel>(selectedPackages?.map(e => e.id) ?? [])
    const [packages, setPackages] = useState<PackageFragmentFragment[]>(selectedPackages ?? [])

    const {data, loading} = useGetPackagesQuery({
        variables: {
            filtering: {
                items: [
                    {key: "shipmentId", value: "null", comparator: Comparator.Equals}]
            }
        }
    })

    const onRowCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedRowIdList(newRowSelectionModel)
    }, [setSelectedRowIdList])

    const uniqueOrders = useMemo(()=> {
        return getUniqueOrders(selectedPackages)
    }, [selectedPackages])

    useEffect(() =>
    {
        setSelectedPackages(packages.filter((e) => selectedRowIdList.includes(e.id)))
    }, [packages, selectedRowIdList, setSelectedPackages])

    useEffect(() =>
    {
        const result = data?.result?.list
        if (result)
        {
            setPackages((prevState) => [...prevState.concat(...result)])
        }
    }, [data])

    return  <Row>
        <Col xl={8}>
            <NonPaginatedDataGrid
                definition={{
                    tableKey: TABLE_KEY_PACKAGE,
                    columns: getPackageColumns(true),
                    initState: {
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            }
                        }
                    }
                }}
                loading={loading}
                checkboxSelection
                data={packages}
                rowSelectionModel={selectedRowIdList}
                onRowSelectionModelChange={onRowCheckChange}
            />
            <div style={{height: "8px"}}/>
            {children}
        </Col>
        <Col xl={4}>
            <Collapser label={'selected package info'} open>
                <Row>
                    {
                        uniqueOrders.map((e) =>
                        {
                            return <Col md={4} xl={12}>
                                <DeliveryInfoCard order={e}/>
                            </Col>
                        })
                    }
                </Row>
            </Collapser>
        </Col>
    </Row>

}

export default SelectPackagesForShipmentPanel