import {Badge, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Order,
    useGetSpiralStairBomLazyQuery
} from "../../generated/graphql";
import BomInArticleModalBody from "./component/BomWithArticleModalBody";
import {SpiralStairInBomType} from "./SpiralStairInBomType";

type SpiralStairData = (SpiralStairInBomType & { articleId: string, orderId: string })

export default ({order, isOpen, toggle}: {order:Order, isOpen:boolean, toggle:()=>void})=>{
    const initSpiralStairs = useCallback(() =>
    {
        return order.articleRows?.flatMap((e) =>
            {
                const spiralStair = e?.article?.spiralStair
                if (!spiralStair)
                {
                    return null
                }
                return {
                    ...e?.article?.spiralStair,
                    articleRow: e,
                    articleId: e.article?.id,
                    orderId: order.id,
                }
            })?.filter((e) => e != null) as SpiralStairData[]
            ?? []
    }, [order])

    const [spiralStairs, setSpiralStairs] = useState<SpiralStairData[]>(initSpiralStairs())
    const [isEditable] = useState( order.status !== "delivered")
    const [targetIndex, setTargetIndex] = useState(0);
    const [boms, setBoms] = useState<any>()

    const [getBomsQuery , {loading}] = useGetSpiralStairBomLazyQuery()

    const currentItem = useMemo(() =>
    {
        if (spiralStairs.length === 0)
        {
            return null
        }

        return spiralStairs[targetIndex]
    }, [spiralStairs, targetIndex])

    useEffect(() =>
    {
        if (!currentItem || !isOpen)
        {
            return
        }

        const fetch = async () =>
        {
            setBoms(null)
            const {orderId, articleId} = currentItem
            const response = await getBomsQuery({
                variables: {
                    orderId: orderId!,
                    articleId: articleId
                }
            })
            setBoms(response.data?.getSpiralStairBom ?? [])
        }
        fetch().then()
    }, [currentItem, setBoms, getBomsQuery, isOpen])

    return (
        <>
            <Modal size="md" style={{maxWidth:"800px"}} isOpen={isOpen} toggle={toggle} backdrop={false}>
                <ModalHeader toggle={toggle}>
                    Bill of Material {order.number}
                </ModalHeader>
                <ModalBody>
                    {spiralStairs.length === 0 || !currentItem
                        ? <Badge color="info">No spiral stairs</Badge>
                        : <>
                            <ButtonGroup>
                                {
                                    spiralStairs.map((e, index) =>
                                    {
                                        return <Button key={e.id} size="sm"
                                                       outline={targetIndex !== index}
                                                       onClick={() => setTargetIndex(index)}>
                                            Article #{e.articleRow?.article?.artNo}
                                        </Button>
                                    })
                                }
                            </ButtonGroup>
                            {
                                boms == null || loading
                                    ? <Spinner/>
                                    : <BomInArticleModalBody orderId={currentItem.orderId!}
                                                             articleId={currentItem.articleId}
                                                             spiralStair={currentItem}
                                                             boms={boms}
                                                             isEditable={isEditable}
                                                             setSpiralStairs={setSpiralStairs}
                                                             toggle={toggle}/>
                            }
                        </>
                    }
                </ModalBody>
            </Modal>
        </>
    );
}
