const ColumnDirection = (valueGetter?: (params: any) => any) =>
{
    return {
        field: 'direction',
        headerName: 'Direction',
        type: 'singleSelect',
        valueGetter: valueGetter,
        valueOptions: ['left', 'right']
    }
}

export default ColumnDirection