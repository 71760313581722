import {StraightStairHookProps} from "../StraightStairV2Types";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CreateStraightStairExtraMutation,
    StraightStairExtraFragmentFragment,
    useCreateStraightStairExtraMutation,
    useDeleteStraightStairExtraMutation
} from "../../generated/graphql";
import {useCallback} from "react";


const useExtraHook = (props: StraightStairHookProps) =>
{
    const {straightStairV2, setStraightStairV2} = props;

    const {
        executeMutation: createExtraMutation,
        loading: addExtraLoading
    } = useMutationHandler(useCreateStraightStairExtraMutation)

    const {
        executeMutation: deleteExtraMutation,
        loading: deleteExtraLoading
    } = useMutationHandler(useDeleteStraightStairExtraMutation)

    const setStraightStairExtras = useCallback((onUpdate: (newExtras: StraightStairExtraFragmentFragment[]) => StraightStairExtraFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, extras: onUpdate([...prevState.extras])}
        })
    }, [setStraightStairV2])

    const updateExtra = useCallback((extra: StraightStairExtraFragmentFragment) =>
    {
        setStraightStairExtras((newExtras) =>
        {
            const index = newExtras.findIndex(e => e.id === extra.id);
            if (index === -1)
            {
                return newExtras;
            }

            newExtras[index] = extra;
            return newExtras;
        })
    }, [setStraightStairExtras])

    const onCreateExtraSuccess = useCallback((data: CreateStraightStairExtraMutation | null) =>
    {
        const extra = data?.createStraightStairExtra
        if (extra)
        {
            setStraightStairExtras((newExtras) =>
            {
                newExtras.push(extra)
                return newExtras;
            })
        }
    }, [setStraightStairExtras])

    const addExtra = useCallback(async (extra: { name: string, price: number }) =>
    {
        if (!straightStairV2)
        {
            return
        }

        await createExtraMutation({variables: {straightStairV2Id: straightStairV2.id, extra}},
            {onSuccess: onCreateExtraSuccess})
    }, [createExtraMutation, onCreateExtraSuccess, straightStairV2])

    const onDeleteExtraSuccess = useCallback((id: string) =>
    {
        setStraightStairExtras((newExtras) =>
        {
            return newExtras.filter((e) => e.id !== id)
        })
    }, [setStraightStairExtras])

    const deleteExtra = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteExtraMutation({variables: {id: id}},
            {onSuccess: () => onDeleteExtraSuccess(id)})
    }, [straightStairV2, deleteExtraMutation, onDeleteExtraSuccess])

    return {
        addExtraLoading, deleteExtraLoading,
        addExtra, deleteExtra, updateExtra,
    }
}

export default useExtraHook;