import React, {memo, ReactNode, useCallback, useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader, Spinner, Table} from "reactstrap";
import {
    StraightStairItemSummary,
    StraightStairSummaryFragmentFragment,
    useStraightStairV2SummaryLazyQuery
} from "../../generated/graphql";
import {formatCurrency} from "../../UTIL";

type Props = {
    id: string
}

const SummaryTable = memo(({title, children}: { title: string, children: ReactNode }) =>
{
    return <>
        <h4>{title}</h4>
        <Table striped responsive bordered size="sm">
            <thead>
            <tr>
                <th>Item</th>
                <th>Price(EUR)</th>
                <th>Weight</th>
                <th>Quantity</th>
                <th>Total Price(EUR)</th>
                <th>Total Weight</th>
            </tr>
            </thead>
            <tbody>
            {children}
            </tbody>
        </Table>
    </>
})

const SummaryRow = memo(({item, name}: { item: StraightStairItemSummary, name: string }) =>
{
    return <tr style={{textAlign: "right"}}>
        <td style={{textAlign: "left"}}>{name}</td>
        <td>{formatCurrency(item.price)}</td>
        <td>{item.weight}</td>
        <td>{item.quantity}</td>
        <td>{formatCurrency(item.totalPrice)}</td>
        <td>{item.totalWeight}</td>
    </tr>
})

const StraightStairSummaryPanel = memo(({id}: Props) =>
{
    const [summaryQuery, {loading}] = useStraightStairV2SummaryLazyQuery()
    const [isOpen, setIsOpen] = useState(false)
    const [straightStairV2Summary, setStraightStairV2Summary] = useState<StraightStairSummaryFragmentFragment | null | undefined>(null)

    const getSummary = useCallback(async () =>
    {
        const response = await summaryQuery({variables: {id}})
        setStraightStairV2Summary(response?.data?.straightStairV2Summary)
    }, [summaryQuery, id])

    const toggle = useCallback(() =>
    {
        setIsOpen(prevState => !prevState)
    }, [])

    useEffect(() =>
    {
        if (isOpen)
        {
            getSummary()
        } else
        {
            setStraightStairV2Summary(null)
        }
    }, [isOpen, getSummary])

    return <>
        <Button size='sm' onClick={toggle}>Price summary</Button>
        <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
            <ModalHeader toggle={toggle}>Straight stair summary</ModalHeader>
            <ModalBody>
                {loading && <Spinner/>}
                {straightStairV2Summary && <>
                    {straightStairV2Summary.segments.map((segment, index) => (
                        <SummaryTable title={`Segment ${index + 1}`}>
                            <SummaryRow item={segment.stair} name={'Stair'}/>
                            <SummaryRow item={segment.step} name={'Step'}/>
                            <SummaryRow item={segment.contrastMarking} name={'Contrast Marking'}/>
                            <SummaryRow item={segment.extraBoltsForStep} name={'Extra Bolts for Step'}/>
                            <SummaryRow item={segment.platform} name={'Platform'}/>
                            {segment.supports.map((e, index) => (
                                <SummaryRow item={e} name={`Support ${index + 1}`}/>
                            ))}
                        </SummaryTable>
                    ))}

                    {straightStairV2Summary.extraRailings.length > 0 &&
                        <SummaryTable title={`Extra Railings`}>
                            {straightStairV2Summary.extraRailings.map((e, index) => (
                                <SummaryRow item={e} name={`${index + 1}`}/>
                            ))}
                        </SummaryTable>
                    }

                    {straightStairV2Summary.separateKickPlates.length > 0 &&
                        <SummaryTable title={`Separate Kick Plates`}>
                            {straightStairV2Summary.separateKickPlates.map((e, index) => (
                                <SummaryRow item={e} name={`${index + 1}`}/>
                            ))}
                        </SummaryTable>
                    }

                    <div style={{textAlign: "right"}}>
                        <p>Total Price: {formatCurrency(straightStairV2Summary.totalPrice)}</p>
                        <p>Total Weight: {straightStairV2Summary.totalWeight}</p>
                    </div>

                </>
                }
            </ModalBody>
        </Modal>
    </>
})

export default StraightStairSummaryPanel