import React, {memo, useCallback, useMemo, useState} from "react";
import {
    CreateStraightStairSupportMutation, StraightStairSupportFragmentFragment,
    StraightStairSupportMaterialType,
    StraightStairSupportType
} from "../../generated/graphql";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import Pen from "../../common/icons/Pen";
import Trash from "../../common/icons/Trash";
import {enumToOptions} from "../../UTILmore";
import {PATH_STRAIGHT_STAIR_IMAGE} from "../StraightStairConstants";
import NotificationPopup from "../../common/lib/NotificationPopup";

const SupportModal = memo(({support, isOpen, toggle, onUpdateClick}:
                               {
                                   support: StraightStairSupportFragmentFragment,
                                   isOpen: boolean, toggle: () => void
                                   onUpdateClick: (support: StraightStairSupportFragmentFragment) => void
                               }) =>
{
    const [supportData, setSupportData] = useState(support)

    const generateMaterialOptions = useMemo(() =>
    {
        const length = supportData.length

        let list: StraightStairSupportMaterialType[]
        if (length >= 6000)
        {
            list = [StraightStairSupportMaterialType.Material100]
        } else if (length >= 4000)
        {
            list = [StraightStairSupportMaterialType.Material80]
        } else
        {
            list = [StraightStairSupportMaterialType.Material70, StraightStairSupportMaterialType.Material80]
        }

        return list.map((e) =>
        {
            return {id: e, name: e.replace("material", "")}
        })
    }, [supportData.length])

    const [materialOptions, setMaterialOptions] = useState(generateMaterialOptions)

    const onChange = useCallback((key: string, value: string) =>
    {
        setSupportData(prevState => ({...prevState, [key]: value}))
    }, [])

    const onLengthBlur = useCallback(() =>
    {
        const length = supportData.length

        let materialList: StraightStairSupportMaterialType[]
        if (length >= 6000)
        {
            materialList = [StraightStairSupportMaterialType.Material100]
        } else if (length >= 4000)
        {
            materialList = [StraightStairSupportMaterialType.Material80]
        } else
        {
            materialList = [StraightStairSupportMaterialType.Material70, StraightStairSupportMaterialType.Material80]
        }

        const materialOptions = materialList.map((e) =>
        {
            return {id: e, name: e.replace("material", "")}
        })

        setMaterialOptions(materialOptions)

        const foundMaterial = materialList.find((e) => e === supportData.material)
        if (!foundMaterial)
        {
            supportData.material = materialList[0]
            return
        }
    }, [supportData])

    const onUpdate = useCallback(() =>
    {
        const {length, type} = supportData
        if (type === StraightStairSupportType.Console && length >= 2000)
        {
            NotificationPopup.error(`Maximum height of the console: 2000`)
            return
        }

        onUpdateClick(supportData)
        toggle()
    }, [onUpdateClick, supportData, toggle])

    const getSupportImage = useCallback((support: StraightStairSupportFragmentFragment) =>
    {
        let fileName: string
        if (support.adj)
        {
            fileName = 'Suppadj'
        } else
        {
            fileName = "Supports"
        }
        return `${PATH_STRAIGHT_STAIR_IMAGE}/ADJ/${fileName}.JPG`
    }, [])

    const supportFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "type",
                type: "select",
                options: enumToOptions(StraightStairSupportType)
            },
            {
                name: "length", type: "number",
                label: "Height of the support",
                min: 250,
                max: 8000,
                step: 10,
                hint: '(250~8000mm)',
                onCustomBlur: onLengthBlur
            },
            {
                name: "material",
                type: "select",
                options: materialOptions
            },
            {name: "quantity", type: "number",},
            {name: "adj", label: "Adjustable poles", type: "checkbox"},
        ]
    }, [materialOptions, onLengthBlur])

    return <Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
        <ModalHeader isOpen={isOpen} toggle={toggle}>Edit Support</ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <NewFormRenderer
                        columns={2}
                        formDefinition={supportFormDefinition}
                        object={supportData}
                        onChange={onChange}/>
                </Col>
                <Col sm={5}>
                    <img src={getSupportImage(supportData)} alt={"support"} width={"100%"}/>
                </Col>
            </Row>

        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={onUpdate}>Update</Button>
        </ModalFooter>
    </Modal>
})


const SupportRow = memo(({support, onEditClick, onDeleteClick}: {
    support: StraightStairSupportFragmentFragment,
    onEditClick: (id: string) => void, onDeleteClick: (id: string) => void
}) =>
{
    const textAlignRight: any = {textAlign: "right"}

    return <tr key={`support${support.id}`}>
        <td>{support.type}</td>
        <td style={textAlignRight}>{support.length}</td>
        <td>{support.material.replace("material", "")}</td>
        <td style={textAlignRight}>{support.quantity}</td>
        <td>{support.adj ? "V" : "X"}</td>
        <td>
            <span className="link" onClick={() => onEditClick(support.id)}>
                <Pen/>
            </span>
            <span className="link" style={{marginLeft: "12px"}}
                  onClick={() => onDeleteClick(support.id)}>
                <Trash/>
            </span>
        </td>
    </tr>
})

const StraightStairSupportsPanel = memo(({supports, addSupport, deleteSupport, updateSupport}:
                                             {
                                                 supports: StraightStairSupportFragmentFragment[],
                                                 addSupport: () => Promise<CreateStraightStairSupportMutation | null | undefined>,
                                                 deleteSupport: (id: string) => void,
                                                 updateSupport: (support: StraightStairSupportFragmentFragment) => void
                                             }) =>
{
    const [support, setSupport] = useState<StraightStairSupportFragmentFragment | null>(null)

    const toggleSupportModal = useCallback(() => setSupport(null), [])

    const onEditClick = useCallback((id) =>
    {
        const support = supports.find(e => e.id === id)
        if (support)
        {
            setSupport(support)
        }
    }, [supports])

    const handleDeleteClick = useCallback((id: string) =>
    {
        if (window.confirm(`Are you sure you want to delete support?`))
        {
            deleteSupport(id)
        }
    }, [deleteSupport])

    const handleAddClick = useCallback(async () =>
    {
        const response = await addSupport()
        if (response?.createStraightStairSupport)
        {
            setSupport(response.createStraightStairSupport)
        }
    }, [addSupport])

    return <>

        <h3>Supports</h3>

        {
            supports.length > 0
                ? <Table striped responsive bordered>
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Height(mm)</th>
                        <th>Material</th>
                        <th>Quantity</th>
                        <th>ADJ</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        supports.map((support) =>
                        {
                            return <SupportRow key={`support${support.id}`}
                                               support={support}
                                               onEditClick={onEditClick}
                                               onDeleteClick={handleDeleteClick}/>
                        })
                    }
                    </tbody>
                </Table>
                : <p>No supports</p>
        }

        <Button color="primary" style={{marginTop: "4px"}} onClick={handleAddClick}>Add support</Button>

        {support &&
            <SupportModal support={support} isOpen={true} toggle={toggleSupportModal} onUpdateClick={updateSupport}/>}
        <hr/>
    </>
})

export default StraightStairSupportsPanel