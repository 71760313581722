import React from "react";
import {DrawingsOverlay} from "../../../../logistics/SpiralStairsListPage";

const ColumnDrawings = (valueGetter: (params: any) => any) =>
{
    return {
        field: 'drawings',
        headerName: 'Drawings',
        filterable: false,
        sortable: false,
        width: 80,
        valueGetter: valueGetter,
        renderCell: (params) =>
        {
            const value = params.value || []
            return <DrawingsOverlay files={value} stairId={params.id}/>
        }
    }
}
export default ColumnDrawings;