import React, {Component} from 'react';
import {Col, Label, Row} from 'reactstrap';
import _ from 'lodash';
import {labelize} from "../UTIL";
import Collapser from "./Collapser";
import CreateUpdateInfo from "./CreateUpdateInfo";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {InventoryRoute} from "../inventory/InventoryRouters";

class DetailRenderer extends Component {
    render()
    {
        const {object,columns} = this.props;

        const picked = _.pickBy(object, (val, key) => {
            return ["__typename", "createdAt", "updatedAt", "creator"].indexOf(key) === -1; //&& !_.isArray(val);
        })
        const parsedList = Object.entries(picked).map(([key, value]) => ({key: key, value: value}))
        const array = _.chunk(parsedList, columns || 4);

        return (<div>
            {array.map((chunk, chunkKey) =>
                (<Row key={chunkKey}>
                    {chunk.map(field =>
                    {
                        if(/blank/.test(field.key))
                        {
                            return <Col sm={12/(columns || 4)} key={field.key}>
                            </Col>
                        }
                        let value;
                        if(field.value === null){
                            value = "";
                        }
                        else if(_.isBoolean(field.value))
                        {
                            value = field.value?"Yes":"No";
                        }
                        else if(_.isArray(field.value))
                        {
                            value = _.reduce(field.value,(acc,e)=>e.name?`${acc}${e.name}, `:'','');
                            value = value.substring(0,value.length - 2);
                        }
                        else if(typeof(field.value) === 'object')
                        {
                            value = field.value.name || field.value.displayName;
                            if (["company", "contact", "project", "order", 'supplier'].indexOf(field.key) !== -1) {
                                const url = field.value.isInventory ? `${InventoryRoute.INDEX}/` : `/`
                                const to = `${url}${field.key}Detail/${field.value.id}`
                                value = <Link to={`${to}`}>{value}</Link>
                            }
                        }
                        else if(typeof(field.value) === "string" && field.value.indexOf('\n')!==-1)
                        {
                            value = field.value;
                        }
                        else
                        {
                            value = field.value;
                        }

                        const label = field.key === 'project' ? 'Project name' : labelize(field.key)
                        return (
                        <Col sm={12/(columns || 4)} key={field.key}>
                            <Label>{label}</Label>
                            <p style={{ wordWrap:"break-word"}}>{value}</p>
                        </Col>)
                    })}
                </Row>)
            )}
            {!this.props.hideUpdateInfo &&
            <Collapser>
                <CreateUpdateInfo object={this.props.object}/>
            </Collapser>}

            {this.props.extra &&
            <Collapser>
                {this.props.extra}
            </Collapser>}
        </div>);

    }
}
DetailRenderer.propTypes = {
    object:PropTypes.object.isRequired,
    hideUpdateInfo:PropTypes.bool,
    columns:PropTypes.number,
    extra:PropTypes.object
};
export default DetailRenderer;

