import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    ArticleRowFragmentFragment,
    ShipmentFragmentFragment, useCreateVismaInvoiceWithArticleRowsMutation,
    useGetOrderLazyQuery
} from "../../generated/graphql";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import {sumBy} from "lodash";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import CellArticleRow from "../../common/data-grid/cell/CellArticleRow";
import {GridRowSelectionModel} from "@mui/x-data-grid";

const TABLE_KEY = "TABLE_KEY_CREATE_INVOICE"

type Props = {
    toggle: () => void
    shipment: ShipmentFragmentFragment
    orderId: string | null
}

type ArticleRowInTable = {
    id: string
    articleRow: ArticleRowFragmentFragment
    quantity: number
}

const CreateInvoiceInShipmentModal = ({shipment, orderId, toggle}: Props) =>
{
    const [shippingCost, setShippingCost] = useState<number>(0)
    const [packagingCost, setPackagingCost] = useState<number>(0)
    const [data, setData] = useState<ArticleRowInTable[]>([])
    const [selectedArticleRowIdList, setSelectedArticleRowIdList] = useState<GridRowSelectionModel>([])

    const [getOrderQuery, {loading: isOrderLoading}] = useGetOrderLazyQuery()
    const [createInvoiceMutation, {loading}] = useCreateVismaInvoiceWithArticleRowsMutation()

    const handleSubmit = useCallback(async () =>
    {
        if (!orderId)
        {
            return
        }
        try
        {

            await createInvoiceMutation({
                variables: {
                    orderId: orderId,
                    shippingCost: shippingCost,
                    packagingCost: packagingCost,
                    rows: data.filter((e) => selectedArticleRowIdList.includes(e.id))
                        .map((e) => ({articleRowId: e.id, quantity: e.quantity}))
                }
            })
            NotificationPopup.success(`Create invoice success.`)
            toggle();
        } catch (e)
        {
            NotificationPopup.error(`Failed to create invoice. ${e}`)
        }
    }, [createInvoiceMutation, toggle, shippingCost, packagingCost, orderId, data, selectedArticleRowIdList])

    const onShippingCostChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = e.target.value
        setShippingCost(parseInt(value))
    }, []);

    const onPackagingCostChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = e.target.value
        setPackagingCost(parseInt(value))
    }, []);

    const isOpen = useMemo(() =>
    {
        return orderId != null
    }, [orderId])

    const getOrder = useCallback(async () =>
    {
        if (!orderId)
        {
            return
        }
        const response = await getOrderQuery({variables: {id: orderId}})
        const order = response.data?.order

        setPackagingCost(order?.packagingCost ?? 0)
        setShippingCost(order?.shippingCost ?? 0)

        const packageItems = shipment.packages?.flatMap((e) => e.packageItems?.map((i) => i)) ?? []
        const checkedArticleRowIds: string[] = []

        const rows = order?.articleRows?.filter(articleRow => articleRow != null)
            ?.map((e) =>
            {
                const articleRow = e!
                const articleRowId = articleRow.id

                const filteredPackageItems = packageItems.filter((e) => e?.articleRowId === articleRowId)
                const packedQuantity = sumBy(filteredPackageItems, e => e?.quantity ?? 0)
                const quantity = Math.min(packedQuantity, articleRow.quantity ?? 0)

                if (filteredPackageItems.length > 0)
                {
                    checkedArticleRowIds.push(articleRowId)
                }

                return {
                    id: articleRowId,
                    articleRow: articleRow!,
                    quantity: quantity
                }
            })


        setData(rows ?? [])
        setSelectedArticleRowIdList(checkedArticleRowIds)
    }, [orderId, getOrderQuery, shipment]);

    const onRowCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedArticleRowIdList(newRowSelectionModel)
    }, [])


    useEffect(() =>
    {
        getOrder()
    }, [getOrder])

    const onQuantityChanged = useCallback((id: string, e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setData(prevState =>
        {
            const index = prevState.findIndex((e) => e.id === id)
            const value = parseInt(e.target.value)
            if (index === -1)
            {
                return prevState
            }
            prevState[index].quantity = Math.min(value, prevState[index].articleRow.quantity ?? 0)
            return [...prevState]
        })
    }, [])

    const columns = useMemo(() =>
    {
        return [
            {
                headerName: 'Art no',
                field: 'articleRow',
                filterable: false,
                renderCell: (params) =>
                {
                    const articleRow = params.value
                    return <CellArticleRow articleRowId={articleRow.id} article={articleRow.article}
                                           stockArticle={articleRow.stockArticle}/>
                }
            },
            {
                headerName: 'name',
                field: 'articleRow.name',
                filterable: false,
            },
            {
                field: "quantity",
                renderCell: (params) =>
                {
                    const value = params.value
                    const articleRowQuantity = params.row.articleRow.quantity
                    return <span>
                        <input type="number"
                               value={value}
                               min={0}
                               max={articleRowQuantity}
                               style={{width: "60px"}}
                               onChange={(e) => onQuantityChanged(params.id, e)}/>
                            / {articleRowQuantity}
                    </span>
                }
            },
        ]
    }, [onQuantityChanged]);

    return <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Create invoice</ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <label>Shipping Cost</label>
                    <Input
                        type="number"
                        value={shippingCost}
                        onChange={onShippingCostChange}/>
                </Col>
                <Col>
                    <label>Packaging Cost</label>
                    <Input
                        type="number"
                        value={packagingCost}
                        onChange={onPackagingCostChange}/>
                </Col>
            </Row>

            <h5 style={{marginTop: '8px'}}>Article rows</h5>
            <NonPaginatedDataGrid
                checkboxSelection
                disableRowSelectionOnClick
                loading={isOrderLoading}
                definition={{
                    tableKey: TABLE_KEY,
                    pageSize: 100,
                    columns: columns,
                    disableTooBar: true
                }}
                rowSelectionModel={selectedArticleRowIdList}
                onRowSelectionModelChange={onRowCheckChange}
                data={data}/>
        </ModalBody>
        <ModalFooter>
            <Button color="success"
                    disabled={loading}
                    onClick={handleSubmit}>
                Submit {loading && <Spinner/>}
            </Button>
        </ModalFooter>
    </Modal>
}

export default CreateInvoiceInShipmentModal