import React, {useCallback, useEffect, useState} from "react";
import './app.css';
import {Button, Input} from "reactstrap";
import {camelCaseToNormalString} from "./utils";
import {scaleRatio} from "./ModuleTemplate";
import config, {environment} from "../config";
import ArticleListModal from "./ArticleListModal";
import {ModuleRampType} from "../generated/graphql";
import {State} from "./ModuleRampPage";
import {formatCurrency} from "../UTIL";

import {Modes} from "./Modes";

type InfoData = {
    artID: string[];
    name: string;
    type: string;
    [key:string]: any;
};

const fixValue = (value:any) => {
    if(typeof value === 'number'){
        return (Math.round(value * 100) / 100).toFixed(2);
    }
    return value;
}

export default (props) => {
    const {
        rampGlobalData,
        selectedRampData,
        price,
        setPrice,
        deleteModel,
        debugLog,
        onDebugShowAllNodesClick,
    }:{rampGlobalData, selectedRampData?:InfoData, price, setPrice, deleteModel, debugLog, onDebugShowAllNodesClick} = props;

    const [suggestionPrice, setSuggestionPrice] = useState(0);
    const [currency, setCurrency] = useState("");
    const [articleListModalOpen, setArticleListModalOpen] = useState(false);
    const toggleArticleListModalOpen = () => {
        setArticleListModalOpen(!articleListModalOpen);
    }

    const displayData = {...selectedRampData};
    const isDebug = config.environment === environment.LOCAL;

    displayData.type = displayData.type ? ModuleRampType[displayData.type] : null;
    displayData.width = displayData.width != null ? fixValue(displayData.width/scaleRatio)+" mm" : null;
    displayData.height = displayData.height != null ? fixValue(displayData.height/scaleRatio)+" mm" : null;
    displayData.length = displayData.length != null ? fixValue(displayData.length/scaleRatio)+" mm" : null;
    displayData.diameter = displayData.diameter != null ? fixValue(displayData.diameter/scaleRatio)+" mm" : null;
    displayData.slopeDegree = displayData.slopeDegree != null ? fixValue(displayData.slopeDegree)+"°" : null;
    displayData.origin = null; //displayData.origin.toString();

    const _handleHotKeyPress = useCallback((e) => {
        if(e.target.tagName === 'INPUT') return;
        if(e.key.toLowerCase()==='d'){
            deleteModel();
        }
    }, [deleteModel]);

    useEffect(() => {
        let priceSum = 0;
        rampGlobalData.rows.forEach((data) => {
            priceSum += data.price;
        });
        setSuggestionPrice(priceSum);
        setCurrency(rampGlobalData.rows[0]?.currency);
    }, [rampGlobalData, setSuggestionPrice]);

    useEffect(()=>{
        window.addEventListener('keypress', _handleHotKeyPress);
        return ()=>{
            window.removeEventListener('keypress', _handleHotKeyPress);
        }
    }, [_handleHotKeyPress]);

    const {artNos, otherProps, ...regularData} = displayData;
    const displayHeightAsThickness = artNos?.toString().startsWith("RAM") || artNos?.toString().startsWith("RAX");

    return <div className={"infoBox"}>
        <div className={"block title"} >
            Information
        </div>
        <div className={"block"} >
            <table>
                <tbody>
                <tr>
                    <td className={"key"}>Article List</td>
                    <td className={"value"}>
                        <Button size="sm" color="secondary" onClick={() => {
                        toggleArticleListModalOpen();
                        return true;
                    }}>Show Article List
                        </Button>
                        <ArticleListModal isOpen={articleListModalOpen} toggle={toggleArticleListModalOpen} data={rampGlobalData}/>
                    </td>
                </tr>
                <tr>
                    <td className={"key"}>Summarized price</td>
                    <td className={"value"}>
                        {formatCurrency(suggestionPrice)} {currency}
                    </td>
                </tr>
                <tr>
                    <td className={"key"}>Price</td>
                    <td className={"value"}>
                        <Input className="textInput" value={price} onChange={(e)=>{setPrice(e.target.value)}} />
                    </td>
                </tr>
                {isDebug && <tr>
                    <td className={"key"}>Debug</td>
                    <td className={"value"}>
                        <Button color="primary" onClick={onDebugShowAllNodesClick}>
                            Show all nodes
                        </Button>
                        <Button color="primary" onClick={()=>{console.log("mode=", Object.values(Modes)[State.getInstance().mode], State.getInstance().selectedModule )}}>
                            Current editor mode
                        </Button>
                    </td>
                </tr>}
                </tbody>
            </table>
        </div>

        {selectedRampData &&
            <>
                <div className={"block title"} >
                    Selected Module
                </div>
                <div className={"block"} >
                    <table>
                        <tbody>
                        <tr className={"infoRow"}><td className={"key"}>Article ID</td><td className={"value"}>{artNos.join(', ')}</td></tr>
                        {
                            Object.keys(regularData).map((key, index) => {
                                const replacedKeyName = key ==='height' && displayHeightAsThickness ? 'thickness': key;

                                return regularData[key] ? (
                                    <tr key={"infoRow" + index} className={"infoRow"}>
                                        <td className={"key"}>{camelCaseToNormalString(replacedKeyName)}</td>
                                        <td className={"value"}>{fixValue(regularData[key])}</td>
                                    </tr>
                                ) : null
                            })
                        }
                        {otherProps.length>0 ? <tr className={"infoRow"}>
                            <td colSpan={2} className={"key"}>Other Props</td>
                        </tr>:null}
                        {
                            Object.keys(otherProps).map((key, index) =>
                                otherProps[key] ?
                                    <tr key={"infoRow"+index} className={"infoRow"}><td className={"key"}>{camelCaseToNormalString(key)}</td><td className={"value"}>{fixValue(otherProps[key])}</td></tr> :
                                    null
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <div className={"block title"} >
                    Operation
                </div>
                <div className={"block"} >
                    <div className={"infoRow"}>
                        <Button onClick={deleteModel} color="danger">
                            (D)elete
                        </Button>
                    </div>
                </div>
                {isDebug && <>
                    <div className={"block title"} >
                        Local Debug
                    </div>
                    <div className={"block"}>
                        <div className={"infoRow"}><Button color="primary" onClick={debugLog}>
                            Debug log
                        </Button></div>
                    </div>
                </>}
            </>
        }
    </div>;
}
