import React, { useCallback, useState} from 'react';
import {Alert, Table, UncontrolledTooltip} from "reactstrap";
import UploadFile, {CustomerDocCategoryType} from "../../common/UploadFile";
import _, {filter} from 'lodash';
import FileList from "../../common/FileList";
import StdQuery from "../../common/StdQuery";
import GetOrder from '../../order/graphql/GetOrder.graphql';
import TaskFooter from "../../common/task-workflow/TaskFooter";
import getFiles from '../DrawJob/graphql/GetFiles.graphql';
import {Link} from "react-router-dom";
import Paragraph from "../../common/Paragraph";
import {filterArticles, useArticleSelect} from "../DrawJob/util";
import {CommentPanel} from "../DrawJob/CommentComponents";

const orderInfoRender = (order)=>
{
    const {company,project,id} = order;

    return <div>
        <Paragraph><strong>Company: </strong>{company.name}</Paragraph>
        <Paragraph><strong>Project: </strong>{_.get(project,"name")}</Paragraph>
        <Link to={`/orderDetail/${id}`} target="_blank">
            <p style={{fontSize:"16px"}}>Open Order</p>
        </Link>
    </div>
};

export const OrderInfo = ({order,orderId})=>
{
    if(!order)
    {
        return <StdQuery query={GetOrder} variables={{id: orderId}}>
            {({order}) => order ? orderInfoRender(order) : "Failed to find order"
            }
        </StdQuery>
    }
    else
    {
        return orderInfoRender(order);
    }
};

const transformSelectedArticles = (selectedArticles,articles)=>
{
    return _.filter(_.map(selectedArticles,(val,key)=>
    {
        if(val)
        {
            return _.find(articles, ar => ar.id === key);
        }
        else{
            return null;
        }
    }),_.negate(_.isNull));
}


const prepareVariables = (order,selectedArticles,autoArticles, comments)=>
{
    const articles = _.filter(_.map(order.articleRows,ar=>ar.article),_.negate(_.isNull));
    const selectedArticlesMap = transformSelectedArticles(selectedArticles,articles);
    const selectedAutoArticlesMap = transformSelectedArticles(autoArticles,articles);
    return {
        articles:{
            value: JSON.stringify(selectedArticlesMap),
            type: "json",
        },
        autoDrawArticles:{
            value: JSON.stringify(selectedAutoArticlesMap),
            type: "json",
        },
        comments: {
            value: JSON.stringify(comments),
            type: "json"
        }
    };
}



const ArticleSelect = ({onChange,articles,selectedArticles})=>
{
    return <div>
        <Table>
            <thead>
            <tr>
                <th>
                        <span className="link" id="SelectAllTooltip" onClick={()=>
                        {
                            const selectedArticles = {};
                            articles.forEach(article =>{ selectedArticles[article.id]=true});
                            onChange(selectedArticles);
                        }}>
                        Selected
                        </span>
                    <UncontrolledTooltip target='SelectAllTooltip'>Select all articles</UncontrolledTooltip>
                </th>
                <th>Art No</th>
                <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {articles.map(article => <tr key={article.id}>

                <td>
                    <input className='big-checkbox' required={true} type='checkbox'
                           checked={selectedArticles[article.id] || false}
                           onChange={(e)=>
                           {
                               const val = e.target.checked;
                               onChange(sel=>({...sel,[article.id]:val}))
                           }}/>
                </td>
                <td>{article.artNo}</td>
                <td>{article.name}</td>
            </tr>)}
            </tbody>
        </Table>
    </div>
}




export default ({toggle,variables,update,onSubmit})=>
{
    const [autoArticles,setAutoArticles,selectedArticles,setSelectedArticles] = useArticleSelect(variables);
    const [comments, setComments] = useState(variables.comments ?? [])

    const save = useCallback((order)=>
    {
        update(prepareVariables(order, selectedArticles, autoArticles, comments));
    },[selectedArticles,autoArticles,update,comments]);
    const submit = useCallback((order)=>
    {
        onSubmit(prepareVariables(order, selectedArticles, autoArticles, comments));
    },[selectedArticles,autoArticles,onSubmit,comments]);
    return <StdQuery query={GetOrder} variables={{id:variables.orderId}}>
        {data =>
        {
            const order = data.order;
            const articles = order.articleRows
                .map(ar => ({
                    ...ar.article,
                    name: ar.name,
                    index: ar.index
                }))
                .filter(article => article.id && article.artNo)
                .sort((a, b) => a.index - b.index);
            return <div>
                <OrderInfo order={order} />
                <Alert color="warning">
                    <Paragraph>If no articles needs to be drawn, just click submit.</Paragraph>
                    <Paragraph>Do not select the same articles for both categories.</Paragraph>
                </Alert>
                <ol>
                    <li>Select articles that require manual handling</li>
                    <li>Select automatically drawn articles</li>
                    <li>Upload customer documentation for selected articles</li>
                </ol>
                <h3>Articles that require manual handling</h3>
                <ArticleSelect selectedArticles={selectedArticles} articles={filterArticles(autoArticles,articles)}
                               onChange={setSelectedArticles} />
                <h3>Articles that are automatically drawn</h3>
                <ArticleSelect selectedArticles={autoArticles} articles={filterArticles(selectedArticles,articles)}
                               onChange={setAutoArticles} />

                <CommentPanel comments={comments}
                              setComments={(newComments)=> {
                                  setComments(newComments)
                              }}/>
                <hr/>

                <h2>Upload Customer Documentation Files</h2>
                <StdQuery query={getFiles} variables={{orderId:order.id}}>
                    {data =>
                    {
                        const customerDocumentation = filter(data.files,
                        file=>file.fileCategory.id === CustomerDocCategoryType.id);
                        return <React.Fragment>
                            <FileList files={customerDocumentation} onDelete={()=>{}}/>
                            <hr/>
                        </React.Fragment>
                    }
                    }
                </StdQuery>
                <UploadFile orderId={order.id} fileCategory={CustomerDocCategoryType} />
                <TaskFooter toggle={toggle} save={()=>save(order)}
                            submit={()=>submit(order)}/>
            </div>
        }}
    </StdQuery>
}

