import {map} from "lodash";
import Paragraph from "../../../Paragraph";
import React from "react";

export const Radius = (segments) =>
{
    return <div>
        {
            map(segments, (seg) =>
                <Paragraph key={seg.id} style={{marginLeft: "7px"}}>
                    <small>Section {seg.index + 1}:</small>
                    <strong>{seg.radius}</strong></Paragraph>)
        }</div>

}

const ColumnRadius = (valueGetter?: (params: any) => any) =>
{
    return {
        field: 'radius',
        sortable: false,
        filterable: false,
        valueGetter: valueGetter,
        renderCell: (params) =>
        {
            const value = params.value
            return value ? Radius(value) : null
        },
    }
}

export default ColumnRadius;