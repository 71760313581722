import {useCallback, useState} from "react";
import {UpdatePurchaseOrderPriceData} from "./UpdatePurchaseOrderPriceModal";
import NotificationPopup from "../lib/NotificationPopup";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    PriceSuggestionFragmentFragment,
    useUpdateArticleRowMutation
} from "../../generated/graphql";


type Props = {
    priceSuggestion: PriceSuggestionFragmentFragment | null;
    articleId: string;
    purchaseOrder: {
        number?: string | null | undefined
        currency?: string | null | undefined
        articleRows?: {
            id: string
            price?: number | null | undefined
            article?: {
                id?: string | null | undefined
            } | null | undefined
        }[] | null | undefined
    } | null | undefined
};


const useUpdatePurchaseOrderPriceHook = ({purchaseOrder, priceSuggestion, articleId}: Props) =>
{
    const [newPriceModalData, setNewPriceModalData] = useState<UpdatePurchaseOrderPriceData | null>(null)

    const {
        executeMutation: updateArticleRowMutation,
        loading: updateArticleRowLoading
    } = useMutationHandler(useUpdateArticleRowMutation)

    const closeNewPriceModal = useCallback(() =>
    {
        setNewPriceModalData(null)
    }, []);

    const updatePurchaseOrderPrice = useCallback(async () =>
    {
        if (!newPriceModalData || !newPriceModalData.articleRow.id)
        {
            return
        }
        await updateArticleRowMutation({
            variables: {
                id: newPriceModalData.articleRow.id,
                articleRow: {price: newPriceModalData.newPrice}
            },
        }, {
            onSuccess: () =>
            {
                closeNewPriceModal()
                NotificationPopup.success(`Updated Purchase Order`);
            }
        });
    }, [newPriceModalData, updateArticleRowMutation, closeNewPriceModal])

    const checkNewPriceData = useCallback((newPrice?: number) =>
    {
        const newInPrice = newPrice || priceSuggestion?.inPrice;
        if (!purchaseOrder || !newInPrice)
        {
            return
        }

        const formatNewPrice = Math.round(newInPrice * 100) / 100

        const articleRow = purchaseOrder.articleRows?.find((e) => e.article?.id === articleId)
        if (articleRow && articleRow.price !== formatNewPrice)
        {
            setNewPriceModalData({
                articleRow: articleRow,
                newPrice: formatNewPrice,
                purchaseOrder: purchaseOrder,
            })
        }
    }, [articleId, priceSuggestion, purchaseOrder]);

    return {
        newPriceModalData, closeNewPriceModal,
        updatePurchaseOrderPrice, checkNewPriceData,
        updateArticleRowLoading
    }
}

export default useUpdatePurchaseOrderPriceHook