import React, {useMemo, useState} from "react";
import {
    Badge,
    Button,
    Modal,
    PopoverHeader,
    PopoverBody,
    Input,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip, Table
} from "reactstrap";
import {Link} from "react-router-dom";
import {POStatuses, StatusEnum, StatusValueList} from "./Status";
import Create from "./Create";
import {withRouter} from "react-router";
import {InventoryRoute} from "../InventoryRouters";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {GetPurchaseOrdersV2Document} from "../../generated/inventoryGraphql";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";

const KEY_TABLE = "TABLE_INVENTORY_ARTICLE"

const ArticleOverlay = (props) =>
{
    const targetId = `PopoverFocus${props.row.id}`
    const width = 200

    return <div style={{textAlign: "center"}}>
        <span role="img" aria-label="List of articles" id={targetId}>🔎</span>
        <UncontrolledTooltip placement="right" target={targetId} trigger="hover"
                             style={{backgroundColor: "white", color: "black", maxWidth: width, width: width}}>
            <PopoverHeader style={{fontSize: '12px', fontWeight: "bold"}}>
                Articles PO number {props.row.id}</PopoverHeader>
            <PopoverBody style={{padding: 0}}>
                {props.row.articleRows.length > 0 ?
                    <Table bordered striped size="sm">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.row.articleRows.map((row, i) =>
                            {
                                return <tr key={i}>
                                    <td style={{fontSize: '12px', textAlign: "left"}}>{row.name}</td>
                                    <td style={{fontSize: '12px', textAlign: "right"}}>{row.quantity}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                    : <div>No articles found!</div>
                }
            </PopoverBody>
        </UncontrolledTooltip>
    </div>
}

export function PurchaseOrderIdFilter({
                                          column: {filterValue, setFilter},
                                      })
{
    return (
        <Input
            style={{width: '120px'}}
            type={'number'}
            min={0}
            step={1}
            value={filterValue || ''}
            onChange={e =>
            {
                setFilter(parseInt(e.target.value) || undefined);
            }}
            placeholder={`Filter me`}
        />
    )
}

const InventoryPurchaseOrderListPage = (props) =>
{
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(o => !o);

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'idAsString',
                searchable: true,
                renderCell: (params) =>
                {
                    const value = params.value
                    return <div style={{textAlign: "right"}}>
                        <Link to={`${InventoryRoute.PURCHASE_DETAIL}/${value}`}>{value}</Link>
                    </div>;
                }
            },
            {
                field: 'status',
                type: 'singleSelect',
                valueOptions: Object.entries(StatusEnum).map(([key, value]) => ({value: value, label: key})),
                renderCell: (params) =>
                {
                    const value = params.value
                    const v: StatusValueList = POStatuses[value];
                    return <Badge color={v.variant}>{v.label}</Badge>
                }
            },
            {
                field: 'supplier.name',
                headerName: 'Supplier',
                searchable: true,
            },
            ColumnDate("dateOfDispatch", "Date Of Dispatch"),
            {
                field: 'articles',
                filterable: false,
                sortable: false,
                renderCell: (params) =><ArticleOverlay row={params.row}/>
            },
            ColumnCreatedAt(),
            {
                field: 'creator',
            },
        ]
    }, [])

    return <div>
        <Modal isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Create Purchase Order
            </ModalHeader>
            <ModalBody>
                <Create onSubmit={(po) =>
                {
                    toggle();
                    props.history.push(`${InventoryRoute.PURCHASE_DETAIL}/${po.id}`);
                }}/>
            </ModalBody>
        </Modal>
        <Button onClick={() => setOpen(true)} style={{marginBottom: "8px"}}>Create Purchase Order</Button>

        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                initState: {
                    sorting: {sortModel: [{field: 'id', sort: 'desc'}]},
                    columns: {columnVisibilityModel: {id: true}},
                    filter: {
                        filterModel: {
                            items: [{field: 'status', value: "created", operator: 'is'}]
                        }
                    }
                }
            }}
            query={GetPurchaseOrdersV2Document}/>
    </div>
}


export default withRouter(InventoryPurchaseOrderListPage)