import {
    ShipmentFragmentFragment,
    ShipmentStatus,
    useSaveShipmentMutation,
    useCustomerPickupMutation,
    useDeleteShipmentMutation,
    useGetShipmentQuery, useUpdateOrderMutation,
    useUpdateShipmentMutation, useMarkShipmentAsBookedMutation, PackageFragmentFragment
} from "../generated/graphql";
import {
    Button,
    ButtonGroup,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table
} from "reactstrap";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import NotificationPopup from "../common/lib/NotificationPopup";
import PackageDataGrid from "../package/component/PackageNonPaginatedDataGrid";
import BookShipmentResultModal from "./component/BookShipmentResultModal";
import ShipmentStatusBadge from "./component/ShipmentStatusBadge";
import {
    downloadCargoListPDFBySelectedArticleRow,
    downloadShipmentPdf, formatCurrency
} from "../UTIL";
import {ok} from "assert";
import SelectPackagesForShipmentPanel from "./component/SelectPackagesForShipmentPanel";
import DeliveryInfoCard  from "./component/DeliveryInfoCard";
import {IsEconomy} from "../Auth";
import CellArticleRow from "../common/data-grid/cell/CellArticleRow";
import {getUniqueOrders} from "./ShipmentUtils";
import {groupBy, omit, pick, round} from "lodash";
import {shipmentFormDefinitions} from "./model/ShipmentFormDefinitions";
import CreateInvoiceInShipmentModal from "./component/CreateInvoiceInShipmentModal";

const SaveButton = (props) =>
{
    const {shipment, onSaveCompleted} = props
    const [loading, setLoading] = useState(false)
    const [saveShipment] = useSaveShipmentMutation({refetchQueries: ['getShipment']})

    const onSaveClick = useCallback(async () =>
    {
        if (window.confirm(`Are you sure you want to save this shipment?`))
        {
            try
            {
                setLoading(true)
                const response = await saveShipment({variables: {id: shipment.id}})
                onSaveCompleted(response)
            } catch (e)
            {
                NotificationPopup.error(`Save failed. ${e}`)
            }
            setLoading(false)
        }
    }, [shipment.id, onSaveCompleted, saveShipment])

    const shouldShowButton = shipment.status === ShipmentStatus.NotBooked || shipment.status === ShipmentStatus.Failed

    if (!shouldShowButton)
    {
        return null
    }

    return <Button color="success"
                   disabled={loading}
                   onClick={onSaveClick}>Save in NShift
        {loading && <Spinner/>}
    </Button>
}

const CustomerPickupButton = (props) =>
{
    const {shipment} = props
    const [loading, setLoading] = useState(false)
    const [customerPickup] = useCustomerPickupMutation({refetchQueries: ['getShipment']})

    const onBookClick = useCallback(async () =>
    {
        if (window.confirm(`This will not book the shipment in nShift. Are you sure you want to complete this shipment?`))
        {
            try
            {
                setLoading(true)
                await customerPickup({variables: {id: shipment.id}})
            } catch (e)
            {
                NotificationPopup.error(`Update failed. ${e}`)
            }
            setLoading(false)
        }
    }, [shipment.id, customerPickup])

    const shouldShowButton = shipment.status === ShipmentStatus.NotBooked || shipment.status === ShipmentStatus.Failed

    if (!shouldShowButton)
    {
        return null
    }

    return <Button color="secondary"
                   disabled={loading}
                   onClick={onBookClick}>Customer pickup
        {loading && <Spinner/>}
    </Button>
}

const DeleteButton = (props) =>
{
    const {shipment} = props
    const [isLoading, setIsLoading] = useState(false)
    const [deleteShipment] = useDeleteShipmentMutation()

    const onDeleteClick = useCallback(async () =>
    {
        if (window.confirm(`Are you sure you want to delete this shipment?`))
        {
            try
            {
                setIsLoading(true)
                await deleteShipment({variables: {id: shipment.id}})
                props.history.push("/shipments");
            } catch (e)
            {
                NotificationPopup.error(`Delete shipment failed. ${e}`)
            }
            setIsLoading(false)
        }
    }, [shipment.id, props.history, deleteShipment])

    const shouldShowButton = shipment.status === ShipmentStatus.NotBooked
    if (!shouldShowButton)
    {
        return null
    }
    return <Button color="danger"
                   onClick={onDeleteClick}
                   disabled={isLoading}>Delete shipment
        {isLoading && <Spinner/>}
    </Button>
}

const ExportPdfButton = (props) =>
{
    const {shipment} = props
    const [loading, setLoading] = useState(false)

    const shouldShowButton = shipment.status === ShipmentStatus.Booked

    const onDownloadClick = useCallback(async () =>
    {
        setLoading(true)
        await downloadShipmentPdf(shipment.id, shipment.number)
        setLoading(false)
    }, [setLoading, shipment.id, shipment.number])

    if (!shouldShowButton)
    {
        return null
    }

    return <Button color="info"
                   onClick={onDownloadClick}>Export PDF
        {loading && <Spinner color="warning"/>}
    </Button>
}

const validateShipmentsBelongToSameOrder = (shipment: ShipmentFragmentFragment) =>
{
    ok(shipment.packages, `Missing packages`)
    const packageItems = shipment.packages.flatMap((e) => e.packageItems ?? [])

    const articleRows = packageItems.map((e) => ({...e.articleRow, quantity: e.quantity}))
        .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))

    ok(articleRows.length > 0, `Article rows can't be empty`)
    const firstOrder = articleRows[0].order
    ok(firstOrder, `Missing order`)

    const isAllSameOrderId = articleRows.every((e) => e.orderId === firstOrder.id)
    ok(isAllSameOrderId, `Cannot contains different orders`)
    return firstOrder
}

const CargoListModal = ({shipment, isOpen, toggle}) =>
{
    const [selectedArticleRows, setSelectedArticleRows] = useState<any>([]);
    const [articleRowData, setArticleRowData] = useState<any>({});
    const [isDownloading, setIsDownloading] = useState(false)
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (packageItemId) =>
    {
        setSelectedArticleRows((prevSelected) =>
            prevSelected.includes(packageItemId)
                ? prevSelected.filter((id) => id !== packageItemId)
                : [...prevSelected, packageItemId]
        );
    };

    const handleConfirmClick = useCallback(async () =>
    {
        const groupArticleRow = groupBy(selectedArticleRows, (articleRowId) => articleRowData[articleRowId].orderId);

        const request = Object.keys(groupArticleRow).map((orderId) => ({
            orderId,
            articleRows: groupArticleRow[orderId].map((articleRowId) => ({
                id: articleRowId,
                quantity: articleRowData[articleRowId].quantity,
            })),
        }));

        setIsDownloading(true)
        await downloadCargoListPDFBySelectedArticleRow(request, `shipment-${shipment.number}-cargo-list`)
        setIsDownloading(false)
        toggle();
    }, [articleRowData, selectedArticleRows, shipment.number, toggle])

    const handleSelectAllChange = useCallback(() =>
    {
        if (selectAll)
        {
            setSelectedArticleRows([]);
        } else
        {
            setSelectedArticleRows(Object.keys(articleRowData));
        }
        setSelectAll(prevState => !prevState);
    }, [articleRowData, selectAll])

    useEffect(() =>
    {
        const newArticleRowData = {};
        shipment.packages.forEach((e) =>
        {
            e.packageItems.forEach((packageItem) =>
            {
                const articleRowId = packageItem.articleRow.id;

                if (!newArticleRowData[articleRowId])
                {
                    newArticleRowData[articleRowId] = {
                        ...packageItem.articleRow,
                        quantity: 0,
                    };
                }

                const maxQuantity = packageItem.articleRow.quantity
                const quantity = newArticleRowData[articleRowId].quantity += packageItem.quantity * e.quantity

                newArticleRowData[articleRowId].quantity = Math.min(quantity, maxQuantity)
            });
        });

        setArticleRowData(newArticleRowData);
    }, [setArticleRowData, shipment.packages])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader closeButton>Select article row</ModalHeader>
            <ModalBody>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <input
                                    className='big-checkbox'
                                    type="checkbox"
                                    onChange={handleSelectAllChange}
                                    checked={selectAll}
                                />
                            </div>
                        </th>
                        <th>Article no</th>
                        <th>Article Name</th>
                        <th>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>

                    {Object.keys(articleRowData).map((articleRowId) =>
                    {
                        const articleRow = articleRowData[articleRowId];
                        return (
                            <tr key={articleRowId} id={articleRowId}>
                                <td>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <input
                                            className='big-checkbox'
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(articleRowId)}
                                            checked={selectedArticleRows.includes(articleRowId)}/>
                                    </div>
                                </td>
                                <td>
                                    <CellArticleRow articleRowId={articleRow.id} article={articleRow.article}
                                                    stockArticle={articleRow.stockArticle}/>
                                </td>

                                <td>{articleRow.name}</td>
                                <td>{articleRow.quantity}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirmClick} disabled={isDownloading}>
                    Download PDF {isDownloading && <Spinner/>}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

const CargoListButton = (props) =>
{
    const {onClick} = props

    return <Button color="warning"
                   onClick={onClick}>Cargolist PDF</Button>
}

const AssignShippingCostModal = (props) =>
{
    const {isOpen, toggle, shipment, order} = props
    const inputRef = useRef<HTMLInputElement>(null);
    const [updateOrder, {loading}] = useUpdateOrderMutation({refetchQueries: ["getShipment"]})

    const handleSubmit = useCallback(async () =>
    {
        if (!order || !inputRef.current)
        {
            return
        }

        try
        {
            const inputValue = inputRef.current.value;
            await updateOrder({
                variables: {
                    id: order.id,
                    order: {shippingCost: Number(inputValue)}
                }
            })
            NotificationPopup.success(`Update success.`)
            toggle()
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [updateOrder, order, toggle])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Assign shipping cost for order</ModalHeader>
        <ModalBody>
            <Table bordered striped size="sm">
                <tbody>
                <tr>
                    <td>Original shipment cost</td>
                    <td style={{textAlign: 'right'}}>{formatCurrency(shipment.price)}</td>
                </tr>
                <tr>
                    <td>Shipping cost in order</td>
                    <td style={{textAlign: 'right'}}>{order?.shippingCost ? formatCurrency(order.shippingCost) : "None"}</td>
                </tr>
                <tr>
                    <td>Set shipping cost for order</td>
                    <td style={{textAlign: 'right'}}>
                        <input
                            style={{textAlign: 'right'}}
                            type="number"
                            defaultValue={round(shipment.price * 1.3, 2)}
                            ref={inputRef}
                        />
                    </td>
                </tr>
                </tbody>
            </Table>
        </ModalBody>
        <ModalFooter>
            <Button onClick={handleSubmit}>Update
                {loading && <Spinner/>}
            </Button>
        </ModalFooter>
    </Modal>
}

const AssignShippingCostSection = ({shipment}: { shipment: ShipmentFragmentFragment }) =>
{
    const [order, setOrder] = useState<any>()

    const onClick = useCallback(() =>
        {
            try
            {
                const firstOrder = validateShipmentsBelongToSameOrder(shipment)
                setOrder(firstOrder)
            } catch (e)
            {
                NotificationPopup.error(`${e}`)
            }
        },
        [setOrder, shipment])

    const shouldShowButton = shipment.status === ShipmentStatus.Booked && shipment.price

    if (!shouldShowButton)
    {
        return null
    }

    return <>
        <Button color="success" onClick={onClick}>Assign shipping cost</Button>
        <AssignShippingCostModal isOpen={order} toggle={() => setOrder(null)}
                                 order={order} shipment={shipment}/>
    </>
}


const PackagesPanel = (props: { shipment: ShipmentFragmentFragment, onUpdateClicked: () => void }) =>
{
    const {shipment, onUpdateClicked} = props

    const UpdateButton = useCallback(() =>
    {
        if (shipment.status === ShipmentStatus.NotBooked)
        {
            return <Button size={"sm"}
                           style={{margin: "8px 0"}}
                           onClick={onUpdateClicked}>
                Update Packages
            </Button>
        }

        return null
    }, [shipment, onUpdateClicked])

    return <div>
        <h3>Packages</h3>

        <PackageDataGrid packages={shipment.packages ?? []}/>

        <UpdateButton/>
    </div>
}

const EditPackagesPanel = (props: {
    shipment: ShipmentFragmentFragment,
    onUpdateClicked: (selectedPackageIdList: string[]) => void
    onCancelClicked: () => void
}) =>
{
    const {shipment, onUpdateClicked, onCancelClicked} = props
    const [selectedPackages, setSelectedPackages] = useState<PackageFragmentFragment[]>(shipment.packages ?? [])

    const handleOnUpdateClicked = useCallback(() =>
    {
        const selectedPackageIdList = selectedPackages.map((e)=> e.id)
        onUpdateClicked(selectedPackageIdList)
    }, [onUpdateClicked, selectedPackages])

    return <div>
        <h4>Update packages</h4>
        <SelectPackagesForShipmentPanel selectedPackages={selectedPackages}
                                        setSelectedPackages={setSelectedPackages}>
                <Button onClick={onCancelClicked}>Cancel</Button>
                <Button color="success" style={{marginLeft: "8px"}}
                        onClick={handleOnUpdateClicked}>Update</Button>
        </SelectPackagesForShipmentPanel>
    </div>
}

const MarkAsBookSection = (props: { shipment: ShipmentFragmentFragment }) =>
{
    const {shipment} = props
    const [markShipmentAsBookedMutation, {loading}] = useMarkShipmentAsBookedMutation({refetchQueries: ['getShipment']})

    const handleSubmit = useCallback(async () =>
    {
        if (!window.confirm(`Are you sure you want to mark shipment ${shipment.number} as booked?`))
        {
            return
        }
        const response = await markShipmentAsBookedMutation({
            variables: {
                id: shipment.id,
            }
        })
        if (response.data)
        {
            NotificationPopup.success(`Mark success.`)
        } else
        {
            NotificationPopup.error(`Mark fail.`)
        }

    }, [markShipmentAsBookedMutation, shipment.id, shipment.number])

    const shouldShowButton = shipment.status === ShipmentStatus.Saved
    if (!shouldShowButton)
    {
        return null
    }

    return <>
        <Button onClick={handleSubmit}
                color="primary"
                disabled={loading}>
            Mark as booked
            {loading && <Spinner/>}
        </Button>
    </>
}

export default (props) =>
{
    const shipmentId = props.match.params.id

    const [isUpdatingPackage, setIsUpdatingPackage] = useState(false)
    const [isOpenSavedResultModal, setIsOpenSavedResultModal] = useState(false)
    const [savedResult, setSavedResult] = useState<any>()
    const [isEditable, setIsEditable] = useState(false)
    const [createInvoiceOrderId, setCreateInvoiceOrderId] = useState<string | null>(null)
    const [isOpenCargoListModal, setIsOpenCargoListModal] = useState(false)
    const [addressErrorMessage, setAddressErrorMessage] = useState<string | null>(null)

    const {data, loading, error} = useGetShipmentQuery({variables: {id: shipmentId}})
    const [updateShipment] = useUpdateShipmentMutation({refetchQueries: ['getShipment']})

    const onUpdateShipment = useCallback(async ({object}) =>
    {
        if (!data || !data.shipment)
        {
            return
        }

        try
        {
            await updateShipment({
                variables: {
                    id: shipmentId,
                    shipment: omit(object, ["price", "bookedAt", "savedAt"])
                }
            })
            NotificationPopup.success(`Update success.`)
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [updateShipment, shipmentId, data])

    const toggleSavedResultModal = useCallback(() =>
    {
        setIsOpenSavedResultModal(value => !value)
    }, [])

    const toggleCargoList = useCallback(() =>
    {
        setIsOpenCargoListModal(prevState => !prevState)
    }, [setIsOpenCargoListModal])

    const onSaveCompleted = useCallback((response) =>
    {
        setSavedResult(response.data?.saveShipment)
        toggleSavedResultModal()
    }, [setSavedResult, toggleSavedResultModal])

    const onCreateInvoiceClicked = useCallback(() =>
    {
        try
        {
            if (!data?.shipment)
            {
                return
            }
            
            const firstOrder = validateShipmentsBelongToSameOrder(data.shipment)
            setCreateInvoiceOrderId(firstOrder.id)
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [data?.shipment]);

    const formDefinition = useMemo(() =>
    {
        return shipmentFormDefinitions(false)
    }, [])

    const orders = useMemo(() =>
    {
        return getUniqueOrders(data?.shipment?.packages)
    }, [data])

    useEffect(() =>
    {
        if (orders.length === 0)
        {
            setAddressErrorMessage('No delivery info')
            return
        }

        const firstOrder = orders[0];

        const isAddressDifferent = !orders.every(order =>
            order.deliveryTitle === firstOrder.deliveryTitle &&
            order.deliveryStreet === firstOrder.deliveryStreet &&
            order.deliveryPostalCode === firstOrder.deliveryPostalCode &&
            order.deliveryCity === firstOrder.deliveryCity &&
            order.deliveryCountry === firstOrder.deliveryCountry
        );

        if (isAddressDifferent)
        {
            setAddressErrorMessage('Delivery address is different')
            return
        }
        setAddressErrorMessage(null)
    }, [orders])

    useEffect(() =>
    {
        const shipment = data?.shipment
        const editable = shipment != null && (shipment.status === ShipmentStatus.NotBooked || shipment.status === ShipmentStatus.Failed)
        setIsEditable(editable)
    }, [data])

    return <div>
        {loading && <Spinner/>}
        {error && <p>Error: ${error}</p>}

        {data && data.shipment && <div>
            <h1> Shipment {data.shipment.number}</h1>
            <ShipmentStatusBadge status={data.shipment.status}/>
            <Row style={{marginTop: "8px"}}>
                <Col sm={8}>
                    <EditableDetailRenderer
                        formDefinition={formDefinition}
                        object={data.shipment}
                        columns={3}
                        onSubmit={onUpdateShipment}
                        open={false}
                        extra={null}
                        isEditable={isEditable}/>
                </Col>
                <Col sm={3}>
                    <ButtonGroup vertical>
                        <SaveButton shipment={data.shipment} onSaveCompleted={onSaveCompleted}/>
                        <CustomerPickupButton shipment={data.shipment}/>
                        <DeleteButton shipment={data.shipment} history={props.history}/>
                        <ExportPdfButton shipment={data.shipment}/>
                        <IsEconomy altRender={null}>
                            <Button color="primary"
                                    onClick={onCreateInvoiceClicked}>Create invoice</Button>
                        </IsEconomy>
                        <CargoListButton shipment={data.shipment} onClick={toggleCargoList}/>
                        <AssignShippingCostSection shipment={data.shipment}/>
                        <MarkAsBookSection shipment={data.shipment}/>
                    </ButtonGroup>
                </Col>
            </Row>
            <CreateInvoiceInShipmentModal orderId={createInvoiceOrderId}
                                          shipment={data.shipment}
                                          toggle={() => setCreateInvoiceOrderId(null)}/>

            <CargoListModal isOpen={isOpenCargoListModal}
                            toggle={toggleCargoList}
                            shipment={data.shipment}/>

            <Row style={{marginTop: "8px", marginBottom: "8px"}}>
                {orders.map((e) => <Col md={3}>
                    <DeliveryInfoCard order={e}/>
                </Col>)}
            </Row>
            {addressErrorMessage && <p style={{color: "red"}}>{addressErrorMessage}</p>}

            {
                isUpdatingPackage
                    ? <EditPackagesPanel shipment={data.shipment}
                                         onCancelClicked={() => setIsUpdatingPackage(false)}
                                         onUpdateClicked={async (selectedPackages) =>
                                         {
                                             const shipment = pick(data.shipment, formDefinition.map((e) => e.name))
                                             await onUpdateShipment({object: {packageIds: selectedPackages, ...shipment}})
                                             setIsUpdatingPackage(false)
                                         }}/>

                    : <PackagesPanel shipment={data.shipment}
                                     onUpdateClicked={() => setIsUpdatingPackage(true)}/>

            }
        </div>}

        {savedResult && <BookShipmentResultModal toggle={toggleSavedResultModal} isOpen={isOpenSavedResultModal}
                                                 result={JSON.parse(savedResult)}/>}
    </div>
}

