import React from "react";
import './app.css';
import { SelectedModuleType } from "./ModuleSettingBar";

import rampImage from "./image/ramp.jpg";
import railingImage from "./image/railing.jpeg";
import supportLegImage from "./image/supportLeg.jpg";

const ModulePanelList = {
    "Ramp":{
        [SelectedModuleType.ramp]: {label:"Ramp", image: rampImage},
        [SelectedModuleType.trianglePlatform]: {label:"Triangle Platform", image: rampImage},
        [SelectedModuleType.startRamp]: {label:"Start Ramp", image: rampImage},
        [SelectedModuleType.rampToPlatform]: {label:"Ramp to platform", image: rampImage},
    },
    "Kick Plate":{
        [SelectedModuleType.kickPlate]: {label:"Plate", image: railingImage},
    },
    "Industrial Railing":{
        [SelectedModuleType.industrial]: {label:"Railing", image: railingImage},
        [SelectedModuleType.industrialParts]: {label:"Parts", image: railingImage},
    },
    "Child Safe Railing":{
        [SelectedModuleType.childSafe]: {label:"Railing", image: railingImage},
        [SelectedModuleType.childSafeParts]: {label:"Parts", image: railingImage},
    },
    "Stair":{
        [SelectedModuleType.stair]: {label:"Stair", image: railingImage},
    },
    "Wood Railing":{
        [SelectedModuleType.wood]: {label:"Railing", image: railingImage},
    },
    "Support Leg":{
        [SelectedModuleType.supportLeg]: {label:"Leg", image: supportLegImage},
    },

};

export default ({setSelectedModuleType, selectedModuleType}) => {

    return <div className={"modulePanel"}>
        {

            Object.keys(ModulePanelList).map((groupName) => {
                const group = ModulePanelList[groupName];

                return <div className="group">
                    <span className="label">{groupName}</span>
                    {
                        Object.keys(group).map((t, index) => {
                            const type = parseInt(t);
                            const {image, label} = group[type];
                            let className = "button"
                            if(selectedModuleType===type){
                                className += " selected";
                            }

                            return (
                                <div
                                    key={index}
                                    className={className}
                                    onClick={()=>{setSelectedModuleType(type)}}>
                                    <img alt={label} src={process.env.PUBLIC_URL + image} className="icon"/>
                                    {label}
                                </div>
                            )
                        })
                    }
                </div>
            })
        }
    </div>;

}
