type PackageType = {
    name: string,
    id: string
}

export const PackageTypes: PackageType[] = [
    { id: "CS", name: "Box(CS)" },
    { id: "EP", name: "EUR pallet(EP)" },
    { id: "XP", name: "Other pallets(XP)" },
    { id: "PC", name: "Parcel(PC)" },
    { id: "PI", name: "Pipe(PI)" },
    { id: "NE", name: "Unpacked or unpackaged(NE)" }
]
