import {
    getAngleBetweenVectorsByAxisY,
} from "./utils";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {ModuleObjectGroup, Node} from "./ShpaeObject";
import ModuleTemplate, {
    childSafePole,
    getModuleTemplate,
    pairNodeDistance,
    scaleRatio, stairConst,
    store
} from "./ModuleTemplate";
import SceneManager, {SceneName} from "./SceneManager";
import {ModuleRampType} from "../generated/graphql";
import {Tools} from "@babylonjs/core";
import {RampEdge} from "./Module/Ramp";
import {pickStepCountByDistanceToGround, StairStep, StairStringer} from "./Module/Stair";
import {legAutoComplete} from "./AutoCompleteLeg";

export const stairAutoComplete = (selectedModule:any, selectedNode:Node, node:Node, ground):ModuleObjectGroup|null => {
    const { poleSpacing } = childSafePole;
    const { stairStringerGap} = stairConst;
    const stairSet = new ModuleObjectGroup();
    const stairWidth = Vector3.Distance(node.origin, selectedNode.origin) + poleSpacing - stairStringerGap * 2;
    const stairStepId = Object.values(store[ModuleRampType.StairStep]).findIndex(e => {
        return e?.lengthMM ? Math.abs(e.lengthMM * scaleRatio - stairWidth) < pairNodeDistance : false;
    });
    const railingType = selectedModule.otherProps.railing === 'none' ? undefined : selectedModule.otherProps.railing;
    const distanceToGround = node.origin.y- ground.position.y;
    const stepCount = selectedModule.otherProps.step === 'auto' ?
        pickStepCountByDistanceToGround(distanceToGround) :
        selectedModule.otherProps.step;
    const railingVector = node.origin.subtract(selectedNode.origin);
    const isRightToLeft = Math.abs(Tools.ToDegrees(getAngleBetweenVectorsByAxisY(railingVector, (node.parent as RampEdge).vector))) < 90;
    let leftNode:Node, rightNode:Node;
    if(isRightToLeft){
        rightNode = selectedNode;
        leftNode = node;
    }else{
        rightNode = node;
        leftNode = selectedNode;
    }

    const selectedStringer = store[ModuleRampType.StairStringer].filter((stringerData)=>{
        const step = stringerData.lengthMM!*scaleRatio/poleSpacing;
        return stepCount === step && railingType === stringerData.railing;
    });

    const leftStringerData = selectedStringer.find((stringerData)=> stringerData.side === 'left')
    const rightStringerData = selectedStringer.find((stringerData)=> stringerData.side === 'right')
    if(!leftStringerData || !rightStringerData) return null;
    const leftStringerTemplate = new ModuleTemplate({type:ModuleRampType.StairStringer, ...leftStringerData});
    const rightStringerTemplate = new ModuleTemplate({type:ModuleRampType.StairStringer, ...rightStringerData});
    const leftStringer = new StairStringer(leftStringerTemplate, SceneManager.getInstance()[SceneName.main]);
    const rightStringer = new StairStringer(rightStringerTemplate, SceneManager.getInstance()[SceneName.main]);
    leftStringer.connectNode?.attachTo(leftNode);
    rightStringer.connectNode?.attachTo(rightNode);
    stairSet.addModule(leftStringer, rightStringer);
    const stepTemplate = getModuleTemplate({type:ModuleRampType.StairStep, id:stairStepId});
    let lastStep:StairStep|null = null;
    for(let stepIndex = 0; stepIndex < stepCount; stepIndex++){
        const newStep = new StairStep(stepTemplate.clone(), SceneManager.getInstance()[SceneName.main]);
        const stringerRightNode = rightStringer.stepNodes[stepIndex];
        newStep.leftNode?.attachTo(stringerRightNode);
        stairSet.addModule(newStep);
        lastStep = newStep;
    }

    if(!lastStep) return null;
    // attachLeg
    const legModuleTemplate = getModuleTemplate({type:ModuleRampType.ScrewLeg, id:0});
    lastStep.letNodes.forEach(node=> {
        const newLeg = legAutoComplete(legModuleTemplate, node, ground);
        if(newLeg) {
            stairSet.addModule(...newLeg);
        }
    })

    stairSet.overrideChildrenEventBox();
    stairSet.displayData.otherProps = {
        stepCount,
        stairWidth,
    }

    return stairSet;
}