import {
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators
} from "@mui/x-data-grid";

export const FilterOperatorString = getGridStringOperators()
    .filter(
    (operator) =>  {
        return operator.value === 'equals' || operator.value === 'contains' ||
            operator.value === 'startsWith' || operator.value === 'isNotEmpty'
    }
)


export const FilterOperatorStringWithEmpty = getGridStringOperators()
    .filter((operator) =>  operator.value === 'equals' || operator.value === 'contains' || operator.value === 'startsWith' || operator.value === 'isEmpty'
    )

export const FilterOperatorStringEquals = getGridStringOperators().filter(
    (operator) => operator.value === 'equals'
)

export const FilterOperatorSingleSelect = getGridSingleSelectOperators().filter(
    (operator) => operator.value === 'is' || operator.value === 'not'
)

export const FilterOperatorDate = getGridDateOperators().filter(
    (operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore' || operator.value === 'is'
)


export const FilterOperatorNumber = getGridNumericOperators().filter(
    (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>=' || operator.value === '<='
)