import React, {useCallback, useEffect, useRef, useState} from "react";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {
    ActionManager,
    ArcRotateCamera,
    Axis,
    ExecuteCodeAction,
    Mesh,
    MeshBuilder,
    Observable,
    Tools,
} from "@babylonjs/core";
import {AdvancedDynamicTexture, TextBlock} from "@babylonjs/gui";
import * as _ from "lodash";

import ModuleSettingBar from "./ModuleSettingBar";
import MaterialPanel from "./ModulePanel";
import ModuleTemplate, {getModule, getModuleTemplate, pairNodeDistance, scaleRatio} from "./ModuleTemplate";
import {ModuleObject, ModuleObjectGroup, Node} from "./ShpaeObject";
import SceneControlBar from "./SceneControlBar";
import {MaterialName} from "./Materials";
import InfoBox from "./InfoBox";
import {setupEditorMode} from "./EditorMode";
import SceneManager, {SceneName} from "./SceneManager";
import {setPickedModule} from "./utils";

import {
    ModuleRampPart,
    ModuleRampType,
    useGetInventoryRampArticleListQuery,
    useGetModuleRampByArticleRowLazyQuery,
    useUpdateModuleRampMutation, ModuleRampGroup
} from "../generated/graphql";

import './app.css';
import {Col, Row} from "reactstrap";
import LoadingPlaceHolder from "./LoadingPlaceHolder";
import useStepRecordHook from "./hooks/useStepRecordHook";
import useCameraHook, { CameraType } from "./hooks/useCameraHook";
import {Prompt} from "react-router";
import { Modes } from "./Modes";
import {downloadModuleRampPdf} from "../UTIL";
import LoadingView from "../common/LoadingView";
import useUpdateArticleList, {MonoArtNoModuleTemplate} from "./hooks/useUpdateArticleListHook";
import {ArticleListDataType} from "./ArticleListModal";

const createMessageOverlay = (scene, advancedTexture)=>
{
    const textBlock = new TextBlock();
    textBlock.color = "white";
    textBlock.fontSize = 24;
    textBlock.isVisible = false;
    advancedTexture.addControl(textBlock);
    return textBlock;
}


export const CANVAS_ASPECT_RATIO = 3/2

export class State {
    get mode(): Modes
    {
        return this._mode;
    }

    set mode(value: Modes)
    {
        this._mode = value;
        this.modeObservable.notifyObservers(value);
    }
    camera:ArcRotateCamera | undefined;

    models:(ModuleObject|ModuleObjectGroup)[] = [];
    pickedModule: ModuleObject|ModuleObjectGroup|null = null;
    local: string|null = null;
    private static _singleton:State|null = null
    private _mode:Modes = Modes.normal;
    modeObservable:Observable<Modes> = new Observable<Modes>();
    private _selectedModule:ModuleTemplate | null = null;

    setSelectedModule(module:ModuleTemplate|null) {
        const removeMeshes = SceneManager.getInstance()[SceneName.addModule].meshes;
        for(let i=removeMeshes.length-1; i>=0; i--){ // must dispose backward
            removeMeshes[i].dispose();
        }

        this._selectedModule = module;
        switch (module?.type){
            case ModuleRampType.StartRamp:
            case ModuleRampType.TrianglePlatform:
            case ModuleRampType.Ramp:
            case ModuleRampType.RampToPlatform:
                this.mode = Modes.pickRampAttachA;
                break;
            case ModuleRampType.IndustrialPole:
                this.mode = Modes.pickIndustrialPoleAttachA;
                break;
            case ModuleRampType.IndustrialRailing:
                this.mode = Modes.pickIndustrialRailingAttachA;
                break;
            case ModuleRampType.IndustrialRailingPiece:
                this.mode = Modes.pickIndustrialRailingPieceAttachA;
                break;
            case ModuleRampType.ChildSafeSection:
                this.mode = Modes.pickChildSafeAttachA;
                break;
            case ModuleRampType.ChildSafeRailingPiece:
                this.mode = Modes.pickChildSafePieceAttachA;
                break;
            case ModuleRampType.KickPlate:
                this.mode = Modes.pickKickPlateAttachA;
                break;
            case ModuleRampType.StairStep:
                this.mode = Modes.pickStairAttachA;
                break;
            case ModuleRampType.StairStringer:
                this.mode = Modes.pickStairStringerAttachA;
                break;
            case ModuleRampType.SupportLeg:
            case ModuleRampType.ScrewLeg:
                this.mode = Modes.pickSupportLegAttachA;
                break;
            case ModuleRampType.WoodPole:
                this.mode = Modes.pickIndustrialPoleAttachA;
                break;
            default:
                this.mode = Modes.normal;
                // this._selectedModule = null;
                break;
        }
    }

    pickEdge() {
        this._selectedModule = null;
        this.mode = Modes.normal;
    }

    get selectedModule(): ModuleTemplate | null
    {
        return this._selectedModule;
    }
    getMode()
    {
        return this._mode;
    }


    static getInstance()
    {
        if(!this._singleton)
        {
            this._singleton = new State();
        }
        return this._singleton;
    }
}


export let setPickedModuleData: React.Dispatch<any>;


export default ({match})=>
{
    // sideBar
    const [selectedModuleType, setSelectedModuleType] = useState<any>([]);
    const [showNewModuleCanvas, setShowNewModuleCanvas] = useState(false);
    const [shouldShowModuleSettingBar, setShouldShowModuleSettingBar] = useState(true);
    const newModuleCanvasRef = useRef<HTMLCanvasElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { data:rampArticleListData, loading:inventoryRampArticleListQueryLoading } = useGetInventoryRampArticleListQuery();

    const previewRef = useRef(null);
    const [models, setModels] = useState<(ModuleObject|ModuleObjectGroup)[]>([]);
    const [selectedRampData, setSelectedRampData] = useState<any>(undefined);
    const [price, setPrice] = useState<number>(0);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [getModuleRampByArticleRow] = useGetModuleRampByArticleRowLazyQuery();
    const [updateModuleRamp] = useUpdateModuleRampMutation({refetchQueries:["articleRow"]});
    const [isDownloadDrawing, setIsDownloadDrawing] = useState(false)
    setPickedModuleData = setSelectedRampData;

    const [
        articleListForMutation,
        groupListForMutation,
        articleListForGrid,
        updateArticleList
    ] = useUpdateArticleList(rampArticleListData) as [MonoArtNoModuleTemplate[], {moduleIds: number[]}[], ArticleListDataType, () => void];
    const {undo, redo, newStep, canUndo, canRedo, initStep} = useStepRecordHook({setModels})
    const {moveCamera} = useCameraHook()


    const handleUndo = useCallback(() =>
    {
        const result = undo();
        if (result) {
            setUnsavedChanges(true);
            updateArticleList();
        }
    }, [updateArticleList, undo]);

    const handleRedo = useCallback(() =>
    {
        const result = redo();
        if (result) {
            setUnsavedChanges(true);
            updateArticleList();
        }
    }, [updateArticleList, redo]);

    const handleNewStep = useCallback((modules: (ModuleObject|ModuleObjectGroup)[]) =>
    {
        newStep(modules)
        State.getInstance().models = modules;
        setUnsavedChanges(true);
        updateArticleList();
    }, [updateArticleList, newStep]);

    const save = useCallback(async ()=>{
        //TODO: Check if price is changed and already have purchase order, ask to update purchase order price
        await updateModuleRamp({variables:{
                rampId: match.params.id,
                articleRowId: match.params.articleRowId,
                moduleRampParts: articleListForMutation,
                moduleRampGroups: groupListForMutation,
                articleRow:{
                    price
                }
            }
        });
        State.getInstance().mode = Modes.saved;
        setUnsavedChanges(false);
    }, [updateModuleRamp, match.params.id, match.params.articleRowId, price, articleListForMutation, groupListForMutation]);

    const load = useCallback((moduleRampParts:ModuleRampPart[], moduleRampGroups:ModuleRampGroup[])=>{
        State.getInstance().models.forEach(model=>{
            model.remove();
        });

        const models:(ModuleObject|ModuleObjectGroup)[] = [];
        moduleRampParts.forEach( moduleRampPart => {
            const {
                moduleId,
                originX,
                originY,
                originZ,
                length,
                width,
                height,
                diameter,
                type,
                slopeDegree,
                ...otherProps
            } = moduleRampPart;
            const moduleTemplate = new ModuleTemplate({
                origin: new Vector3(originX, originY, originZ),
                type: type as ModuleRampType,
                lengthMM: length/scaleRatio,
                widthMM: width/scaleRatio,
                heightMM: height/scaleRatio,
                diameterMM: diameter/scaleRatio,
                slopeDegree,
                moduleId,
                ..._.omit(otherProps,"__typename", "id")
            });
            ModuleObject.moduleObjectCount = Math.max(moduleId, ModuleObject.moduleObjectCount);

            // Omit invisible parts
            if(moduleTemplate.type === ModuleRampType.BetweenRamp || moduleTemplate.type === ModuleRampType.ChildSafeRailingPlasticStrip) return;

            const newModule = getModule(moduleTemplate, SceneManager.getInstance()[SceneName.main]);

            models.push(newModule);
        });

        ModuleObject.moduleObjectCount += 1;

        // attach nodes
        const allNotAttachedNodes:(Node|null)[] = models.flatMap(m=>m.nodes);
        for(let i=0; i<allNotAttachedNodes.length; i++){
            const node1 = allNotAttachedNodes[i];
            if(!node1) continue;
            for(let j=0; j<allNotAttachedNodes.length; j++){
                const node2 = allNotAttachedNodes[j];
                if(!node2) continue;
                if(node1 === node2) continue;
                if(Vector3.Distance(node1.origin, node2.origin) < pairNodeDistance ){
                    node1.attach.push(node2);
                    node2.attach.push(node1);
                    allNotAttachedNodes[i] = null;
                    allNotAttachedNodes[j] = null;
                }
            }
        }
        State.getInstance().models = models; // update State models for moduleRampGroups init
        moduleRampGroups.forEach( moduleRampGroup => {
            const {
                moduleIds
            } = moduleRampGroup;

            const newModuleRampGroup = new ModuleObjectGroup(moduleIds as number[]);
            models.push(newModuleRampGroup);
        });

        State.getInstance().models = models;
        setModels(models);
        setUnsavedChanges(false);
        updateArticleList();
        initStep(models);
    }, [setModels, updateArticleList, initStep]);

    const deleteModel = useCallback((model:ModuleObject|ModuleObjectGroup|null)=>{
        if(!model) return;
        // const after = State.getInstance().models.filter(m => m!==model);
        model.remove();
        setSelectedRampData(null);
        handleNewStep(State.getInstance().models);
    }, [handleNewStep, setSelectedRampData]);

    // Diff effect
    useEffect(()=>{
        if(State.getInstance().models === models) return;

        const additional = models.filter(m => !State.getInstance().models.includes(m));
        const removal = State.getInstance().models.filter(m => !models.includes(m));

        additional.filter(m=> m instanceof ModuleObject).forEach((model)=>{
            console.log("additional model", model)
            model.init();
        });
        removal.forEach((model)=>{
            model.remove();
        });

        State.getInstance().models = models;
        additional.filter(m=> m instanceof ModuleObjectGroup).forEach((model)=>{
            console.log("additional ModuleObjectGroup", model)
            model.init();
        });

        State.getInstance().models = models;
        updateArticleList();
    }, [models, updateArticleList]);

    const camera3DView = useCallback(()=>{
        setShouldShowModuleSettingBar(true);
        moveCamera(CameraType.threeDimensions)
        if (!previewRef.current)
        {
            return;
        }
        const img: HTMLImageElement = previewRef.current;
        img.style.display = "none";
    }, [moveCamera]);

    const cameraThreeView = useCallback((type:CameraType)=>{
        setShouldShowModuleSettingBar(false);
        moveCamera(type)

        if (!canvasRef.current || !previewRef.current)
        {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const img: HTMLImageElement = previewRef.current;
        img.style.display = "block";
        img.src = canvas.toDataURL("image/png");
    }, [moveCamera]);

    const downloadScreenshot = useCallback(() => {
        const mainScene = SceneManager.getInstance()[SceneName.main];
        mainScene.render();
        mainScene.render();
        if(!canvasRef.current) {return;}

        const canvas:HTMLCanvasElement = canvasRef.current;
        const link = document.createElement('a');
        link.href = canvas.toDataURL("image/png");
        link.download = 'image.png';
        link.click();
    }, []);

    const downloadDrawing = useCallback(async () => {
        if(!previewRef.current) {return;}
        const img:HTMLImageElement = previewRef.current;
        cameraThreeView(CameraType.top);
        const topImageData = img.src;
        camera3DView();
        const articleRowId = match.params.articleRowId
        if (articleRowId)
        {
            const imageBase64 = topImageData.split(',')[1]
            setIsDownloadDrawing(true)
            await downloadModuleRampPdf(articleRowId,'module-ramp', imageBase64)
            setIsDownloadDrawing(false)
        }
    }, [cameraThreeView, camera3DView, match.params.articleRowId]);

    const handleDebugShowAllNodesClick = useCallback(() => {
        State.getInstance().mode = Modes.debugShowAllNodes;
    }, []);

    // init
    useEffect(() => {
        if(inventoryRampArticleListQueryLoading) return;
        const sceneManager = SceneManager.getInstance();
        sceneManager.initScene(SceneName.main, canvasRef);
        sceneManager.initScene(SceneName.addModule, newModuleCanvasRef);

        // store ref for disposing
        const scene1 = sceneManager[SceneName.main];
        const scene2 = sceneManager[SceneName.addModule];

        const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", undefined, sceneManager[SceneName.main]);
        const messageOverlay = createMessageOverlay(sceneManager[SceneName.main], advancedTexture);
        const initModule = getModuleTemplate({type:ModuleRampType.StartRamp, id:0}); //TODO: use selection to pick
        initModule.origin = Vector3.Zero();
        if(initModule){
            const initRamp = getModule(initModule, SceneManager.getInstance()[SceneName.main]);
            setModels(State.getInstance().models.concat(initRamp));
        }else {
            messageOverlay.text = "Cannot initialize because initModule is not valid";
            messageOverlay.isVisible = true;
        }

        const hideInThreeView = new Mesh("hideInThreeView", sceneManager[SceneName.main]);
        // skybox
        const skybox = MeshBuilder.CreateSphere("skyBox", {diameter:10000.0}, sceneManager[SceneName.main]);
        skybox.rotate(Axis.X, Tools.ToRadians(180));
        skybox.material = sceneManager[SceneName.main].materialStore[MaterialName.skyboxMaterial];
        hideInThreeView.addChild(skybox);


        const ground = MeshBuilder.CreateDisc("ground", {radius:5000}, sceneManager[SceneName.main]);
        ground.rotate(Axis.X, Tools.ToRadians(90));
        ground.material = sceneManager[SceneName.main].materialStore[MaterialName.groundMaterial];
        ground.actionManager = new ActionManager(sceneManager[SceneName.main]);
        sceneManager[SceneName.main].highlightLayer?.addExcludedMesh(ground);
        const onMouseClickAction = new ExecuteCodeAction(ActionManager.OnPickTrigger, ()=>{setPickedModule(null)});
        ground.actionManager.registerAction(onMouseClickAction);
        hideInThreeView.addChild(ground);

        // const axisMesh = createAxis(sceneManager[SceneName.main],500);
        // hideInThreeView.addChild(axisMesh);

        setupEditorMode(messageOverlay, handleNewStep, ground, setShowNewModuleCanvas);

        const asyncFn = async () => {
            const moduleRampRes = await getModuleRampByArticleRow({variables:{ id: match.params.articleRowId }});
            const articleRow = {...moduleRampRes.data?.articleRow};
            const moduleRampParts = {...articleRow?.article?.moduleRamp?.moduleRampParts} as any;
            const moduleRampGroups = {...articleRow?.article?.moduleRamp?.moduleRampGroups} as any;
            if(!moduleRampParts) return;
            // const data = moduleRampParts.map((part)=>({
            //     type: part.type,
            //     diameter:0,
            //     height:0,
            //     length:0,
            // }))
            load(Object.values(moduleRampParts), Object.values(moduleRampGroups));
            State.getInstance().local = articleRow?.quote?.company?.locale ?? articleRow?.order?.company?.locale ?? null;
            setPrice(articleRow?.price as number);
        }
        asyncFn().then();

        return ()=>{
            State.getInstance().modeObservable.clear();
            State.getInstance().models.forEach((m)=> {
                if(m instanceof ModuleObject){
                    m.clearAction();
                }
            });
            scene1.dispose();
            scene2.dispose();
        }
    }, [handleNewStep, getModuleRampByArticleRow, match.params.articleRowId, load, inventoryRampArticleListQueryLoading]);

    useEffect(()=>{
        if(!canvasRef.current || !previewRef.current) {return;}

        const canvas:HTMLCanvasElement = canvasRef.current;
        const img:HTMLImageElement = previewRef.current;
        img.width = canvas.width;
        img.height = canvas.height;
        img.style.left = `${canvas.offsetLeft}px`;

    }, []);

    const _handleSetPrice = useCallback((value)=>{
        setUnsavedChanges(true);
        setPrice(parseFloat(value));
    }, [setPrice]);

    const resizeCanvas = useCallback(() =>
    {
        const canvas = canvasRef.current;
        if (canvas)
        {
            const width = window.innerWidth
            canvas.width = width
            canvas.height = width / CANVAS_ASPECT_RATIO
            SceneManager.getInstance()[SceneName.main]?.engine?.resize();
        }
    }, [])

    useEffect(() =>
    {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
        return () =>
        {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [resizeCanvas]);

    const newModuleCanvasClassName = showNewModuleCanvas ? "newModuleCanvas show" : "newModuleCanvas"

    return <div>
        <Prompt when={unsavedChanges} message={`You have unchanged changes, are you sure you want to leave?`} />
        <Row>
            <SceneControlBar
                articleRowId={match.params.articleRowId}
                canUndo={canUndo}
                canRedo={canRedo}
                undo={handleUndo}
                redo={handleRedo}
                save={save}
                unsavedChanges={unsavedChanges}
                camera3DView={camera3DView}
                cameraThreeView={cameraThreeView}
                downloadScreenshot={downloadScreenshot}
                downloadDrawing={downloadDrawing}
            />
        </Row>

        <Row>
            <Col md={2} style={{maxWidth: "130px"}}>
                <div style={{height: "42px"}}/>
                <MaterialPanel
                    selectedModuleType={selectedModuleType}
                    setSelectedModuleType={setSelectedModuleType}
                />
            </Col>
            <Col>
                <Row>
                    <ModuleSettingBar
                        selectedModuleType={selectedModuleType}
                        newStep={handleNewStep}
                        canvasRef={canvasRef}
                        shouldShow={shouldShowModuleSettingBar}
                    />
                </Row>
                <Row>

                        <div className="canvasWrapper">
                            {inventoryRampArticleListQueryLoading ?<LoadingPlaceHolder />:null }
                            <img
                                className="previewImage"
                                ref={previewRef}
                                alt=""/>
                            <canvas
                                style={{width: "100%"}}
                                ref={canvasRef}
                                id="renderCanvas"
                                touch-action="none"
                                onFocus={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    const x = window.scrollX;
                                    const y = window.scrollY;

                                    setTimeout(() => {
                                        window.scrollTo(x, y);
                                    }, 100)
                                }}
                            />
                            <canvas
                                width={300}
                                height={300}
                                ref={newModuleCanvasRef}
                                id="newModuleCanvas"
                                touch-action="none"
                                className={newModuleCanvasClassName}
                            />

                        </div>

                </Row>
            </Col>
            <Col md={2} style={{maxWidth: "200px"}}>
                <>
                    <InfoBox
                        rampGlobalData={articleListForGrid}
                        selectedRampData={selectedRampData}
                        price={price}
                        setPrice={_handleSetPrice}
                        deleteModel={() =>
                        {
                            deleteModel(State.getInstance().pickedModule)
                        }}
                        debugLog={() =>
                        {
                            console.log("pickedModule", State.getInstance().pickedModule)
                        }}
                        onDebugShowAllNodesClick={handleDebugShowAllNodesClick}
                    />
                </>
            </Col>
        </Row>

        {isDownloadDrawing && <LoadingView/>}
    </div>
}

