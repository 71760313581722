import {RefObject} from "react";
import {Engine} from "@babylonjs/core/Engines/engine";
import {Scene} from "@babylonjs/core/scene";
import {ArcRotateCamera, Color3, HemisphericLight, HighlightLayer, Light} from "@babylonjs/core";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import Materials from "./Materials";

export enum SceneName {
    main='main',
    addModule='addModule',
}

export type IntegratedScene = Scene & {
    name:string,
    engine:Engine,
    camera:ArcRotateCamera,
    lights:Light[],
    materialStore:Materials,
    highlightLayer?:HighlightLayer,
}

export default class SceneManager {
    private static instance:SceneManager;
    [SceneName.main]:IntegratedScene
    [SceneName.addModule]:IntegratedScene

    initScene(sceneName:SceneName, canvasRef:RefObject<HTMLCanvasElement>, ){
        console.log(`Start initialize scene: ${sceneName}`)
        if (!Engine.isSupported()) {
            alert('WebGL is not supported on your browser.');
            return;
        }

        const canvasElement:HTMLCanvasElement | null | undefined = canvasRef.current;
        if(!canvasElement) return;

        const stopWheelEventHandler = (e)=>{
            e.preventDefault();
            e.stopPropagation();
        }

        canvasElement.addEventListener('wheel', stopWheelEventHandler, { passive: false });
        const engine = new Engine(canvasElement);
        engine.canvasTabIndex=-1;
        this[sceneName] = new Scene(engine) as IntegratedScene;
        this[sceneName].name = sceneName;
        this[sceneName].engine = engine;
        // this[sceneName].ambientColor = new Color3(1, 1, 1);
        this[sceneName].preventDefaultOnPointerDown = false;
        this[sceneName].preventDefaultOnPointerUp = false;

        this[sceneName].materialStore = new Materials(this[sceneName]);

        const camera = this._newCameraBySceneName(sceneName, canvasElement);
        camera.attachControl(canvasElement, true);
        camera.panningSensibility = 10;
        camera.angularSensibilityX = 1000;
        camera.angularSensibilityY = 1000;
        this[sceneName].camera = camera;
        const lightFront = new HemisphericLight("light", new Vector3(1, 0, 1), this[sceneName]);
        lightFront.specular = new Color3(0.1, 0.1, 0.1);
        this[sceneName].lights.push(lightFront);
        const lightFront2 = new HemisphericLight("light", new Vector3(1, 0, -1), this[sceneName]);
        lightFront2.specular = new Color3(0.2, 0.2, 0.2);
        this[sceneName].lights.push(lightFront2);

        engine.runRenderLoop(() => {
            this[sceneName].render();
        });
    }

    _newCameraBySceneName(sceneName:SceneName, canvasElement):ArcRotateCamera{
        const camera = new ArcRotateCamera("mainCamera", Math.PI/2,Math.PI/2,50,Vector3.Zero(), this[sceneName]);

        switch (sceneName){
            case SceneName.main:
                camera.panningInertia = 0.1;
                camera.panningSensibility = 2;
                camera.orthoBottom = -100;
                camera.orthoTop = 100;
                camera.orthoLeft = -100;
                camera.orthoRight = 100;
                camera.lowerRadiusLimit = 10;
                camera.upperRadiusLimit = 200;
                camera.position = new Vector3(5,50,5);
                camera.setTarget(new Vector3(5,0,5));

                const ratio = canvasElement.height / canvasElement.width;
                const setOrthoCameraTopBottom = (camera, ratio) => {
                    camera.orthoTop = camera.orthoRight * ratio
                    camera.orthoBottom = camera.orthoLeft * ratio
                }

                let oldRadius = camera.radius;
                const observeCamera = () => {
                    if (oldRadius !== camera.radius) {
                        const radiusChangeRatio = camera.radius / oldRadius;
                        (camera.orthoLeft as number) *= radiusChangeRatio;
                        (camera.orthoRight as number) *= radiusChangeRatio;
                        oldRadius = camera.radius;
                        setOrthoCameraTopBottom(camera, ratio);
                    }
                }
                this[sceneName].onBeforeRenderObservable.add(observeCamera);

                // HighlightLayer is for underground visible
                const hl = new HighlightLayer("hl1", this[sceneName]);
                hl.outerGlow = false;
                hl.blurHorizontalSize = 0.1;
                hl.blurVerticalSize = 0.1;
                this[sceneName].highlightLayer = hl;
                break;

            case SceneName.addModule:
                camera.panningInertia = 0.1;
                camera.panningSensibility = 2;
                camera.orthoBottom = -100;
                camera.orthoTop = 100;
                camera.orthoLeft = -100;
                camera.orthoRight = 100;
                camera.lowerRadiusLimit = 10;
                camera.upperRadiusLimit = 200;
                camera.position = new Vector3(5,40,10);
                camera.setTarget(new Vector3(5,0,10));
                break;
        }
        return camera;
    }

    static getInstance():SceneManager{
        if(!SceneManager.instance){
            SceneManager.instance = new SceneManager();
        }
        return SceneManager.instance;
    }

    // Can't dispose by SceneManager because SceneManager's refs lost before page dispose, will cause this[sceneName] undefined
    // static dispose(sceneName:SceneName) {
    //     canvas.removeEventListener('wheel', wheelEventHandler);
    //     ground.actionManager?.unregisterAction(onMouseClickAction);
    //     sceneInstance.onBeforeRenderObservable.remove(observeCamera);
    //
    //     this[sceneName].engine.stopRenderLoop();
    //     this[sceneName].dispose();
    //     this[sceneName].materialStore = null;
    //     // this[sceneName].materialStore.dispose();
    //
    //     SceneManager.instance = null;
    // }
}