import {Node} from "./ShpaeObject";
import {Ramp, RampEdge, StartingRamp, TrianglePlatform} from "./Module/Ramp";
import {IndustrialPole, IndustrialRailing, IndustrialRailingCurvePiece} from "./Module/IndustrialPole";
import {ChildSafeRailingPiece, ChildSafeSection} from "./Module/ChildSafeSection";
import {WoodPole} from "./Module/WoodPole";
import {getAllNodesSameExtendedRampEdge, instanceOf, magnetZero} from "./utils";
import {RampToPlatform} from "./Module/RampToPlatform";
import {KickPlate} from "./Module/KickPlate";
import {StairStep, StairStringer} from "./Module/Stair";
import {ScrewLeg, SupportLeg} from "./Module/SupportLeg";
import {Vector3} from "@babylonjs/core/Maths/math.vector";

const getIsBetweenStairStringer = (node: Node) => {
    const nodesOnEdge = getAllNodesSameExtendedRampEdge(node);
    const nodesAttachedToStringer = nodesOnEdge.filter(node => node.attach.some(attachNode=> attachNode.ancestor instanceof StairStringer));
    const vectorsToStringerNodesMultipleDir = nodesAttachedToStringer.reduce((positiveDir, stringerNode:Node) => {
        const vectorToStringerNode = stringerNode.origin.subtract(node.origin);
        magnetZero(vectorToStringerNode);
        positiveDir = vectorToStringerNode.x === 0 ? positiveDir:positiveDir * vectorToStringerNode.x;
        positiveDir = vectorToStringerNode.y === 0 ? positiveDir:positiveDir * vectorToStringerNode.y;
        positiveDir = vectorToStringerNode.z === 0 ? positiveDir:positiveDir * vectorToStringerNode.z;
        positiveDir = positiveDir > 0 ? 1 : -1;
        return positiveDir;
    }, 1);

    // all stringer vectors distance multiply is negative = is between a pair of stringer
    return vectorsToStringerNodesMultipleDir < 0;
}

export const nodeForRampEdge = (node:Node) => {
    const {slopeDegree} = node.parent as RampEdge;

    if(node.attach.length===0){
        if (Math.abs(slopeDegree) > 0.1)
        {
            return instanceOf(node.ancestor, [IndustrialPole, WoodPole, KickPlate]);
        } else
        {
            return instanceOf(node.ancestor, [Ramp, StartingRamp, TrianglePlatform,
                IndustrialPole, WoodPole, RampToPlatform, KickPlate, StairStringer, ChildSafeSection]);
        }
    }else{
        return false;
    }
}

export const checkIndustrialPoleRailingNodeOccupied = (node:Node):boolean => {
    return node.attach.length > 0;
}

export const nodeForIndustrialRailing = (node:Node):boolean => {
    return node.parent instanceof IndustrialPole &&
        node.parent.railingNode === node &&
        node.parent.railingNode.attach.length<2;
}

export const nodeForIndustrialRailingCurvePiece = (node: Node): boolean => {
    return node.parent instanceof IndustrialPole &&
        node.parent.railingNode === node &&
        node.parent.railingNode.attach.length<2;
}

export const nodeRailingForIndustrialPole = (node:Node):boolean => {
    return node.parent instanceof IndustrialRailing || node.parent instanceof IndustrialRailingCurvePiece;
}

export const nodeOfChildSafeSectionForRailingParts = (node:Node):boolean => {
    return node.parent instanceof ChildSafeSection && node.attach.length===0;
}

export const checkRampEdgeOccupied = ((node:Node) =>
    node.attach.some(attachNode=> {
        return attachNode.ancestor instanceof IndustrialPole ||
            attachNode.ancestor instanceof ChildSafeSection ||
            attachNode.parent instanceof StairStringer ||
            attachNode.parent instanceof RampEdge ||
            attachNode.ancestor instanceof WoodPole
    })
);

export const nodeOfRampForPole = (node:Node):boolean => {
    // check if the node is child of ramp AND not occupied by other pole or ramp
    if (!(node.parent instanceof RampEdge)) return false;

    const isOccupied = checkRampEdgeOccupied(node);
    const isBetweenStairStringer = getIsBetweenStairStringer(node);

    return !isOccupied && !isBetweenStairStringer;
}

export const nodeOfRampForKickPlate = (node:Node):boolean => {
    // check if the node is child of ramp AND not occupied by other pole or ramp
    if (!(node.parent instanceof RampEdge)) return false;

    const isOccupied = node.attach.some(attachNode=> {
        return (
            attachNode.parent instanceof RampEdge ||
            attachNode.parent instanceof StairStringer ||
            attachNode.parent instanceof KickPlate
        );
    });

    return !isOccupied;
}

export const nodeOfRampForStair = (node: Node): boolean => {
    if (!(node.parent instanceof RampEdge)) return false;
    if (Math.abs(node.parent.slopeDegree) > 0) {
        return false;
    }

    const isOccupied = checkRampEdgeOccupied(node);
    const isBetweenStairStringer = getIsBetweenStairStringer(node);

    return !isOccupied && !isBetweenStairStringer;
}

export const AB_SectionOccupationCheck = (
    nodeA: Node,
    getVector: (node:Node) => Vector3,
    nodeParentTypeCheck:(node:Node) => boolean,
    checkOccupied:(node:Node) => boolean,
    testFn:(node:Node) => boolean,
) => {
    const nodesOnVector = getAllNodesSameExtendedRampEdge(nodeA, getVector, nodeParentTypeCheck);

    const occupiedNodes = nodesOnVector.filter((node)=>{
        return checkOccupied(node);
    });

    return (nodeB:Node):boolean => {
        // check if the node is child of ramp AND not occupied by other pole or ramp
        if(!testFn(nodeB) || !nodeParentTypeCheck(nodeB)) {
            return false;
        }
        const isAfterOccupied = occupiedNodes.some(occupiedNode => {
            const v1 = nodeB.origin.subtract(nodeA.origin);
            const v2 = occupiedNode.origin.subtract(nodeA.origin);
            magnetZero(v1);
            magnetZero(v2);
            if(v2.x===0 && v2.y===0 && v2.z===0){
                return false;
            }

            const isSameDir = v1.x*v2.x >= 0 && v1.y*v2.y >= 0 && v1.z*v2.z >= 0;
            return v1.length() > v2.length() && isSameDir;
        })

        return !isAfterOccupied;
    }
}


export const nodeForStairStep = (node:Node) => {
    return node.parent instanceof StairStep;
}

export const nodeForStairStepAndChildSafeSection = (node:Node) => {
    return node.attach.length < 2 && instanceOf(node.ancestor, [Ramp, ChildSafeSection]);
}

export const nodeForStairStepAndIndustrialPole = (node:Node) => {
    return node.attach.length < 2 && instanceOf(node.ancestor, [Ramp, IndustrialPole]);
}

export const nodeOfRampForSupportLeg = (node:Node) => {
    return node.attach.length===0 && instanceOf(node.ancestor, [SupportLeg, ScrewLeg]);
}

export const nodeOfSupportLegForRamp = (node:Node) => {
    return node.attach.length===0 && instanceOf(node.ancestor, [Ramp, StairStep]);
}

export const nodeOfStairStringerForRamp = (node:Node):boolean => {
    const {slopeDegree} = node.parent as RampEdge;

    if(node.attach.length===0 && Math.abs(slopeDegree) === 0){
        return instanceOf(node.ancestor, [Ramp]);
    } else {
        return false;
    }
}

export const nodeOfChildSafeSection = (node: Node) =>
{
    return node.attach.length === 0 && node.parent instanceof ChildSafeRailingPiece;
}