import React, {memo, useCallback, useMemo} from "react";
import {AsnEnabledFunc} from "../StraightStairV2Types";
import {
    PreferredMaterialType, RailingStandardType, RailingType,
    StepType, StraightStairSurfaceTreatmentFragmentFragment, StraightStairV2FragmentFragment,
    StringerMaterial
} from "../../generated/graphql";
import {Alert, Button, Col, Row} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import {maxLoadOptions, preferredMaterialOptions, StairTypeAndRailing} from "../StraightStairOptions";
import {PATH_STRAIGHT_STAIR_IMAGE} from "../StraightStairConstants";
import Select from "../../common/FixRequiredSelect";

type Props = {
    straightStairV2: StraightStairV2FragmentFragment,
    onChange: (key: string, value: string) => void,
    toggleSurfaceTreatmentModal: () => void,
    isAsnEnabled: AsnEnabledFunc,
    surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]
    stringerOptions: StringerMaterial[]
}

const SurfaceTreatmentSummary = ({surfaceTreatments}: {
    surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]
}) =>
{
    const getText = useCallback((isPowderCoating: boolean) =>
    {
        const count = surfaceTreatments.filter((e) => isPowderCoating ? e.isCheckedPC : e.isCheckedPl)
            .length

        const text = isPowderCoating ? "powder coated" : "galvanized"

        if (count === 0)
        {
            return `None ${text}.`
        }

        if (count === surfaceTreatments.length)
        {
            return `All parts ${text}.`
        }

        return `${count} parts ${text}.`
    }, [surfaceTreatments])

    const textStyle = {fontSize: "18px"}
    return <>
        <Alert color="warning" style={{marginTop: "8px", maxWidth: "300px"}}>
            <li style={textStyle}>{getText(false)}</li>
            <li style={textStyle}>{getText(true)}</li>
        </Alert>
    </>
}

const StairTypeAndRailingSelect = ({onChange, model}: { onChange: any, model: any }) =>
{
    const options = useMemo(() =>
    {
        return [
            {id: StairTypeAndRailing.industrial, name: "Industrial"},
            {id: StairTypeAndRailing.childSafeFlatBarSimple, name: "ChildSafe Flat Bar Simple"},
            {id: StairTypeAndRailing.childSafeFlatBarStandard, name: "ChildSafe Flat Bar Standard"},
            {id: StairTypeAndRailing.childSafeRodSimple, name: "ChildSafe Rod Simple"},
            {id: StairTypeAndRailing.childSafeStandardTube, name: "ChildSafe Standard Tube"},
        ]
    }, [])

    const defaultValue = useMemo(() =>
    {
        const railingStandard = model.railingStandard
        let type: StairTypeAndRailing
        if (railingStandard === RailingStandardType.Industrial)
        {
            type = StairTypeAndRailing.industrial
        } else
        {
            const railing: RailingType = model.railingType
            switch (railing)
            {
                case RailingType.PlsSimple:
                    type = StairTypeAndRailing.childSafeFlatBarSimple
                    break;
                case RailingType.PlsStd:
                    type = StairTypeAndRailing.childSafeFlatBarStandard
                    break;
                case RailingType.RodSimple:
                    type = StairTypeAndRailing.childSafeRodSimple
                    break;
                case RailingType.StdTube:
                    type = StairTypeAndRailing.childSafeStandardTube
                    break;
            }
        }

        return options.find((e) => e.id === type)

    }, [model, options])

    const handleOnChange = useCallback((e) =>
    {
        let railingStandardType: RailingStandardType
        let railing: RailingType = RailingType.StdTube
        const id = e.id

        if (id === StairTypeAndRailing.industrial)
        {
            railingStandardType = RailingStandardType.Industrial
            railing = RailingType.StdTube
        } else
        {
            railingStandardType = RailingStandardType.Childsafe
            switch (id)
            {
                case StairTypeAndRailing.childSafeFlatBarSimple:
                    railing = RailingType.PlsSimple
                    break;
                case StairTypeAndRailing.childSafeFlatBarStandard:
                    railing = RailingType.PlsStd
                    break;
                case StairTypeAndRailing.childSafeRodSimple:
                    railing = RailingType.RodSimple
                    break;
                case StairTypeAndRailing.childSafeStandardTube:
                    railing = RailingType.StdTube
                    break;
            }
        }

        onChange({
            railingStandard: railingStandardType,
            railingType: railing,
            extraRailingExecution: railingStandardType,
            extraRailingType: railing
        })
    }, [onChange])

    return <Select
        getOptionLabel={({name}) => name}
        getOptionValue={({id}) => id}
        options={options}
        onChange={handleOnChange}
        defaultValue={defaultValue}/>
}

const StairTabPane = memo(({
                               straightStairV2, onChange,
                               toggleSurfaceTreatmentModal, isAsnEnabled, surfaceTreatments,
                               stringerOptions
                           }: Props) =>
{
    const stepTypeOptions = useMemo(() =>
    {
        return [
            {id: StepType.StandardGrate, name: 'Grating'},
            {id: StepType.Tearplate, name: 'Tear plate'},
            {id: StepType.TightMeshGrate, name: 'Grating - 15x76'},
        ]
    }, [])

    const stairFormDefinition = useMemo(() =>
    {
        const asnEnable = isAsnEnabled(straightStairV2.stepType)
        return [
            {
                name: "stepType",
                type: "select",
                options: stepTypeOptions
            },
            {
                name: "maxLoad",
                type: "select",
                options: maxLoadOptions
            },
            {
                name: "preferredMaterial",
                type: "select",
                options: preferredMaterialOptions,
            },
            {
                name: "stringerMaterial",
                type: "select",
                disabled: straightStairV2.isMinStringerMaterial,
                options: stringerOptions.map((e) => ({id: e, name: `${e.toUpperCase()}`})),
            },
            {name: "description", type: "textarea"},
            {name: "blank", type: "hidden", hideField: (_) => true},
            {
                name: "isMinStringerMaterial",
                label: "Auto min set stringer material",
                type: "checkbox",
            },
            {name: "blank", type: "hidden", hideField: (_) => true},
            {name: "blank", type: "hidden", hideField: (_) => true},
            {name: "kickplate", type: "checkbox"},
            {name: "close", label: "Closed steps", type: "checkbox", disabled: asnEnable},
            {name: "asn", label: 'ASN', type: "checkbox", disabled: !asnEnable},
            {
                name: "stairTypeAndRailing",
                type: 'custom',
                input: (model, onChange) => <StairTypeAndRailingSelect model={model} onChange={onChange}/>
            }
        ]
    }, [straightStairV2, isAsnEnabled, stringerOptions, stepTypeOptions])

    const getPreferredMaterialImage = (preferredMaterial: PreferredMaterialType) =>
    {
        let fileName: string
        switch (preferredMaterial)
        {
            case PreferredMaterialType.Pls:
                fileName = 'ParPLS'
                break;
            case PreferredMaterialType.Upe:
                fileName = 'ParUPE'
                break;
        }
        return `${PATH_STRAIGHT_STAIR_IMAGE}/ADJ/${fileName}.JPG`
    }

    const getStepImage = (stair: StraightStairV2FragmentFragment) =>
    {
        let mat: string
        switch (stair.stepType)
        {
            case StepType.PlateForConcrete:
                mat = "B"
                break;
            case StepType.PrepForCarpet:
            case StepType.PrepForTerrazzo:
            case StepType.PrepForTiles:
            case StepType.PrepForWood:
                mat = "P"
                break;
            case StepType.StandardGrate:
                mat = "G"
                break;
            case StepType.Tearplate:
                mat = "T"
                break;
            case StepType.TightMeshGrate:
                mat = "GT"
                break;
        }

        const asn = stair.asn ? 1 : 0
        const kick = stair.kickplate ? 1 : 0
        return `${PATH_STRAIGHT_STAIR_IMAGE}/Steps/${mat}-${kick}-${asn}.jpg`
    }

    return <>
        <Row>
            <Col sm={9} xl={5}>
                <NewFormRenderer
                    columns={3}
                    formDefinition={stairFormDefinition}
                    object={straightStairV2}
                    onChange={onChange}/>
                <Button color={"primary"} style={{marginTop: "16px"}}
                        onClick={toggleSurfaceTreatmentModal}>
                    Surface treatment
                </Button>
                <SurfaceTreatmentSummary surfaceTreatments={surfaceTreatments}/>
            </Col>

            <Col sm={3} xl={6}>
                <Row>
                    <Col xl={6} style={{marginBottom: "8px"}}>
                        <p style={{margin: 0}}>Step</p>
                        <img src={getStepImage(straightStairV2)} alt="step"
                             style={{width: "100%"}}/>
                    </Col>

                    <Col xl={6}>
                        <p style={{margin: 0}}>Prefered Material</p>
                        <img src={getPreferredMaterialImage(straightStairV2.preferredMaterial)} alt="preferredMaterial"
                             style={{width: "100%"}}/>
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr/>
    </>
})

export default StairTabPane
