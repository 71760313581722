import {GridFilterInputSingleSelect} from "@mui/x-data-grid";
import Dot from "../../icons/Dot";
import Up from "../../icons/Up";
import DoubleUp from "../../icons/DoubleUp";
import React from "react";


const ColumnPurchaseOrderPrioritisation = () =>
{
    return {
        field: 'purchaseOrder.order.prioritisation',
        headerName: 'Prioritisation',
        type: 'singleSelect',
        customFilterOperators: [
            {
                label: 'is',
                value: '=',
                getApplyFilterFn: (filterItem) =>
                {
                    if (!filterItem.field || !filterItem.value || !filterItem.operator)
                    {
                        return null;
                    }

                    return (params) =>
                    {
                        return filterItem.value === params.value.level;
                    };
                },
                InputComponent: GridFilterInputSingleSelect
            },
        ],
        valueOptions: [
            {value: 0, label: 'Normal'},
            {value: 1, label: 'High'},
            {value: 2, label: 'Highest'},
        ],
        renderCell: (params) =>
        {
            const value = params.value
            let sign;
            switch (value.level)
            {
                case 0:
                    sign = <Dot/>;
                    break;
                case 1:
                    sign = <Up/>;
                    break;
                case 2:
                    sign = <DoubleUp/>;
                    break;
                default:
                    break;
            }
            return <>{sign} {value.name}</>
        },
    }
}

export default ColumnPurchaseOrderPrioritisation;