import {State} from "./ModuleRampPage";
import {ModuleObject, Node} from "./ShpaeObject";
import {
    getModule,
    getModuleTemplate,
    scaleRatio,
} from "./ModuleTemplate";
import SceneManager, {SceneName} from "./SceneManager";
import {ModuleRampType} from "../generated/graphql";
import {pickScrewLegModuleByHeight, pickSupportLegModuleByHeight, SupportLeg} from "./Module/SupportLeg";

import {Modes} from "./Modes";

export const legAutoComplete = (selectedModule:any, node:Node, ground):ModuleObject[]|null => {
    const { isCustomHeight, customHeightMM } = selectedModule.otherProps;
    const distanceToGround = isCustomHeight ? customHeightMM*scaleRatio : node.origin.y - ground.position.y;
    const addedModules:ModuleObject[] = [];
    let reselectedModuleContent;
    if(selectedModule.type===ModuleRampType.SupportLeg){
        reselectedModuleContent = pickSupportLegModuleByHeight(distanceToGround/scaleRatio);
    } else {
        reselectedModuleContent = pickScrewLegModuleByHeight(distanceToGround/scaleRatio);
    }

    if(!reselectedModuleContent){
        State.getInstance().mode = Modes.noLegFitsHeight;
        return null;
    }

    reselectedModuleContent.type = selectedModule.type;
    reselectedModuleContent.heightMM = distanceToGround/scaleRatio;
    const reselectedModule = getModuleTemplate(reselectedModuleContent)
    selectedModule = getModule(reselectedModule, SceneManager.getInstance()[SceneName.main]);
    addedModules.push(selectedModule);
    (selectedModule as SupportLeg).topNode?.attachTo(node);

    return addedModules;
}