import SimpleSurfaceTreatmentSubPanel from "../../../../spiralStair/SimpleSurfaceTreatmentSubPanel";
import React from "react";
import {SimpleSurfaceTreatment} from "../../../../generated/graphql";

const ColumnRalColors = (valueGetter?: (params: any) => SimpleSurfaceTreatment[] | null) =>
{
    return {
        field: 'simpleSurfaceTreatments',
        headerName: 'RAL colors',
        valueGetter: valueGetter,
        renderCell: (params) =>
        {
            console.log("params", params)
            const value = params.value
            const glossType = params.row.glossType;
            return value ? <SimpleSurfaceTreatmentSubPanel value={value} glossType={glossType} /> : null
        },
    }
}

export default ColumnRalColors;