import {useQuickCreateStraightStairMutation, useQuickCreateStraightStairV2Mutation} from "../../generated/graphql";
import React, {useCallback} from "react";
import ModalForm from "../ModalForm";
import config, {environment} from "../../config";
import {withRouter} from "react-router";
import {SupplierSelector} from "./CreateArticle";
import {Button} from "reactstrap";

const STRAIGHT_STAIR_PRODUCT_TYPE_ID = "2";

export const CreateStraightStair = withRouter(({orderId, history}) =>
{
    const [createStraightStair] = useQuickCreateStraightStairMutation({refetchQueries: ["quote", "getOrder"]})

    const onSubmit = useCallback(async ({supplierId}) =>
    {
        const variables = {orderId, supplierId}
        const res = await createStraightStair({variables: variables});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStair;
            history.push(`/straightStairDetail/${articleRow?.article?.id}/${articleRow?.id}`);
        }
    }, [orderId, history, createStraightStair]);
    return <ModalForm size="lg" title={"Straight stair"} noFooter activationButtonText={"Straight stair"}
                      buttonProps={{outline: true}}>
        {toggle =>
        {
            return <SupplierSelector productTypeId={STRAIGHT_STAIR_PRODUCT_TYPE_ID} onSubmit={onSubmit} toggle={toggle}
                                     skipSupplier={false}/>
        }}
    </ModalForm>
});

export const QuickCreateStraightStair = withRouter(({quoteId, orderId, history}) =>
{
    const [quickCreate] = useQuickCreateStraightStairMutation({refetchQueries: ["quote", "getOrder"]})
    const onSubmit = useCallback(async ({supplierId}) =>
    {
        const variables = {quoteId, orderId, supplierId}
        const res = await quickCreate({variables: variables});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStair;
            history.push(`/straightStairDetail/${articleRow?.article?.id}/${articleRow?.id}`);
        }
    }, [quoteId, orderId, history, quickCreate]);

    return <ModalForm size="lg" title={"Straight stair"} noFooter activationButtonText={"Straight stair"}
                      buttonProps={{outline: true}}>
        {toggle =>
        {
            return <SupplierSelector productTypeId={STRAIGHT_STAIR_PRODUCT_TYPE_ID} onSubmit={onSubmit} toggle={toggle}
                                     skipSupplier={false}/>
        }}
    </ModalForm>
});


export const CreateStraightStairV2 = withRouter(({quoteId, orderId, history}) =>
{
    const [createStraightStairV2] = useQuickCreateStraightStairV2Mutation({refetchQueries: ["quote", "getOrder"]})

    const onSubmit = useCallback(async () =>
    {
        const res = await createStraightStairV2({variables: {quoteId, orderId}});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStairV2;
            history.push(`/straightStairV2Detail/${articleRow.article?.id}/${articleRow.id}`);
        }
    }, [createStraightStairV2, orderId, quoteId, history]);

    if (config.environment === environment.PRODUCTION)
    {
        return null
    }
    return <Button className={"primary-button-outline"} outline={true} onClick={onSubmit}>
        Straight stair V2
    </Button>;
});