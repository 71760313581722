import {ModuleObject, Node, ShapeObject} from "../ShpaeObject";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import ModuleTemplate, {childSafePole} from "../ModuleTemplate";
import {Axis, CreateBox, Curve3, Mesh, MeshBuilder, Space, Tools} from "@babylonjs/core";
import {RampEdge} from "./Ramp";
import {getAngleBetweenVectorsByAxisY} from "../utils";
import {nodeOfChildSafeSection, nodeOfChildSafeSectionForRailingParts, nodeOfRampForPole} from "../NodeFilterFunctions";
import {MaterialName} from "../Materials";
import {Scene} from "@babylonjs/core/scene";
import {ChildSafeRailingPieceType, ModuleRampType} from "../../generated/graphql";

export class ChildSafeRailingPiece extends ModuleObject {
    vector:Vector3 = Axis.Z.clone();
    startNode:Node|undefined;
    endNode:Node|undefined;
    piece:Mesh|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {length, width, height, slopeDegree, otherProps} = this.moduleTemplate;
        const {pieceType, angle, jointTargetSlopeDegree} = otherProps;
        const angleRadius = Tools.ToRadians(angle);
        switch (pieceType){
            case ChildSafeRailingPieceType.StraightJoint:
                this.piece = CreateBox(`straightJoint-${this.id}`,{width, height, depth:length}, this.scene);
                this.piece.translate(this.origin, 1);
                this.piece.translate(new Vector3(0, 0, length/2), 1, Space.WORLD);
                this.piece.rotate(Axis.X, Tools.ToRadians(-(slopeDegree+jointTargetSlopeDegree)/2 ));
                this.meshes.push(this.piece);

                this.startNode = new Node(this, this.origin.clone(), nodeOfChildSafeSectionForRailingParts);
                this.endNode = new Node(this, this.origin.add(new Vector3(0,0, length)), nodeOfChildSafeSectionForRailingParts);
                this.children.push(this.startNode, this.endNode);
                this.nodes.push(this.startNode, this.endNode);
                break;
            case ChildSafeRailingPieceType.Joint:
                const halfLength = (length)/2+Math.sin(angleRadius)*0.15; // 0.15 is a magic number, however it works
                const endNodePosition = new Vector3(Math.sin(angleRadius)*halfLength, 0, halfLength+Math.cos(angleRadius)*halfLength);
                const shape = [
                    new Vector3(0, 0, 0),
                    new Vector3(width, 0, 0),
                    new Vector3(width, height, 0),
                    new Vector3(0, height, 0),
                ];
                // cubic-bezier(0,.66,.33,1)
                // https://cubic-bezier.com/#0,.66,.33,1
                const jointPath = Curve3.CreateCubicBezier(
                    Vector3.Zero(),
                    new Vector3(0, 0, halfLength*2/3),
                    new Vector3(Math.sin(angleRadius)*halfLength/3, 0, halfLength+Math.cos(angleRadius)*halfLength/3),
                    endNodePosition,
                    20).getPoints();

                this.piece = MeshBuilder.ExtrudeShape("railing-joint", {
                    shape,
                    path: jointPath,
                    closeShape: true,
                    cap: Mesh.CAP_ALL
                }, this.scene);
                this.piece.translate(this.origin, 1);
                this.piece.translate(new Vector3(-width/2, -height / 2, 0), 1);
                this.meshes.push(this.piece);

                this.startNode = new Node(this, this.origin.clone(), nodeOfChildSafeSectionForRailingParts);
                this.endNode = new Node(this, this.origin.add(endNodePosition), nodeOfChildSafeSectionForRailingParts);
                this.children.push(this.startNode, this.endNode);
                this.nodes.push(this.startNode, this.endNode);

                break;

            case ChildSafeRailingPieceType.WallEnd:
                this.piece = CreateBox(`wallEnd-${this.id}`,{width, height, depth:length}, this.scene);
                this.piece.translate(this.origin, 1);
                this.piece.translate(new Vector3(0, 0, length/2), 1, Space.WORLD);
                this.meshes.push(this.piece);
                const wallPiece = CreateBox(`wallEnd-${this.id}`,{width, height, depth:length}, this.scene);
                wallPiece.translate(this.origin, 1);
                wallPiece.rotate(Axis.X, Tools.ToRadians(90));
                wallPiece.translate(new Vector3(0, -length/2, length), 1, Space.WORLD);
                this.meshes.push(wallPiece);

                this.startNode = new Node(this, this.origin.clone(), nodeOfChildSafeSectionForRailingParts);
                this.endNode = new Node(this, this.origin.add(new Vector3(0,0, length)), nodeOfChildSafeSectionForRailingParts);
                this.children.push(this.startNode, this.endNode);
                this.nodes.push(this.startNode, this.endNode);
                break;
            case ChildSafeRailingPieceType.End:
                const endPieceHeight = 3;
                //Shape profile in XY plane
                const crossSection = [
                    new Vector3(0, 0, 0),
                    new Vector3(width, 0, 0),
                    new Vector3(width, height, 0),
                    new Vector3(0, height, 0),
                ];

                const path = Curve3.CreateCubicBezier(
                    Vector3.Zero(),
                    new Vector3(0, 0, endPieceHeight),
                    new Vector3(0, 0, endPieceHeight/2),
                    new Vector3(0, -endPieceHeight, 0),
                    20).getPoints();
                this.piece = MeshBuilder.ExtrudeShape("childSafeRailingEnd", {shape: crossSection, closeShape: true, path, cap: Mesh.CAP_ALL}, this.scene);
                this.piece.translate(this.origin, 1);
                this.piece.translate(new Vector3(-width/2, -height/2, 0), 1, Space.WORLD);
                this.meshes.push(this.piece);

                this.startNode = new Node(this, this.origin.clone(), nodeOfChildSafeSectionForRailingParts);
                this.children.push(this.startNode);
                this.nodes.push(this.startNode);

                this.rotate(Axis.X, slopeDegree);
                break;

        }

        this.material = this.scene.materialStore[MaterialName.blackMaterial];
    }

    attachTo(attachNode, fromNode){
        const childSafeSection = attachNode.parent as ChildSafeSection;
        if(fromNode.parent instanceof ChildSafeRailingPiece){
            let angle = childSafeSection.moduleTemplate.rotationY;
            if(attachNode === childSafeSection.startNode){
                angle += 180;
            }
            this.rotate(Axis.Y, angle, attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new ChildSafeRailingPiece(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }

}

export class ChildSafePole extends ShapeObject {
    bottomNode:Node|undefined;
    railingNode:Node|undefined;

    constructor(
        parent:ChildSafeSection,
        origin:Vector3 = Vector3.Zero(),
    )
    {
        super(parent, origin);

        const {
            centerPoleWidth:poleWidth,
            centerPoleDepth:poleDepth,
            centerPoleHeight,
            subPoleHeight,
            horizontalPoleHeight,
        } = childSafePole;

        const centerPole = CreateBox(`mesh-centerPole-${this.id}`,{width:poleWidth, height:centerPoleHeight, depth:poleDepth}, this.scene);
        centerPole.translate(this.origin, 1);
        centerPole.translate(Axis.Y, centerPoleHeight/2);
        // centerPole.material = Materials.metalMaterial;
        this.meshes.push(centerPole);

        const leftSubPole = CreateBox(`mesh-leftSubPole-${this.id}`,{width:poleWidth, height:subPoleHeight, depth:poleDepth}, this.scene);
        leftSubPole.translate(this.origin, 1);
        leftSubPole.translate(Axis.Y, centerPoleHeight/2);
        leftSubPole.translate(Axis.Z, horizontalPoleHeight/2 - poleWidth/2);
        // leftSubPole.material = Materials.metalMaterial;
        this.meshes.push(leftSubPole);

        const rightSubPole = CreateBox(`mesh-rightSubPole-${this.id}`,{width:poleWidth, height:subPoleHeight, depth:poleDepth}, this.scene);
        rightSubPole.translate(this.origin, 1);
        rightSubPole.translate(Axis.Y, centerPoleHeight/2);
        rightSubPole.translate(Axis.Z, -(horizontalPoleHeight/2 - poleWidth/2));
        // rightSubPole.material = Materials.metalMaterial;
        this.meshes.push(rightSubPole);

        const topHoriPole = CreateBox(`mesh-topHoriPole-${this.id}`,{width:poleWidth, height:poleDepth, depth:horizontalPoleHeight}, this.scene);
        topHoriPole.translate(this.origin, 1);
        topHoriPole.translate(Axis.Y, (poleWidth+centerPoleHeight-subPoleHeight)/2+subPoleHeight);
        // topHoriPole.material = Materials.metalMaterial;
        this.meshes.push(topHoriPole);

        const bottomHoriPole = CreateBox(`mesh-bottomHoriPole-${this.id}`,{width:poleWidth, height:poleDepth, depth:horizontalPoleHeight}, this.scene);
        bottomHoriPole.translate(this.origin, 1);
        bottomHoriPole.translate(Axis.Y, (poleWidth+centerPoleHeight-subPoleHeight)/2);
        // bottomHoriPole.material = Materials.metalMaterial;
        this.meshes.push(bottomHoriPole);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        this.vector = new Vector3(0,centerPoleHeight,0);

        this.bottomNode = new Node(this, this.origin.clone().add(new Vector3(-poleWidth/2, 0, 0)), nodeOfRampForPole);
        // this.bottomNode.nodeAttachableTypes = [NodeAttachableType.ramp];
        // this.railingNode = new Node(this, this.origin.clone().add(this.vector));
        // this.railingNode.nodeAttachableTypes = [NodeAttachableType.railing];
        // this.children.push(this.bottomNode, this.railingNode);
        this.children.push(this.bottomNode);
        this.nodes.push(this.bottomNode);
    }


    clone(parent){
        return new ChildSafePole(parent, this.origin.clone());
    }
}

export class ChildSafeSection extends ModuleObject {
    startNode:Node|undefined;
    endNode:Node|undefined;
    poleNodes:Node[] = [];

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {length, width, height, slopeDegree} = this.moduleTemplate;
        const {poleSpacing, centerPoleHeight, railingPadding} = childSafePole;
        const poleNum = Math.floor(length/poleSpacing);
        this.vector = new Vector3(0,0, length);

        this.startNode = new Node(this, this.origin.clone().add(new Vector3(0,0,-poleSpacing/2+railingPadding)), nodeOfChildSafeSection);
        this.endNode = new Node(this, this.origin.clone().add(new Vector3(0,0,length-poleSpacing/2-railingPadding)), nodeOfChildSafeSection);
        this.nodes.push(this.startNode, this.endNode);
        this.children.push(this.startNode, this.endNode);

        const railing = CreateBox(`railing-${this.id}`,{width, height, depth:length-railingPadding*2}, this.scene);
        railing.translate(this.origin, 1);
        this.material = this.scene.materialStore[MaterialName.blackMaterial];
        this.meshes.push(railing);
        railing.translate(new Vector3(0, 0,(length-poleSpacing)/2), 1);
        this.rotate(Axis.X, slopeDegree);

        this.startNode.move(new Vector3(0, centerPoleHeight,0));
        this.endNode.move(new Vector3(0, centerPoleHeight,0));
        railing.translate(new Vector3(0, centerPoleHeight,0), 1, Space.WORLD);//translate railing after rotate

        for(let poleIndex = 0; poleIndex<poleNum; poleIndex++){
            const steppingLength = poleSpacing*poleIndex/length;
            const steppingVector = this.vector.multiply(new Vector3(steppingLength, steppingLength, steppingLength));
            const poleOrigin = this.origin.add(steppingVector);
            const newPole = new ChildSafePole(this, poleOrigin);
            this.children.push(newPole);
            this.poleNodes.push(newPole.bottomNode as Node);
            this.nodes.push(newPole.bottomNode as Node);
        }
    }

    attachTo(attachNode, fromNode){
        const rampEdge = attachNode.parent;
        if(rampEdge instanceof RampEdge && fromNode.parent instanceof ChildSafePole){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.parent.vector.negate(), rampEdge.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
            this.vector = rampEdge.vector;
        }
    }

    clone(scene:Scene){
        const cloned = new ChildSafeSection(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }

}

export class ChildSafeRailingPlasticStrip extends ModuleObject {

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.type = ModuleRampType.ChildSafeRailingPlasticStrip;
        this.init();
    }

    // This Module doesn't display in viewport. Only calculate when updateCSV_Data()
    build(){}
}