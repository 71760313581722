import React, {memo} from "react";
import {Badge, Button, Card, CardBody, CardTitle} from "reactstrap";
import {ImageFragmentFragment} from "../../generated/graphql";

type Props = {
    image: ImageFragmentFragment
    handleDownload: (image: ImageFragmentFragment) => void
    setOpenImage: (image: ImageFragmentFragment) => void
}

const ImageCard = memo(({image, handleDownload, setOpenImage}: Props) =>
{
    return <Card>
        <img
            alt={image.title}
            src={`${image.url}?width=600&height=900`}
            style={{objectFit: 'cover', cursor: 'pointer', aspectRatio: '2/3'}}
            onClick={() => setOpenImage(image)}/>
        <CardBody>
            <CardTitle tag="h6">
                {image.title}
            </CardTitle>
            {
                image.tags.map((e) => <Badge key={`image-tag-${e.id}`}
                                             style={{margin: '0 2px'}}>
                    {e.name}
                </Badge>)
            }
            <div style={{marginTop: '8px'}}>
                <Button size='sm'
                        onClick={() => handleDownload(image)}>
                    Download
                </Button>
            </div>
        </CardBody>
    </Card>
})

export default ImageCard;