import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import { graphql,withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import { flowRight as compose } from 'lodash';
import GetPurchaseOrder from './graphql/GetPurchaseOrder.graphql';
import DetailRenderer from "../common/DetailRenderer";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import PurchaseOrderFormDef from "./PurchaseOrderFormDef";
import ArticleRows from "../common/ArticleRows";
import CreateArticleRow from "../common/CreateArticleRow";
import UpdatePurchaseOrderMutation from './graphql/UpdatePurchaseOrder.graphql';
import RemovePurchaseOrderMutation from './graphql/RemovePurchaseOrder.graphql';
import WorkflowDetail from "../common/WorkflowDetail";
import BPMDiagram from "../common/BPMDiagram";
import Collapser from "../common/Collapser";
import PdfModal from "../order/PdfModal";
import Paragraph from "../common/Paragraph";
import { formatCurrency, poTotal } from "../UTIL";
import CommentList from "../common/comment/CommentList";
import CreateFreeTextRow from "../common/createArticle/CreateFreeTextRow";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import {isInventoryPath} from "../inventory/InventoryRouters";
import NotificationPopup from "../common/lib/NotificationPopup";

export const OmittedFields = ['id', 'articleRows', 'activityIds', 'processInstanceId'];

const Details = ({ po }) => {
    const [updatePurchaseOrder] = useMutation(UpdatePurchaseOrderMutation, {
        refetchQueries: ["getPurchaseOrder"]
    });
    const { creator, assignee, status, createdAt, updatedAt, tenantId } = po;
    return <EditableDetailRenderer columns={3} formDefinition={PurchaseOrderFormDef(po)}
        onSubmit={({ object }) => updatePurchaseOrder({
            variables: {
                id: po.id,
                purchaseOrder: object,
            }
        })
        } object={po} extra={<DetailRenderer object={{
            creator, assignee, createdAt,
            updatedAt, status, tenantId,
        }} />} />
};

class PurchaseOrderDetailPage extends Component {


    constructor() {
        super();
        this.state = {
            activeTab: "default",
            dropdownOpen: false,
            pdf: false
        };
        this.downloadPDF = this.downloadPDF.bind(this);
        this.removePurchaseOrder = this.removePurchaseOrder.bind(this);
    }
    downloadPDF() {
        this.setState({ pdf: true });
        // const purchaseOrder = this.props.data.purchaseOrder;
        // downloadPurchaseOrderPDF(purchaseOrder);
    }

    async setDefaultDeliveryDestination() {
        await this.props.updatePurchaseOrder({
            variables: {
                id: parseInt(this.props.match.params.id),
                purchaseOrder: {
                    deliveryTitle: 'Eurostair Produktion AB',
                    deliveryStreet: 'Säterivägen 30',
                    deliveryCity: 'Säffle',
                    deliveryCountry: 'Sweden',
                    deliveryPostalCode: '661 30',

                }
            }
        });
    }

    renderAddress = (purchaseOrder) => {
        return <Card>
            <CardHeader>
                Delivery address
            </CardHeader>
            <CardBody>
                <p>{purchaseOrder.deliveryTitle}</p>
                <p>{purchaseOrder.deliveryStreet}</p>
                <p>{purchaseOrder.deliveryPostalCode} {purchaseOrder.deliveryCity} {purchaseOrder.deliveryCountry}</p>
                <p>{purchaseOrder.deliveryFreeText}</p>
                {/*{!purchaseOrder.deliveryStreet &&*/}
                {/*<Button color='primary' onClick={this.setDefaultDeliveryDestination.bind(this)}>Set delivery address to Säffle.</Button>}*/}

            </CardBody>
        </Card>
    };

    setTab = (activeTab) => {
        this.setState({ activeTab });
    };

    async removePurchaseOrder() {
        if (window.confirm(`Are you sure you want to remove this purchase order?`)) {
            await this.props.removePurchaseOrder({
                variables:
                {
                    id: parseInt(this.props.match.params.id)
                }
            });
            this.props.history.push(`/purchaseOrders`);
        }
    }

    workflow(purchaseOrder) {
        if (purchaseOrder.status === 'done') {
            return <h2>Workflow finished</h2>;
        }
        else if (!purchaseOrder.processInstanceId) {
            return <h2>No workflow</h2>;
        }
        else {
            return <Collapser open>
                <h1>Purchase Order workflow</h1>
                <BPMDiagram width={1200} height={450} processInstanceId={purchaseOrder.processInstanceId} activityIds={purchaseOrder.activityIds} />
            </Collapser>;
        }

    }

    render() {
        const data = this.props.data;
        if (data.loading) {
            return (<p>Loading..</p>);
        }
        else if (data.error) {
            console.error(data.error);
            return (<p>Error!</p>);
        }
        else if (data.purchaseOrder == null) {
            return (<p>Not found :(</p>);
        }
        const purchaseOrder = data.purchaseOrder;

        return (
            <div>
                <PdfModal obj={purchaseOrder} isOpen={this.state.pdf} toggle={() => this.setState({ pdf: !this.state.pdf })} />
                <Nav tabs fill>
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'default'} onClick={() => {
                            this.setTab('default');
                        }}>
                            {
                                isInventoryPath(window.location.pathname) ? <h4>Order {purchaseOrder.number}</h4>
                                    : <h4>Purchase Order {purchaseOrder.number}</h4>
                            }
                        </NavLink>
                    </NavItem>
                    {purchaseOrder.order &&
                        <NavItem>
                            <NavLink active={this.state.activeTab === 'comments'} onClick={() => {
                                this.setTab('comments');
                            }}>
                                <h4>Comments <Badge color={"info"}>{purchaseOrder.order.comments.length > 0 && purchaseOrder.order.comments.length}</Badge></h4>
                            </NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink active={this.state.activeTab === 'workflow'} onClick={() => {
                            this.setTab('workflow');
                        }}>
                            <h4>Workflow</h4>
                        </NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}>
                        <DropdownToggle nav>
                            <h4>Actions</h4>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem tag='span'>
                                <Button color='secondary'
                                        onClick={() => NotificationPopup.error(`Please use the 'Edit' button located under the 'Purchase Order Number'`)}>
                                    Edit
                                </Button>
                            </DropdownItem>
                            <DropdownItem header>
                                PDFs
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button disabled={purchaseOrder.status === "started" && purchaseOrder.processInstanceId} color='primary' onClick={this.downloadPDF.bind(this)}>Purchase Order PDF</Button>
                                {purchaseOrder.status === "started" && <small><Paragraph>Finish the task first..</Paragraph></small>}
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color='danger' onClick={() => this.removePurchaseOrder()}>Delete Purchase Order</Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab} style={{ paddingTop: "35px" }}>
                    <TabPane tabId="default">
                        <Row>
                            <Col sm={4}>
                                {/*<DetailRenderer object={_.omit(purchaseOrder,OmittedFields)} />*/}
                                <Details po={purchaseOrder} />
                            </Col>
                            <Col sm={2}>
                                {this.renderAddress(purchaseOrder)}
                            </Col>
                            <Col sm={4}>
                                <WorkflowDetail purchaseOrderId={purchaseOrder.id} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>Rows</h2>
                                <ButtonGroup>
                                    <CreateArticleRow purchaseOrderId={purchaseOrder.id}
                                                      locale={purchaseOrder?.order?.company?.locale}/>
                                    <CreateFreeTextRow purchaseOrderId={purchaseOrder.id} />
                                </ButtonGroup>

                                <ArticleRows articleRows={purchaseOrder.articleRows} currency={purchaseOrder.currency} displayReceivedQuantityAndDate={true} />
                                <Row className="justify-content-between">
                                    <Col>
                                    </Col>
                                    <Col sm={4}>
                                        <p style={{ marginLeft: "55px" }}>
                                            Total: {formatCurrency(poTotal(purchaseOrder))} {purchaseOrder.currency}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </TabPane>
                    {purchaseOrder.order &&
                        <TabPane tabId="comments">
                            <Row>
                                <Col sm={6}>
                                    <CommentList orderId={purchaseOrder.order.id} comments={purchaseOrder.order.comments} assignee={purchaseOrder.assignee?.displayName}/>
                                </Col>
                            </Row>
                        </TabPane>
                    }
                    <TabPane tabId="workflow">
                        <Row>
                            <Col md={12}>
                                {this.state.activeTab === "workflow" && this.workflow(purchaseOrder)}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>



            </div>);
    }


}


export default compose(graphql(GetPurchaseOrder, {
    options: (props) => {
        return { variables: { id: parseInt(props.match.params.id) } };
    }
}), graphql(RemovePurchaseOrderMutation, { name: 'removePurchaseOrder' }),
    graphql(UpdatePurchaseOrderMutation, { name: 'updatePurchaseOrder' }))(withRouter(withApollo(PurchaseOrderDetailPage)));
