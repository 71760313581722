import {useCallback, useMemo, useState} from "react";
import {getAllModuleObject, getAllModuleObjectGroup, instanceOf} from "../utils";
import {State} from "../ModuleRampPage";
import {
    ChildSafeRailingPieceType,
    GetInventoryRampArticleListQuery,
    ModuleRampPartInputType,
    ModuleRampType
} from "../../generated/graphql";
import {ArticleListDataRowsType, ArticleListDataType} from "../ArticleListModal";
import ModuleTemplate, {store} from "../ModuleTemplate";
import * as _ from "lodash";
import {getArticleName} from "../../UTIL";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {ModuleObject, Node} from "../ShpaeObject";
import {Ramp, StartingRamp, TrianglePlatform} from "../Module/Ramp";

export type MonoArtNoModuleTemplate = Omit<ModuleTemplate, 'artNos'|'clone'> & {artNo: string, quantity:number, moduleId?:number}

const useUpdateArticleList = (rampArticleListData: GetInventoryRampArticleListQuery | undefined) => {
    const [shouldUpdateFlag, toggleShouldUpdateFlag] = useState(false);
    const updateArticleList = useCallback(() => {
        toggleShouldUpdateFlag((f)=>!f);
    }, [toggleShouldUpdateFlag]);

    const [allModuleTemplate, groupListForMutation] = useMemo(() => {
        noop(shouldUpdateFlag);
        let allModuleTemplate:ModuleTemplate[] = getAllModuleObject().map(m=> m.moduleTemplate);
        const groupList = getAllModuleObjectGroup().map(mg=>{
            const moduleIds = mg.moduleObjects.map(m=>m.moduleId);

            return {
                moduleIds: moduleIds
            };
        });

        // additional parts
        allModuleTemplate = concatBetweenRamp(allModuleTemplate);
        allModuleTemplate = concatRailingPlasticStrip(allModuleTemplate);

        return [allModuleTemplate, groupList];
    }, [shouldUpdateFlag]);


    const articleListForMutation = useMemo(()=>{
        const moduleRampParts:ModuleRampPartInputType[] = allModuleTemplate.map(m=>{
            const {origin, otherProps, ...others} = m;

            return {
                originX: origin.x,
                originY: origin.y,
                originZ: origin.z,
                ..._.omit(otherProps, ["heightForCalculate", "lengthForCalculate", "minHeightMM", "maxHeightMM"]),
                ...others,
            };
        });
        return moduleRampParts;
    }, [allModuleTemplate]);

    const articleListForGrid: ArticleListDataType = useMemo(()=>{
        noop(shouldUpdateFlag);
        const monoArticleList: MonoArtNoModuleTemplate[] = [];
        allModuleTemplate.forEach(moduleTemplate => {
            const {artNos , ...others} = moduleTemplate as ModuleTemplate;
            const singleModuleTemplate = artNos.map(artNo=>{
                return {artNo, quantity:1, ...others};
            });

            monoArticleList.push(...singleModuleTemplate);
        });

        const dataRowsForGrid:ArticleListDataRowsType = [];
        const locale = State.getInstance().local;
        const rampArticleList = rampArticleListData?.Inventory_searchArticle;
        _.chain(monoArticleList)
            .groupBy('artNo')
            .map(function(m, artNo) {
                const foundArt = rampArticleList?.find(art => {
                    return art.artNo === m[0]?.artNo;
                });
                const quantity = m.reduce((amount, piece)=>piece.quantity+amount, 0);
                const unitPrice = foundArt?.price ?? NaN;
                const price =  unitPrice * quantity;
                const currency = foundArt?.currency as string;
                const name = foundArt ? getArticleName(foundArt, locale) : m[0]?.name

                dataRowsForGrid.push({
                    id: artNo,
                    quantity,
                    name: name,
                    unitPrice,
                    price,
                    currency,
                });
                return [ artNo, m.length, m[0]?.name, unitPrice, price, currency ];
            })
            .sortBy('artNo')
            .value();

        const columns: GridColDef[] = [
            { field: 'id', headerName: 'Article ID', width: 100 },
            { field: 'quantity', headerName: 'Quantity', width: 70 },
            { field: 'name', headerName: 'Name', width: 250 },
            { field: 'unitPrice', headerName: 'Unit Price', width: 150, align: 'right', headerAlign:'right' },
            { field: 'price', headerName: 'Sum Price', width: 150, align: 'right', headerAlign:'right' },
        ];

        return {columns, rows:dataRowsForGrid};
    }, [rampArticleListData, allModuleTemplate, shouldUpdateFlag]);

    return [articleListForMutation, groupListForMutation, articleListForGrid, updateArticleList];
}


const concatBetweenRamp = (splitModuleTemplate:ModuleTemplate[]):ModuleTemplate[] => {
    // Calculate BetweenRamp
    let allRampNodes:Node[] = [];
    let betweenRampNode:Node[] = [];
    let betweenRampCount = 0;
    const betweenRampTemplate = new ModuleTemplate({...store[ModuleRampType.BetweenRamp][0], type:ModuleRampType.BetweenRamp});

    getAllModuleObject().forEach((model)=>{
        if (model.type === ModuleRampType.Ramp){
            allRampNodes = allRampNodes.concat(model.nodes);

        }
    })

    allRampNodes.forEach(node=>{
        if(instanceOf(node.attach[0]?.ancestor, [Ramp, StartingRamp, TrianglePlatform]) &&
            (node.ancestor as Ramp).moduleTemplate.slopeDegree !== (node.attach[0].ancestor as Ramp).moduleTemplate.slopeDegree
        ){
            betweenRampNode.push(node);
        }
    })

    for (let i=0; i<betweenRampNode.length; i++){
        if(!betweenRampNode[i]) continue;
        const fromRamp = betweenRampNode[i].ancestor as ModuleObject;
        const attachRamp = betweenRampNode[i].attach[0].ancestor as ModuleObject;
        // if(fromRamp.moduleTemplate.slopeDegree === attachRamp.moduleTemplate.slopeDegree) continue;
        betweenRampCount++;

        let hasSameConnection = false;

        for (let j=i+1; j<betweenRampNode.length; j++){
            if(fromRamp === betweenRampNode[j].ancestor && attachRamp === betweenRampNode[j].attach[0].ancestor){
                hasSameConnection = true;
                delete betweenRampNode[j];
            }
        }

        if(hasSameConnection){// Only count 2 betweenRamp for each ramp connection
            betweenRampCount++;
        }
    }

    betweenRampCount = Math.floor(betweenRampCount/2); // We double count on each side of node
    const additionalBetweenRamp:ModuleTemplate[] = [];
    for(let count = 0; count<betweenRampCount; count++) {
        additionalBetweenRamp.push(betweenRampTemplate)
    }

    return splitModuleTemplate.concat(additionalBetweenRamp);
}

const concatRailingPlasticStrip = (splitModuleTemplate:ModuleTemplate[]):ModuleTemplate[] => {
    const plasticStripTemplate = new ModuleTemplate({...store[ModuleRampType.ChildSafeRailingPlasticStrip][0], type:ModuleRampType.ChildSafeRailingPlasticStrip});
    let length = 0;
    // childSafeSection length already include railingPadding, no need to add joint length
    splitModuleTemplate.forEach((moduleTemplate)=>{
        if(moduleTemplate.type === 'childSafeSection'){
            length += moduleTemplate.length;
        } else if(
            moduleTemplate.type === ModuleRampType.ChildSafeRailingPiece &&
            moduleTemplate.otherProps.pieceType === ChildSafeRailingPieceType.End
        ){
            length += moduleTemplate.length;
        }
    });
    length *= 1.1; // add 10% for reserved

    const quantity = Math.ceil(length/plasticStripTemplate.length);
    const additionalPlasticStrip:ModuleTemplate[] = [];
    for(let count = 0; count < quantity; count++) {
        additionalPlasticStrip.push(plasticStripTemplate);
    }
    return splitModuleTemplate.concat(additionalPlasticStrip);
}

const noop = (...any:any) => {return any};

export default useUpdateArticleList;
