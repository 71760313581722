import React, {memo, useCallback, useMemo} from "react";
import {DoubleRailingAndExtendedEnabledFunc} from "../StraightStairV2Types";
import {Button, Col, Input, Row, Table} from "reactstrap";
import {enumToOptions} from "../../UTILmore";
import {
    ContrastMarking, PlatformRailingType,
    PlatformType,
    PriceStepType,
    RailingStandardType,
    RailingType, StraightStairSegmentFragmentFragment, StraightStairSupportFragmentFragment,
    StringerMaterial
} from "../../generated/graphql";
import NewFormRenderer from "../../common/NewFormRenderer";
import {PATH_STRAIGHT_STAIR_IMAGE} from "../StraightStairConstants";
import {
    getStringerMaterialOptions,
    maxLoadOptions,
    preferredMaterialOptions,
    scpOptions
} from "../StraightStairOptions";
import {calculateT1Width} from "../StraightStairUtils";
import Info from "../../common/icons/Info";
import ModalForm from "../../common/ModalForm";
import Select from "../../common/FixRequiredSelect";
import StraightStairSupportsPanel from "./StraightStairSupportsPanel";
import useSupportHook from "../hooks/useSupportHook";

type Props = {
    segment: StraightStairSegmentFragmentFragment,
    onChange: (id: string, key: string, value: any) => void,
    onCalculateNumberOfStepsClick: (id: string) => void,
    stairRailingStandard: RailingStandardType,
    stairRailingType?: RailingType | null,
    stairStringerMaterial: StringerMaterial,
    isDoubleRailingAndExtendedEnabled: DoubleRailingAndExtendedEnabledFunc,
    updateSegmentSupports: (segmentId: string, supports: StraightStairSupportFragmentFragment[]) => void
}

enum RailingSideAndWallHandrailType
{
    no,
    oneRailingSide,
    twoRailingSide,
    mixRailingAndWallHandrail,
    oneWallHandrail,
    twoWallHandrail,
}

const RailingSideAndWallHandrailSelect = ({onChange, model}: { onChange: any, model: any }) =>
{
    const options = useMemo(() =>
    {
        return [
            {id: RailingSideAndWallHandrailType.no, name: 'No'},
            {id: RailingSideAndWallHandrailType.oneRailingSide, name: '1 Railing side'},
            {id: RailingSideAndWallHandrailType.twoRailingSide, name: '2 Railing side'},
            {id: RailingSideAndWallHandrailType.mixRailingAndWallHandrail, name: '1 Railing side and 1 Wall handrail'},
            {id: RailingSideAndWallHandrailType.oneWallHandrail, name: '1 Wall handrail'},
            {id: RailingSideAndWallHandrailType.twoWallHandrail, name: '2 Wall handrail'},
        ]
    }, [])

    const defaultValue = useMemo(() =>
    {
        const railingSide = model.railingSide
        const wallHandrail = model.wallHandrail
        let type: RailingSideAndWallHandrailType
        if (railingSide === 2)
        {
            type = RailingSideAndWallHandrailType.twoRailingSide
        } else if (wallHandrail === 2)
        {
            type = RailingSideAndWallHandrailType.twoWallHandrail
        } else if (railingSide === 1 && wallHandrail === 1)
        {
            type = RailingSideAndWallHandrailType.mixRailingAndWallHandrail
        } else if (railingSide === 1)
        {
            type = RailingSideAndWallHandrailType.oneRailingSide
        } else if (wallHandrail === 1)
        {
            type = RailingSideAndWallHandrailType.oneWallHandrail
        } else
        {
            type = RailingSideAndWallHandrailType.no
        }
        return options.find((e) => e.id === type)

    }, [model, options])

    const handleOnChange = useCallback((e) =>
    {
        let railingSide = 0
        let wallHandrail = 0
        switch (e.id)
        {
            case RailingSideAndWallHandrailType.no:
                // nothing
                break;
            case RailingSideAndWallHandrailType.oneRailingSide:
                railingSide = 1
                break;
            case RailingSideAndWallHandrailType.twoRailingSide:
                railingSide = 2
                break;
            case RailingSideAndWallHandrailType.mixRailingAndWallHandrail:
                railingSide = 1
                wallHandrail = 1
                break;
            case RailingSideAndWallHandrailType.oneWallHandrail:
                wallHandrail = 1
                break;
            case RailingSideAndWallHandrailType.twoWallHandrail:
                wallHandrail = 2
                break;
        }

        onChange({railingSide, wallHandrail})
    }, [onChange])

    return <Select
        getOptionLabel={({name}) => name}
        getOptionValue={({id}) => id}
        options={options}
        onChange={handleOnChange}
        defaultValue={defaultValue}/>
}

const PlatformRailingRow = memo(({side, checked, length, type, onChange}: {
    side: "L1" | "L2" | "W1" | "W2",
    checked: boolean,
    length: number,
    type: PlatformRailingType
    onChange: (key: string, value: string | number) => void
}) =>
{
    const onTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        onChange(`platformRailing${side}Type`, e.target.value)
    }, [onChange, side])

    const onLengthChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = e.target.value === "" ? null : Number(e.target.value)
        if (!value)
        {
            return
        }
        onChange(`platformRailing${side}Length`, value)
    }, [onChange, side])

    if (!checked)
    {
        return null
    }

    return <tr key={`platformRailingRow${side}`}>
        <th>{side}</th>
        <th>
            <Input type="select" value={type} onChange={onTypeChange}>
                {
                    Object.entries(PlatformRailingType)
                        .map(([key, value]) => <option key={`type${key}`} value={value}>{key}</option>)
                }
            </Input>
        </th>
        <th>
            <Input
                type="number"
                onClick={(e: any) => e.target?.select()}
                onChange={onLengthChange}
                step={10}
                min={0}
                max={6000}
                value={length ?? 0}/>
        </th>
    </tr>
})

const PlatformRailingPanel = memo(({segment, stairStringerMaterial, onChange}:
                                       {
                                           segment: StraightStairSegmentFragmentFragment,
                                           stairStringerMaterial: StringerMaterial
                                           onChange: (id: string, key: string, value: string | boolean | number) => void
                                       }) =>
{
    const containerStyle: any = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        height: '200px',
        position: 'relative',
    };

    const checkboxStyle: any = {
        position: 'absolute',
    };

    const textStyle: any = {
        position: 'absolute',
    };

    const rectangleStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: 'black',
        borderTop: segment.platformRailingW2Checked ? '3px dashed' : '',
        borderBottom: segment.platformRailingW1Checked ? '3px dashed' : '',
        borderLeft: segment.platformRailingL1Checked ? '3px dashed' : '',
        borderRight: segment.platformRailingL2Checked ? '3px dashed' : '',
        position: 'relative',
        backgroundColor: "#F5F5F5",
        width: '100%',
        height: '100%',
    };

    const t1Width = useMemo(() =>
    {
        return calculateT1Width(stairStringerMaterial, segment.stepWidth)
    }, [stairStringerMaterial, segment.stepWidth])

    const handleOnChange = useCallback((key: string, value: string | boolean | number) =>
    {
        onChange(segment.id, key, value)
    }, [onChange, segment.id])

    return (
        <>
            <div style={containerStyle}>
                <div
                    style={{...checkboxStyle, top: '0px', left: '50%', transform: 'translateX(-50%)'}}>
                    <input
                        type="checkbox"
                        checked={segment.platformRailingW2Checked}
                        onChange={(e) => handleOnChange("platformRailingW2Checked", e.target.checked)}/>
                </div>

                <div style={rectangleStyle}>
                    <div style={{...textStyle, top: '3%'}}>W2</div>
                    <div style={{...textStyle, bottom: '3%'}}>W1</div>
                    <div style={{...textStyle, left: '3%', top: '50%', transform: 'translateY(-50%)'}}>L1</div>
                    <div style={{...textStyle, right: '3%', top: '50%', transform: 'translateY(-50%)'}}>L2</div>

                    <div style={{...checkboxStyle, top: '50%', left: '-20px', transform: 'translateY(-50%)'}}>
                        <input
                            type="checkbox"
                            checked={segment.platformRailingL1Checked}
                            onChange={(e) => handleOnChange("platformRailingL1Checked", e.target.checked)}/>
                    </div>
                    <div style={{...checkboxStyle, top: '50%', right: '-20px', transform: 'translateY(-50%)'}}>
                        <input
                            type="checkbox"
                            checked={segment.platformRailingL2Checked}
                            onChange={(e) => handleOnChange("platformRailingL2Checked", e.target.checked)}/>
                    </div>
                </div>

                <div style={{...checkboxStyle, bottom: '0px', left: '50%', transform: 'translateX(-50%)'}}>
                    <input
                        type="checkbox"
                        checked={segment.platformRailingW1Checked}
                        onChange={(e) => handleOnChange("platformRailingW1Checked", e.target.checked)}/>
                </div>
            </div>

            <p>T1 width: {t1Width}</p>

            <Table>
                <thead>
                <tr>
                    <th></th>
                    <th>R/H</th>
                    <th>Length(mm)</th>
                </tr>
                </thead>
                <tbody>
                <PlatformRailingRow side={"L1"}
                                    length={segment.platformRailingL1Length}
                                    checked={segment.platformRailingL1Checked}
                                    type={segment.platformRailingL1Type}
                                    onChange={handleOnChange}/>
                <PlatformRailingRow side={"L2"}
                                    length={segment.platformRailingL2Length}
                                    checked={segment.platformRailingL2Checked}
                                    type={segment.platformRailingL2Type}
                                    onChange={handleOnChange}/>
                <PlatformRailingRow side={"W1"}
                                    length={segment.platformRailingW1Length}
                                    checked={segment.platformRailingW1Checked}
                                    type={segment.platformRailingW1Type}
                                    onChange={handleOnChange}/>
                <PlatformRailingRow side={"W2"}
                                    length={segment.platformRailingW2Length}
                                    checked={segment.platformRailingW2Checked}
                                    type={segment.platformRailingW2Type}
                                    onChange={handleOnChange}/>
                </tbody>
            </Table>
        </>
    );
})

const GeometryPanel = memo(({geometry}: { geometry: StraightStairSegmentFragmentFragment['geometry'] }) =>
{
    const ergonomyTextColor = useMemo(() =>
    {
        const ergonomy = geometry?.ergonomy ?? 0
        if (ergonomy >= 600 && ergonomy <= 650)
        {
            return '#00FF00'
        }
        return '#FF2D2D'
    }, [geometry])

    return <Row>
        <Col lg={6}>
            <img style={{width: '100%'}}
                 src={`${PATH_STRAIGHT_STAIR_IMAGE}/geometry.jpg`}
                 alt={"geometry"}/>
        </Col>
        <Col lg={6}>
            <p>Step Height(Sh): {geometry?.stepHeight ?? 0}</p>
            <p>Efective Step Depth(Sd): {geometry?.efectiveStepDepth ?? 0} </p>
            <p>Ergonomy(2xSh+Sd):
                <span style={{backgroundColor: ergonomyTextColor}}>{` ${geometry?.ergonomy ?? 0}`}</span>
            </p>
            <p>Stairs Horizontal Length(Lx): {geometry?.stairsHorizontalLength ?? 0}</p>
            <p>Stringer Length(Lc): {geometry?.stringerLength ?? 0}</p>
            <p>Stair Angle(α): {geometry?.stairAngle ?? 0}</p>
        </Col>
    </Row>
})


const SegmentTabPane = memo(({
                                 segment, onChange,
                                 onCalculateNumberOfStepsClick,
                                 stairRailingStandard, stairRailingType,
                                 stairStringerMaterial,
                                 isDoubleRailingAndExtendedEnabled,
                                 updateSegmentSupports
                             }: Props) =>
{
    const {
        addSupport,
        deleteSupport,
        updateSupport,
    } = useSupportHook({supports: segment.supports, segmentId: segment.id, updateSegmentSupports})

    const segmentFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "stairHeight",
                type: "number",
                min: 200,
                max: 6000,
                step: 10,
                hint: "(200~6000mm)",
            },
            {
                name: "numberOfSteps",
                type: "number",
                min: 1,
                max: 33,
                hint: "(1~33)",
            },

            {
                name: "overlap",
                type: "number",
                step: 10,
            },
            {name: "blank", type: "hidden", hideField: () => true},
            {
                name: "calculate",
                label: " ",
                type: "custom",
                input: (model) =>
                {
                    return <Button size="sm" onClick={() => onCalculateNumberOfStepsClick(model.id)}>auto</Button>
                }
            },
            {name: "blank", type: "hidden", hideField: () => true},

            {
                name: "stepWidth",
                type: "number",
                hint: "(600~1500mm)",
                step: 10,
                min: 600,
                max: 1500,
            },
            {
                name: "stepDepth",
                type: "select",
                options: Array.from({length: 19}, (_, index) =>
                {
                    const value = 130 + index * 10
                    return {id: value, name: value.toString()}
                })
            },
            {
                name: "additionalStep",
                label: "Additional step on floor level",
                type: "checkbox"
            },
            {
                name: "contrastmarking",
                type: "select",
                options: enumToOptions(ContrastMarking)
            },
            {
                name: "priceSteps",
                type: "select",
                options: [{id: PriceStepType.Pirex, name: "ESPL"},
                    {id: PriceStepType.Eurostair, name: "No steps"}]
            },
            {
                name: "adjustableFeet",
                type: "checkbox",
                hint: <ModalForm title={"Adjustable feet"}
                                 button={toggle => <span style={{display: "inline"}} className="link"
                                                         onClick={toggle}>
                                      <Info/>
                                  </span>}>
                    {() => <img style={{width: "100%"}}
                                src={`${PATH_STRAIGHT_STAIR_IMAGE}/ADJ/ADJ feet.JPG`}
                                alt={"adjustable feet"}/>}
                </ModalForm>
            },
        ]
    }, [onCalculateNumberOfStepsClick])

    const railingFormDefinition = useMemo(() =>
    {
        const isDoubleRailingAndExtendedEnable = isDoubleRailingAndExtendedEnabled({
            railingSide: segment.railingSide,
            wallHandrail: segment.wallHandrail,
            extraHandrail: segment.extraHandrail,
        })

        return [
            {
                name: 'railingSideAndWallHandrail',
                label: "Railing Side and Wall Handrail",
                type: 'custom',
                input: (model, onChange) =>
                {
                    return <RailingSideAndWallHandrailSelect model={model}
                                                             onChange={onChange}/>
                }
            },
            {
                name: "extraHandrail",
                type: "checkbox",
                disabled: segment.railingSide === 0
            },
            {
                name: "doubleRailing",
                label: "Extra handrail double",
                type: "checkbox",
                disabled: !isDoubleRailingAndExtendedEnable
            },
            {
                name: "railingExtended",
                label: "Extended handrail +300 mm",
                type: "checkbox",
                disabled: !isDoubleRailingAndExtendedEnable
            },
            {
                name: "railingGate",
                label: "Gate",
                type: "checkbox",
            },
            {
                name: "railingPlacement",
                label: "Placement",
                type: "select",
                options: [{id: "top", name: "top"}, {id: "bottom", name: "bottom"}],
                hideField: () => !segment.railingGate
            },
        ]
    }, [segment.railingGate, segment.railingSide, isDoubleRailingAndExtendedEnabled,
        segment.wallHandrail, segment.extraHandrail])

    const platformTypeOptions = useMemo(() =>
    {
        return [
            {id: PlatformType.StandardGrate30, name: 'Grating 30'},
            {id: PlatformType.StandardGrate40, name: 'Grating 40'},
            {id: PlatformType.Tearplate, name: 'Tear plate'},
            {id: PlatformType.TideMeshGrate, name: 'Grating - 15x76'},
        ]
    }, [])

    const platformFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "platformIntegrated",
                label: "Integrated stringers",
                type: "checkbox"
            },
            {
                name: "platformLength",
                label: "Length",
                hint: "(600~4100mm)",
                type: "number",
                step: 10,
                max: 4100,
            },
            {
                name: "platformWidth",
                label: "Width",
                type: "number",
                hint: "(600~4100mm)",
                step: 10,
                max: 4100,
                disabled: segment.platformIntegrated
            },
            {
                name: "platformType",
                label: "Type",
                type: "select",
                options: platformTypeOptions,
                disabled: segment.platformIntegrated
            },
            {
                name: "platformMaxLoad",
                label: "Max.Load",
                type: "select",
                options: maxLoadOptions,
                disabled: segment.platformIntegrated
            },
            {
                name: "platformMaterialType",
                label: "Material type",
                type: "select",
                options: preferredMaterialOptions,
                disabled: segment.platformIntegrated
            },
            {
                name: "platformFrameMaterial",
                label: "Frame material",
                type: "select",
                disabled: segment.platformIsMinFrameMaterial || segment.platformIntegrated,
                options: getStringerMaterialOptions(segment.platformMaterialType)
                    .map((e) => ({id: e, name: `${e.toUpperCase()}`})),
            },
            {
                name: "platformIsMinFrameMaterial",
                label: "Auto min set frame material",
                type: "checkbox",
                disabled: segment.platformIntegrated,
            },
            {
                name: "platformSCP1",
                label: "Entrance side",
                type: "select",
                options: scpOptions,
                disabled: segment.platformIntegrated
            },
            {
                name: "platformSCP2",
                label: "Exit side",
                type: "select",
                options: scpOptions,
                disabled: segment.platformIntegrated
            },
        ]
    }, [segment.platformIntegrated, segment.platformMaterialType,
        segment.platformIsMinFrameMaterial, platformTypeOptions])

    const platformRailingFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "platformKickplate",
                type: "checkbox",
                hideField: () => segment.platformRailingExecution !== RailingStandardType.Industrial
            },
        ]
    }, [segment.platformRailingExecution])

    const getRailingImage = useCallback((railingStandard: RailingStandardType, railingType: RailingType | null | undefined,
                                         segment: StraightStairSegmentFragmentFragment) =>
    {
        let std

        if (railingStandard === RailingStandardType.Industrial)
        {
            std = 1
        } else
        {
            switch (railingType)
            {
                case RailingType.StdTube:
                    std = 3
                    break;
                case RailingType.PlsStd:
                    std = 4
                    break;
                case RailingType.RodSimple:
                    std = 5
                    break;
                case RailingType.PlsSimple:
                    std = 6
            }
        }
        if (segment.railingSide === 0)
        {
            std = 0
        }

        const cb12 = segment.extraHandrail ? 1 : 0
        const cb14 = segment.doubleRailing ? 1 : 0
        const cb3 = segment.railingExtended ? 1 : 0

        const fileName = `UPE${std}${segment.railingSide}${cb12}${segment.wallHandrail}${cb14}${cb3}.jpg`
        return `${PATH_STRAIGHT_STAIR_IMAGE}/St/${fileName}`
    }, [])

    const handleRailingChange = useCallback((key, value) =>
    {
        if (key === 'railingSideAndWallHandrail')
        {
            onChange(segment.id, 'railingSide', value.railingSide)
            onChange(segment.id, 'wallHandrail', value.wallHandrail)
            return
        }
        onChange(segment.id, key, value)
    }, [onChange, segment.id])

    return <>
        <Row>
            <Col>
                <NewFormRenderer
                    columns={3}
                    formDefinition={segmentFormDefinition}
                    object={segment}
                    onChange={(key, value) => onChange(segment.id, key, value)}
                />
            </Col>
            <Col>
                <GeometryPanel geometry={segment.geometry}/>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col>
                <h3>Railing</h3>
                <NewFormRenderer
                    columns={3}
                    formDefinition={railingFormDefinition}
                    object={segment}
                    onChange={handleRailingChange}/>
            </Col>
            <Col>
                <img style={{height: '250px'}} src={getRailingImage(stairRailingStandard, stairRailingType, segment)}
                     alt={"railing"}/>
            </Col>
        </Row>
        <hr/>

        <Row>
            <Col lg={6}>
                <h3>Platform</h3>
                <NewFormRenderer
                    columns={3}
                    formDefinition={platformFormDefinition}
                    object={segment}
                    onChange={(key, value) => onChange(segment.id, key, value)}/>
            </Col>
        </Row>
        <hr/>

        <Row>
            <Col lg={6} style={{maxWidth: '400px'}}>
                <h3>Platform Railing</h3>
                <NewFormRenderer
                    columns={2}
                    formDefinition={platformRailingFormDefinition}
                    object={segment}
                    onChange={(key, value) => onChange(segment.id, key, value)}/>
                <PlatformRailingPanel segment={segment} onChange={onChange}
                                      stairStringerMaterial={stairStringerMaterial}/>
            </Col>
        </Row>
        <Row>
            <Col style={{maxWidth: '1200px'}}>
                <StraightStairSupportsPanel supports={segment.supports}
                                            updateSupport={updateSupport}
                                            addSupport={addSupport}
                                            deleteSupport={deleteSupport}/>
            </Col>
        </Row>
    </>
})

export default SegmentTabPane