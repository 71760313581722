import React, {useCallback, useState} from 'react';
import StdQuery from "../common/StdQuery";
import QuoteQuery from './graphql/Quote.graphql';
import {
    Badge,
    Button,
    ButtonGroup, Card, Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {Link} from "react-router-dom";
import UploadFile from "../common/UploadFile";
import FilesSubpanel from "../common/subpanels/FilesSubpanel";
import DeleteQuoteMutation from './graphql/DeleteQuote.graphql';
import {useMutation} from '@apollo/client';
import TemplatesGeneration from "./templates/TemplatesGeneration";
import ArticleRows from "../common/ArticleRows";
import ModalForm from "../common/ModalForm";
import FormRenderer, {selectRenderer} from "../common/FormRenderer";
import Auth from "../Auth";
import FreeTextQuery from '../common/graphql/FreeTextSearchQuery.graphql';
import Picker from "../common/Picker";
import {FORMAT_DATE, formatCurrency, formatToSwedishTime, idToName, quoteTotal} from "../UTIL";
import CreateFollowupTask from "../quote/CreateFollowupTask";
import UpdateQuoteMutation from './graphql/UpdateQuote.graphql';
import UpdateQuote from './graphql/UpdateQuote.graphql';
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import gql from "graphql-tag";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import CommentList from "../common/comment/CommentList";
import CreateProjectMutation from '../project/graphql/CreateProject.graphql';
import WorkflowDetail from "../common/WorkflowDetail";
import CreateContactMutation from '../contact/graphql/CreateContact.graphql';
import ConvertToOrderModal from "./ConvertToOrderModal";
import NotificationPopup from "../common/lib/NotificationPopup";
import CreateArticleRow from "../common/CreateArticleRow";
import CreateFreeTextRow from "../common/createArticle/CreateFreeTextRow";
import CreateArticle, {CreateSpiralStair, QuickCreateModuleRamp} from "../common/createArticle/CreateArticle";
import {QuickCreateStraightStair, CreateStraightStairV2} from "../common/createArticle/CreateStraightStair";
import DatePickerWithWeek from "../common/DatePickerWithWeek";
import PredictedWorkloadButton from "../common/PredictedWorkloadButton";
import {PrioritizedProject} from "../generated/graphql";
import SplitQuoteModal from "./SplitQuoteModal";

const GetCompany = gql`
    query getCompany($id:ID!)
    {
        company(id:$id)
        {
            id
            name
            contacts
            {
                id
                name
                isActive
            }
        }
    }`;

const markQuoteAsLostMutation = gql`
    mutation markQuoteAsLost($id:ID!,$lostReason:String!,$lostComment:String)
    {
        markQuoteAsLost(id:$id,lostReason:$lostReason,lostComment:$lostComment) {
            id
        }
    }`;

export const QuoteLostReasons = [
    {id:"contractor lost",name:"The company did not get the job"},
    {id:"quality",name:"Quality"},
    {id:"deliveryTime",name:"Delivery Time"},
    {id:"price",name:"Price"},
    {id:"projectCancelled",name:"Project Cancelled"},
    {id:"relations",name:"Relations"}
];





const MarkAsLost = ({toggle,isOpen,quoteId,lost})=>
{
    const [updateQuote] = useMutation(UpdateQuoteMutation,{refetchQueries:["quote"]});
    const [markQuoteAsLost] = useMutation(markQuoteAsLostMutation,{refetchQueries:["quote","tasks"]});
    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Mark quote as lost
        </ModalHeader>
        <ModalBody>
            {!lost &&
            <FormRenderer formDefinition={[
                {name:"lostReason",type:"select",options:QuoteLostReasons,required:true},
                {name:"lostComment",type:"textarea"}
            ]}
                          onSubmit={async({object})=>
                          {
                              await markQuoteAsLost({
                                  variables: {
                                      id: quoteId,
                                      lostReason: object.lostReason,
                                      lostComment: object.lostComment
                                  }
                              });
                              toggle();
                          }
                          } />}
            {lost && <div>
                <Button color={"primary"} onClick={async()=>
                {
                    if(window.confirm(`Are you sure you want to mark quote as not lost?`))
                    {
                        toggle();
                        await updateQuote({
                            variables:{
                                id:quoteId,
                                quote:
                                {
                                    status:"active",
                                    lostReason: "",
                                    lostComment:"",
                                }
                            }
                        });
                        NotificationPopup.success(`Marked quote as active`);
                    }

                }}>
                    Mark as no longer lost.
                </Button>
            </div>}
        </ModalBody>
    </Modal>

};

export const CreateContact = ({companyId,onCreate})=>
{
    const [createContact] = useMutation(CreateContactMutation,{refetchQueries:["getCompany"]});
    return <ModalForm title={"Create contact"}
                      button={toggle=><span className="link" onClick={()=>toggle()}>Create Contact</span>}>
        {toggle=>
        {
            return <FormRenderer
            formDefinition={[
                {name:"name",required:true},
                {name:"email"},
                {name:"workPhone"},
                {name:"mobilePhone"}

                ]}
            onSubmit={async({object})=>
            {
                const res = await createContact({
                    variables:
                    {
                        contact:
                        {
                            ...object,
                            companyId,
                            tenantId:Auth.getTenant()
                        }
                    }
                });
                if(onCreate)
                {
                    onCreate(res.data.createContact);
                }
                toggle();
            }} />
        }}
    </ModalForm>
};


const CreateProject = ({onCreate})=>
{
    const [createProject] = useMutation(CreateProjectMutation);
    return <ModalForm title={"Create project"}
                      button={toggle=><span className="link" onClick={()=>toggle()}>Create Project</span>}>
        {toggle=>
        {
            return <FormRenderer
            formDefinition={[{name:"name",required:true},
                {name:"description",type:"textarea"}]}
            onSubmit={async({object})=>
            {
                const res = await createProject({
                    variables:
                    {
                        project:
                        {
                            ...object,
                            tenantId:Auth.getTenant()
                        }
                    }
                });
                if(onCreate)
                {
                    onCreate(res.data.createProject);
                }
                toggle();
            }} />
        }}
    </ModalForm>
};

export const ProjectPicker = ({model,onChange})=>
{
    const [newProject,setNewProject] = useState(null);
    if(newProject)
    {
        return <p>{newProject.name}</p>;
    }
    let startValue = null;
    if(newProject)
    {
        startValue = {value:newProject.id,label:newProject.name};
    }
    else if(model && model.project)
    {
        startValue = {value:model.project.id,label:model.project.name};
    }
    return <React.Fragment>
        <CreateProject onCreate={(project)=>
        {
            onChange(project);
            setNewProject(project);
        }}/>

        <Picker types={["project"]}
                startValue={startValue}
                onChange={onChange} query={FreeTextQuery} />
    </React.Fragment>
};

const DetailBody = ({quote})=>
{
    const [updateQuote] = useMutation(UpdateQuote,{refetchQueries:["quote"]});
    const def = [
        {
            name:"companyId",
            hint:<div><Link to="/createCompany" target="_blank">Create company</Link></div>,
            startValue:quote.company?{value:quote.company.id,label:quote.company.name}:null,
            type:"search",
            types:["company"],
            required:true
        },
        {
            name: "preferredDeliveryDate", type: "custom",
            required: true,
            transformValue: ({value}) => `${formatToSwedishTime(value, FORMAT_DATE)}`,
            input: function (model, onChange) {
                return <div>
                    <DatePickerWithWeek defaultValue={model["preferredDeliveryDate"]}
                                        onChange={onChange}/>
                    <PredictedWorkloadButton/>
                </div>
            }
        },
        {
            name:"projectId",
            label: "Project name",
            type: 'custom', input: function (model, onChange) {
                return <ProjectPicker model={quote} onChange={onChange} />
            },
        },
        {
            name:"currency",
            type:"currencyPicker",
            required:true
        },
        {
            name: 'projectStage',
            label: "Part of project",
            hint: "(project stage, descriptive name for quote)"
        },
        {
            name: "contactId",
            required: true, type: 'custom', input: function (model, onChange) {
                if (model.companyId)
                {
                    return <StdQuery query={GetCompany} variables={{id: model.companyId}}>
                        {(data) => {
                            if(!data.company)
                            {
                                return <p>Failed to find company by id:{model.companyId}</p>;
                            }
                            return <div>
                                <div>
                                    <CreateContact companyId={model.companyId}
                                                    onCreate={async contact=>onChange(contact)} />
                                </div>
                                {selectRenderer({
                                    required: true,
                                    name: 'contactId',
                                    options: data.company.contacts.filter((e)=> e.isActive)
                                        .sort((a,b) => a.name.localeCompare(b.name))
                                },
                                model.contactId, onChange)}
                            </div>
                        }}
                    </StdQuery>

                }
                else
                {
                    return <p>..</p>;
                }

            }
        },
        {
            name:"assigneeId",
            type:"userPicker",
        },
        {name:"shippingCost",type:"currency",required:true,defaultValue:0,
transformValue:({value,object})=>`${value || "0"} ${object.currency || ""}`},
        {name:"packagingCost",type:"currency",required:false,defaultValue:0,
transformValue:({value,object})=>`${value || "0"} ${object.currency || ""}`},
        {name:"status",type:"hidden"},
        {
            name: "prioritizedProject", type: "select",
            options: Object.entries(PrioritizedProject)
                .map(([key, value]) => ({id: value, name: key}))
        },
        {name:"lostReason",hideField:()=>!quote.lostReason,type:"hidden"}
    ];
    return <EditableDetailRenderer formDefinition={def}
                                   object={{...quote,lostReason:idToName(QuoteLostReasons,quote.lostReason)}}
                                   columns={2} onSubmit={async({object})=>
    {
        await updateQuote({variables:{quote:object,id:quote.id}});

    }
    }/>
};

export default ({match,history})=>
{
    const [activeTab, setTab] = useState("overview");
    const [dropdown, setDropdown] = useState(false);
    const [deleteQuote] = useMutation(DeleteQuoteMutation);
    const [taskModalOpen,setTaskModalOpen] = useState(false);
    const [lostModalOpen,setLostModalOpen] = useState(false);
    const [convertModal,setConvertModal] = useState(false);
    const [splitQuoteModal, setSplitQuoteModal] = useState(false);

    const toggleConvertModal = ()=>setConvertModal(isOpen=>!isOpen);
    const toggleTaskModal = ()=>setTaskModalOpen(isOpen=>!isOpen);
    const toggleLostModal = ()=>setLostModalOpen(isOpen=>!isOpen);
    const toggleDropdown = ()=>setDropdown(prevValue => !prevValue);

    const onConvertToOrderClick = useCallback((quote) => {
        if (!quote?.preferredDeliveryDate) {
            NotificationPopup.error("Please select a preferred delivery date.")
            return
        }
        toggleConvertModal()
    }, [])

    const toggleSplitQuoteModal = useCallback(() => {
        setSplitQuoteModal(isOpen => !isOpen)
    }, [])

    const deprecateEditButtonAlert = useCallback(() => {
        NotificationPopup.error(`Please use the 'Edit' button located under the 'Quote Number'`)
    }, [])

    return <StdQuery query={QuoteQuery} variables={{id:parseInt(match.params.id)}}>
        {(data) =>
        {
            if(data.quote == null)
            {
                return <p>Could not find quote.</p>
            }
            const {status,number,files,id} = data.quote;
            const quote = data.quote;
            return <div>
                <CreateFollowupTask isOpen={taskModalOpen} toggle={toggleTaskModal} quoteId={quote.id} refetchQueries={["tasks","quote"]} />
                <MarkAsLost isOpen={lostModalOpen} toggle={toggleLostModal} quoteId={quote.id} lost={status==='lost'} />
                <ConvertToOrderModal quote={quote} isOpen={convertModal} toggle={toggleConvertModal}  />
                <SplitQuoteModal isOpen={splitQuoteModal} toggle={toggleSplitQuoteModal} quote={quote} history={history}/>
                <Nav tabs fill>
                    <NavItem>
                        <NavLink active={activeTab === 'overview'} onClick={() => {
                            setTab('overview');
                        }}>
                            <h4>Quote {number}</h4>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'files'} onClick={() => {
                            setTab('files');
                        }}>
                            <h4>Files</h4>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'comments'} onClick={() => {
                            setTab('comments');
                        }}>
                            <h4>Comments <Badge color={"info"}>{quote.comments.length > 0 && quote.comments.length}</Badge></h4>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'templates'} onClick={() => {
                            setTab('templates');
                        }}>
                            <h4>Templates</h4>
                        </NavLink>
                    </NavItem>
                    <Dropdown nav isOpen={dropdown} toggle={()=>toggleDropdown()}>
                        <DropdownToggle nav>
                            <h4>Actions</h4>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Button color='secondary' onClick={deprecateEditButtonAlert}>
                                    Edit
                                </Button>
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color='secondary' onClick={()=>onConvertToOrderClick(quote)}>
                                    Convert to Order
                                </Button>
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color="success" onClick={toggleSplitQuoteModal}>
                                    Split Quote
                                </Button>
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color="info" onClick={()=>setLostModalOpen(true)}>
                                    {status !== "lost" && <span>Mark quote as lost</span>}
                                    {status === 'lost' && <span>Mark quote as no longer lost</span>}
                                </Button>
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color="primary" onClick={()=>setTaskModalOpen(true)}>
                                    Create task
                                </Button>
                            </DropdownItem>
                            <DropdownItem tag='span'>
                                <Button color='danger' onClick={async() =>
                                {
                                    if(window.confirm(`Are you sure you want to delete quote ${number}?`))
                                    {
                                        await deleteQuote({variables:{id}});
                                        history.push(`/quotes`);
                                    }

                                }}>
                                    Delete Quote
                                </Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                </Nav>
                <TabContent activeTab={activeTab} style={{paddingTop:"35px"}}>
                    <TabPane tabId="overview">
                        <Row>
                            <Col sm={6}>
                                <DetailBody quote={quote}/>
                            </Col>
                            <Col sm={3}>
                                <WorkflowDetail quoteId={quote.id} />
                            </Col>
                        </Row>
                        <br/>
                        <div style={{display:"inline-flex", marginBottom:"7px"}}>

                            <span style={{width:"15px"}} />
                            <Card style={{width:"640px"}}>
                                <ButtonGroup>
                                    <CreateArticleRow quoteId={quote.id} locale={quote.company?.locale}/>
                                    <CreateArticle quoteId={quote.id}/>
                                    <CreateSpiralStair quoteId={quote.id}/>
                                    <QuickCreateStraightStair quoteId={quote.id} />
                                    <CreateStraightStairV2 quoteId={quote.id} />
                                    <QuickCreateModuleRamp quoteId={quote.id} />
                                    <CreateFreeTextRow quoteId={quote.id}/>
                                </ButtonGroup>
                            </Card>
                        </div>

                        <ArticleRows quoteId={quote.id} articleRows={quote.articleRows} hideProdInfo currency={quote.currency}/>
                        <Row className="justify-content-between">
                            <Col>
                            </Col>
                            <Col sm={4}>
                                <p style={{marginLeft:"55px"}}>
                                    Total: {formatCurrency(quoteTotal(quote))} {quote.currency}
                                </p>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="files">
                        <UploadFile noModal quoteId={id}/>
                        <FilesSubpanel files={files}/>
                    </TabPane>
                    <TabPane tabId="comments">
                        <Row>
                            <Col sm={6}>
                                <CommentList comments={quote.comments} quoteId={quote.id} assignee={quote.assignee?.displayName}/>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="templates">
                        <TemplatesGeneration quote={quote} defaultCategory={"quote"}/>
                    </TabPane>
                </TabContent>
            </div>
        }}
    </StdQuery>
};
