import React, {memo} from "react";
import {OnCopySegmentClick, OnDeleteSegmentClick} from "../StraightStairV2Types";
import {Button, Nav, NavItem, NavLink} from "reactstrap";
import Trash from "../../common/icons/Trash";
import Copy from "../../common/icons/Copy";
import {STRAIGHT_STAIR_TAB_STAIR} from "../StraightStairConstants";
import {getSegmentId} from "../StraightStairUtils";
import {StraightStairSegmentFragmentFragment} from "../../generated/graphql";

type Props = {
    activeTab: string,
    onTabClick: (tab: string) => void,
    segments: StraightStairSegmentFragmentFragment[] | null | undefined,
    straightStairV2Id: string,
    onAddClick: () => void,
    onDeleteClick: OnDeleteSegmentClick,
    onCopyClick: OnCopySegmentClick
}

const StairNavBar = memo(({
                              activeTab, onTabClick, segments,
                              onAddClick, onDeleteClick, onCopyClick
                          }: Props) =>
{
    return <Nav tabs card>
        <NavItem>
            <NavLink active={activeTab === STRAIGHT_STAIR_TAB_STAIR}
                     onClick={() => onTabClick(STRAIGHT_STAIR_TAB_STAIR)}>
                <h4>Stair</h4>
            </NavLink>
        </NavItem>
        {segments && segments.map((segment, i) =>
        {
            const index = i + 1
            const id = segment.id
            const key = getSegmentId(id)
            return <NavItem key={key}>
                <NavLink active={activeTab === key} onClick={() => onTabClick(key)}>
                    <h4>
                        Section {index}
                        &nbsp;
                        <span style={{float: "right", marginLeft: "4px"}} className="link"
                              onClick={() => onDeleteClick(index, id)}>
                            <Trash/>
                        </span>
                        <span style={{float: "right", marginLeft: "4px"}} className="link"
                              onClick={() => onCopyClick(index, id)}>
                            <Copy/>
                        </span>
                    </h4>
                </NavLink>
            </NavItem>
        })}
        <NavItem>
            <Button size="sm" color="success" onClick={onAddClick}>
                Add Section
            </Button>
        </NavItem>
    </Nav>
})

export default StairNavBar