export enum Modes
{
    pickRampAttachA,
    pickIndustrialPoleAttachA,
    pickIndustrialRailingAttachA,
    pickIndustrialRailingAttachB,
    pickIndustrialRailingPieceAttachA,
    pickChildSafeAttachA,
    pickChildSafePieceAttachA,
    pickKickPlateAttachA,
    pickKickPlateAttachB,
    pickStairAttachA,
    pickStairAttachB,
    pickStairStringerAttachA,
    pickChildSafeAttachB,
    pickSupportLegAttachA,
    pickAttachB,
    saved,
    normal,
    noAvailableNode,
    noLegFitsHeight,
    belowGround,
    unableToAttach,
    selectionErr,
    debugShowAllNodes,
    noAvailableStair,
}
