import React, {Component} from 'react';
import {formatCurrency} from "../UTIL";
import ColumnId from "../common/data-grid/columns/ColumnId";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {StockArticlesV2Document} from "../generated/inventoryGraphql";

const stockArticleColumns = () => {
    return [
        {
            field: 'artNo',
            headerName: 'Article number',
            searchable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            searchable: true,
        },
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            filterable: false,
            renderCell: (params) => <div>{formatCurrency(params.value)} {params.row.currency}</div>
        },
        {
            field: 'internalPrice',
            headerName: 'Internal price',
            type: 'number',
            filterable: false,
            renderCell: (params) => <div>{formatCurrency(params.value)} {params.row.internalCurrency}</div>
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            width: 100,
            sortable: false,
            filterable: false
        },
        {
            field: 'reservedQuantitySum',
            headerName: 'Reserved Quantity',
            type: 'number',
            sortable: false,
            filterable: false
        },
        {
            field: 'availableQuantity',
            headerName: 'Available Quantity',
            type: 'number',
            sortable: false,
            filterable: false,
            renderCell: (params) => <div>{params.row.quantity - params.row.reservedQuantitySum}</div>
        },
        {
            field: 'orderedQuantity',
            headerName: 'Ordered Quantity',
            type: 'number',
            sortable: false,
            filterable: false
        },
        ColumnId(),
    ]
}

const stockArticleDefaultSort = {sortModel: [{field: 'artNo', sort: 'asc'}]}

const TABLE_KEY = "STOCK_ARTICLE_PAGE"

class StockArticleListPage extends Component {
    render() {
        return (
            <div>
                <PaginatedDataGrid
                    definition={{
                        tableKey: TABLE_KEY,
                        columns: stockArticleColumns(),
                        initState: {
                            sorting: stockArticleDefaultSort,
                        },
                    }}
                    query={StockArticlesV2Document}
                />
            </div>
        )
    }
}


export default StockArticleListPage;
