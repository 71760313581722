import React from 'react';
import gql from "graphql-tag";
import {map} from 'lodash';
import {useMutation} from "@apollo/client";
import FormRenderer from "../../common/FormRenderer";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {InventoryRoute} from "../InventoryRouters";
import {ArticleProductType} from "./ProductType.enum";
import {InventoryArticleCategoryType} from "../../generated/inventoryGraphql";

export default (props) => {
    const {toggle} = props
    const history = props.history
    const [create] = useMutation(gql`mutation($article:ArticleInputType!){
        createArticle(article: $article)
        {
            id
        }
    }`)
    return <FormRenderer onSubmit={async ({object}) => {
        try {
            const res = await create({variables: {article: object}})
            NotificationPopup.success(`Created article`);
            history.push(`${InventoryRoute.ARTICLE_DETAIL}/${res.data.createArticle.id}`);
        } catch (e) {
            NotificationPopup.error(`Failed to create article ${e.message}`);
        }

        toggle();
    }} object={{name: '', sellOnline: false}} columns={2} formDefinition={[
        {name: "name", label: "Name(SV)", required: true},
        {name: "nameEn", label: "Name(EN)"},
        {name: "nameDe", label: "Name(DE)"},
        {name: "nameNo", label: "Name(NO)"},
        {name: "description",},
        {name: "artNo", required: true},
        {name: "lowAlert", required: true, type: "currency"},
        {name: "price", required: true, type: "currency"},
        {name: "currency", required: true, type: "currencyPicker"},
        {name: "purchasePrice", required: true, type: "currency"},
        {name: "purchaseCurrency", required: true, type: "currencyPicker"},
        {name: "internalPrice", required: true, type: "currency"},
        {name: "internalCurrency", required: true, type: "currencyPicker"},
        {name: "sellOnline", type: "checkbox"},
        {
            name: "productType",
            type: "select",
            options: map(ArticleProductType, (type, key) => ({id: key, name: key}))
        },
        {
            name: "category", type: "select", required: true, options:
                map(InventoryArticleCategoryType, (type, key) => ({id: type, name: key}))
        }
    ]}/>
}
