import React from 'react';
import StdQuery from "../common/StdQuery";
import gql from "graphql-tag";
import {Button, Col, Row} from "reactstrap";
import FormRenderer from "../common/FormRenderer";
import UpdateCompany from './graphql/UpdateCompany.graphql';
import {useMutation} from "@apollo/client";
import NotificationPopup from "../common/lib/NotificationPopup";
import logger from "../Logger";
import Auth, {IsEconomy} from "../Auth";
import DetailRenderer from "../common/DetailRenderer";
import _ from 'lodash';
import moment from "moment";
import GetTermsOfPayment from '../common/graphql/GetTermsOfPayment.graphql';

const getCompany = gql`
    query getCompany($id:ID!)
    {
        company(id:$id)
        {
            id
            name
            organisationNumber
            customerNumber: id
            billingCountry
            deliveryCountry
            creditRatingComment
            creditRatingDate
            creditRatingLimit
            creditRatingLimitCurrency
            defaultTermsOfPayment
            {
                id
                name
            }
        }
    }
`;


export default ({match:{params:{id}},history})=>
{
    const altRender = <p>You do not have the economy group. No access. <br/>
        User: {Auth.getUsername()} Groups: {Auth.getGroups()}</p>;
    const [updateCompany] = useMutation(UpdateCompany,{refetchQueries:["getCompany"]});
    return <StdQuery query={getCompany} variables={{id}}>
        {data=>
        {
            const {company} = data;
            const locale = company?company.locale:null;
            if(!company)
            {
                return `failed to find company by id ${id}`;
            }

            return <IsEconomy altRender={altRender}>
                <Button onClick={()=>history.push(`/companyDetail/${company.id}`)} >
                    Back to company
                </Button>
                <h1>Credit Rating - {company.name}</h1>
                <Row>
                    <Col sm={4}>
                        <DetailRenderer hideUpdateInfo object={_.pick(company,
                        ["organisationNumber","customerNumber",
                            "billingCountry","deliveryCountry"])} columns={2} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <FormRenderer object={company} formDefinition={[
                            {
                                name: "creditRatingComment", type: "textarea"
                            },
                            {
                                name: "creditRatingDate", type: "date", defaultValue: moment().format("YYYY-MM-DD")
                            },
                            {name:"defaultTermsOfPaymentId",label:`Default Terms of payment`,
                                type:"select",query:GetTermsOfPayment,
                                variables:{locale}},
                        ]} onSubmit={async({object}) =>
                        {
                            try{
                                const res = await updateCompany({
                                    variables:
                                    {
                                        id,
                                        company: object
                                    }
                                });
                                if(res.errors)
                                {
                                    NotificationPopup.error(`Failed to update credit rating. ${res.errors.toString()}`);
                                }
                                else
                                {
                                    NotificationPopup.success(`Updated the credit rating of ${company.name}`);
                                    logger.info(`${Auth.getUsername()} updated credit rating of ${company.name}`,{object});
                                }
                            }
                            catch(e)
                            {
                                NotificationPopup.error(`Failed to update credit rating. ${e.message}`);
                            }
                        }}/>

                    </Col>
                </Row>
            </IsEconomy>
        }}
    </StdQuery>
}
