import {GridPaginationModel, GridSortModel, GridValidRowModel,} from '@mui/x-data-grid';
import React, {useCallback, useEffect, useRef, useState} from "react";
import queryString from "qs";
import {withRouter} from "react-router";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import _, {omit} from 'lodash';
import {Comparator, FilteringItemInputType, FilterLogicOperator,} from "../../generated/graphql";
import {useApolloClient} from "@apollo/client";
import {GridInitialStatePro} from "@mui/x-data-grid-pro/models/gridStatePro";
import Auth from "../../Auth";
import {Button, ButtonToolbar} from "reactstrap";
import {lookup} from "../../UTIL";
import {ColumnDefinition} from "./ColumnDefinition";
import BaseDataGrid, {KEY_PAGE_SIZE, TableButton} from "./BaseDataGrid";
import {DataGridProProps} from "@mui/x-data-grid-pro";
import NotificationPopup from "../lib/NotificationPopup";

const mapComparator = (operator?: string) =>
{
    switch (operator?.toLowerCase())
    {
        case 'contains':
            return Comparator.Contains;
        case 'equals':
        case 'is':
        case '=':
            return Comparator.Equals;
        case 'not':
        case '!=':
            return Comparator.NotEqual;
        case 'after':
        case 'onorafter': // onOrAfter
        case "gte":
        case ">=":
            return Comparator.Gte;
        case 'before':
        case 'onorbefore': // onOrBefore
        case "lte":
        case "<=":
            return Comparator.Lte;
        case "in":
            return Comparator.In;
        case "startswith": // startsWith
            return Comparator.StartsWith;
        case 'isnotempty': //isNotEmpty
            return Comparator.IsNotEmpty
        default:
            throw new Error(`unknown comparator: ${operator}`)
    }
}


type QueryState = {
    filter?: GridFilterModel
    sort?: GridSortModel
    page?: GridPaginationModel
}



type PaginatedTableDefinition = {
    tableKey: string
    columns: ColumnDefinition []
    initState?: GridInitialStatePro
    assignedToMeLabel?: string
    buttons?: TableButton[]
}

type Props = DataGridProProps<GridValidRowModel> & {
    definition: PaginatedTableDefinition
    query: any
    queryVariables: any
    filterTenantId?: boolean
    persistFilter?: FilteringItemInputType[]
    history: any
}

const DEFAULT_PAGE_SIZE = 20

const QUERY_PAGE = 'page'
const QUERY_PAGE_SIZE = 'pageSize'
const QUERY_SORT_ORDER = 'sortOrder'
const QUERY_ID = 'id'
const QUERY_SEARCH = 'searching'
const QUERY_FILTER_OPERATOR = 'filterOperator'

const fetchData = async (definition, setData, setLoading, queryState, query, client, history, filterTenantId, persistFilter, queryVariables) => {
    console.log("fetchData")
    setLoading(true);

    const variables: any = {}
    let search = '?';

    // page
    const pageIndex = queryState.page?.page ?? 0;
    const pageSize = queryState.page?.pageSize ?? DEFAULT_PAGE_SIZE;
    search += `${QUERY_PAGE}=${pageIndex}&${QUERY_PAGE_SIZE}=${pageSize}`;
    variables['pagination'] = {
        pageIndex: pageIndex,
        pageSize: pageSize
    }

    // filter
    const filterItems = queryState.filter?.items
    const filters = filterItems && filterItems.length > 0
        ? filterItems.filter((e) => {
                if (e.operator === 'isNotEmpty')
                {
                    return true
                }
                return e.value != null && e.value !== ''})
            .map((e) => {
                const key = e.field;
                let value = e.value ?? ''

                const data = definition.columns.find((e) => e.field === key)
                if (data?.type === 'number') {
                    value = Number(value);
                }
                return {
                    key: data && data.customFilterField ? data.customFilterField : e.field,
                    value: data && data.parseFilterValue ? data.parseFilterValue(value) : value,
                    comparator: mapComparator(e?.operator),
                }
            })
        : []

    if (filterTenantId) {
        filters.push({key: 'tenantId', value: Auth.getTenant(), comparator: Comparator.Equals})
    }

    if (persistFilter) {
        persistFilter.forEach((e) => filters.push(e))
    }

    if (filters.length > 0) {
        search += `&${queryString.stringify(filterItems, {allowDots: true})}`;

        const logicOperator = queryState.filter?.logicOperator ?? FilterLogicOperator.And
        search += `&${QUERY_FILTER_OPERATOR}=${logicOperator}`;

        variables['filtering'] = {
            items: filters,
            operator: logicOperator?.toUpperCase()
        }
    }

    // search
    if (queryState?.filter?.quickFilterValues && queryState?.filter?.quickFilterValues.length > 0) {
        let value = ""
        queryState?.filter?.quickFilterValues.forEach((e) => value += `${e} `)
        value = value.trim()

        const searchFields = definition.columns.map((e) => {
            if (e.searchable) {
                return e.customSearchField ?? e.field
            }
            return null
        })
            .filter((e) => e != null)

        const searchInput = {fields: searchFields, value: value}
        search += `&${QUERY_SEARCH}=${value}`;
        variables['searching'] = searchInput
    }

    // order
    let ordering: any = null
    if (queryState?.sort && queryState?.sort?.length > 0) {
        const sort = queryState.sort[0]
        const sortId = definition.columns.find((e) => e.field === sort.field)
            ?.customSortField ?? sort.field
        ordering = {sortOrder: sort.sort, id: sortId}
        search += `&${queryString.stringify(ordering)}`;
        variables['ordering'] = ordering
    }

    if (window.location.search !== search) {
        history.replace(`${window.location.pathname}${search}`);
    }

    try {
        const res = await client.query(
            {
                query: query,
                variables: {...variables, ...queryVariables}
            }
        );
        const rows = res.data.result.list.map((e) => {
                let row: any = {}
                definition.columns.forEach((column) => {
                    row[column.field] = lookup(e, column.field)
                })
                return {...row, ...e}
            }
        )
        setData({rows: rows, totalCount: res.data.result.total});
    } catch (e: any) {
        NotificationPopup.error(`Failure to load data for datagrid. ${e.message}`);
    } finally {
        setLoading(false);
    }
}

const PaginatedDataGrid = (props: Props) =>
{
    const {definition, query, history, filterTenantId, persistFilter, queryVariables} = props
    const client = useApolloClient()
    const [data, setData] = useState({rows: [], totalCount: 0})
    const [loading, setLoading] = useState(false)
    const prevIsLoading =  useRef(false);
    const hadRequestFetchWhenLoading = useRef(false);

    const parsedParams = queryString.parse(window.location.search, {ignoreQueryPrefix: true, allowDots: true});

    const getFilterItems = () =>
    {
        if (Object.keys(parsedParams).length === 0)
        {
            return definition.initState?.filter?.filterModel?.items ?? []
        }
        return _.map(_.omit(parsedParams, [QUERY_PAGE, QUERY_PAGE_SIZE, QUERY_SORT_ORDER, QUERY_ID, QUERY_SEARCH, QUERY_FILTER_OPERATOR]), (value) => value)
    }

    const [queryState, setQueryState] = useState<QueryState>(
        {
            filter: {
                items: getFilterItems(),
                logicOperator: parsedParams.filterOperator,
                quickFilterValues: parsedParams.searching ? [parsedParams.searching] : undefined,
            },
            sort: parsedParams.id && parsedParams.sortOrder
                ? [{field: parsedParams.id, sort: parsedParams.sortOrder}]
                : definition.initState?.sorting?.sortModel
                ?? [{field: "id", sort: "asc"}],
            page: {
                pageSize: parsedParams.pageSize
                    ? parseInt(parsedParams.pageSize)
                    : parseInt(localStorage.getItem(`${definition.tableKey}${KEY_PAGE_SIZE}`) ?? `${DEFAULT_PAGE_SIZE}`),
                page: parsedParams.page ? parseInt(parsedParams.page) : 0,
            }
        }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFetchData = useCallback(_.debounce(fetchData, 500), []);

    useEffect(() => { // debounce and prevent fetch during loading. Put off the fetch till previous fetch is done.
        const fetch = async () => {
            debounceFetchData(definition, setData, setLoading, queryState, query, client, history, filterTenantId, persistFilter, queryVariables);
        }

        if(loading && prevIsLoading.current){ // if the update is during loading
            hadRequestFetchWhenLoading.current = true;
            return;
        }

        if((prevIsLoading.current && !loading && hadRequestFetchWhenLoading.current) || // if loading finish but have some updates during loading
            (!loading && !prevIsLoading.current) // simply some updates
        ){
            hadRequestFetchWhenLoading.current = false;
            fetch().then();
        }

        prevIsLoading.current = loading;
    }, [loading, setLoading, queryState, query, client, history, filterTenantId, persistFilter, queryVariables, debounceFetchData, definition]);

    const onFilterChange = useCallback((filterModel: GridFilterModel) =>
    {
        setQueryState({...queryState, filter: filterModel});
    }, [queryState]);

    const onSortChange = useCallback((sortModel: GridSortModel) =>
    {
        setQueryState({...queryState, sort: sortModel});
    }, [queryState]);

    const onPageChange = useCallback((pageModel: GridPaginationModel) =>
    {
        setQueryState({...queryState, page: pageModel});
    }, [queryState]);

    const isFilterButtonOutline = useCallback((btn: TableButton) =>
    {
        const queryFilterItems = queryState.filter?.items.map((e) => omit(e, "id"))
        return !_.isEqual(btn.filters?.items, queryFilterItems)
    }, [queryState.filter])

    const buttons = () =>
    {
        const {columns, buttons, assignedToMeLabel} = definition;
        const assignable = _.some(columns, {field: 'assignee.username'});
        const assignFilter = [{field: 'assignee.username', value: Auth.getUsername(), operator: 'is'}];
        return <ButtonToolbar style={{marginBottom: '16px'}}>
            {assignable && <Button outline style={{marginRight: "8px"}} color="primary"
                                   active={_.isEqual(queryState.filter?.items, assignFilter)}
                                   onClick={() => onAssigneeToMeClick(assignFilter)}>
                {assignedToMeLabel ?? "Assigned to me"}
            </Button>}
            {buttons && buttons.map(preset =>
            {
                return <Button outline={isFilterButtonOutline(preset)} style={{marginRight: "8px"}} key={preset.name}
                               active={_.isEqual(queryState.filter?.items, preset.filters)}
                               onClick={() =>
                               {
                                   const change = {
                                       filter: preset.filters,
                                       sort: preset.sort ? preset.sort : queryState.sort
                                   };

                                   setQueryState(change)
                               }}>
                    {preset.name}
                </Button>;
            })}
            <Button outline color="warning"
                    active={_.isEqual(queryState.filter?.items, [])}
                    onClick={onClearFilterClick}>
                Clear all filters
            </Button>
        </ButtonToolbar>
    };

    const onAssigneeToMeClick = useCallback((assignFilter) =>
    {
        setQueryState(
            {
                ...queryState,
                filter: {items: assignFilter},
                page: {
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE
                }
            })
    }, [queryState])

    const onClearFilterClick = useCallback(() =>
    {
        setQueryState(
            {
                ...queryState,
                filter: {items: []},
                page: {
                    page: 0,
                    pageSize: DEFAULT_PAGE_SIZE
                }
            })
    }, [queryState])

    return (
        <div>
            {buttons()}
            <BaseDataGrid
                {...props}
                loading={loading}
                tableKey={definition.tableKey}
                initialState={
                    {
                        filter: {filterModel: queryState.filter},
                        sorting: {sortModel: queryState.sort},
                        pagination: {paginationModel: queryState.page},
                        columns: definition.initState?.columns ?? {columnVisibilityModel: {id: false}}
                    }
                }
                rows={data.rows}
                columns={definition.columns}
                paginationModel={queryState.page}
                onPageChange={onPageChange}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                paginationMode="server"
                rowCount={data.totalCount}
                filterModel={queryState.filter}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                sortingMode="server"
                sortModel={queryState.sort}
                onSortModelChange={onSortChange}
            />
        </div>
    );
}


export default withRouter(PaginatedDataGrid);
