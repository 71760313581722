import {useCallback, useEffect, useState} from "react";
import {
    ArticleRow, Segment, SpiralStair, SpiralStairInputType,
    useCreateSegmentMutation, useGetPriceLazyQuery, useGetPrioritisationQuery,
    useRemoveSegmentMutation,
    useUpdateArticleRowMutation,
    useUpdateSegmentsMutation,
    useUpdateSpiralStairMutation
} from "../generated/graphql";
import {getSpiralStairForm} from "./definitions";
import {prepare} from "../common/NewFormRenderer";
import NotificationPopup from "../common/lib/NotificationPopup";
import {filter, omit} from 'lodash';
import {nextSegmentIndex} from "../UTIL";
import Logger from "../Logger";
import * as _ from "lodash"
import useUpdatePurchaseOrderPriceHook from "../common/update-purchase-order-price/useUpdatePurchaseOrderPriceHook";

export const useSpiralStairActions = (spiralStair:SpiralStair,articleRow:Partial<ArticleRow> | null | undefined)=>
{
    const [saveLoading,setSaveLoading] = useState(false);
    const [spiralStairState,setSpiralStair] = useState<Partial<SpiralStair>>({
            ...prepare(spiralStair, getSpiralStairForm()),
            drawingInformation:spiralStair.drawingInformation,
            revisionTag: spiralStair.revisionTag
        } as Partial<SpiralStair>
    );
    const [articleRowModel,setArticleRowModel] = useState(articleRow);
    const [segments,setSegments] = useState(spiralStair.segments as Segment[]);
    const [priceSuggestion,setPriceSuggestion] = useState({price:0,inPrice:0,currency:"SEK", logs:[] as any[]});

    const [updateSpiralStair] = useUpdateSpiralStairMutation({
        refetchQueries:["spiralStairDetail"]
    });
    const {data:prioritisationList} = useGetPrioritisationQuery();
    const [getPriceQuery] = useGetPriceLazyQuery({variables:{ id:spiralStair.id,articleRowId:articleRow?.id}});
    const [updateSegments] = useUpdateSegmentsMutation({refetchQueries:["spiralStairDetail"]});
    const [removeSegment] = useRemoveSegmentMutation({refetchQueries:["spiralStairDetail"]});
    const [saveArticleRow] = useUpdateArticleRowMutation({refetchQueries:["articleRow"]});
    const [addSegment] = useCreateSegmentMutation({refetchQueries:["spiralStairDetail"]});

    const {
        newPriceModalData,
        closeNewPriceModal,
        updatePurchaseOrderPrice,
        checkNewPriceData,
    } = useUpdatePurchaseOrderPriceHook({purchaseOrder: spiralStair?.purchaseOrder, priceSuggestion, articleId: spiralStair.articleId})

    const getPrice = useCallback(async () =>
    {
        const response = await getPriceQuery()
        const data = response?.data?.spiralStair?.priceSuggestion
        if (!data)
        {
            return;
        }

        const {price, inPrice, currency, logs} = data;
        const priceSuggestion = {
            price: price,
            inPrice: inPrice,
            currency: currency || "",
            logs: logs ?? []
        }

        setPriceSuggestion(priceSuggestion);
        return priceSuggestion
    }, [getPriceQuery]);

    const save = useCallback(async ()=>
    {
        setSaveLoading(true);
        if(articleRow)
        {
            if(articleRowModel)
            {

                await saveArticleRow({
                    variables:
                    {
                        id:articleRow.id as string,
                        articleRow:
                        {
                            // @ts-ignore
                            price:parseFloat(articleRowModel?.price),
                            name:articleRowModel.name,
                            prioritisationId: articleRowModel.prioritisationId ? articleRowModel.prioritisationId : 0,
                        }
                    }
                });
            }
            else
            {
                throw new Error(`Article row model not set`);
            }
        }
        //
        // const updateSpiralStairState = {...spiralStairState};
        // updateSpiralStairState.surfaceTreatment = spiralStairState?.surfaceTreatment?.map(pc => _.pick(pc, ["id", "isChecked", "partName", "ralCode"]))
        await updateSpiralStair({variables:
            {
                id:spiralStair.id,
                spiralStair: _.omit(spiralStairState, "SurfaceTreatmentOverview") as SpiralStairInputType,
            }, refetchQueries:["spiralStairDetailBom","spiralStairDetail"]});
        await updateSegments({
            variables:
            {
                segments:segments?.map(s=>omit(s,"__typename"))
            },
            refetchQueries:["getPrice"]
        });
        NotificationPopup.success(`Successfully saved`);
        try
        {
            const priceSuggestion = await getPrice()
            checkNewPriceData(priceSuggestion?.inPrice);
        }
        catch(e:any)
        {
            NotificationPopup.error(`Failed to update price: ${e.message}`);
            Logger.error(e);
        }
        finally
        {
            setSaveLoading(false);
        }

    },[checkNewPriceData, articleRow, articleRowModel, saveArticleRow, segments,
        spiralStair.id, spiralStairState, updateSegments, updateSpiralStair, getPrice]);

    const deleteSegment = useCallback(async(e,segment)=>
    {
        e.stopPropagation();
        if(window.confirm(`Are you sure you want to delete Section ${segment.index + 1}?`))
        {
            await removeSegment({variables:
                {
                    id:segment.id
                }});
            const res = await updateSegments({variables:
                {
                    segments:filter(segments,({id})=>id!==segment.id)
                    .map((row,index) =>({...omit(row,["__typename"]),index}))
                },refetchQueries:["getPrice"]});
            setSegments(res.data?.updateSegments as Segment[]);

        }

    },[removeSegment, segments, updateSegments]);

    const copySegment = useCallback(async (e,segment)=>
    {
        e.stopPropagation();
        if(window.confirm(`Copy Section ${segment.index+1} ?`))
        {
            let newSegment:Segment = {
                ...omit(segment,["__typename","id","index"]),
                index:nextSegmentIndex(segments),
            } as Segment;
            const res = await addSegment({variables:
                    {
                        segment:newSegment,
                        spiralStairId:spiralStair.id
                    },refetchQueries:["getPrice"]});
            newSegment = res.data?.createSegment as Segment;
            setSegments(old=>[...old,newSegment]);
        }
    },[addSegment, segments, spiralStair.id]);

    const createSegment = useCallback(async()=>
    {
        let newSegment:Partial<Segment> = {
            index:nextSegmentIndex(segments),
            radius:600,
            railing1:false,
            railing2:false,
            railing3:false,
            railing4:false,
            height:0,
            stepsPrRot:13,
            stepHeights:0,
            console:0,
            startAngle:90,
            supportLegAmount:0,
            supportLegLength:0,
            strutAmount:0,
            strutLength:650,
            platformNumPickets:0,
            platformS1:0,
            platformS2:0,
        };
        const res = await addSegment({
            variables:
            {
                segment:newSegment,
                spiralStairId:spiralStair.id
            },
            refetchQueries:["getPrice"]
        });
        newSegment = res.data?.createSegment as Segment;
        setSegments(old=>[...old,newSegment as Segment]);
    },[addSegment, spiralStair.id, segments]);

    useEffect(() =>
    {
        getPrice()
    }, [getPrice])

    return {spiralStairState,setSpiralStair,segments,setSegments,articleRowModel,setArticleRowModel,
        save,createSegment,deleteSegment,copySegment,
        priceSuggestion,saveLoading, prioritisationList,
        checkNewPriceData, newPriceModalData, closeNewPriceModal, updatePurchaseOrderPrice};
}

