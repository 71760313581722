import NotificationPopup from "./common/lib/NotificationPopup";

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export const useMutationHandler = <T,D>(mutation:(d?:D)=>T,actionName:string, callBack?:(isSuccess:boolean)=>void)=>
{
    return async(d?:D)=>
    {
        try
        {
            await mutation(d);
            NotificationPopup.success(`${actionName} success`);
            if(callBack) callBack(true);
        }
        catch(e:any)
        {
            let errMsg=`${actionName} field. ${e.message}`;
            console.error(errMsg);
            NotificationPopup.error(errMsg);
            if(callBack) callBack(false);
        }
    };
}

export const enumToOptions = (data: { [p: string]: string }, nameMapFn?: (value: string) => string) =>
{
    return Object.entries(data).map(([key, value]) =>
    {
        return {id: value, name: nameMapFn ? nameMapFn(key) : key}
    })
}