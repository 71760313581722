import {UncontrolledTooltip} from "reactstrap";
import React from "react";


export default (props) =>
{
    const {children} = props
    return <UncontrolledTooltip
        {...props}
        style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            color: "#333",
            padding: "9px",
            textAlign: "left"
        }}>
        {children}
    </UncontrolledTooltip>
}