import ColumnId from "../common/data-grid/columns/ColumnId";
import React from "react";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import {Link} from "react-router-dom";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {GetShipmentsDocument, ShipmentStatus} from "../generated/graphql";
import ColumnStatus from "../common/data-grid/columns/ColumnStatus";
import {ShipmentStatusExt} from "./model/ShipmentStatusExt";


const columns = () =>
{
    return [
        {
            field: "number",
            headerName: "Number",
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`/shipment-detail/${params.id}`}>{params.value ?? "-"}</Link>
            }
        },
        ColumnStatus(Object.values(ShipmentStatus).map((e) => ({
            id: e,
            name: ShipmentStatusExt.label(e),
            color: ShipmentStatusExt.color(e)
        }))),
        ColumnDate("pickupDate", "Pickup date"),
        {
            field: "pickupEarliest",
            headerName: "Pickup earliest",
            filterable: false,
        },
        {
            field: "pickupLatest",
            headerName: "Pickup latest",
            filterable: false,
        },
        {
            field: "packages",
            width: 200,
            sortable: false,
            searchable: true,
            customFilterField: 'packages.some.mark',
            customSearchField: 'packages.some.mark',
            renderCell: (params) =>
            {
                return <div>
                    {
                        params.value.map((e) => <Link style={{display: 'block'}}
                                                      to={`/package-detail/${e.id}`}>
                            {e.mark ?? "-"}
                        </Link>)
                    }
                </div>
            }
        },
        ColumnDate("createdAt", "Created at"),
        ColumnDate("savedAt", "Saved at"),
        ColumnDate("bookedAt", "Booked at"),
        ColumnId(),
    ]
}

const TABLE_KEY = "SHIPMENT"

const columnVisibilityModel = {
    columnVisibilityModel: {
        id: false,
    }
}

const sortPickupDate = {field: 'pickupDate', sort: 'desc'}

const filterNotBookOrFailed = {items: [{field: 'status', value: ['failed', 'notBooked'], operator: 'in'}]}

export default (props) =>
{
    return <div>
        <PaginatedDataGrid
            definition={{
                tableKey: TABLE_KEY,
                columns: columns(),
                initState: {
                    sorting: {sortModel: [sortPickupDate]},
                    columns: columnVisibilityModel,
                    filter: {
                        filterModel: filterNotBookOrFailed
                    }
                },
                buttons: [{
                    name: "Undelivered shipments",
                    filters: filterNotBookOrFailed,
                    sort: [sortPickupDate]
                }]

            }}
            query={GetShipmentsDocument}
        />
    </div>
}
