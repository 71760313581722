import {
    ActionEvent,
    ActionManager,
    Axis,
    BoundingBox,
    Color3,
    CreateSphere,
    ExecuteCodeAction,
    IAction,
    Material,
    Mesh,
    Quaternion,
    Space,
    Tools
} from "@babylonjs/core";
import {Scene} from "@babylonjs/core/scene";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {State} from "./ModuleRampPage";
import ModuleTemplate, {pairNodeDistance} from "./ModuleTemplate";
import {MaterialName} from "./Materials";
import {getAllModuleObject, setPickedModule} from "./utils";
import SceneManager, {IntegratedScene, SceneName} from "./SceneManager";
import {ModuleRampType} from "../generated/graphql";
import {Modes} from "./Modes";

export class ShapeObject {

    public get origin(): Vector3 {
        return this._origin;
    }

    public set origin(origin: Vector3)
    {
        // const moveOffset = origin.subtract(this._origin);
        // this.eventBoxes.forEach(eventBox=>{
        //     eventBox.position = eventBox.position.add(moveOffset);
        // })
        this._origin = origin;
    }

    static objectCount: number = 0;
    id: number = 0;
    children: ShapeObject[] = [];
    nodes: Node[] = [];
    ancestor: ShapeObject = this;
    vector: Vector3 = Vector3.Zero();
    meshes:Mesh[] = [];
    eventBoxes: Mesh[] = [];
    onMouseOverAction: IAction | undefined;
    onMouseOutAction: IAction | undefined;
    onMouseClickAction: IAction | undefined;

    private _material:Material|null = null;
    private onMouseOverFn;
    private onMouseOutFn;
    private onMouseClickFn;

    constructor(
        public parent: ShapeObject | Scene = SceneManager.getInstance()[SceneName.main],
        protected _origin: Vector3,
        protected readonly scene: IntegratedScene = SceneManager.getInstance()[SceneName.main],
    )
    {
        this.id = ShapeObject.objectCount++;

        if(parent instanceof ShapeObject){
            this.ancestor = parent;
            while(this.ancestor.parent instanceof ShapeObject){
                this.ancestor = this.ancestor.parent;
            }
        }

        this.meshes.push = (...meshArray) => {
            if(this._material){
                meshArray.forEach((mesh)=>{
                    mesh.material = this._material;
                });
            }
            meshArray.forEach((mesh)=>{
                this._addToHighlightLayer(mesh);
            });

            return Array.prototype.push.apply(this.meshes, meshArray);
        };
    }

    // Make parts underground visible with a border
    _addToHighlightLayer(mesh:Mesh) {
        if(mesh.id.startsWith("eventTrigger")) return;

        if(mesh.getRawBoundingInfo().boundingBox.maximumWorld.y < -0){
            this.scene.highlightLayer?.addMesh(mesh, Color3.White());
        } else {
            this.scene.highlightLayer?.removeMesh(mesh);
        }
    }

    move(offset: Vector3) {
        this.origin = this.origin.add(offset);
        this.children.forEach(child => {
            child.move(offset);
        });
        this.meshes.forEach(mesh => {
            mesh.position = mesh.position.add(offset);
            this._addToHighlightLayer(mesh);
        });
    }

    rotate(axis:Vector3, degree:number, rotateOrigin:Vector3 = this.origin) {
        if(degree===0){
            return;
        }
        const rotQuart = Quaternion.RotationAxis(axis, Tools.ToRadians(degree) );

        this.origin.rotateByQuaternionAroundPointToRef(rotQuart, rotateOrigin, this.origin);

        this.children.forEach(child => {
            let childNewOrigin:Vector3 = Vector3.Zero();
            child.origin.rotateByQuaternionAroundPointToRef(rotQuart, rotateOrigin, childNewOrigin);
            const offset = childNewOrigin.subtract(child.origin);
            child.move(offset);
            child.rotate(axis, degree);
        });

        this.meshes.forEach(mesh => {
            mesh.position.rotateByQuaternionAroundPointToRef(rotQuart, rotateOrigin, mesh.position);
            mesh.rotate(axis, Tools.ToRadians(degree), Space.WORLD);
            this._addToHighlightLayer(mesh);
        });

        this.vector.rotateByQuaternionToRef(rotQuart, this.vector);
    }

    get material(){
        return this._material;
    }

    set material(m){
        this.meshes.forEach(mesh=>{
            mesh.material = m;
        })
        this._material = m;
    }


    // getAncestor() {
    //     let ancestor:ShapeObject = this;
    //     while(!(ancestor.parent instanceof Scene) && ancestor.parent){
    //         ancestor = ancestor.parent;
    //     }
    //
    //     return ancestor;
    // }

    // getAllAttachableNodes(nodeAttachableTypes:NodeAttachableType[] = allNodeAttachableTypes):Node[]{
    //     const nodeList:Node[] = [];
    //     this.nodes.forEach(node=>{
    //         const mach = nodeAttachableTypes.some(r=> node.nodeAttachableTypes.indexOf(r) >= 0); // if child is one of any acceptable types.
    //         if(mach){
    //             nodeList.push(node);
    //         }
    //     })
    //
    //     return nodeList;
    // }

    getBoundingBox(){
        const allMeshes = this.getAllMeshes();
        if(allMeshes.length===0) return new BoundingBox(Vector3.Zero(), Vector3.Zero());

        this.scene.render(); // render to make sure bounding box calculate
        const shapeBoundingBox = new BoundingBox(
            new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
            new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE)
        );

        allMeshes.forEach((mesh) =>{
            const boundingBox = mesh.getBoundingInfo().boundingBox;
            shapeBoundingBox.reConstruct(
                Vector3.Minimize(shapeBoundingBox.minimumWorld, boundingBox.minimumWorld),
                Vector3.Maximize(shapeBoundingBox.maximumWorld, boundingBox.maximumWorld)
            );
        });
        return shapeBoundingBox;
    }

    activateEventBox() {
        this.eventBoxes.forEach(eventBox=>{
            eventBox.setEnabled(true);
        })
    }

    deactivateEventBox() {
        this.eventBoxes.forEach(eventBox=>{
            eventBox.setEnabled(false);
        })
    }

    clearAction() {
        this.eventBoxes.forEach(eventBox=>{
            if(!eventBox.actionManager) return;
            if(this.onMouseOverAction){
                eventBox.actionManager.unregisterAction(this.onMouseOverAction);
            }
            if(this.onMouseOutAction){
                eventBox.actionManager.unregisterAction(this.onMouseOutAction);
            }
            if(this.onMouseClickAction){
                eventBox.actionManager.unregisterAction(this.onMouseClickAction);
            }
        });

        this.children.forEach(shape=>{
            shape.clearAction();
        });
    }

    onOver( func?:((evt:ActionEvent)=>void) | null | undefined) {
        if(func===undefined){
            return this.onMouseOverFn();
        }

        this.eventBoxes.forEach(eventBox=>{
            if(this.onMouseOverAction && eventBox.actionManager){
                eventBox.actionManager.unregisterAction(this.onMouseOverAction);
            }
        });

        this.onMouseOverFn = func;
        if(func){
            this.onMouseOverAction = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, func);
            this.eventBoxes.forEach(eventBox=>{
                if(this.onMouseOverAction && eventBox.actionManager){
                    eventBox.actionManager.registerAction(this.onMouseOverAction);
                }
            });
        }
    }

    onOut( func?:((evt:ActionEvent)=>void) | null) {
        if(func===undefined){
            return this.onMouseOutFn();
        }

        this.eventBoxes.forEach(eventBox=>{
            if(this.onMouseOutAction && eventBox.actionManager){
                eventBox.actionManager.unregisterAction(this.onMouseOutAction);
            }
        });

        this.onMouseOutFn = func;
        if(func) {
            this.onMouseOutAction = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, func);
            this.eventBoxes.forEach(eventBox=>{
                if(this.onMouseOutAction && eventBox.actionManager){
                    eventBox.actionManager.registerAction(this.onMouseOutAction);
                }
            });
        }
    }

    onClick( func?:((evt:ActionEvent)=>void) | null) {
        if(func===undefined){
            return this.onMouseClickFn();
        }

        this.eventBoxes.forEach(eventBox=>{
            if(this.onMouseClickAction && eventBox.actionManager){
                eventBox.actionManager.unregisterAction(this.onMouseClickAction);
            }
        });

        this.onMouseClickFn = func;
        if(func) {
            this.onMouseClickAction = new ExecuteCodeAction(ActionManager.OnPickTrigger, func);
            this.eventBoxes.forEach(eventBox=>{
                if(this.onMouseClickAction && eventBox.actionManager){
                    eventBox.actionManager.registerAction(this.onMouseClickAction);
                }
            });
        }
    }

    attachTo(attachNode:Node, fromNode:Node){}

    getAllMeshes(){
        let allMeshes = [...this.meshes];
        this.children.forEach(shape=>{
            allMeshes = allMeshes.concat(shape.getAllMeshes())
        })
        return allMeshes;
    }

    getAllChildren(){
        let allChildren = [...this.children];
        this.children.forEach(shape=>{
            allChildren = allChildren.concat(shape.getAllChildren())
        })
        return allChildren;
    }

    remove(){
        this.meshes.forEach((mesh)=>{
            this.scene.removeMesh(mesh);
        })
        this.eventBoxes.forEach(eventBox=>{
            this.scene.removeMesh(eventBox);
        })

        this.nodes.forEach((node)=>{
            node.attach.forEach((attachedNode)=>{
                let index = attachedNode.attach.indexOf(node);
                if (index !== -1) {
                    attachedNode.attach.splice(index, 1);
                }
            })
        })

        this.children.forEach((module)=>{
            module.remove();
        })

    }

    enableEdgesRendering(options?:{edgesWidth, edgesColor}){
        this.meshes.forEach((mesh)=>{
            mesh.enableEdgesRendering();
            mesh.edgesWidth = options?.edgesWidth | 1;
            mesh.edgesColor = options?.edgesColor;

            mesh.outlineWidth = 0.1;
            mesh.outlineColor = options?.edgesColor;
            mesh.renderOutline = true;

            mesh.material = this.scene.materialStore[MaterialName.whiteMaterial];
        })

        this.children.forEach((module)=>{
            module.enableEdgesRendering(options);
        })
    }

    disableEdgesRendering(){
        this.meshes.forEach((mesh)=>{
            mesh.disableEdgesRendering();
            mesh.renderOutline = false;
            mesh.material = this._material;
        })

        this.children.forEach((module)=>{
            module.disableEdgesRendering();
        })
    }
}


export class ModuleObject extends ShapeObject{
    static moduleObjectCount: number = 0;
    moduleId = 0;
    type: ModuleRampType | undefined;
    isPicked = false;
    moduleTemplate: ModuleTemplate;

    constructor(moduleTemplate:ModuleTemplate, protected readonly scene:IntegratedScene=SceneManager.getInstance()[SceneName.main]){
        super(scene, moduleTemplate.origin.clone(), scene);
        this.moduleTemplate = moduleTemplate.clone();
        this.moduleId = this.moduleTemplate.moduleId <= 0 ? ModuleObject.moduleObjectCount++ : this.moduleTemplate.moduleId;
        this.moduleTemplate.moduleId = this.moduleId;
    }

    build(){}
    init(){ // This init() must call from inherited class constructor to make sure all props claim in inherited class are accessible.
        this.build();
        this.setupEventBox();
        this.rotate(Axis.Y, this.moduleTemplate.rotationY, this.origin, true);
        this.move(this.moduleTemplate.origin.subtract(this.origin));
    }

    setupEventBox() {
        const actionManager = new ActionManager(this.scene);
        const allChildren = [this, ...this.getAllChildren()];

        allChildren.forEach(shape=>{
            if(shape instanceof Node) return;
            this.eventBoxes = this.eventBoxes.concat(shape.meshes);
        })
        this.eventBoxes.forEach(eventBox=>{
            eventBox.actionManager = actionManager;
            eventBox.outlineWidth = 0.2;
            eventBox.outlineColor = new Color3(245/255, 159/255, 36/255);
            eventBox.isPickable = true;
        });

        allChildren.forEach(shape=>{
            if(shape instanceof Node) return;
            shape.onOver(()=>{
                if(State.getInstance().mode !== Modes.normal) return;
                shape.eventBoxes.forEach(eventBox=>{
                    eventBox.outlineWidth = 0.2;
                    eventBox.outlineColor = new Color3(245/255, 159/255, 36/255);
                    eventBox.renderOutline = true;
                })
            });
            shape.onOut(()=>{
                shape.eventBoxes.forEach(eventBox=>{eventBox.renderOutline = this.isPicked;})
            });
            shape.onClick(()=>{
                if(State.getInstance().mode !== Modes.normal) return;

                setPickedModule(this);
                this.eventBoxes.forEach(eventBox=>{
                    eventBox.outlineColor = new Color3(255/255, 170/255, 50/255);
                    eventBox.isPickable = false;
                    eventBox.renderOutline = true;
                });
                this.isPicked = true;
            });
        })
    }

    rotate(axis:Vector3, degree:number, origin:Vector3 = this.origin, isInit = false) {
        super.rotate(axis, degree, origin);
        if (axis.equals(Axis.Y) && !isInit) {
            this.moduleTemplate.rotationY += degree;
        }
    }

    move(offset: Vector3) {
        super.move(offset);
        this.moduleTemplate.origin = this.origin;
    }

    remove() {
        super.remove();
        State.getInstance().models = State.getInstance().models.filter(m => m!==this);
    }

    clone(scene:Scene):ModuleObject {return this;}
}

export class InvisibleModuleObject extends ModuleObject{
    init(){}
    move(){}
    rotate(){}
    remove() {
        State.getInstance().models = State.getInstance().models.filter(m => m!==this);
    }
}

// This is for selection and operate
export class ModuleObjectGroup {
    nodes:Node[] = []; // keep this empty, just for align to ModuleObject
    moduleObjects: ModuleObject[] = [];
    moduleIds: number[] = [];
    eventBoxes: Mesh[] = [];
    isPicked = false;
    displayData:{artNos:string[], otherProps:any} = {artNos:[], otherProps:{}};

    constructor(moduleIds: number[] = [] ,protected readonly scene:IntegratedScene = SceneManager.getInstance()[SceneName.main]){
        this.moduleIds = moduleIds;
        this.init();
    }

    init(){
        if(this.moduleIds.length !== this.moduleObjects.length){
            this.moduleObjects = getAllModuleObject().filter(m =>
                this.moduleIds.includes(m.moduleId)
            );
            this.displayData.artNos = [];
            this.moduleObjects.forEach( module => {
                this.displayData.artNos.push(...module.moduleTemplate.artNos);
            });
            this.overrideChildrenEventBox();
        }
    }

    addModule(...modules: ModuleObject[]) {
        this.moduleObjects.push(...modules);
        this.moduleIds.push(...modules.map(m => m.moduleId));
        modules.forEach( module => {
            this.displayData.artNos.push(...module.moduleTemplate.artNos);
        });
    }

    overrideChildrenEventBox() {
        const actionManager = new ActionManager(this.scene);
        let allChildren = this.moduleObjects.reduce((all, object) => all.concat(object, ...object.getAllChildren()) , [] as ShapeObject[]);

        allChildren = allChildren.filter(m => !(m instanceof Node));
        allChildren.forEach(shape=> {
            this.eventBoxes = this.eventBoxes.concat(shape.meshes);
        })
        this.eventBoxes.forEach(eventBox=>{
            eventBox.actionManager = actionManager;
            eventBox.outlineWidth = 0.2;
            eventBox.outlineColor = new Color3(245/255, 159/255, 36/255);
            eventBox.isPickable = true;
        });

        allChildren.forEach(shape=>{
            shape.onOver(()=>{
                if(State.getInstance().mode !== Modes.normal) return;
                this.eventBoxes.forEach(eventBox=>{
                    eventBox.outlineWidth = 0.2;
                    eventBox.outlineColor = new Color3(245/255, 159/255, 36/255);
                    eventBox.renderOutline = true;
                })
            });
            shape.onOut(()=>{
                this.eventBoxes.forEach(eventBox=>{eventBox.renderOutline = this.isPicked;})
            });
            shape.onClick(()=>{
                if(State.getInstance().mode !== Modes.normal) return;

                setPickedModule(this);
                this.eventBoxes.forEach(eventBox=>{
                    eventBox.outlineColor = new Color3(255/255, 170/255, 50/255);
                    eventBox.isPickable = false;
                    eventBox.renderOutline = true;
                });
                this.isPicked = true;
            });
        })
    }

    remove(){
        this.moduleObjects.forEach(moduleObject=>moduleObject.remove());
        this.moduleObjects = [];
    }
}

export enum NodeAttachableType {
    ramp,
    pole,
    railing,
}
export const allNodeAttachableTypes = [NodeAttachableType.ramp, NodeAttachableType.pole, NodeAttachableType.railing];
export class Node extends ShapeObject{
    attach: Node[] = [];
    attachTestFn:(node:Node)=>{};

    constructor(
        parent:ShapeObject,
        origin:Vector3,
        attachBTestFn:(node:Node)=>boolean,
        protected readonly scene:IntegratedScene=SceneManager.getInstance()[SceneName.main]
    )
    {
        super(parent, origin);
        this.setupEventBox();
        this.attachTestFn = attachBTestFn;
    }

    setupEventBox() {
        const newEventBox = CreateSphere(`eventTrigger-${this.id}`,{diameter:1.5}, this.scene);
        newEventBox.position = this.origin;
        newEventBox.actionManager = new ActionManager(this.scene);
        newEventBox.outlineWidth = 0.1;
        newEventBox.outlineColor = Color3.Red();
        newEventBox.setEnabled(false);
        this.eventBoxes.push(newEventBox);
        this.meshes.push(newEventBox);

        this.onOver((event)=>{
            event.source.renderOutline = true;
            event.source.outlineWidth = 0.2;
            event.source.outlineColor = new Color3(245/255, 159/255, 36/255);
        });
        this.onOut((event)=>{
            event.source.renderOutline = false;
        });
    }

    filterAttachable(nodes:Node[]) {
        let attachableNode:Node[] = [];
        nodes.forEach((node)=>{
            if(this.attachTestFn(node) && node.attachTestFn(this)){
                attachableNode.push(node);
            }
        })

        return attachableNode;
    }

    attachTo(attachNode:Node, fromNode:Node = this):boolean {
        const moveOffset = attachNode.origin.subtract(this.origin);
        const anc = this.ancestor;
        anc.move(moveOffset);

        // call all parent who have attachTo()
        let attachHandler:ShapeObject|Scene = this.parent;
        while(attachHandler instanceof ShapeObject){
            attachHandler.attachTo(attachNode, fromNode);

            attachHandler = attachHandler.parent;
        }

        // occupied nodes
        // empty nodeAttachableTypes of those overlapping nodes, mark those overlapping nodes as occupied
        let allNodes:Node[] = [];
        let attachError = false;
        State.getInstance().models.forEach((model)=>{
            if(model instanceof ModuleObject){
                allNodes = allNodes.concat(model.nodes);
            }
        })
        fromNode.ancestor.nodes.forEach((node1)=>{
            allNodes.every((node2)=>{
                if(Vector3.Distance(node1.origin, node2.origin) > pairNodeDistance ){ return true; } // continue
                if(node1.attachTestFn(node2) && node2.attachTestFn(node1)){
                    node1.attach.push(node2);
                    node2.attach.push(node1);
                }else{
                    attachError = true;
                }

                return false; // break
            })
        });
        return !attachError;
    }

    enableEdgesRendering(){}
    disableEdgesRendering(){}
}
