import React, {memo} from "react";
import {Badge} from "reactstrap";
import {sum} from "lodash";
import {ShipmentStatus} from "../generated/graphql";


export type ArticleRowWithPackageItems = {
    quantity?: number | null,
    packageItems?: Array<{
        id: string,
        quantity: number,
        package: {
            id: string,
            quantity: number,
            shipment?: { id: string, number: string, status: ShipmentStatus } | null
        }
    }> | null
};

const ArticleRowPackageBadge = memo(({articleRow}: { articleRow: ArticleRowWithPackageItems }) =>
{
    if (!articleRow.packageItems || articleRow.packageItems.length === 0)
    {
        return null
    }

    const packageQuantity = articleRow.packageItems?.map((e) =>
    {
        return e.package.quantity * e.quantity ?? 0
    })
    const totalPackagedQuantity = sum(packageQuantity)
    const articleRowQuantity = articleRow.quantity ?? 0

    if (totalPackagedQuantity === 0)
    {
        return null
    }
    if (totalPackagedQuantity < articleRowQuantity)
    {
        return <Badge color='info'>partially packed</Badge>
    }

    const isAllBooked = articleRow.packageItems.every((e) =>
    {
        const shipment = e.package.shipment
        return shipment && shipment.status === ShipmentStatus.Booked
    })

    if (!isAllBooked)
    {
        return <Badge color='primary'>packed</Badge>
    }

    return <Badge color='success'>booked</Badge>
})

export default ArticleRowPackageBadge