import React, {Component} from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {flowRight as compose} from 'lodash';
import GetContactQuery from './graphql/GetContact.graphql';
import gql from 'graphql-tag';
import Collapser from "../common/Collapser";
import CreateUpdateInfo from "../common/CreateUpdateInfo";
import OrderSubPanel from "../order/OrderSubPanel";
import QuotesSubPanel from "../common/subpanels/QuotesSubPanel";

class ContactDetailPage extends Component
{
    constructor(props)
    {
        super(props);
        this.id = parseInt(props.match.params.id);
    }

    async removeContact(contact)
    {
        if(window.confirm(`Are you sure you want to remove ${contact.name}?`))
        {
            await this.props.mutate({id:this.id});
            console.log(`removed ${this.id}`);
            console.log(this.props);
            this.props.history.push("/contacts");
        }

    }

    render()
    {
        const data = this.props.data;
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error)
        {
            return (<p>Error!</p>);
        }
        const contact = data.contact;
        if(contact === null)
        {
            return (<p>You either can't access this contact or it has been deleted.</p>);
        }
        return (
            <div>
                <Row>
                    <Col sm={9}>
                        <h1>{contact.name}</h1>
                        <hr/>
                    </Col>
                    <Col sm={3}>
                        <Link to={`/contactEdit/${contact.id}`}><Button color='secondary'>Edit</Button></Link>
                        &nbsp;
                        <Button onClick={this.removeContact.bind(this,contact)} color='secondary'>Remove</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <Label>Name</Label>
                        <p>{contact.name}</p>
                        <Label>description</Label>
                        <p>{contact.description}</p>
                        <Label>Title</Label>
                        <p>{contact.title}</p>
                        <Label>Assignee</Label>
                        <p>{contact.assignee?contact.assignee.displayName:"None"}</p>
                        <Collapser badge>
                            <CreateUpdateInfo object={contact}/>
                        </Collapser>
                    </Col>
                    <Col sm={3}>
                        <Label>Company</Label>
                        <p><Link to={`/companyDetail/${contact.company.id}`}>{contact.company.name}</Link></p>
                        <Label>E-Mail</Label>
                        <p><a href={`mailto:${contact.email || "none"}`}>{contact.email || "none"}</a></p>
                        <Label>Active</Label>
                        <p>{contact.isActive ? "True" : "False"}</p>
                    </Col>
                    <Col sm={3}>
                        <Label>Can buy online</Label>
                        <p>{contact.buysOnline?"Yes":"No"}</p>
                        <Label>Mobile phone</Label>
                        <p>{contact.mobilePhone}</p>
                        <Label>Work phone</Label>
                        <p>{contact.workPhone}</p>
                        <Label>Creator</Label>
                        <p>{contact.creator.displayName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Orders</h3>
                        <Collapser label={"Show orders"} open>
                            <OrderSubPanel orders={contact.orders}/>
                        </Collapser>
                    </Col>
                </Row>
                <Row style={{marginTop:"20px"}}>
                    <Col>
                        <h3>Quotes</h3>
                        <Collapser label={"Show quotes"} open>
                            <QuotesSubPanel quotes={contact.quotes} noCompanyName={true} noContactName={true}/>
                        </Collapser>
                    </Col>
                </Row>
            </div>);
    }


}
const removeContactMutation = gql`
    mutation removeContact($id:ID!)
    {
        removeContact(id:$id)
    }
`;


export default compose(graphql(GetContactQuery,{
    options:(props) =>
    {
        return {variables:{id:parseInt(props.match.params.id)}};
    }
}),
graphql(removeContactMutation,{
    options:
    (props) => {
        return {variables: {id: parseInt(props.match.params.id)},refetchQueries:["getCompany"]};
    }
})
)(withRouter(ContactDetailPage));
