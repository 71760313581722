import {Badge, Progress, UncontrolledTooltip} from "reactstrap";
import React from "react";
import {ArticleRow, ProductionStatus, SchedulerTask} from "../generated/graphql";
import {formatToSwedishTime} from "../UTIL";
import {filter, map, sum} from "lodash";
import {round} from "../spiralStair/lib/Util";

type Props = {
    id?: string
    progress?: number
    productionStatus?: ProductionStatus
}

export const calculateProgress = (articleRow: ArticleRow & {schedulerTasks: SchedulerTask})=> {
    if (!articleRow || !articleRow.schedulerTasks || articleRow.schedulerTasks.length === 0)
    {
        return null;
    }
    const totalSum = sum(map(articleRow?.schedulerTasks, t => t?.duration ?? 0));
    const sumDone = sum(map(filter(articleRow?.schedulerTasks, task => task?.finishedAt), task => task.duration));
    return round((sumDone / totalSum) * 100);
}

const ProductionProgressBar = ({id, progress, productionStatus}: Props) =>
{
    if (!id || progress == null)
    {
        return <Badge color="secondary">Not estimated yet.</Badge>
    }

    return <div style={{width: '100%'}}>
        <span style={{whiteSpace: "nowrap"}}>
            {progress}%
            {productionStatus && productionStatus.estimatedFinishDate &&
                <>
                    <span id={"dateInfo" + id}
                          style={{
                              fontSize: "8px",
                              marginLeft: "7px"
                          }}>
                        {formatToSwedishTime(productionStatus.estimatedFinishDate)}
                    </span>
                    <UncontrolledTooltip
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #999",
                            color: "#333",
                            padding: "9px",
                            textAlign: "left"
                        }}
                        placement="top"
                        target={"dateInfo" + id}
                        autohide={false}
                        flip={"true"}
                        trigger="hover"
                    >estimated finish date</UncontrolledTooltip>
                </>
            }
        </span>
        <Progress style={{"height": "3px"}} value={progress}/>
    </div>;
}

export default ProductionProgressBar;