import {
    ModeOfDeliveryDocument,
    TermsOfdeliveryDocument, TermsOfPaymentDocument,
    useUpdateCompanyMutation,
    useUpdateOrderMutation
} from "../generated/graphql";
import React, {useCallback} from "react";
import NotificationPopup from "../common/lib/NotificationPopup";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import FormRenderer from "../common/FormRenderer";
import _ from "lodash";
import {CountryList} from "../common/CountryList";
import {getDefaultDeliveryCountry, RECEIVER_PAYS_FREIGHT_ID} from "./OrderDetailPage";
import {camelToTitle} from "../UTIL";
import {ConsigneeAccountList} from "../shipment/model/ConsigneeAccountList";

export const getOrderInvalidFields = (order) =>
{
    return ValidateOrderFormDefinition(order)
        .filter((e) =>
        {
            if (!e.required)
            {
                return false
            }
            return e.defaultValue ? !e.defaultValue : !order[e.name]
        }).map((e) => e.label ?? camelToTitle(e.name))
}

const ValidateOrderFormDefinition = (order) =>
{
    if (!order)
    {
        return []
    }
    const company = order.company
    return [
        {
            name: "modeOfDeliveryId", label: `Mode of delivery`,
            type: "select", query: ModeOfDeliveryDocument, required: true,
            defaultValue: order.modeOfDelivery?.id,
            variables: {
                locale: order.company.locale
            }
        },
        {
            name: "termsOfDeliveryId", label: `Terms of delivery`,
            type: "select", query: TermsOfdeliveryDocument, required: true,
            defaultValue: order.termsOfDelivery?.id,
            variables: {
                locale: order.company.locale
            }
        },
        {
            name: "termsOfPaymentId", label: `Terms of payment`,
            type: "select", query: TermsOfPaymentDocument, required: true,
            defaultValue: order.termsOfPayment?.id,
            variables: {
                locale: order.company.locale
            }
        },
        order.company.customerReferenceNumberRequired
            ? {name: "customerReferenceNumber", required: true}
            : {name: "blank", type: "hidden", hideField: () => true},
        {name: 'deliveryTitle', required: true},
        {name: 'deliveryStreet', required: true},
        {name: 'deliveryPostalCode', pattern: "[0-9 ]+", required: true},
        {name: 'deliveryCity', required: true},
        {
            name: 'deliveryCountry', type: "select", options: CountryList, required: true,
            defaultValue: getDefaultDeliveryCountry(order.deliveryCountry, order.tenantId)
        },
        {name: 'deliveryContactName'},
        {name: 'deliveryContactPhone'},
        {
            name: 'setDeliveryAddress', label: ' ', type: 'custom', input: (model, onChange) =>
            {
                return <div>
                    <p style={{marginBottom:0,fontWeight:"bold"}}>Company Delivery address</p>
                    {company.deliveryTitle && company.deliveryStreet && <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}><small>
                        {company.deliveryTitle} <br/>
                        {company.deliveryStreet} <br/>
                        {company.deliveryCity} &nbsp;{company.deliveryPostalCode}
                    </small>
                        <Button size={"sm"} onClick={() =>
                        {
                            model.deliveryTitle = company.deliveryTitle
                            model.deliveryStreet = company.deliveryStreet
                            model.deliveryPostalCode = company.deliveryPostalCode
                            model.deliveryCity = company.deliveryCity
                            model.deliveryCountry = getDefaultDeliveryCountry(company.deliveryCountry, order.tenantId)
                            model.deliveryContactName = company.deliveryContactName
                            model.deliveryContactPhone = company.deliveryContactPhone
                            onChange()
                        }}>Set address</Button>
                    </div>}
                </div>
            }
        },
        {
            name: 'company.billingStreet',
            label: `Billing billingStreet`,
            required: true,
            defaultValue: _.get(order, 'company.billingStreet'),
        },
        {
            name: 'company.billingStreet2',
            label: `Billing billingStreet2`,
            defaultValue: _.get(order, 'company.billingStreet2'),
        },
        {
            name: 'company.billingCity',
            label: `Billing City`,
            required: true,
            defaultValue: _.get(order, 'company.billingCity'),
        },
        {
            name: 'company.billingPostalCode',
            label: `Billing Postal Code`,
            required: true,
            pattern: "[0-9 ]+",
            defaultValue: _.get(order, 'company.billingPostalCode'),
        },
        {
            name: 'company.billingEmail',
            label: `Billing Email`,
            type: "email",
            defaultValue: _.get(order, 'company.billingEmail'),
        },
        {
            name: 'consignee',
            type: 'select',
            options: ConsigneeAccountList,
            required: order.termsOfDelivery?.id === RECEIVER_PAYS_FREIGHT_ID,
            hideField: () => order.termsOfDelivery?.id !== RECEIVER_PAYS_FREIGHT_ID
        }
    ]
}

const ValidateOrderInformationModal = ({order, toggle, isOpen}) =>
{
    const [updateOrderMutation] = useUpdateOrderMutation()
    const [updateCompanyMutation] = useUpdateCompanyMutation()

    const handleOnSubmit = useCallback(async ({object}) =>
    {
        try
        {
            const company: any = {}
            const newOrder: any = {}

            Object.entries(object).forEach(([key, value]) =>
            {
                if (key === "setDeliveryAddress")
                {
                    return
                }

                if (key.startsWith("company"))
                {
                    const newKey = key.split('.').pop()
                    if (newKey)
                    {
                        company[newKey] = value
                    }
                } else
                {
                    newOrder[key] = value
                }
            })

            await updateOrderMutation({variables: {id: order.id, order: newOrder}})
            await updateCompanyMutation({variables: {id: order.company.id, company: company}})
            toggle()
        } catch (e)
        {
            NotificationPopup.error(`Something error. ${e}`)
        }

    }, [updateCompanyMutation, updateOrderMutation, order, toggle])

    return <Modal size="lg" isOpen={order && isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Validate order information</ModalHeader>
        <ModalBody>
            <FormRenderer formDefinition={ValidateOrderFormDefinition(order)}
                          columns={2}
                          object={order}
                          onSubmit={handleOnSubmit}/>
        </ModalBody>
    </Modal>
}

export default ValidateOrderInformationModal
