import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {
    Axis,
    CreateBox,
    SubMesh, Tools,
} from "@babylonjs/core";
import {ModuleObject, Node} from "../ShpaeObject";
import ModuleTemplate, {childSafePole, stairConst, supportLegConst} from "../ModuleTemplate";
import {MaterialName} from "../Materials";
import {getAngleBetweenVectorsByAxisY} from "../utils";
import {Scene} from "@babylonjs/core/scene";
import {ModuleRampType} from "../../generated/graphql";
import {RampEdge} from "./Ramp";
import {
    nodeForStairStep,
    nodeForStairStepAndChildSafeSection, nodeForStairStepAndIndustrialPole,
    nodeOfRampForSupportLeg
} from "../NodeFilterFunctions";


export const pickStepCountByDistanceToGround = (distanceToGround:number):number => {
    const { poleSpacing } = childSafePole;
    const fullStairLength = distanceToGround/Math.atan(Tools.ToRadians(30));
    return Math.floor(fullStairLength / poleSpacing) - 1;
}

export class StairStep extends ModuleObject
{
    type:ModuleRampType = ModuleRampType.StairStep;
    rotateAxis:Vector3 = Axis.X.clone(); //All modules attached to this ramp should rotate with this axis (poles, railings...)
    rightNode:Node|undefined;
    leftNode:Node|undefined;
    letNodes:Node[] = [];

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        this.moduleTemplate.slopeDegree = Math.abs(this.moduleTemplate.slopeDegree);
        const {width, height, length, origin} = this.moduleTemplate;
        const {stairStringerGap} = stairConst;
        this.origin = origin;

        const box = CreateBox(`mesh-stairStep-${this.id}`,{width:width, height:height, depth:length}, this.scene);
        const offset = new Vector3(width, height, length);
        box.translate(offset, 0.5);
        box.translate(this.origin, 1);
        this.meshes.push(box);

        this.rightNode = new Node(this, this.origin.add(new Vector3(width/2, 0, -stairStringerGap)), ()=>true, this.scene);
        this.leftNode = new Node(this, this.origin.add(new Vector3(width/2, 0, length+stairStringerGap)), ()=>true, this.scene);
        const legNode1 = new Node(this, this.origin.add(new Vector3(width-supportLegConst.margin, 0, supportLegConst.margin)), nodeOfRampForSupportLeg, this.scene);
        const legNode2 = new Node(this, this.origin.add(new Vector3(width-supportLegConst.margin, 0, length-supportLegConst.margin)), nodeOfRampForSupportLeg, this.scene);

        this.letNodes.push(legNode1, legNode2);
        this.children.push(this.rightNode, this.leftNode, legNode1, legNode2);
        this.nodes.push(this.rightNode, this.leftNode, legNode1, legNode2);

        this.vector = Axis.X.clone();

        this.material = this.scene.materialStore[MaterialName.rampMaterial_small];

        box.subMeshes = [];
        const verticesCount = box.getTotalVertices();
        new SubMesh(1, 0, verticesCount, 0, 6, box);
        new SubMesh(1, 0, verticesCount, 6, 6, box);
        new SubMesh(1, 0, verticesCount, 12, 6, box);
        new SubMesh(1, 0, verticesCount, 18, 6, box);
        new SubMesh(0, 0, verticesCount, 24, 6, box);
        new SubMesh(0, 0, verticesCount, 30, 6, box);
    }

    attachTo(attachNode, fromNode){
        if(attachNode.parent instanceof RampEdge && fromNode.parent instanceof StairStep){
            const fromNodeVector = fromNode === fromNode.parent.leftNode ? fromNode.parent.vector : fromNode.parent.vector.negate();
            const angle = getAngleBetweenVectorsByAxisY(fromNodeVector, attachNode.parent.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new StairStep(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}

export class StairStringer extends ModuleObject {
    type:ModuleRampType = ModuleRampType.StairStringer;
    connectNode: Node|undefined;
    stepNodes: Node[] = [];

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {
            height,
            width,
            length,
            origin,
            slopeDegree,
            otherProps
        } = this.moduleTemplate;
        const {railing, side} = otherProps;
        const {poleSpacing} = childSafePole;
        const {offsetWithRamp} = stairConst;
        this.origin = origin;

        const railingTestFn =
            railing === 'childSafe' ? nodeForStairStepAndChildSafeSection :
            railing === 'industrial' ? nodeForStairStepAndIndustrialPole :
            nodeForStairStep;


        const edge = side === 'right' ?
            new RampEdge(this, this.origin.add(new Vector3(-poleSpacing/2,-height/2,offsetWithRamp)), new Vector3(0,0,length), slopeDegree, this.scene, railingTestFn):
            new RampEdge(this, this.origin.add(new Vector3(poleSpacing/2,-height/2,length+offsetWithRamp)), new Vector3(0,0,-length), -slopeDegree, this.scene, railingTestFn);
        this.children.push(edge);
        this.nodes = [...edge.nodes];
        this.stepNodes = [...edge.nodes];
        this.vector = edge.vector.clone();


        this.connectNode = new Node(this, this.origin, ()=>true, this.scene);
        this.nodes.push(this.connectNode);
        this.children.push(this.connectNode);

        const box = CreateBox(`mesh-stairStringer-${this.id}`,{width:width, height:height, depth:length}, this.scene);
        const offset = new Vector3(width, -height, length);
        // box.rotate(Axis.X, slopeDegree)
        box.translate(offset, 0.5);
        box.translate(this.origin, 1);
        if(side === 'right'){
            box.translate(new Vector3(-poleSpacing/2,0,0), 1);
        }else{
            box.translate(new Vector3(poleSpacing/2,0,0), 1);
        }
        this.meshes.push(box);

        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        if(slopeDegree!==null && slopeDegree!==0) {
            this.rotate(Axis.X, slopeDegree);
        }
    }

    enableEdgesRendering(options){
        super.enableEdgesRendering(options);

        this.meshes.forEach((mesh)=>{
            mesh.material = this.scene.materialStore[MaterialName.transMaterial];
        });
    }

    attachTo(attachNode, fromNode){
        const {otherProps} = this.moduleTemplate;
        const {side} = otherProps;

        const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), attachNode.parent.vector);
        if(side==='right') {
            this.rotate(Axis.Y, Tools.ToDegrees(angle)+90, attachNode.origin);
        } else {
            this.rotate(Axis.Y, Tools.ToDegrees(angle)-90, attachNode.origin);
        }

    }

    clone(scene:Scene){
        const cloned = new StairStringer(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}
