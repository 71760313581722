import {
    PackageItemFragmentFragment,
    PackageItemInput,
    ShipmentStatus,
    useDeletePackageMutation,
    useGetPackageQuery,
    useUpdatePackageMutation
} from "../generated/graphql";
import {Button, Col, Row, Spinner} from "reactstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnId from "../common/data-grid/columns/ColumnId";
import CellArticleRow from "../common/data-grid/cell/CellArticleRow";
import {Link} from "react-router-dom";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import {PackageShareFormDefinition} from "./CreatePackagePage";
import NotificationPopup from "../common/lib/NotificationPopup";
import IconOpenInNew from "../assets/images/open_in_new.svg";
import AddArticleRowForPackagePanel from "./component/AddArticleRowForPackagePanel";
import {GridActionsCellItem, GridRowParams} from "@mui/x-data-grid";
import Trash from "../common/icons/Trash";
import Checkmark from "../common/icons/Checkmark";
import Pen from "../common/icons/Pen";

const TABLE_KEY = "PACKAGE_DETAIL_ARTICLE_ROWS"

type EditPackageItemsPanelProps = {
    packageItems: PackageItemFragmentFragment[] | null | undefined
    onUpdateClicked: (packageInputs: PackageItemInput[]) => void
    onCancelClicked: () => void
}

type PackageItemsPanelProps = {
    onAddItemClicked: () => void
    onUpdateClicked: (packageInputs: PackageItemInput[]) => void
    packageItems: PackageItemFragmentFragment[] | null | undefined
    isEditable: boolean
}

const EditQuantityInput = (props: { onClicked: (quantity: number) => void, defaultValue?: number }) =>
{
    const {onClicked, defaultValue} = props
    const [inputValue, setInputValue] = useState<string>(`${defaultValue}`);

    return <div>
        <input
            type="number"
            style={{margin: 0, width: "100px"}}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}/>
        <span className="link" onClick={() => onClicked(Number(inputValue))}>
                <Checkmark/>
            </span>
    </div>
};


const EditPackageItemsPanel = (props: EditPackageItemsPanelProps) =>
{
    const {packageItems, onUpdateClicked, onCancelClicked} = props
    const articleRowsForPackageRef = useRef<any>()

    const handleOnUpdateClicked = useCallback(() =>
    {
        const packageItems = articleRowsForPackageRef.current?.getPackageItemsInput()
        onUpdateClicked(packageItems)
    }, [onUpdateClicked, articleRowsForPackageRef])

    return <div>
        <h3>Update article rows</h3>
        <AddArticleRowForPackagePanel ref={articleRowsForPackageRef}
                                      packageItems={packageItems}/>
        <div style={{height: "8px"}}/>
        <Button onClick={onCancelClicked}>Cancel</Button>
        <Button color="success" style={{marginLeft: "8px"}}
                onClick={handleOnUpdateClicked}>Update</Button>
    </div>
}

const PackageItemsPanel = (props: PackageItemsPanelProps) =>
{
    const {packageItems, onAddItemClicked, onUpdateClicked, isEditable} = props
    const [isEditQuantity, setIsEditQuantity] = useState(false)

    const parseToUpdateRequest = useCallback((packageItems) =>
    {
        return packageItems.map(e => ({
            articleRowId: e.articleRowId,
            quantity: e.quantity
        }))
    }, [])

    const handleEditQuantity = useCallback((id, quantity) =>
    {
        const item = packageItems?.find((e) => e.id === id)
        if (!item)
        {
            NotificationPopup.error(`Failed to find package item by id ${id}`)
            return
        }
        item.quantity = quantity
        onUpdateClicked(parseToUpdateRequest(packageItems))
        setIsEditQuantity(false)
    }, [packageItems, onUpdateClicked, parseToUpdateRequest])

    const handleDeleteClicked = useCallback((id) =>
    {
        if (window.confirm(`Are you sure you want to delete package item?`))
        {
            const result = packageItems?.filter((e) => e.id !== id)
            console.log(`${JSON.stringify(result)}`)
            onUpdateClicked(parseToUpdateRequest(result))
            setIsEditQuantity(false)
        }
    }, [packageItems, onUpdateClicked, parseToUpdateRequest])

    const getColumns = useCallback(() =>
    {
        const columns: any = [
            {
                headerName: 'Art no',
                field: 'articleRow',
                filterable: false,
                renderCell: (params) =>
                {
                    const articleRow = params.value
                    return <CellArticleRow articleRowId={articleRow.id} article={articleRow.article}
                                           stockArticle={articleRow.stockArticle}/>
                }
            },
            {
                headerName: 'Name',
                field: 'articleRow.name',
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                type: 'number',
                renderCell: (params) =>
                {
                    const quantity = params.value
                    if (isEditQuantity)
                    {
                        return <EditQuantityInput defaultValue={quantity}
                                                  onClicked={(quantity) => handleEditQuantity(params.id, quantity)}/>
                    }
                    return <span>
                        {quantity}
                        {isEditable && <span className="link"
                                             onClick={() => setIsEditQuantity(true)}>
                           <Pen/>
                        </span>}
                    </span>;
                },
            },
            {
                field: 'articleRow.order',
                headerName: 'Order',
                renderCell: (params) =>
                {
                    const order = params.value
                    return order ? <Link to={`/orderDetail/${order.id}`}>{order?.number}</Link>
                        : "-"
                }
            },
            ColumnId()
        ]

        if (isEditable)
        {
            columns.push({
                field: 'actions',
                type: 'actions',
                width: 50,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        icon={<Trash/>}
                        label="Delete"
                        onClick={() => handleDeleteClicked(params.id)}
                    />,
                ]
            })
        }

        return columns
    }, [isEditQuantity, handleEditQuantity, handleDeleteClicked, isEditable])

    return <div>
        <h3>Article rows
            {isEditable && <span>
                <Button size={"sm"}
                        style={{marginLeft: "8px"}}
                        onClick={onAddItemClicked}>
                    Add
                </Button>
            </span>
            }
        </h3>
        <NonPaginatedDataGrid
            definition={{
                tableKey: TABLE_KEY,
                columns: getColumns(),
                initState: {columns: {columnVisibilityModel: {id: false}}}
            }}
            data={packageItems ?? []}/>
    </div>
}

export default (props) =>
{
    const packageId = props.match.params.id
    const [isEditingPackageItems, setIsEditingPackageItems] = useState(false)

    const {data, loading, error} = useGetPackageQuery({variables: {id: packageId}})
    const [updatePackage] = useUpdatePackageMutation({refetchQueries: ['getPackage']})
    const [deletePackage] = useDeletePackageMutation()

    const [isEditable, setIsEditable] = useState(false)

    const onUpdatePackage = useCallback(async ({object}) =>
    {
        if (!data || !data.package)
        {
            return
        }

        try
        {
            await updatePackage({
                variables: {
                    id: packageId,
                    packageInput: object
                },
            })
            NotificationPopup.success(`Update success.`)
            setIsEditingPackageItems(false)
        } catch (e)
        {
            NotificationPopup.error(`Failed to update. ${e}`)
        }
    }, [updatePackage, packageId, data])

    const onUpdatePackageItems = useCallback(async (packageItems: PackageItemInput[]) =>
    {
        await onUpdatePackage({object: {packageItemInputs: packageItems}})
    }, [onUpdatePackage])

    const onDeleteClick = useCallback(async () =>
    {
        if (window.confirm(`Are you sure you want to delete this package?`))
        {
            await deletePackage({variables: {id: packageId}})
            props.history.push("/packages");
        }
    }, [deletePackage, packageId, props.history])

    useEffect(() =>
    {
        const shipment = data?.package?.shipment
        if (shipment == null)
        {
            setIsEditable(true)
            return
        }

        setIsEditable(shipment.status === ShipmentStatus.NotBooked || shipment.status === ShipmentStatus.Failed)
    }, [data])

    return <div>
        {loading && <Spinner/>}
        {error && <p>Error: ${error}</p>}

        {data && data.package && <div>
            <Row>
                <Col sm={10}>
                    <h1> Package {data.package.mark}</h1>
                </Col>
                {
                    isEditable && <Col sm={2}>
                        <Button color="warning" onClick={onDeleteClick}>Delete</Button>
                    </Col>
                }
            </Row>

            <EditableDetailRenderer
                formDefinition={PackageShareFormDefinition}
                object={data.package}
                columns={3}
                onSubmit={onUpdatePackage}
                open={false}
                extra={null}
                isEditable={isEditable}/>

            {
                data.package.shipmentId &&
                <h4 style={{marginTop: "16px"}}>Shipment {data.package.shipment?.number ?? "-"}
                    <Link to={`/shipment-detail/${data.package.shipmentId}`} target="_blank">
                        <img src={IconOpenInNew} style={{width: '20px', marginLeft: "4px"}} alt={"link shipment"}/>
                    </Link>
                </h4>
            }
            <hr/>
            {
                isEditingPackageItems
                    ? <EditPackageItemsPanel packageItems={data.package.packageItems}
                                             onCancelClicked={() => setIsEditingPackageItems(false)}
                                             onUpdateClicked={onUpdatePackageItems}/>
                    : <PackageItemsPanel
                        onAddItemClicked={() => setIsEditingPackageItems(true)}
                        onUpdateClicked={onUpdatePackageItems}
                        packageItems={data.package.packageItems}
                        isEditable={isEditable}/>
            }
        </div>
        }
    </div>
}

