import React, {memo} from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import ColumnAssignee from "../../common/data-grid/columns/ColumnAssignee";
import ColumnsStatus from "../../common/data-grid/columns/ColumnStatus";
import {PurchaseOrderStatus} from "../../UTIL";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {
    Comparator,
    PurchaseOrdersV2InInventoryDocument
} from "../../generated/graphql";
import ColumnTenantId from "../../common/data-grid/columns/ColumnTenantId";
import {InventoryRoute} from "../InventoryRouters";
import {articleDetailLink} from "../../workflow-forms/DrawJob/ArticleList";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";

const KEY_TABLE = 'TABLE_KEY_INVENTORY_ORDER_LIST'
const SUPPLIER_NAME = 'Eurostair Produktion AB'

const ArticleRowCell = memo(({articleRows}: any) =>
{
    if (!articleRows)
    {
        return <div>-</div>
    }

    const nonStockArticleRows = articleRows.filter((ar) => ar.article && !ar.isStockArticle)
    const stockArticles = articleRows.filter((ar) => ar.isStockArticle)
    return <div>
        {nonStockArticleRows.map(ar =>
            {
                const article = ar.article
                if (!article)
                {
                    return '-'
                }
                return <p key={article.id} style={{margin: 0}}>
                    {articleDetailLink(article, article.artNo)}
                </p>
            }
        )}
        {stockArticles.length > 0 &&
            <div style={{margin: 0}}>{stockArticles.length} Stock articles</div>}
    </div>
})

const columns = () =>
{
    return [
        {
            field: 'number',
            headerName: 'Number',
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`${InventoryRoute.CRM_PURCHASE_ORDER_DETAIL}/${params.id}`}>{params.value}</Link>
            },
        },
        ColumnsStatus(PurchaseOrderStatus),
        {
            field: 'order.number',
            headerName: 'Customer Order',
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`${InventoryRoute.CRM_ORDER_DETAIL}/${params.row.order.id}`}>{params.value}</Link>
            },
        },
        ColumnTenantId(),
        ColumnDate('order.preferredDeliveryDate', 'Preferred Delivery Date'),
        {
            field: 'articleRows',
            sortable: false,
            filterable: false,
            renderCell: (params) => <ArticleRowCell articleRows={params.value}/>,
        },
        ColumnCreatedAt(),
        ColumnAssignee('creator.username', 'Created by', 'creatorId'),
        ColumnId(),
    ]
}

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const InventoryOrderListPage = () =>
{
    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                },
            }}
            query={PurchaseOrdersV2InInventoryDocument}
            persistFilter={[{key: 'supplier.name', value: SUPPLIER_NAME, comparator: Comparator.Equals}]}
        />
    )
}


export default withApollo(InventoryOrderListPage);
