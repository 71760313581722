import {OrderWithDeliveryInfoFragmentFragment, PackageFragmentFragment} from "../generated/graphql";

export type OrderWithUniquePackageNumbers = OrderWithDeliveryInfoFragmentFragment & {
    packageNumbers: Set<string>
}

export const getUniqueOrders = (packages?: PackageFragmentFragment[] | null) =>
{
    const uniqueOrders = new Map<string, OrderWithUniquePackageNumbers>()

    packages?.forEach(p =>
    {
        p?.packageItems?.forEach((item) =>
        {
            const order = item?.articleRow?.order
            const orderId = order?.id
            const mark = p.mark

            if (!orderId || !order)
            {
                return
            }

            if (!uniqueOrders.has(orderId))
            {
                uniqueOrders.set(orderId, {...order, packageNumbers: new Set([mark])})
            } else
            {
                uniqueOrders.get(orderId)?.packageNumbers.add(mark)
            }
        })
    })

    return Array.from(uniqueOrders.values())
}