import React from "react";
import {map} from "lodash";
import Paragraph from "../../../Paragraph";


export const Extra = (extra) =>
{
    return <div>
        {map(extra, (ext) =>
            <Paragraph key={ext.id} style={{marginLeft: "7px"}}>
                <small>{ext.name}:</small>
                <strong>{ext.price}</strong></Paragraph>)}
    </div>
}

const ColumnExtra = (valueGetter?: (params: any) => any) =>
{
    return {
        field: 'extra',
        sortable: false,
        filterable: false,
        valueGetter: valueGetter,
        renderCell: (params) =>
        {
            const value = params.value
            return value ? Extra(value) : null
        },
    }
}
export default ColumnExtra