import React, {memo, useMemo} from "react";
import {OrderWithDeliveryInfoFragmentFragment} from "../../generated/graphql";
import {WarningTermsOfDelivery} from "../CreateShipmentPage";
import {Link} from "react-router-dom";
import {OrderWithUniquePackageNumbers} from "../ShipmentUtils";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";


type Props = {
    order: OrderWithUniquePackageNumbers | null | undefined
}

const DeliveryTitle = memo(({text}: { text: string }) =>
{
    return <h6 style={{margin: '8px 0 0 0', fontWeight: 'bold'}}>{text}</h6>
})

const DeliveryText = memo(({text, textColor}: { text?: string | null, textColor?: string }) =>
{
    return <p style={{margin: 0, color: textColor ? textColor : "black"}}>{text ?? ''}</p>
})

const Address = ({order}: { order: OrderWithDeliveryInfoFragmentFragment }) =>
{
    const isOrderDataComplete = useMemo(() =>
    {
        return order.deliveryTitle && order.deliveryStreet &&
            order.deliveryPostalCode && order.deliveryCity && order.deliveryCountry
    }, [order])

    return <>
        <DeliveryTitle text={"Delivery info"}/>
        <DeliveryText text={order.deliveryTitle}/>
        <DeliveryText text={order.deliveryStreet}/>
        <DeliveryText
            text={`${order.deliveryPostalCode ?? ''} ${order.deliveryCity ?? ''} ${order.deliveryCountry ?? ''}`}/>
        <DeliveryText text={order.deliveryContactName}/>
        <DeliveryText text={order.deliveryContactPhone}/>
        <DeliveryText text={order.deliveryFreeText}/>
        {!isOrderDataComplete &&
            <p style={{color: "red"}}>Order {order.number} delivery address or contract info uncompleted</p>}
    </>
}

export default ({order}: Props) =>
{
    const isWarningTermsOfDelivery = useMemo(() =>
    {
        return WarningTermsOfDelivery.includes(order?.termsOfDelivery?.name ?? "")
    }, [order?.termsOfDelivery?.name])

    return <>
        {
            order ? <Card style={{marginBottom: "4px"}}>
                    <CardHeader style={{padding: "8px 16px"}}>
                        <Link to={`/orderDetail/${order.id}`} target={"_blank"}>
                            <h5>Order {order.number}</h5>
                        </Link>
                    </CardHeader>
                    <CardBody style={{padding: "8px 16px"}}>
                        <Row>
                            <Col xl={6}>
                                <Address order={order}/>
                                <DeliveryTitle text={"Selected Packages"}/>
                                {
                                    Array.from(order.packageNumbers.values()).map((e) => <DeliveryText text={e}/>)
                                }
                            </Col>
                            <Col xl={6}>
                                {
                                    order.company && <>
                                        <DeliveryTitle text={"Company"}/>
                                        <Link to={`/companyDetail/${order.company.id}`} target={"_blank"}>
                                            {order.company.name}
                                        </Link>
                                    </>
                                }

                                <DeliveryTitle text={"Terms of payment"}/>
                                <DeliveryText text={order.termsOfPayment?.name ?? '-'}/>

                                <DeliveryTitle text={"Terms of delivery"}/>
                                <DeliveryText text={order.termsOfDelivery?.name ?? '-'}
                                              textColor={isWarningTermsOfDelivery ? 'orange' : undefined}/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                : <DeliveryText text={'No delivery info'} textColor={'red'}/>
        }
    </>
}
