import ColumnId from "../common/data-grid/columns/ColumnId";
import React from "react";
import {Link} from "react-router-dom";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {GetPackagesDocument} from "../generated/graphql";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnPackageType from "../common/data-grid/columns/ColumnPackageType";

export const getPackageColumns = (isCreateShipment: boolean) =>
{
    const columns: any = []
    columns.push({
        field: "mark",
        headerName: "Shipping mark",
        searchable: true,
        renderCell: (params) =>
        {
            return <Link to={`/package-detail/${params.id}`}
                         target={isCreateShipment ? "_blank" : ""}>
                {params.value ?? "-"}
            </Link>
        }
    })

    if (!isCreateShipment)
    {
        columns.push({
            field: "shipment",
            headerName: "Shipment",
            customFilterField: 'shipment.number',
            customSortField: 'shipment.number',
            renderCell: (params) =>
            {
                const shipment = params.value
                return shipment ? <Link to={`/shipment-detail/${shipment.id}`}>{shipment.number}</Link>
                    : "-"
            }
        })
    }

    columns.push(...[
        {
            field: "orders",
            sortable: false,
            searchable: true,
            width: 100,
            customFilterField: 'packageItems.some.articleRow.order.number',
            customSearchField: 'packageItems.some.articleRow.order.number',
            valueGetter: (params) =>
            {
                const uniqueOrders = new Map<string, string>()
                params.row.packageItems.forEach((e) =>
                {
                    const orderId = e.articleRow?.order?.id
                    if (orderId && !uniqueOrders.has(orderId))
                    {
                        const orderNumber = e?.articleRow?.order?.number ?? '-'
                        uniqueOrders.set(orderId, orderNumber)
                    }
                })
                return Array.from(uniqueOrders)
            },
            renderCell: (params) =>
            {
                const value = params.value
                return <div>
                    {
                        value.map(([key, value]) =>
                        {
                            return <Link style={{display: "block"}}
                                         to={`/orderDetail/${key}`}
                                         target={isCreateShipment ? "_blank" : ""}>
                                {value}
                            </Link>
                        })
                    }
                </div>
            }
        },
        {
            field: "articleRows",
            sortable: false,
            searchable: true,
            customFilterField: 'packageItems.some.articleRow.article.artNo',
            customSearchField: 'packageItems.some.articleRow.article.artNo',
            valueGetter: (params) =>
            {
                const artNos = params.row.packageItems.map((e) =>
                {
                    return e?.articleRow?.article?.artNo
                        ?? e?.articleRow?.stockArticle?.artNo
                        ?? e?.articleRow?.name
                })
                const uniqueArtNos = new Set(artNos)
                return Array.from(uniqueArtNos)
            },
            renderCell: (params) =>
            {
                return <div>{params.value.map((e) => <div>{e}</div>)}</div>
            }
        },
        {
            field: "dimensions",
            filterable: false,
            sortable: false,
            renderCell: (params) =>
            {
                const row = params.row

                const length = row?.length ?? "0"
                const width = row?.width ?? "0"
                const height = row?.height ?? "0"
                return <div>{length} x {width} x {height}</div>
            }
        },
        {
            field: "weight", type: 'number', width: 80
        },
        {field: 'quantity', type: 'number', width: 80},
        ColumnPackageType(),
        ColumnDate("createdAt", "Created at"),
        ColumnId(),
    ])
    return columns
}

const columnVisibilityModel = {
    columnVisibilityModel: {
        id: false
    }
}

const sortModel = {
    sortModel:
        [{field: 'createdAt', sort: 'desc'}]
}

const KEY_TABLE = "PACKAGE_LIST"

export default () =>
{
    return <div>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: getPackageColumns(false),
                initState: {
                    sorting: sortModel,
                    columns: columnVisibilityModel
                }
            }}
            query={GetPackagesDocument}
        />
    </div>
}
