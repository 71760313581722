import {ModuleObject, Node} from "../ShpaeObject";
import {ModuleRampType} from "../../generated/graphql";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Axis, CreateBox, SubMesh, Tools} from "@babylonjs/core";
import ModuleTemplate from "../ModuleTemplate";
import {MaterialName} from "../Materials";
import {getAngleBetweenVectorsByAxisY} from "../utils";
import {Scene} from "@babylonjs/core/scene";
import {RampEdge} from "./Ramp";

export class RampToPlatform extends ModuleObject
{
    type: ModuleRampType = ModuleRampType.RampToPlatform;

    constructor(moduleTemplate: ModuleTemplate, scene)
    {
        super(moduleTemplate, scene);
        this.init();
    }

    build()
    {
        const {width, height, length, origin} = this.moduleTemplate;
        this.origin = origin;

        const bottomRight = this.origin.clone().add(new Vector3(0, 0, length));

        const edge = new RampEdge(this, bottomRight, new Vector3(width, 0, 0), 0, this.scene);
        this.children.push(edge);
        this.nodes = this.nodes.concat(edge.nodes);

        const box = CreateBox(`ramp-to-platform${this.id}`, {width: width, height: height, depth: length}, this.scene);
        const offset = new Vector3(width, height, length);
        box.translate(offset, 0.5);
        box.translate(this.origin, 1);
        this.meshes.push(box);

        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        box.subMeshes = [];
        const verticesCount = box.getTotalVertices();
        new SubMesh(0, 0, verticesCount, 0, 6, box);
        new SubMesh(0, 0, verticesCount, 24, 6, box);
    }

    attachTo(attachNode: Node, fromNode: Node)
    {
        if (attachNode.parent instanceof RampEdge && fromNode.parent instanceof RampEdge)
        {
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), attachNode.parent.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    enableEdgesRendering(options?:{edgesWidth, edgesColor})
    {
        super.enableEdgesRendering(options);

        this.meshes.forEach((mesh) =>
        {
            mesh.material = this.scene.materialStore[MaterialName.transMaterial];
        });
    }

    clone(scene: Scene)
    {
        return new RampToPlatform(this.moduleTemplate, scene);
    }
}
