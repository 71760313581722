import React from 'react';
import {useStdMutation} from "../spiralStair/lib/Util";
import {useCallback, useState} from "react";
import NotificationPopup from "../common/lib/NotificationPopup";
import {Button, Spinner} from "reactstrap";
import gql from "graphql-tag";
import {filter} from 'lodash';

const CreateInvoiceButton = ({purchaseOrderId, internalInvoices}) =>
{
    const [createVismaInvoice, {loading}] = useStdMutation(`Create invoice`, gql`mutation($purchaseOrderId:ID!)
    {
        createInternalVismaInvoice(purchaseOrderId:$purchaseOrderId)
    }`, {variables: {purchaseOrderId}});
    const [clicked, setClicked] = useState(false);
    const createInvoice = useCallback(async () =>
    {
        try
        {
            setClicked(true);
            await createVismaInvoice();
        } catch (e)
        {
            NotificationPopup.error(`Failed to create internal visma invoice. ${e.message}`);
            console.error(e);
        }
    }, [createVismaInvoice])
    if (clicked && loading)
    {
        return <Spinner color={"warning"}/>
    }
    return <div>
        {!clicked && <Button onClick={() => {
            if (internalInvoices.length > 0) {
                const yes = window.confirm(`This order already has internal invoice. \nAre you sure you want to create an internal invoice?`)
                if (yes) {
                    createInvoice()
                }
            } else {
                createInvoice()
            }
        }}>
            Create internal invoice in Visma
        </Button>}
    </div>
}


export default ({purchaseOrders, internalInvoices})=>
{
    const filtered = filter(purchaseOrders,po=>po.supplier.processDefinitionKey === "ESPRPurchaseOrder" &&
    po.tenantId !== "ESNO");
    let error;
    if(filtered.length > 1)
    {
        error = `There are more than one ESPR POs on this order.`;
    }
    else if(filtered.length === 1)
    {
        return <CreateInvoiceButton purchaseOrderId={filtered[0].id} internalInvoices={internalInvoices}/>
    }
    else
    {
        const po = purchaseOrders[0];
        if(po?.tenantId === 'ESNO')
        {
            error = `ESNO tenant can't create internal invoice.`;
        }
        else
        {
            error = "There are no ESPR POs on this order.";
        }

    }
    return <Button disabled={true} color={"warning"}>
        {error}
    </Button>

}
