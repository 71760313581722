import {
    Texture,
    StandardMaterial,
    Color3,
    DynamicTexture,
    MultiMaterial,
    Tools,
} from "@babylonjs/core";
import sceneBackground from "./image/sceneBackground.jpg";
// import grass from "./image/grass.png";
import grass from "./image/gravel.jpg";
import steel from "./image/steel.png";
import rampTexture from "./image/rampFaceTexture.png";
import upArrow from "./image/upArrow.png";
import upArrowBlack from "./image/upArrow_black.png";
import {Scene} from "@babylonjs/core/scene";

export enum MaterialName {
    skyboxMaterial,
    groundMaterial,
    whiteMaterial,
    blackMaterial,
    grayMaterial,
    transMaterial,
    rampMaterial,
    rampMaterial_small,
    rampUpMaterial,
    rampUpMaterialForView,
    metalMaterial,
}

export default class Materials{

    constructor(scene:Scene) {
        this[MaterialName.whiteMaterial] = new StandardMaterial("whiteMaterial", scene);
        this[MaterialName.whiteMaterial].diffuseColor = Color3.White();

        this[MaterialName.blackMaterial] = new StandardMaterial("blackMaterial", scene);
        this[MaterialName.blackMaterial].diffuseColor = Color3.Black();

        this[MaterialName.grayMaterial] = new StandardMaterial("grayMaterial", scene);
        this[MaterialName.grayMaterial].diffuseColor = Color3.Gray();

        this[MaterialName.transMaterial] = new StandardMaterial("transMaterial", scene);
        this[MaterialName.transMaterial].diffuseColor = Color3.Gray();
        this[MaterialName.transMaterial].alpha = 0;

        this[MaterialName.metalMaterial] = new StandardMaterial("metalMaterial", scene);
        this[MaterialName.metalMaterial].diffuseTexture = new Texture(`${process.env.PUBLIC_URL + steel}`, scene);
        this[MaterialName.metalMaterial].specularColor = new Color3(1, 1, 1);
        this[MaterialName.metalMaterial].backFaceCulling = false;

        this[MaterialName.rampUpMaterialForView] = new StandardMaterial("rampMaterialForView", scene);
        this[MaterialName.rampUpMaterialForView].diffuseTexture = new Texture(`${process.env.PUBLIC_URL + upArrowBlack}`, scene);
        this[MaterialName.rampUpMaterialForView].diffuseTexture.hasAlpha = true;
        this[MaterialName.rampUpMaterialForView].useAlphaFromDiffuseTexture = true;
        // Preparing images
        const skyBoxImage = new Image();
        skyBoxImage.src = `${process.env.PUBLIC_URL + sceneBackground}`;
        const rampImage = new Image();
        rampImage.src = `${process.env.PUBLIC_URL + rampTexture}`;
        const arrowImg = new Image();
        arrowImg.src = `${process.env.PUBLIC_URL + upArrow}`;
        const arrowBlackImg = new Image();
        arrowBlackImg.src = `${process.env.PUBLIC_URL + upArrowBlack}`;

        const skyboxMaterial = new StandardMaterial("skyBoxMat", scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.diffuseTexture = new Texture(`${process.env.PUBLIC_URL + sceneBackground}`, scene);
        if(skyboxMaterial.reflectionTexture){
            skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
        }
        skyboxMaterial.diffuseColor = new Color3(1, 1, 1);
        skyboxMaterial.specularColor = new Color3(0, 0, 0);
        this[MaterialName.skyboxMaterial] = skyboxMaterial;

        const groundMaterial = new StandardMaterial("grassMat", scene);
        groundMaterial.diffuseTexture = new Texture(`${process.env.PUBLIC_URL + grass}`, scene);
        // @ts-ignore
        groundMaterial.diffuseTexture.uScale = 300
        // @ts-ignore
        groundMaterial.diffuseTexture.vScale = 300;
        groundMaterial.diffuseTexture.hasAlpha = true; // Set this to let highlight pass through
        groundMaterial.specularColor = new Color3(0, 0, 0);
        this[MaterialName.groundMaterial] = groundMaterial;

        const arrowUpFaceMaterial = new StandardMaterial("arrowUpMat", scene);
        const arrowUpBoxMaterial = new MultiMaterial("arrowUpMat", scene);
        const arrowUpTexture = new DynamicTexture("arrowUpTexture", 512, scene);
        const arrowUpTextureContext = arrowUpTexture.getContext();

        rampImage.onload = function() {
            const uScale = 15;
            const vScale = 30;
            const width = 512/uScale;
            const height = 512/vScale;
            for(let u=0; u<uScale; u++){
                for(let v=0; v<vScale; v++){
                    const uOffset = u*width;
                    const vOffset = v*height;
                    arrowUpTextureContext.drawImage(this,uOffset,vOffset,width,height );
                }
            }

            arrowUpTextureContext.drawImage(arrowImg, 200, 200);
            arrowUpTexture.wAng = -Math.PI/2;
            arrowUpTexture.update();
        }
        arrowUpFaceMaterial.diffuseTexture = arrowUpTexture;
        arrowUpFaceMaterial.diffuseTexture.hasAlpha = true;
        arrowUpFaceMaterial.specularColor = Color3.Black();
        arrowUpFaceMaterial.useAlphaFromDiffuseTexture = true;
        arrowUpBoxMaterial.subMaterials.push(arrowUpFaceMaterial);
        arrowUpBoxMaterial.subMaterials.push(this[MaterialName.metalMaterial]);
        this[MaterialName.rampUpMaterial] = arrowUpBoxMaterial;


        const rampBoxMaterial = new MultiMaterial("rampMaterial", scene);
        const rampFaceMaterial = new StandardMaterial("rampMaterial", scene);
        rampFaceMaterial.diffuseTexture = new Texture(`${process.env.PUBLIC_URL + rampTexture}`, scene);
        rampFaceMaterial.specularColor = Color3.Black();
        (rampFaceMaterial.diffuseTexture as Texture).uScale = 30;
        (rampFaceMaterial.diffuseTexture as Texture).vScale = 15;
        (rampFaceMaterial.diffuseTexture as Texture).wAng = Tools.ToRadians(90);

        rampFaceMaterial.diffuseTexture.hasAlpha = true;
        rampFaceMaterial.useAlphaFromDiffuseTexture = true;
        rampBoxMaterial.subMaterials.push(rampFaceMaterial);
        rampBoxMaterial.subMaterials.push(this[MaterialName.metalMaterial]);
        rampBoxMaterial.backFaceCulling = false;
        this[MaterialName.rampMaterial] = rampBoxMaterial;

        const rampBoxMaterial_small = new MultiMaterial("rampMaterial", scene);
        const rampFaceMaterial_small = new StandardMaterial("rampMaterial", scene);
        rampFaceMaterial_small.diffuseTexture = new Texture(`${process.env.PUBLIC_URL + rampTexture}`, scene);
        rampFaceMaterial_small.specularColor = Color3.Black();
        (rampFaceMaterial_small.diffuseTexture as Texture).uScale = 15;
        (rampFaceMaterial_small.diffuseTexture as Texture).vScale = 15;
        (rampFaceMaterial_small.diffuseTexture as Texture).wAng = Tools.ToRadians(90);

        rampFaceMaterial_small.diffuseTexture.hasAlpha = true;
        rampFaceMaterial_small.useAlphaFromDiffuseTexture = true;
        rampBoxMaterial_small.subMaterials.push(rampFaceMaterial_small);
        rampBoxMaterial_small.subMaterials.push(this[MaterialName.metalMaterial]);
        rampBoxMaterial_small.backFaceCulling = false;
        this[MaterialName.rampMaterial_small] = rampBoxMaterial_small;
    }
    //
    // static getInstance() {
    //     if(!Materials.instance){
    //         Materials.instance = new Materials();
    //     }
    //     return Materials.instance;
    // }
    //
    // static dispose() {
    //     Materials.instance = null;
    // }
}

//Materials.init();