import React, {Component, useState} from 'react';
import {Button, Col, Form, FormGroup, FormText, Input as ReactStrapInput, Label, Row} from 'reactstrap';
import Select from './FixRequiredSelect';
import _ from 'lodash';
import {formatCurrency, labelize} from "../UTIL";
import { Query } from '@apollo/client/react/components';
import SelectUser from "common/SelectUser";
import PropTypes from 'prop-types';
import LocaleQuery from './graphql/LocaleQuery.graphql';
import Picker from "./Picker";
import SearchQuery from './graphql/FreeTextSearchQuery.graphql';
import Dynamic from "./Dynamic";
import FilePicker from "./FilePicker/FilePicker";
import Spinner from "reactstrap/es/Spinner";
import DatePickerWithWeek from "./DatePickerWithWeek";
import TimePicker from "./TimePicker";
import NotificationPopup from "./lib/NotificationPopup";

export const Input = props=>
{
    const [hasBeenClicked,setHasBeenClicked] = useState(false);
    return <ReactStrapInput {...props} onClick={e=>
    {
        if(!hasBeenClicked)
        {
            e.target.select();
            setHasBeenClicked(true);
        }
    }} />;
};

const sortByName = (a,b) => a.name>b.name ? 1 : -1;

const selectInput = (field,options,value,onChange, disableSort)=>
{
    if(value===undefined) value=null;
    if(_.isArray(value))
    {
        value = _.map(value,o=>_.omit(o,'__typename'));
    }

    const defaultMapFn = (value) => {
        return {id: value.id == null?value:value.id, name: value.name || value}
    };

    let mappedOptions = _.map(options, field.mapFn || defaultMapFn)
    if (!field.disableSort) {
        mappedOptions = mappedOptions.sort(sortByName);
    }

    if(value != null && typeof(value) !== "object")
    {
        const searchRes = _.find(mappedOptions,o=>o.id === value);
        if(searchRes == null)
        {
            console.log(`No longer valid value in select: ${field.name} value: ${value}`);
            value = {id:value,name:`Value "${value}" no longer an option. See detail view.`};
        }
        else
        {
            value = searchRes;
        }
    }

    return (<Select
    getOptionLabel={({name}) => name}
    getOptionValue={({id}) => id}
    isMulti={field.multi}
    closeMenuOnSelect={!field.multi}
    required={field.required}
    options={mappedOptions}
    value={value}
    onChange={onChange}
    isClearable={field.isClearable}
    defaultValue={field.defaultValue}
    isDisabled={field.disabled}/>);
};

export const selectRenderer = (field,value,onChange)=>
{
    if (field.query)
    {
        return (<Query variables={field.variables} query={field.query}>
            {({loading, error, data}) =>
            {
                if(loading)return `Loading..`;
                else if(error)return `Error: ${error}`;
                let array = _.find(data,_.isArray);
                if(!_.isArray(array))
                {
                    array = data.result.list;
                }
                return selectInput(field,array,value,onChange);
            }}
        </Query>);
    }
    else {
        return (selectInput(field,field.options,value,onChange));
    }
};

class FormRenderer extends Component {

    constructor(data)
    {
        super();
        let clone = _.assign({},data.object);
        for(let key in clone)
        {
            if(clone.hasOwnProperty(key) && _.isArray(clone[key]))
            {
                clone[key] = _.map(clone[key],o=>_.omit(o,'__typename'));
            }
            else if(clone.hasOwnProperty(key) && clone[key] != null && typeof(clone[key]) === 'object'){
                clone[key + "Id"] = clone[key].id || clone[key].username;
            }
        }
        const model = {};
        data.formDefinition.forEach(def =>
        {
            //To make sure checkbox actually assign a value of false instead of assigning undefined.
            if(_.isBoolean(clone[def.name]))
            {
                model[def.name] = clone[def.name];
            }
            else
            {
                if (def.mapValueFn) {
                    model[def.name] = def.mapValueFn({value: clone[def.name]})
                } else if (clone[def.name] == null && def.defaultValue != null) {
                    model[def.name] = def.defaultValue;
                } else {
                    model[def.name] = clone[def.name];
                }
            }
        });

        this.state = {
            object:model,
            dateTimes:{},
            submitting: false
        };
        this.inputRender = this.inputRender.bind(this);

    }

    onChange(name, e)
    {
        if(_.isArray(e))
        {
            this.setState({"object": {...this.state.object, [name]: e}});
        }
        else
        {
            let value = null;
            if(!e)
            {
                value = null;
            }
            else if(e.target)
            {
                switch(e.target.type)
                {
                    case 'checkbox': value = e.target.checked; break;
                    case 'number':
                        if(e.target.value === '')
                        {
                            value = null
                        }
                        else {
                            value = parseFloat(e.target.value);
                        }
                        break;
                    default:value = e.target.value; break;
                }
            }
            else if(e.id)
            {
                value = e.id;
            }
            else if(e.value)
            {
                value = e.value;
            }
            this.setState({"object": {...this.state.object, [name]: value}});
            this.setState({"originalObject": {...this.state.originalObject, [name]: e}});
        }
    }

    async submit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        const missingRequiredFields = this.props.formDefinition.filter((field) => field.required && this.state.object[field.name] == null);
        if (missingRequiredFields.length > 0) {
            NotificationPopup.error(`The following fields are required: ${missingRequiredFields.map((field) => field.name).join(', ')}`);
            return;
        }

        if(this.state.submitting) {
            console.error('caught double submit');
            return;
        }
        this.setState({submitting: true});
        const clear = ()=>
        {
            this.setState({object:{}})
        };
        this.props.onSubmit({object:this.state.object, originalObject:this.state.originalObject},clear);
        //this.props.onSubmit({object:this.state.object},clear);
        if (this.props.disableTimeout) {
            this.setState({submitting: false})
            return
        }
        setTimeout(()=>this.setState({submitting:false}),5000);
    }
    dateTime(field)
    {
        //pretty stupid way to include material-ui just for the picker so we load it dynamically xD
        return <Dynamic disablePast={field.disablePast} disableFuture={field.disableFuture} value={this.state.object[field.name]} onChange={(date)=>
        {
            console.log(`selected ${date.toString()}`);
            this.setState({"object": {...this.state.object, [field.name]: date.toDate()}});
        }} load={()=>import("./DateTimePicker")} />
    }


    select(field)
    {
        let value = this.state.object[field.name];
        const onChange = this.onChange.bind(this, field.name);
        return selectRenderer(field,value,onChange);
    }

    inputRender(field)
    {
        switch(field.type)
        {
            case 'select':return this.select(field);
            case 'custom':return field.input(this.state.object,this.onChange.bind(this,field.name));
            case 'userPicker':
                return <SelectUser userId={this.state.object[field.name]} onChange={this.onChange.bind(this, field.name)}
                                   isClearable={field.isClearable}/>;
            case 'number':
                return (<Input required={field.required} min={field.min} step={field.step} max={field.max} type="number" value={this.state.object[field.name]==null?"":this.state.object[field.name]}
                               disabled={field.disabled}
                               onChange={this.onChange.bind(this, field.name)}/>);
            case 'currency':
                return (<div>
                    <Input required={field.required} min={field.min} max={field.max} type="number" value={this.state.object[field.name]==null?'':this.state.object[field.name]}
                           onChange={this.onChange.bind(this, field.name)}/>
                    <small>{`${formatCurrency(this.state.object[field.name])}`}</small>

                </div>);
            case 'localePicker':return this.select({...field,query:LocaleQuery,type:"select"});
            case 'custom-search':return <Picker startValue={field.startValue} required={field.required} mapFn={field.mapFn}
                                         onChange={this.onChange.bind(this,field.name)} query={field.query} />;
            case 'search':return <Picker types={field.types} filter={field.filter} startValue={field.startValue} required={field.required}
                                       onChange={this.onChange.bind(this,field.name)} query={SearchQuery} />;
            case 'currencyPicker':return this.select({...field,type:"select",options:["SEK","NOK","DKK","EUR"].map(c=>({id:c,name:c}))});
            case 'checkbox':
                return <>
                    <br/>
                    <Input style={{marginLeft: 4}} className='big-checkbox' required={field.required} type={'checkbox'}
                           checked={this.state.object[field.name] || false}
                           onChange={this.onChange.bind(this, field.name)}/>
                </>
            case 'hidden':
                //TODO: Find a nicer way to do this?
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.object[field.name] = field.value;
                return <input type='hidden' value={field.value}/>;
            case "datetime":
                return this.dateTime(field);
            case "file":
                return this.filePicker(field);
            case "date":
                return <DatePickerWithWeek defaultValue={this.state.object[field.name]}
                                           disableFuture={field.disableFuture}
                                           disablePast={field.disablePast}
                                           onChange={this.onChange.bind(this, field.name)}/>
            case "time":
                return <TimePicker defaultValue={this.state.object[field.name]}
                                   onChange={this.onChange.bind(this, field.name)}/>
            default:
                return (<Input pattern={field.pattern} disabled={field.disabled} required={field.required} type={field.type||"text"} value={this.state.object[field.name]||""}
                               onChange={this.onChange.bind(this, field.name)}/>);
        }
    }

    filePicker = (field)=>
    {
        const onChange = this.onChange.bind(this, field.name);
        return <FilePicker fileId={this.state.object[field.name]} required={field.required} onChange={onChange}/>
    };

    formColumn(formDefinition)
    {
        return formDefinition.map(field =>{
            if(field.hideField && field.hideField(this.state.object))
            {
                return field.hiddenFieldMessage||null;
            }
            else if(field.type === 'infotext')
            {
                return <div key={field.key} style={{backgroundColor: "#b1dae7", padding:"0.3em", marginTop:"1em", marginBottom:"1em",borderRadius: "10px"}}><span role="img" aria-label="Info" style={{marginRight:"1em"}}>ℹ️</span>{field.value}</div>
            }
            else
            {
                return <FormGroup key={field.name}  style={{paddingBottom: 2}}>
                    <Label>{field.label||labelize(field.name)} {field.required && <small style={{color:"grey"}}>(required)</small>}</Label>
                    {field.hint && field.hint}
                    {this.inputRender.bind(this)(field)}
                    <FormText style={{whiteSpace: "pre-line"}}>{field.text}</FormText>
                </FormGroup>
            }

        });
    }

    formBody()
    {
        let formDefinition = this.props.formDefinition;
        if(this.props.columns)
        {
            const chunked = _.chunk(formDefinition,this.props.columns);
            return chunked.map((chunk,index) =>
            <Row key={index}>
                {chunk.map((col,index)=>
                <Col key={index} sm={12 / this.props.columns}>
                    {this.formColumn([col])}
                </Col>
                )}
            </Row>
            );
        }
        else
        {
           return this.formColumn(formDefinition);
        }
    }

    isSubmitButtonEnabled() {
        const isRequiredFinish = this.props.formDefinition.filter((e) => e.required)
            .every((e) => this.state.object[e.name])
        return !isRequiredFinish || this.state.submitting
    }

    render()
    {
        return (
            <Form onSubmit={event => this.submit(event)}>
                {this.formBody()}
                <br/>
                {!this.props.customSubmit &&<Row className="justify-content-between">
                    <Col sm={2}/>
                    <Col sm={3}>
                        {

                        }
                         <Button style={{float:"right", whiteSpace:"nowrap"}} color="success" type="submit" disabled={this.isSubmitButtonEnabled()}>
                             {this.state.submitting&& <Spinner color="light" size={"sm"}/>}
                             {this.props.submitButtonText||"Save"}
                         </Button>
                    </Col>
                </Row>}

                {this.props.customSubmit && this.props.customSubmit}
            </Form>
        )
    }
}
FormRenderer.propTypes =
    {
        formDefinition:PropTypes.arrayOf(PropTypes.object).isRequired,
        object:PropTypes.object,
        onSubmit:PropTypes.func.isRequired,
        columns:PropTypes.number,
        submitButtonText:PropTypes.string,
        customSubmit:PropTypes.object,
        disableTimeout: PropTypes.bool
    };

export default FormRenderer;

