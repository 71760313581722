import {useCallback, useEffect, useState} from "react";
import {StraightStairV2FragmentFragment} from "../../generated/graphql";
import {array, number, object, string, mixed} from "yup/es";

export type Props = {
    straightStairV2: StraightStairV2FragmentFragment | null
}

const getIndexFromString = (input: string, prefix = 'segments'): number | null =>
{
    const match = input.match(new RegExp(`${prefix}\\[(\\d+)]`))
    if (match && match[1])
    {
        return parseInt(match[1])
    }
    return null;
}

const parseSegmentErrorMessage = (context: { path: string }, label: string): string =>
{
    const index = getIndexFromString(context.path);
    const no = index != null ? `${index + 1}` : `-`
    return `Segment${no} ${label}`
}

const parseSegmentPositiveErrorMessage = (context: { path: string }, name: string): string =>
{
    return parseSegmentErrorMessage(context, `"${name}" must be a positive number`)
}

const platformRailingSchema = (side: "L1" |"L2" |"W1"|"W2")=>{
    return number().when(`platformRailing${side}Checked`, {
        is: true,
        then: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Platform railing ${side} length`)),
        otherwise: number().nullable(),
    })
}

const supportPlateSchema = object().shape({
    length: number().positive((context) =>
    {
        const supportIndex = getIndexFromString(context.path, 'supports')
        const supportNo = supportIndex != null ? `${supportIndex + 1}` : `-`
        return parseSegmentErrorMessage(context, `Support${supportNo} "length" must be a positive number`)
    }),
    quantity: number().positive(),
})

const segmentSchema = object().shape({
    stairHeight: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Stair height`)),
    numberOfSteps: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Number of steps`)),
    stepDepth: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Step depth`)),
    stepWidth: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Step width`)),
    overlap: number().positive((context) => parseSegmentPositiveErrorMessage(context, `Overlap`)),
    platformRailingL1Length: platformRailingSchema("L1"),
    platformRailingL2Length: platformRailingSchema("L2"),
    platformRailingW1Length: platformRailingSchema("W1"),
    platformRailingW2Length: platformRailingSchema("W2"),
    platformRailingType: string().when('platformRailingExecution', {
        is: "childsafe",
        then: string().required(),
        otherwise: string().nullable(),
    }),
    geometry: object().shape({
        stringerLength: number()
            .min(500, (context) => parseSegmentErrorMessage(context, `"Stringer length" must be greater than 500`))
            .max(8600, (context) => parseSegmentErrorMessage(context, `"Stringer length" must be less than 8600`))
    }).nullable(),
    platformRailingExecution: mixed().nullable().test(
        function (this: any, value)
        {
            const {
                platformRailingL1Checked,
                platformRailingL2Checked,
                platformRailingW1Checked,
                platformRailingW2Checked
            } = this.parent;
            if (platformRailingL1Checked || platformRailingL2Checked || platformRailingW1Checked || platformRailingW2Checked)
            {
                return value !== null
            }
            return true;
        }
    ),
    railingSide: number().test(
        function (this: any, railingSide)
        {
            const {wallHandrail} = this.parent;
            if (railingSide === 1)
            {
                return wallHandrail <= 1
            }
            if (railingSide === 2)
            {
                return wallHandrail === 0
            }
            return true;
        }
    ),
    supports: array().of(supportPlateSchema)
})

const parseExtraRailingPositiveErrorMessage = (context: { path: string }, label: string): string =>
{
    const index = getIndexFromString(context.path, 'extraRailings');
    const no = index != null ? `${index + 1}` : `-`
    return `ExtraRailing${no} "${label}" must be a positive number`
}

const extraRailingSchema = object().shape({
    quantity: number().positive(),
    length: number().positive((context) => parseExtraRailingPositiveErrorMessage(context, "Length")),
    maxSectionLength: number().positive((context) => parseExtraRailingPositiveErrorMessage(context, "Max section length")),
})

const separateKickPlateSchema = object().shape({
    length: number().positive(),
    quantity: number().positive(),
})

const straightStairSchema = object().shape({
    segments: array().of(segmentSchema),
    extraRailingType: string().when('extraRailingExecution', {
        is: "childsafe",
        then: string().required(),
        otherwise: string().nullable(),
    }),
    extraRailingExecution: string().when('extraRailings', {
        is: (value) => value.length > 0,
        then: string().required(),
        otherwise: string().nullable(),
    }),
    extraRailings: array().of(extraRailingSchema),
    separateKickPlates: array().of(separateKickPlateSchema),
})

const useStraightStairValidationHook = (props: Props) =>
{
    const {straightStairV2} = props

    const [validationErrors, setValidationErrors] = useState<string[]>([])

    const validatePrice = useCallback(async () =>
    {
        try
        {
            await straightStairSchema.validate(straightStairV2, {abortEarly: false});
            setValidationErrors([]);
        } catch (e: any)
        {
            setValidationErrors(e?.errors ?? []);
        }
    }, [straightStairV2])

    useEffect(() =>
    {
        validatePrice()
    }, [validatePrice])

    return {validationErrors}
}

export default useStraightStairValidationHook;