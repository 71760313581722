import React,{Component} from 'react';
import {Col, Input, Label, Row} from "reactstrap";
import {map} from 'lodash';
import ModalForm from "../ModalForm";
import FormRenderer from "../FormRenderer";
import { Mutation} from '@apollo/client/react/components';
import CreateComment from './graphql/CreateComment.graphql';
import PropTypes from 'prop-types';
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import Trash from "../icons/Trash";
import gql from "graphql-tag";
import {formatToSwedishTime} from "../../UTIL";

const DeleteComment = gql`
mutation deleteComment($id:ID!)
{
    deleteComment(id:$id)
}`;

class CommentList extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            content: ''
        };
    }

    row = (comment)=>
    {
        return <ListGroupItem key={comment.id}>
            <Row>
                <Col sm={10}>
                    <small>
                        {comment.creator.displayName} - {formatToSwedishTime(comment.createdAt)}
                    </small>
                    <hr/>
                    <pre style={{fontSize:"1.1em",whiteSpace:"pre-wrap"}}>
                        {comment.content}
                    </pre>

                </Col>
                <Col sm={2}>
                    <Mutation mutation={DeleteComment}>
                        {deleteComment =>
                        <span style={{float: "right"}} className="link" onClick={async () =>
                        {
                            if(window.confirm(`Are you sure you want to delete this comment?`))
                            {
                                const res = await deleteComment({
                                    variables:
                                    {
                                        id: comment.id
                                    },
                                    refetchQueries: ["getOrder","quote","getPurchaseOrder", "getComments"]
                                });
                                console.log(res);
                            }

                        }}>
                             <Trash/>
                        </span>
                        }
                    </Mutation>


                </Col>
            </Row>

        </ListGroupItem>
    };

    updateContent(e) {
        this.setState({
            content: e.target.value
        });
    }

    render()
    {

        return <div>
            <ModalForm title={"Create comment"}  activationButtonText={"Create comment"}>
                {toggle=>
                {
                    return <Mutation mutation={CreateComment} >
                        {createComment =>
                            <>
                                <Label>Content</Label>
                                <Input type="textarea" value={this.state.inputValue}
                                       onChange={this.updateContent.bind(this)}/>
                                <FormRenderer
                                    columns={2}
                                    formDefinition={[
                                        {name: "doSendMail", type: "checkbox", label:"Notify logistics team"},
                                        {name: "doSendMailToProduction", type: "checkbox", label:"Notify production"},
                                        {name: "doSendAssignee", type: "checkbox", label:`Notify ${this.props.assignee}`, hideField: ()=>!this.props.assignee},
                                        {name: "doSendEconomy", type: "checkbox", label:"Notify economy"},
                                    ]}
                                    onSubmit={async form =>
                                    {
                                        const object = form.object
                                        const content = this.state.content
                                        const res = await createComment({
                                            variables:
                                                {
                                                    comment: {
                                                        ...object,
                                                        content,
                                                        quoteId: this.props.quoteId,
                                                        orderId: this.props.orderId,
                                                        articleId: this.props.articleId,
                                                    },
                                                },
                                            refetchQueries:["getOrder","quote","getPurchaseOrder", "getComments"]
                                        });
                                        console.log(res);
                                        toggle();
                                    }
                                    }/>
                            </>
                        }
                    </Mutation>
                }}
            </ModalForm>
            <br/>
            <ListGroup>
            {map(this.props.comments,comment=> this.row(comment))}
            </ListGroup>
        </div>
    }

}

CommentList.propTypes = {
    comments:PropTypes.arrayOf(PropTypes.object).isRequired,
    orderId:PropTypes.string,
    quoteId:PropTypes.string,
    articleId: PropTypes.string,
    assignee: PropTypes.string,
};
export default CommentList;
