import {Button, Col, Row} from "reactstrap";
import React, {useCallback} from "react";
import ImageCard from "./component/ImageCard";
import {ImageFragmentFragment} from "../generated/graphql";
import ImageModal from "./component/ImageModal";
import useImageGalleryHook from "./useImageGalleryHook";

const ImageGalleryPage = () =>
{
    const {
        tags, onTagClicked, clearSelectedTag, selectedTags,
        images, openImage, setOpenImage
    } = useImageGalleryHook()

    const handleDownload = useCallback(async (image: ImageFragmentFragment) =>
    {
        try
        {
            const response = await fetch(image.url);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = (window as any).URL.createObjectURL(blob);
            link.download = `${image.title}.jpg`;
            link.click();
            (window as any).URL.revokeObjectURL(link.href);
        } catch (error)
        {
            console.error('Download failed', error);
        }
    }, [])

    return <div>
        <Row>
            {
                tags?.map((e) =>
                {
                    if (e.disabled)
                    {
                        return null
                    }

                    return <Button color="primary"
                                   key={`tag${e.id}`}
                                   style={{margin: '4px'}}
                                   outline={!selectedTags.includes(e.id)}
                                   onClick={() => onTagClicked(e.id)}>{e.name}</Button>
                })
            }

            <Button key={`btn-clear`}
                    style={{margin: '4px'}}
                    onClick={clearSelectedTag}>Clear</Button>
        </Row>

        <Row style={{marginTop: '16px'}}>
            {
                images?.map((e) =>
                {
                    return <Col sm={12} md={4} lg={2} key={`image-card-${e.id}`}>
                        <ImageCard image={e} handleDownload={handleDownload} setOpenImage={setOpenImage}/>
                    </Col>
                })
            }
        </Row>

        <ImageModal image={openImage} toggle={() => setOpenImage(null)}/>
    </div>
}


export default ImageGalleryPage;