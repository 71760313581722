import Message from "../../icons/Message";
import {UncontrolledTooltip} from "reactstrap";
import React from "react";
import {formatToSwedishTime} from "../../../UTIL";

const Comment = ({label, comment}) =>
{
    return <>
        <span style={{fontWeight: "bold"}}>{label}:</span>
        <br/>
        {comment.content}
        <br/>
        <span>Date: </span>
        {formatToSwedishTime(comment.createdAt)}
    </>
}

const ColumnComments = () =>
{
    return {
        field: 'comments',
        headerName: 'Comments',
        filterable: false,
        sortable: false,
        width: 50,
        renderCell: (params) =>
        {
            const value = params.value
            if (value.length > 0)
            {
                const latestComment = value[0]
                return <>
                    <span id={"commentsPreview" + params.id}
                          style={{fontSize: "8px", marginLeft: "7px"}}><Message/> {value.length}
                    </span>
                    <UncontrolledTooltip
                        style={{textAlign: "left"}}
                        placement="top"
                        target={"commentsPreview" + params.id}
                        flip={true}
                        trigger="hover">
                        <Comment label={'Latest comment'}
                                 comment={latestComment}/>
                        {value.length > 1 && <>
                            <br/>
                            <br/>
                            <Comment label={'2nd latest comment'}
                                     comment={value[1]}/>
                        </>}
                    </UncontrolledTooltip>
                </>;
            }

            return null;
        }
    }
}

export default ColumnComments