import React, {useEffect} from 'react';
import {
    Col,
    FormGroup,
    Input as ReactStrapInput,
    Label,
    Row
} from "reactstrap";
import {selectRenderer} from "../common/FormRenderer";
import {PlatformFormDef, SpiralStairRadii} from "./definitions";
import {freeHeightPlatform, freeHeightStair, round as round2, segmentRotation} from "./lib/Util";
import NewFormRenderer from "../common/NewFormRenderer";
import anslAngle from "./lib/AnslAngle";
import {get} from 'lodash';

const MAX_STEPHEIGHT = 220;
const round = Math.round;
const Input = props=><ReactStrapInput {...props} onClick={e=>e.target.select()} />;

const StepsPrRot = ({onChange, radius, value}) => {

    const stepsPrRotData = {
        600: [13],
        700: [14, 16],
        800: [15, 16, 18],
        900: [15, 16, 18, 20],
        1000: [16, 18, 20, 22],
        1100: [18, 20, 22, 24],
        1200: [20, 22, 24, 27],
        1300: [22, 24, 26, 29]
    };

    if (radius) {
        if (stepsPrRotData[radius].indexOf(value) === -1) {
            onChange(stepsPrRotData[radius][0]);
            return null;
        }

        return selectRenderer({required: true, name: 'stepsPrRot', options: stepsPrRotData[radius]},
            value, val => onChange(val.id));
    } else {
        return <p>..Select Radius first</p>;
    }
};

const StepHeights = ({onChange, segment, minStepHeight}) => {
    const {height, stepHeights} = segment;
    if (height) {
        const minimum = Math.ceil(height / MAX_STEPHEIGHT);
        const maximum = Math.floor(height / minStepHeight);
        let stepHeightsValue = stepHeights;

        let segmentRot;
        const minFreeHeight = 2100;
        const minFreeHeightPlatform = 2000;
        const stepDepth = get(anslAngle,`[${segment.radius}][${segment.stepsPrRot}]`)?.stepDepth;
        let stairFreeHeight;
        let platformFreeHeight;
        let stepHeight;
        try {
            stairFreeHeight = round(freeHeightStair(segment));
            platformFreeHeight = round(freeHeightPlatform(segment));
            stepHeight = round2(height / stepHeights).toFixed(2);
            segmentRot = round(segmentRotation(segment));
        } catch (e) {
            stairFreeHeight = 'N/A';
            platformFreeHeight = 'N/A';
            stepHeight = 'N/A';
            segmentRot = 'N/A'
        }
        const styleStair = {};
        const stylePlatform = {};
        if (stairFreeHeight <= minFreeHeight) {
            styleStair.backgroundColor = "rgba(255, 0, 0, 0.35)";
            styleStair.border = "thick double red";
        }
        if (platformFreeHeight <= minFreeHeightPlatform) {

            stylePlatform.backgroundColor = "rgba(255, 0, 0, 0.35)";
            stylePlatform.border = "thick double red";
        }
        return <div>
            <div style={styleStair}>
                <p style={{margin: 0}}>
                    Free height to step: {stairFreeHeight} mm (min {minFreeHeight}mm)
                </p>
            </div>
            <div style={stylePlatform}>
                <p style={{margin: 0}}>
                    Free height to platform: {platformFreeHeight} mm (min {minFreeHeightPlatform}mm)
                </p>
            </div>
            <p>
                Section total rotational angle: <strong>{segmentRot} &#176;</strong>
            </p>
            <p style={{margin: 0}}>
                <b>Step depth: {stepDepth} mm</b>
            </p>

            <p>Step heights: <strong>{stepHeightsValue}</strong></p>
            Min: {minimum}<input step='1' type='range' value={stepHeightsValue} max={maximum} min={minimum}
                                 onChange={e=>onChange(parseFloat(e.target.value))}/>Max:{maximum}

            <p style={{margin: 0}}>
                <b>Step height: {stepHeight} mm</b>
            </p>
        </div>

    } else {
        return <p>First set a height.</p>;
    }
};


export default ({sameRailType, sameStepType, direction, segment, onChange, minStepHeight, isLastSection })=>
{
    const {height,radius,stepsPrRot,console,startAngle,supportLegAmount,supportLegLength
        ,strutAmount,strutLength,connectionStep,stepHeights} = segment;
    useEffect(()=>
    {
        const minimum = Math.ceil(height / MAX_STEPHEIGHT);
        const maximum = Math.floor(height / minStepHeight);
        if(height && height > minStepHeight && stepHeights)
        {
            if(minimum > maximum)
            {
                return;
            }
            if(stepHeights < minimum)
            {
                window.console.log(`set stepheights minimum ${minimum}  ${stepHeights}`);
                onChange("stepHeights",minimum);
            }
            else if(stepHeights > maximum)
            {
                window.console.log(`set stepheights max  ${maximum}  ${stepHeights}`);
                onChange('stepHeights',maximum);
            }
        }
        else if(height && !stepHeights)
        {
            window.console.log(`set stepheights minimum`);
            onChange("stepHeights",minimum);
        }
    },[stepHeights,onChange,height,minStepHeight])

    const handleOnHeightKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.target.blur()
        }
    }

    return <div>
        <Row>
            <Col>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                Height
                                <small>(required)</small>
                            </Label>
                            <Input type="number" required defaultValue={height}
                                   onBlur={e => onChange("height", parseFloat(e.target.value))}
                                   onKeyDown={e => handleOnHeightKeyDown(e)}/>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                No of Consoles
                            </Label>
                            <Input type="number" required value={console}
                                   onChange={e => onChange("console", parseFloat(e.target.value))}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                Radius
                                <small>(required)</small>
                            </Label>

                            {selectRenderer({
                                    name: "radius",
                                    type: "select",
                                    options: SpiralStairRadii,
                                    required: true
                                }, radius,
                                value => {
                                    onChange("radius", value.id)
                                })}
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>
                                No. of Support Pillars
                            </Label>
                            <div>
                                <Input style={{display: "inline", width: "75%"}} type="number" value={supportLegAmount}
                                       onChange={e => onChange("supportLegAmount", parseFloat(e.target.value))}/>
                                <span>pcs á</span>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>
                                Support Pillar Height
                            </Label>
                            <div>
                                <Input style={{display: "inline", width: "80%"}} type="number" value={supportLegLength}
                                       onChange={e => onChange("supportLegLength", parseFloat(e.target.value))}/>
                                <span> mm</span>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                Steps per rotation
                                <small>(required)</small>
                            </Label>
                            <StepsPrRot onChange={(value) => {
                                onChange("stepsPrRot", value)
                            }} radius={radius} value={stepsPrRot}/>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>
                                No. of Bracings
                            </Label>
                            <div>
                                <Input style={{display: "inline", width: "75%"}} type="number" value={strutAmount}
                                       onChange={e => onChange("strutAmount", parseFloat(e.target.value))}/>
                                <span>pcs á</span>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>
                                Bracing length {strutLength}
                            </Label>
                            {selectRenderer({
                                defaultValue: 650,
                                options: [{id: 650, name: "515-815mm"},
                                    {id: 1050, name: "900-1200mm"},
                                    {id: 1600, name: "1200-2000mm"}],
                                disableSort: true,
                            }, strutLength || 650, val =>
                                onChange("strutLength", val.id))}

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                Step heights
                                <small>(required)</small>
                            </Label>
                            <StepHeights segment={segment} onChange={val => onChange("stepHeights", val)}
                                         minStepHeight={minStepHeight}/>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>
                                Connection Step
                            </Label>
                            <br/>
                            <Input style={{marginLeft: "15px"}} type="checkbox" className="big-checkbox"
                                   checked={connectionStep}
                                   onChange={(e) => {
                                       onChange("connectionStep", e.target.checked)
                                   }}/>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                Platform Angle
                            </Label>
                            <Input type="number" value={startAngle}
                                   onChange={e => onChange("startAngle", parseFloat(e.target.value))}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col sm="4">
                {!connectionStep && <div>
                    <h6>Edit Platform</h6>
                    <NewFormRenderer columns={1}
                                     formDefinition={PlatformFormDef(sameRailType, sameStepType, direction, radius, isLastSection)} object={segment}
                                     onChange={onChange}/>
                </div>}
            </Col>
        </Row>
        <hr/>
    </div>;

}
