import {PackageTypes} from "../../../package/PackageTypes";

export default () =>
{
    return {
        field: "type",
        headerName: "Type",
        type: "singleSelect",
        valueOptions: PackageTypes.map((e) =>
        {
            return {value: e.id, label: e.name}
        }),
    }
}