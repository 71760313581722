import React, {Component, useCallback, useMemo, useState} from 'react';
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, FormGroup, Input, Label,
    Nav,
    NavItem,
    NavLink,
    Row, Spinner,
    TabContent,
    TabPane
} from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation} from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import _, {flowRight as compose} from 'lodash';
import GetOrder from './graphql/GetOrder.graphql';
import {
    ContactIdFormDef,
    downloadCargoListPDF, downloadCEDocumentByOrderId,
    FORMAT_DATE,
    formatCurrency,
    formatToSwedishTime,
    ProjectIdFormDef
} from "../UTIL";
import DetailRenderer from "../common/DetailRenderer";
import ArticleRows from "../common/ArticleRows";
import CreatePurchaseOrders from './graphql/CreatePurchaseOrders.graphql';
import CreateArticleRow from "../common/CreateArticleRow";
import Collapser from "../common/Collapser";
import PurchaseOrderSubPanel from "../purchaseOrder/PurchaseOrderSubPanel";
import UpdateOrderMutation from './graphql/UpdateOrderMutation.graphql';
import BPMDiagram from "../common/BPMDiagram";
import FilesSubpanel from "../common/subpanels/FilesSubpanel";
import UploadFile from "../common/UploadFile";
import WorkflowDetail from "../common/WorkflowDetail";
import GenericWorkflowForm from "../common/GenericWorkflowForm";
import ModalForm from "../common/ModalForm";
import {OrderTracking} from "./OrderTracking";

import FormRenderer from "../common/FormRenderer";
import CommentList from "../common/comment/CommentList";
import PdfModal from "./PdfModal";
import EditableDetailRenderer from "../common/EditableDetailRenderer";
import GetTermsOfDeliveryQuery from '../common/graphql/GetTermsOfDelivery.graphql';
import GetTermsOfPayment from '../common/graphql/GetTermsOfPayment.graphql';
import GetModeOfDelivery from '../common/graphql/GetModeOfDelivery.graphql';

import NotificationPopup from "../common/lib/NotificationPopup";
import TemplatesGeneration from "../quote/templates/TemplatesGeneration";
import {useStdMutation} from "../spiralStair/lib/Util";
import CreateInternalProxyInvoice from "./CreateInternalProxyInvoice";
import {IsEconomy, IsLogistics} from "../Auth";
import CreateInternalInvoice from "../purchaseOrder/CreateInternalInvoice";
import SelectInvoiceNumberModal from "./SelectInvoiceNumberModal";
import SpiralStairBomModal from "../spiralStair/bom/BomInOrderModal"
import CreateFreeTextRow from "../common/createArticle/CreateFreeTextRow";
import {CreateSpiralStair, QuickCreateModuleRamp} from "../common/createArticle/CreateArticle";
import {CreateStraightStair, CreateStraightStairV2} from "../common/createArticle/CreateStraightStair";
import CreateArticle from "../common/createArticle/CreateArticle";
import {PrioritizedProject, useUpdateArticleRowsMutation} from "../generated/graphql";
import ConfirmCancelModel from "./ConfirmCancelModal";
import InvoiceSubPanel from "./invoice/InvoiceSubPanel";
import PredictedWorkloadButton from "../common/PredictedWorkloadButton";
import DatePickerWithWeek from "../common/DatePickerWithWeek";
import {CountryList} from "../common/CountryList";
import DownloadCEDocumentModal from "../common/DownloadCEDocumentModal";
import ValidateOrderInformationModal, {getOrderInvalidFields} from "./ValidateOrderInformationModal";
import {isInventoryPath} from "../inventory/InventoryRouters";
import OrderShipmentPanel from "./OrderShipmentPanel";
import OrderPackagePanel from "./OrderPackagePanel";
import {ConsigneeAccountList} from "../shipment/model/ConsigneeAccountList";

export const RECEIVER_PAYS_FREIGHT_ID = "14"

export const getDefaultDeliveryCountry = (defaultDeliveryCountry, tenantId) => {
    if (defaultDeliveryCountry) {
        return defaultDeliveryCountry
    }

    if (tenantId === "ESSE") {
        return "Sweden"
    }
    if (tenantId === "ESNO") {
        return "Norway"
    }

    return null
}

const Details = ({order})=>
{
    const [updateOrder] = useMutation(UpdateOrderMutation,{
        refetchQueries:["getOrder"]
    });
    const {invoiceDate,invoiceNumber,creator,status,internalInvoiceDate,
        createdAt,updatedAt,internalInvoiceNumber,quoteNumber} = order;

    return <EditableDetailRenderer columns={3} formDefinition={[
        {name:'companyId',label:"Select Company",type:"search",
            types:["company"],
            required:true,
            filter:searchObj=>searchObj.type === "company",
            startValue:{value:order.company.id,label:order.company.name},
        },
        {name:"termsOfDeliveryId",label:`Terms of delivery`,
            type:"select",query:GetTermsOfDeliveryQuery,required:true,
            defaultValue:_.get(order,'company.defaultTermsOfDelivery.id'),
            variables:{
                locale:order.company.locale
            }},
        {name:"preferredDeliveryDate",type:"custom",required:true,
            transformValue: ({value}) => formatToSwedishTime(value, FORMAT_DATE),
            input: function (model, onChange)
            {
                return <div>
                    <DatePickerWithWeek  defaultValue={model["preferredDeliveryDate"]}
                                              onChange={onChange}/>
                    <PredictedWorkloadButton/>
                </div>
            }
        },
        ProjectIdFormDef(order),
        {name:"modeOfDeliveryId",label:`Mode of delivery`,
            type:"select",query:GetModeOfDelivery,required:true,
            defaultValue:_.get(order,'company.defaultModeOfDelivery.id'),
            variables:{
                locale:order.company.locale
            }},
        {
            name: "dateOfDelivery",
            disabled: true,
            transformValue: ({value}) => formatToSwedishTime(value, FORMAT_DATE)
        },
        {name:'projectStage', label: "Part of project", hint: "(project stage, descriptive name for order)"},
        ContactIdFormDef,
        {name:"termsOfPaymentId",label:`Terms of payment`,
            type:"select",query:GetTermsOfPayment,required:true,
            defaultValue:_.get(order,'company.defaultTermsOfPayment.id'),
            variables:{
                locale:order.company.locale
            }},
        {name:'customerOrderAnnotation'},
        {name:'reverseCharge',label:"Reverse Charge (Omvänd byggmoms/skattskyldighet)",type:'checkbox'},
        {name:'noVAT',type:'checkbox'},
        {name:'customerReferenceNumber'},

        {name:"shippingCost",type:"currency",required:true,defaultValue:0,
            transformValue:({value,object})=>`${value || "0"} ${object.currency||""}`},
        {name:"packagingCost",type:"currency",required:false,defaultValue:0,
            transformValue:({value,object})=>`${value || "0"} ${object.currency||""}`},
        {name:"currency",type:"currencyPicker"},
        {name:"description",label:"Description",type:"textarea"},
        {
            name: "prioritizedProject", type: "select",
            options: Object.entries(PrioritizedProject)
                .map(([key, value]) => ({id: value, name: key}))
        },

        {name:"assigneeId",label:"Assignee",type:"userPicker"},
        // {name:"shippingCost",type:"currency",required:true,defaultValue:0},
        // {name:"shippingCostCurrency",type:"currencyPicker",required:true},






        // {name:"invoiceNumber",required:true,hideField:_=>!order.invoiceNumber},
        // {name:"invoiceDate",type:"date",required:true,hideField:_=>!order.invoiceDate},
        // {name:"internalInvoiceNumber",required:true,hideField:_=>!order.internalInvoiceNumber},
        // {name:"internalInvoiceDate",required:true,hideField:_=>!order.internalInvoiceNumber},
    ]} onSubmit={({object})=>updateOrder({variables:{
            id:order.id,
            order:object
        }})
    } object={order} extra={<DetailRenderer object={{
        invoiceDate: formatToSwedishTime(invoiceDate, FORMAT_DATE),
        invoiceNumber,
        internalInvoiceDate: formatToSwedishTime(internalInvoiceDate, FORMAT_DATE),
        internalInvoiceNumber,
        status,
        quoteNumber,
        creator,
        createdAt,
        updatedAt,
    }}/>} />
};

const OrderSum = ({orderSum:{total,totalNoVAT,currency}})=>
{
     return <Row className="justify-content-between">
        <Col>

        </Col>
        <Col sm={6}>
            <p style={{marginLeft:"100px"}}>
                <strong>No VAT Total:</strong> {formatCurrency(totalNoVAT)} {currency}</p>
            <p style={{marginLeft:"100px"}}>
                <strong>Total:</strong> {formatCurrency(total)} {currency}</p>
        </Col>
    </Row>
};

const Address = ({order})=>
{
    const [editing,setEditing] = useState(false);
    const [updateOrder] = useMutation(UpdateOrderMutation,{
        refetchQueries:["getOrder"]
    });
    return <Card>
        <CardHeader>
            <Row className="justify-content-between">
                <Col sm={5}>
                    Delivery Info
                </Col>
                <Col sm={3}>
                    {!editing && <Button size="sm" onClick={()=>setEditing(true)}>
                        Edit
                    </Button>}
                    {editing && <Button size="sm" onClick={()=>setEditing(false)}>
                        Cancel
                    </Button>}
                </Col>
            </Row>

        </CardHeader>
        {!editing && <CardBody>
            <p>{order.deliveryTitle}</p>
            <p>{order.deliveryStreet}</p>
            <p>{order.deliveryPostalCode} {order.deliveryCity} {order.deliveryCountry}</p>
            <p>{order.deliveryContactName}</p>
            <p>{order.deliveryContactPhone}</p>
            <p>{order.deliveryFreeText}</p>
        </CardBody>}
        {editing && <CardBody>
            <FormRenderer columns={1} object={order} onSubmit={({object}) =>
            {
                updateOrder({variables:
                    {
                        id:order.id,
                        order:object
                    }});
                setEditing(false);
            }} formDefinition={[
                {name:'deliveryTitle'},
                {name:'deliveryStreet',required:true},
                {name:'deliveryPostalCode',pattern:"[0-9 ]+"},
                {name:'deliveryCity'},
                {
                    name: 'deliveryCountry',
                    type: "select",
                    options: CountryList,
                    defaultValue: getDefaultDeliveryCountry(order.deliveryCountry, order.tenantId)
                },
                {name:'deliveryContactName'},
                {name:'deliveryContactPhone'},
                {name:'deliveryFreeText',type:'textarea'},
                {
                    name: 'consignee', type: 'select',
                    options: ConsigneeAccountList,
                    hideField: () => order.termsOfDelivery.id !== RECEIVER_PAYS_FREIGHT_ID
                }
            ]} />
        </CardBody>}
    </Card>
};

const EditAllRows = ({order}) => {
    const [updateArticleRows] = useUpdateArticleRowsMutation({refetchQueries:'getOrder'});
    const submit = useCallback(async(form,toggle) =>
    {
        if (window.confirm(`Are you sure you wish to set these values for all rows?`))
        {
            await updateArticleRows({
                variables: {
                    articleRows: _.map(order.articleRows, ar =>
                    ({
                        id: ar.id,
                        discount: form.object.discount || null,
                    }))
                }
            });
            toggle();
        }
    }, [updateArticleRows,order]);

    return <ModalForm title={`Bulk Edit`} activationButtonText={`Edit All Rows`}>
        {toggle =>
        <div>
            <Alert color="warning">Sets these values for all article rows.</Alert>
            <FormRenderer formDefinition={[{name: "discount", type: "number"}]}
                          onSubmit={form=>submit(form,toggle)}/>
        </div>
        }
    </ModalForm>
}

const CreateInvoice = ({orderId, invoices})=>
{
    const [createVismaInvoice,{loading}] = useStdMutation(`Create invoice`,gql`mutation($orderId:ID!)
    {
        createVismaInvoice(orderId:$orderId)
    }`,{variables:{orderId}});
    const [clicked,setClicked] = useState(false);
    const createInvoice = useCallback(async()=>
    {
        try
        {
            setClicked(true);
            await createVismaInvoice();
        }
        catch(e)
        {
            console.error(e);
        }
    },[createVismaInvoice])
    if(clicked && loading)
    {
        return <Spinner color={"warning"}/>
    }
    return <div>
        {!clicked && <Button onClick={() => {
            if (invoices.length > 0) {
                const yes = window.confirm(`This order already has invoice. \nAre you sure you want to create an invoice?`)
                if (yes) {
                    createInvoice()
                }
            } else {
                createInvoice()
            }
        }}>
            Create invoice in Visma
        </Button>}
    </div>
}

const MissingFieldsAlert = ({order, onEditClick}) => {
    const orderInvalidFields = useMemo(() => {
        return getOrderInvalidFields(order)
    }, [order])

    return orderInvalidFields.length === 0
        ? null
        : <Alert color="warning">
            <h6>Missing data</h6>
            <ul style={{paddingLeft: 0, margin: 0}}>
                {orderInvalidFields.map((field, index) => (
                    <li key={index}>{field}</li>
                ))}
            </ul>
            <Button size="sm" color="warning" onClick={onEditClick}>Edit</Button>
        </Alert>
}

class OrderDetailPage extends Component
{


    constructor()
    {
        super();
        this.state = {pdf:false,startDrawJobFormOpen:false,activeTab:"default",
            dropdownOpen:false,proxyInvoice:false,
            invoiceNumberModal:false,
            openSpiralStairBomModal:false,
            openCeModal: false,
            confirmCancelModel:false,
            isDisplayDeliveryDate: true,
            openValidateInformationModal: false
        };
    }

    setTab = (activeTab)=>
    {
        this.setState({activeTab});
    };

    downloadOrderPDF(){
        this.setState({pdf:true});
    }

    downloadCargoListPDF(){
        const order = this.props.data.order;
        downloadCargoListPDF(order.id,`${order.number}-${order.company.name}-cargo-list`);
    }

    async createPurchaseOrders()
    {
        if(window.confirm("Are you sure you want to create purchase orders?\nHave you checked so that the articles are correct?"))
        {
            try
            {
                const res = await this.props.createPurchaseOrders({
                    variables:{orderId:parseInt(this.props.match.params.id)},
                    refetchQueries:["getOrder"]
                });
                if(res.errors)
                {
                    throw new Error(`Failure ${_.map(res.errors,e=>e.message)}`);
                }
                NotificationPopup.success(`Created Purchase Orders`);
            }
            catch(e)
            {
                NotificationPopup.error(`Failed to create Purchase Orders ${e.message}`);
            }
        }
    }

    async setDeliveryAddress()
    {
        const company = this.props.data.order.company;
        await this.props.updateOrder({
            variables: {
                id: parseInt(this.props.match.params.id),
                order: {
                    deliveryStreet: company.deliveryStreet,
                    deliveryCity: company.deliveryCity,
                    deliveryCountry: getDefaultDeliveryCountry(company.deliveryCountry, this.props.data.order.tenantId),
                    deliveryPostalCode: company.deliveryPostalCode,
                    deliveryFreeText: company.deliveryFreeText,
                    deliveryTitle: company.deliveryTitle,
                    deliveryContactName: company.deliveryContactName,
                    deliveryContactPhone: company.deliveryContactPhone,
                }
            }
        });
        //TODO: Add notification here.
    }

    workflow(order)
    {
        if(order.status === 'done')
        {
            return <h2>Workflow finished</h2>;
        }
        else if(!order.processInstanceId)
        {
            return <h2>No workflow</h2>;
        }
        else
        {
            return <Collapser open>
                <h1>Order workflow</h1>
                <BPMDiagram width={1200} height={450} processInstanceId={order.processInstanceId} activityIds={order.activityIds} />
            </Collapser>;
        }

    }

    deleteOrder = async(order)=>
    {
        this.setState({confirmCancelModel:true});
    };

    toggleDropdown = ()=> {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    handleIsDisplayDeliveryDateChange = (checked) => {
        this.setState({isDisplayDeliveryDate: checked});
    };


    renderAdress = (order)=>
    {
        return <Card>
            <CardHeader>
                Delivery address
            </CardHeader>
            <CardBody>
                <p>{order.deliveryTitle}</p>
                <p>{order.deliveryStreet}</p>
                <p>{order.deliveryPostalCode} {order.deliveryCity} {order.deliveryCountry}</p>
                <p>{order.deliveryFreeText}</p>
            </CardBody>

        </Card>
    };

    trackingNumber = (order)=>
    {
       return <OrderTracking order={order}/>
    };

    navbar = (order) => {
        const isFromInventory = isInventoryPath(window.location.pathname)

        const styleOrderNumber = order.cancelledAt ? {
            textDecoration: "line-through",
            color: "red"
        } : null

        const hasPurchaseOrders = _.isEmpty(order.purchaseOrders);
        return <Nav tabs fill>
            <NavItem>
                <NavLink active={this.state.activeTab === 'default'} onClick={() => {
                    this.setTab('default');
                }}>
                    {
                        isFromInventory
                            ? <h4 style={{...styleOrderNumber,fontSize:"12px"}}>Customer Order<br/> {order.number}</h4>
                            : <h4 style={styleOrderNumber}>Order {order.number}</h4>
                    }
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={this.state.activeTab === 'files'} onClick={() => {
                    this.setTab('files');
                }}>
                    <h4>Files</h4>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={this.state.activeTab === 'comments'} onClick={() => {
                    this.setTab('comments');
                }}>
                    <h4>Comments <Badge color={"info"}>{order.comments.length > 0 && order.comments.length}</Badge></h4>
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink active={this.state.activeTab === 'invoices'} onClick={() => {
                    this.setTab('invoices');
                }}>
                    <h4>Invoices</h4>
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink active={this.state.activeTab === 'templates'} onClick={() => {
                    this.setTab('templates');
                }}>
                    <h4>Templates</h4>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={this.state.activeTab === 'workflow'} onClick={() => {
                    this.setTab('workflow');
                }}>
                    <h4>Workflow</h4>
                </NavLink>
            </NavItem>
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle nav>
                    <h4>Actions</h4>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag='span'>
                        <Button color='secondary'
                                onClick={() => NotificationPopup.error(`Please use the 'Edit' button located under the 'Order Number'`)}>
                            Edit
                        </Button>
                    </DropdownItem>
                    {/*<DropdownItem tag='span'>*/}
                    {/*<Button color='primary' onClick={()=>{this.setState({startDrawJobFormOpen:true})}}>*/}
                    {/*Start draw job*/}
                    {/*</Button>*/}
                    {/*</DropdownItem>*/}
                    {hasPurchaseOrders && <DropdownItem tag='span'>
                        <Button color='warning' onClick={this.createPurchaseOrders.bind(this)}>Create Purchase
                            order(s)</Button>
                    </DropdownItem>}
                    <DropdownItem header>
                        PDFs
                    </DropdownItem>
                    <DropdownItem tag='span'>
                        <Button color='primary' onClick={this.downloadOrderPDF.bind(this)}>Order PDF</Button>
                    </DropdownItem>

                    <FormGroup check inline style={{marginLeft: "24px"}}>
                        <Input type="checkbox" checked={this.state.isDisplayDeliveryDate}
                               onChange={e => this.handleIsDisplayDeliveryDateChange(e.target.checked)}/>
                        <Label check>
                            Display Delivery Date
                        </Label>
                    </FormGroup>

                    <DropdownItem tag='span'>
                        <Button color='success' onClick={this.downloadCargoListPDF.bind(this)}>Cargo List PDF</Button>
                    </DropdownItem>
                    {order.tenantId === "ESNO" &&  <DropdownItem tag='span'>
                        <Button color='primary' onClick={() => {
                            if (order.internalProxyInvoices.length > 0) {
                                const yes = window.confirm(`This order already has proxy internal invoice. \nAre you sure you want to create a proxy internal invoice?`)
                                if (yes) {
                                    this.setState({proxyInvoice: true})
                                }
                            } else {
                                this.setState({proxyInvoice: true})
                            }
                        }
                        }>Create internal proxy invoice</Button>
                    </DropdownItem>}
                    {["ESSE","ESNO"].indexOf(order.tenantId) !== -1 &&
                    <IsEconomy>
                        <DropdownItem>
                                <CreateInvoice orderId={order.id} invoices={order.invoices}/>
                        </DropdownItem>
                    </IsEconomy>}
                    <DropdownItem tag='span'>
                        <IsEconomy><CreateInternalInvoice purchaseOrders={order.purchaseOrders} internalInvoices={order.internalInvoices}/></IsEconomy>
                    </DropdownItem>
                    <DropdownItem tag='span'>
                        {order.tenantId === "ESDE" &&
                            <Button color='primary' onClick={()=>this.setState({invoiceNumberModal:true})}>Invoice PDF</Button>
                        }
                    </DropdownItem>
                    <IsLogistics>
                        <DropdownItem>
                            <Button color='primary' onClick={()=>this.setState({openSpiralStairBomModal:true}) } >Bill of Material</Button>
                        </DropdownItem>
                    </IsLogistics>

                    <DropdownItem>
                        <Button color='warning' onClick={()=>this.setState({openCeModal:true}) } >All CE PDF</Button>
                    </DropdownItem>

                    {!order.cancelledAt &&
                        <DropdownItem tag='span'>
                            <Button color='danger' onClick={() => this.deleteOrder(order)}>Mark as Cancelled</Button>
                        </DropdownItem>
                    }

                </DropdownMenu>
            </Dropdown>
            <NavItem>
            </NavItem>
        </Nav>
    };

    downloadCeDocumentClick = async (order, locale) => {
        const fileName = `dop-${order.number}`
        await downloadCEDocumentByOrderId(order.id, locale, fileName)
    }

    render()
    {
        const data = this.props.data;
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error)
        {
            console.error(data.error);
            return (<p>Error!</p>);
        }
        else if(data.order == null)
        {
            return (<p>Not found :(</p>);
        }

        const order = data.order;
        return (
            <div>
                <ConfirmCancelModel order={order} isOpen={this.state.confirmCancelModel} toggle={()=>this.setState({confirmCancelModel:!this.state.confirmCancelModel})} />
                <CreateInternalProxyInvoice isOpen={this.state.proxyInvoice} order={order}
                                 toggle={()=>this.setState({proxyInvoice:!this.state.proxyInvoice})}/>
                <PdfModal obj={order} isOpen={this.state.pdf} toggle={()=>this.setState({pdf:!this.state.pdf})} isDisplayDeliveryDate={this.state.isDisplayDeliveryDate} />
                <SelectInvoiceNumberModal order={order} isOpen={this.state.invoiceNumberModal}
                                          toggle={()=>this.setState({invoiceNumberModal:!this.state.invoiceNumberModal})}/>
                <SpiralStairBomModal order={order} isOpen={this.state.openSpiralStairBomModal} toggle={()=>this.setState({openSpiralStairBomModal:!this.state.openSpiralStairBomModal})}/>
                <DownloadCEDocumentModal toggle={()=>this.setState({openCeModal:!this.state.openCeModal})} isOpen={this.state.openCeModal}
                                         hideWarning={true}
                                         onClick={(locale)=> this.downloadCeDocumentClick(order, locale)}/>
                <ValidateOrderInformationModal order={order} isOpen={this.state.openValidateInformationModal}
                                               toggle={() => this.setState({openValidateInformationModal: !this.state.openValidateInformationModal})}/>

                {this.navbar(order)}
                <TabContent activeTab={this.state.activeTab} style={{paddingTop:"35px"}}>
                    <TabPane tabId="default">
                        <Row className="">
                            <Col md={5}>
                                <Details order={order} />
                            </Col>
                            <Col md={3}>
                                <Address order={order}/>
                                {/*{this.renderAdress(order)}*/}
                                {!order.deliveryStreet && order.company.deliveryStreet &&
                                <Card body>
                                    <CardTitle>Set delivery address to {order.company.name} delivery address.</CardTitle>
                                    <CardText>
                                        Title: {order.company.deliveryTitle}<br/>
                                        Street: {order.company.deliveryStreet}<br/>
                                        Postal Code: {order.company.deliveryPostalCode}<br/>
                                        City: {getDefaultDeliveryCountry(order.company.deliveryCity, order.tenantId)}<br/>
                                        Country: {order.company.deliveryCountry}<br/>
                                        Contact Name: {order.company.deliveryContactName}<br/>
                                        Contact Phone: {order.company.deliveryContactPhone}<br/>
                                        Free Text: {order.company.deliveryFreeText}
                                    </CardText>
                                    <Button color='success' onClick={this.setDeliveryAddress.bind(this)}>Set delivery address</Button>
                                </Card>
                                }
                                <br/>
                                <MissingFieldsAlert order={order}
                                                    onEditClick={() => this.setState({openValidateInformationModal: !this.state.openValidateInformationModal})}/>
                            </Col>
                            <Col md={2}>
                                <WorkflowDetail orderId={order.id} />
                            </Col>
                            <Col md={2}>
                                {this.trackingNumber(order)}
                            </Col>
                        </Row>
                        <br/>
                        <h4>Rows</h4>

                        <div style={{display:"inline-flex", marginBottom:"7px"}}>

                            <span style={{width:"15px"}} />
                            <Card style={{width:"640px"}}>
                                <ButtonGroup>
                                    <CreateArticleRow orderId={order.id} locale={order.company?.locale}/>
                                    <CreateArticle orderId={order.id}/>
                                    <CreateSpiralStair orderId={order.id}/>
                                    <CreateStraightStair orderId={order.id} />
                                    <CreateStraightStairV2 orderId={order.id} />
                                    <QuickCreateModuleRamp orderId={order.id} />
                                    <CreateFreeTextRow orderId={order.id} />
                                </ButtonGroup>
                            </Card>
                            <span style={{width:"15px"}} />
                            <EditAllRows order={order}/>
                        </div>

                        <ArticleRows articleRows={order.articleRows} orderId={order.id} currency={order.currency}
                                     isDisplayCePdf={true} isDisplayPackageStatus
                        />
                        {order.orderSum &&<OrderSum orderSum={order.orderSum} />}
                        <Collapser open>
                            <h4>Purchase Orders</h4>
                            <PurchaseOrderSubPanel purchaseOrders={order.purchaseOrders}/>
                        </Collapser>
                        <div style={{marginTop: "30px"}}>
                            <Collapser open>
                                <OrderShipmentPanel articleRows={order.articleRows}/>
                            </Collapser>
                        </div>
                        <IsLogistics>
                            <div style={{marginTop: "30px"}}>
                                <Collapser open>
                                    <OrderPackagePanel articleRows={order.articleRows} orderId={order.id}/>
                                </Collapser>
                            </div>
                        </IsLogistics>
                    </TabPane>
                    <TabPane tabId="files">
                        <UploadFile noModal orderId={order.id}/>
                        <FilesSubpanel files={order.files}/>
                    </TabPane>
                    <TabPane tabId="comments">
                        <Row>
                            <Col sm={6}>
                                <CommentList orderId={order.id} comments={order.comments} assignee={order.assignee?.displayName}/>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="invoices">
                        <InvoiceSubPanel invoices={order.invoices}
                                         internalInvoices={order.internalInvoices}
                                         internalProxyInvoices={order.internalProxyInvoices}/>
                    </TabPane>
                    <TabPane tabId="templates">
                        <TemplatesGeneration order={order}/>
                    </TabPane>
                    <TabPane tabId="workflow">
                        <Row>
                            <Col md={12}>
                                {this.state.activeTab === "workflow" && this.workflow(order)}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>


                <GenericWorkflowForm variables={{order}}
                                     processDefinitionKey='DrawJob'
                                     toggle={()=>this.setState({startDrawJobFormOpen:!this.state.startDrawJobFormOpen})}
                                     isOpen={this.state.startDrawJobFormOpen}/>
            </div>);
    }


}


export default compose(graphql(GetOrder, {
        options: (props) =>
        {
            return {variables: {id: parseInt(props.match.params.id)}};
        }
    }), graphql(CreatePurchaseOrders, {name: "createPurchaseOrders"}),
graphql(UpdateOrderMutation,{name:"updateOrder"}),
)(withRouter(OrderDetailPage));
