import React from 'react';
import GetSuppliers from '../supplier/graphql/GetSuppliersV2.graphql';
import _ from 'lodash';
import Select from "../common/FixRequiredSelect";
import PlatformImage from "./PlatformImage";
import {Button} from "reactstrap";
import {Comparator} from "../generated/graphql";


export const PlatformTypes = {
    straightExit: {
        id: "straightExit",
        name:"Straight Exit"
    },
    sideExit:
    {
        id:"sideExit",
        name:"Side Exit"
    },
};

export const RailingTypes = [
    {
        id:"std",
        name:"Standard"
    },
    {
        id:"oak",
        name:"Oak"
    }
];

export const SpiralStairCageTypes = {
    std:{
        id: "std",
        name: "Protective cage standard"
    },
    nocage:{
        id: "nocage",
        name: "No protective cage"
    },
    gate:{
        id:"gate",
        name:"Gate"
    },
    // freeCage3000:{
    //     id:"freeCage3000",
    //     name:"Full-circular protective cage incl door, Height 3000 mm"
    // },
    // freeCage4500:{
    //     id:"freeCage4500",
    //     name:"Full-circular protective cage incl door, Height 4500 mm"
    // }
};

export const SpiralStairRadii = [600, 700, 800, 900, 1000, 1100, 1200, 1300];


export const GlossTypes = {
    inside30_40:{id:"inside30_40",name:"30-40"},
    outside70_80:{id:"outside70_80",name:"70-80"},
}
export const PlatingTypes = {
    vfz:{id:"vfz",name:"Hot dip galvanized EN1461"},
    vfz_fe_zn:{id:"vfz_fe_zn",name:"Hot Dip Galvanized EN ISO 1461-Fe/Zn 115"},
}

export const SpiralStairPicketTypes = [

    {
        id: "industrial",
        name: "Industrial railing"
    },
    {
        id: "childSafe",
        name: "Childsafe railing with arcs"
    },
    {
        id:"follower",
        name:"Industrial railing with 1 Follower"
    },
    {
        id:"twoFollower",
        name:"Industrial railing with 2 Followers"
    },
    {
        id:"plateSections",
        name:"Perforated plate"
    },

    {
        id:"closelySpacedPosts",
        name:"Childsafe railing with poles"
    },
    {
        id:"plate",
        name:"Plate",
    },
];

export const SpiralStairStepTypes = [
    {
        id:"33x50",
        name:"33x50 Grating PW model Eurostair",
        minStepHeight: 160,
        enabled: true,
    },
    {
        id:"33x11",
        name:"33x11 Grating P model Eurostair",
        minStepHeight: 160,
        enabled: true,
    },
    {
        id:"Tårplåt",
        name:"Tear plate model Eurostair",
        minStepHeight: 170,
        enabled: true,
    },
    {
        id:"Håldurk",
        name:"Perforated plate model Eurostair",
        minStepHeight: 170,
        enabled: true,
    },
    {
        id:"Slätplåt",
        name:"Plain plate model Eurostair",
        minStepHeight: 170,
        enabled: true,
    },
    {
        id:"GD33x33",
        name:"33x33 Grating P model Eurostair",
        minStepHeight: 160,
        enabled: true,
    },
    {
        id:"GD33x11Serrated",
        name:"33x11 Grating P serrated 1-way model Eurostair",
        minStepHeight: 160,
        enabled: true,
    },
    {
        id:"GD33x33Serrated",
        name:"33x33 Grating P serrated 2-ways model Eurostair",
        minStepHeight: 160,
        enabled: false,
    },
    {
        id:"oak30mm",
        name:"Oak 30 mm model Eurostair",
        minStepHeight: 160,
        enabled: true,
    },
];


export const SurfaceTreatmentTypes = [
    {
        id: "vfz",
        name: "Hot dip galvanized EN1461",
        color: "info"
    },
    {
        id:"vfz_fe_zn",
        name:"Hot Dip Galvanized EN ISO 1461-Fe/Zn 115",
        color:"warning"
    },
    {
        id: "pulverlack",
        name: "Powder coated",
        color: "success"
    },
    {
        id: "vfz_lack",
        name: "Hot dip galvanized + powder coated",
        color: "danger"
    },
    {
        id: "obehandlad",
        name: "Untreated",
        color: "primary"
    },
];


export const PlatformFormDef = (sameRailType, sameStepType, direction, radius, isLastSection)=> {
    const def = [
        {name: "platformPicketType", label: "Type of platform railing", type: "select",options:SpiralStairPicketTypes},
        {name: "platformSpecial", type: "checkbox"},
        {
            name: "platformMaterial",
            defaultValue: "33x50",
            label: "Platform Type",
            type: "select",
            options: SpiralStairStepTypes.filter((e)=> e.enabled)
        },
        {name: "platformNumPickets", defaultValue: 0, label: "No. Platform Poles", type: "number"},
        {name: "platformType",label:"Type of platform",type:"select",options:PlatformTypes},
        {
            name:"blank",type:"hidden",hideField:_=>true
        },
        {name: "platformS1", label: "Platform width (side 1)(stair entrance side)", type: "number", defaultValue: 0},
        {name: "platformS2", label: "Platform length (side 2)", type: "number", defaultValue: 0},
        {name: "nothing", label: " ", type: "custom", input:(formObject, onChange)=> {
            return <div>
                <PlatformImage
                    isLastSection={isLastSection}
                    platformType={formObject.platformType}
                    platformHeight={formObject.platformS2}
                    platformWidth={formObject.platformS1}
                    stairRadius={radius}
                    stairType={direction}
                    formObject={formObject}
                    onChange={onChange}
                />
            </div>
        }},
    ];

    if (sameRailType) {
        def[0] = {
            name: "platformPicketType",
            label: "Type of platform railing",
            type: "custom",
            input: () => {
                if(sameRailType.value){
                    return <p><small>Set as same as stair: </small>{_.find(SpiralStairPicketTypes,({id})=>id===sameRailType.value).name}</p>;
                }
                return <p><small>Set as same as stair, please select stair's railing type first.</small></p>;
            }
        };
    }
    if (sameStepType) {
        def[2] = {
            name: "platformMaterial",
            label: "Platform Type",
            type: "custom",
            input: () => {
                if(sameStepType.value){
                    return <p><small>Set as same as stair: </small>{_.find(SpiralStairStepTypes,({id})=>id===sameStepType.value).name}</p>;
                }
                return <p><small>Set as same as stair, please select stair's step type first.</small></p>;
            }
        };
    }
    return def;
};


const hideInnerRailingFields = model=>!model.hasInnerRailing;

export const getSpiralStairForm = (unsavedChanges, toggleSurfaceTreatment, surfaceTreatmentSubPanel) => [
    {
        name: "direction",
        label: "Rotational Direction of Stair",
        type: "select",
        options: [{id: "left", name: "Left"}, {id: "right", name: "Right"}],
    },
    {
        name: "picketType",
        label: "Type of railing",
        type: "select",
        required: true,
        options: SpiralStairPicketTypes
    },
    {
        name: "cPipeRail",
        label: "Center tube handrail",
        type: "checkbox",
        defaultValue: false,
    },
    {
        name: "railType",
        label: " ",// "Handrail Material"
        type: "hidden",
        defaultValue: "std",
    },
    {
        name: "sameRailTypeOnPlatform",
        label: "Same Type of railing on all platforms",
        type: "checkbox",
    },
    {
        name: "centerPipeElongation",
        label: "Center Tube Elongation",
        type: "number",
        defaultValue: 0
    },
    {
        name:"stepType",type:"select",options:SpiralStairStepTypes.filter((e)=> e.enabled)
    },
    {
        name: "nothing", label: "Extra inner handrail", type: "custom", input: (formObject, onChange) => {
            const options = [
                {value: 0, label: "none"},
                {value: 1, label: "Standard"},
                {value: 2, label: "Extension"},
            ];
            let innerRailingTypeValue = 0;
            if (formObject["hasInnerRailing"]) {
                innerRailingTypeValue = 1;
            }
            if (formObject["hasInnerRailingExtension"]) {
                innerRailingTypeValue = 2;
            }
            return <Select
                options={options}
                value={options[innerRailingTypeValue]}
                onChange={(e, config) => {
                    switch (e.value) {
                        case 0:
                        default:
                            onChange(false, config, "hasInnerRailing");
                            onChange(false, config, "hasInnerRailingExtension");
                            break;
                        case 1:
                            onChange(true, config, "hasInnerRailing");
                            onChange(false, config, "hasInnerRailingExtension");
                            break;
                        case 2:
                            onChange(true, config, "hasInnerRailing");
                            onChange(true, config, "hasInnerRailingExtension");
                            break;
                    }
                }}
            />
        }
    },
    {
        name: "cage",
        type: "select",
        options: _.map(SpiralStairCageTypes, type => type),
    },
    {
        name: "sameStepTypeOnPlatform",
        label: "Same type of material in all platforms",
        type: "checkbox",
    },
    {
        name: "innerRailingHeight",
        label: "Inner Handrail Height",
        type: "number",
        hideField: hideInnerRailingFields,
        required: true,
        defaultValue: 900,
    },
    {name: "description", type: "textarea"},
    {
        name: "surfaceTreatment",
        label: " ",
        type: "custom",
        input: () => {
            return <div>
                {unsavedChanges && <div style={{color: "red", fontSize: "12px"}}>Save before opening this dialog to update price</div>}
                <Button color={"primary"} onClick={() => {
                    if(toggleSurfaceTreatment){toggleSurfaceTreatment();}
                }}>Edit Surface Treatment</Button>
                {
                    surfaceTreatmentSubPanel && surfaceTreatmentSubPanel
                }
            </div>
        },
    },
    {
        name: "innerRailingType",
        label: "Inner railing type",
        type: "custom",
        defaultValue: "std",
        hideField: hideInnerRailingFields,
        input: (formObject) => {
            let text;
            switch (formObject.innerRailingType){
                case "std":
                    text = "Standard";
                    break;
                case "oak":
                    text = "Oak";
                    break;
                default:
                    text = formObject.innerRailingType;
                    break;
            }
            return <div>{text}</div>
        }
    },
    {
        name: "supplierId",
        required: true,
        label: "Supplier",
        type: 'select',
        query: GetSuppliers,
        variables: {
            filtering: {
                items: [{
                    key: "productType_supplier.some.productType.name",
                    value: "Spiral stair",
                    comparator: Comparator.Equals
                }]
            }
        }
    },
    {
        name: "railHeight",
        label: " ",
        type: "hidden",
        defaultValue: 1100,
    },

    {
        name: "hasInnerRailing",
        label: " ",
        type: "checkbox",
        hideField: () => true,
        defaultValue: false,
    },
    {
        name: "hasInnerRailingExtension",
        label: " ",
        type: "checkbox",
        hideField: () => true,
        defaultValue: false,
    },
];