import CompanyListPage from "./company/CompanyListPage";
import ContactListPage from "./contact/ContactListPage";
import CompanyCreatePage from './company/CompanyCreatePage';
import {Route, Switch} from 'react-router-dom';
import CompanyDetail from "./company/CompanyDetail";

import CreateContact from './contact/CreateContactPage';
import UpdateContactPage from "./contact/ContactEditPage";
import ContactDetailPage from './contact/ContactDetailPage';

import ProjectListPage from './project/ProjectListPage';
import ProjectDetailPage from './project/ProjectDetailPage';
import ProjectEditPage from "./project/ProjectEditPage";
import ProjectCreatePage from './project/ProjectCreatePage';
import 'assets/images/menu2.jpg';

import TaskListPage from "./new-task/TaskListPage";
import PurchaseOrderListPage from "./purchaseOrder/PurchaseOrderListPage";
import PurchaseOrderDetailPage from "./purchaseOrder/PurchaseOrderDetailPage";
import OrderListPage from "./order/OrderListPage";
import OrderDetailPage from "./order/OrderDetailPage";
import SupplierListPage from "./supplier/SupplierListPage";
import SupplierDetailPage from "./supplier/SupplierDetailPage";
import SupplierEditPage from "./supplier/SupplierEditPage";
import PurchaseOrderCreatePage from "./purchaseOrder/PurchaseOrderCreatePage";
import SupplierCreatePage from "./supplier/SupplierCreatePage";
import StockArticleListPage from "./stockArticle/StockArticleListPage";
import SpiralStairDetailPage from "./spiralStair/SpiralStairDetailPage";
import DrawJobOverviewPage from "./drawJobOverview/DrawJobOverviewPage";
import LogisticsOrderPage from "./logistics/LogisticsOrderPage";
import Dynamic from "./common/Dynamic";
import AdminPage from "./admin/AdminPage";
import ContactInformation from "./ContactInformation/ContactInformation";

import OrderCreatePage from "./order/OrderCreatePage";
import FinishedTasksPage from "./scheduler/FinishedTasksPage";
import QuoteListPage from "./quote/QuoteListPage";
import SpiralStairsListPage from "./logistics/SpiralStairsListPage";
import PurchaseOrderLogisticsPage from "./purchaseOrder/PurchaseOrderLogisticsPage";
import QuoteDetailPage from "./quote/QuoteDetailPage";
import ArticleDetailPage from "./common/article/ArticleDetailPage";
import ProfilePage from "./profile/ProfilePage";
import StraightStairDetailPage from "./straightStair/StraightStairDetailPage";
import ModuleRampPage from "./module-ramp/ModuleRampPage";
import 'react-notifications-component/dist/theme.css'
import EconomyOrderControlPage from "./economy/EconomyOrderControlPage";
import CreditRatingManagement from "./company/CreditRatingManagement";
import PriceList from "./priceList/PriceList";
import Documents from "./documents/Documents";
import {MembershipPage} from "./Membership";
import ShipmentListPage from "./shipment/ShipmentListPage";
import CreateShipmentPage from "./shipment/CreateShipmentPage";
import ShipmentDetailPage from "./shipment/ShipmentDetailPage";
import PackageListPage from "./package/PackageListPage";
import PackageDetailPage from "./package/PackageDetailPage";
import CreatePackagePage from "./package/CreatePackagePage";
import {InventoryRoute} from "./inventory/InventoryRouters";
import InventoryOrderListPage from "inventory/order/InventoryOrderListPage";
import StraightStairV2DetailPage from "./straightStairV2/StraightStairV2DetailPage";
import {ApolloProvider} from "@apollo/client";
import InventoryArticleListPage from "./inventory/article/List";
import InventoryArticleDetailPage from "./inventory/article/Detail";
import InventorySupplierListPage from "./inventory/supplier/List";
import InventorySupplierDetailPage from "./inventory/supplier/Detail";
import InventoryPurchaseOrderListPage from "./inventory/purchase-order/List";
import InventoryPurchaseOrderDetailPage from "./inventory/purchase-order/Detail";
import InventoryReportsPage from "./inventory/reports/Reports";
import InventoryStockTakingPage from "./inventory/stocktaking/Stocktaking";
import InventoryCodeScannerPage from "./inventory/codeScannerPage/InventoryCodeScannerPage";
import DeviationReportPage from "./deviationReport/DeviationReportPage";
import StraightStairPricingPage from "./straightStairV2/straight-stair-pricing/StraightStairPricingPage";
import ImageGalleryPage from "./image-gallery/ImageGalleryPage";
import InProductionTasksPage from "./scheduler/in-production-tasks/InProductionTasksPage";

const NotFound = function () {
    return (<h1>Not found <span role="img" aria-label="Oops">😕</span></h1>);
};

export default ({inventoryClient})=>
{
    return <Switch>
        <Route exact path='/' component={TaskListPage} />
        <Route exact path='/contacts' component={ContactListPage} />
        <Route exact path='/contactEdit/:id' component={UpdateContactPage} />
        <Route exact path='/contactDetail/:id' component={ContactDetailPage} />
        <Route exact path='/createContact/:companyId?/:companyName?' component={CreateContact} />

        <Route exact path='/companies' component={CompanyListPage} />
        <Route exact path='/createCompany' component={CompanyCreatePage} />
        <Route exact path='/companyDetail/:id' component={CompanyDetail} />
        <Route exact path='/credit-rating/:id' component={CreditRatingManagement} />

        <Route exact path='/projects' component={ProjectListPage} />
        <Route exact path='/projectDetail/:id' component={ProjectDetailPage} />
        <Route exact path='/projectEdit/:id' component={ProjectEditPage} />
        <Route exact path='/createProject' component={ProjectCreatePage} />

        {/*<Route exact path='/tasks' component={TaskListPage}/>*/}
        {/*<Route exact path='/createTask' component={TaskCreatePage}/>*/}

        <Route exact path='/purchaseOrders' component={PurchaseOrderListPage} />
        <Route exact path='/purchaseOrderDetail/:id' component={PurchaseOrderDetailPage} />
        <Route exact path={`${InventoryRoute.CRM_PURCHASE_ORDER_DETAIL}/:id`} component={PurchaseOrderDetailPage} />

        <Route exact path='/createPurchaseOrder' component={PurchaseOrderCreatePage} />

        <Route exact path='/orders' component={OrderListPage} />
        <Route exact path='/orderDetail/:id' component={OrderDetailPage} />
        <Route exact path={`${InventoryRoute.CRM_ORDER_DETAIL}/:id`} component={OrderDetailPage} />
        <Route exact path='/orderCreate' component={OrderCreatePage} />

        <Route exact path='/suppliers' component={SupplierListPage} />
        <Route exact path='/supplierDetail/:id' component={SupplierDetailPage} />
        <Route exact path='/supplierEdit/:id' component={SupplierEditPage} />
        <Route exact path='/createSupplier' component={SupplierCreatePage} />

        <Route exact path='/spiralStairDetail/:id/:articleRowId?' component={SpiralStairDetailPage} />

        <Route exact path='/quotes' component={QuoteListPage} />
        <Route exact path='/quoteDetail/:id' component={QuoteDetailPage} />

        <Route exact path='/articleDetail/:id/:articleRowId?' component={ArticleDetailPage} />

        <Route exact path='/straightStairDetail/:id/:articleRowId?' component={StraightStairDetailPage} />
        <Route exact path='/straightStairV2Detail/:id/:articleRowId?' component={StraightStairV2DetailPage} />

        <Route exact path='/moduleRampDetail/:id/:articleRowId?' component={ModuleRampPage} />

        <Route exact path='/profile' component={ProfilePage} />

        <Route exact path='/draw-overview' component={DrawJobOverviewPage} />

        <Route exact path='/economy-control' component={EconomyOrderControlPage} />

        <Route exact path='/logistics' component={LogisticsOrderPage} />

        <Route exact path='/logisticsPO' component={PurchaseOrderLogisticsPage} />

        <Route exact path='/admin' component={AdminPage} />

        <Route exact path={InventoryRoute.SCHEDULER} component={(props) => <Dynamic {...props} load={() => import(`scheduler/SchedulerPage`)} />} />

        <Route exact path={InventoryRoute.FINISHED_TASKS} component={FinishedTasksPage} />
        <Route exact path={InventoryRoute.IN_PRODUCTION_TASKS} component={InProductionTasksPage} />

        <Route exact path='/spiralStairs' component={SpiralStairsListPage} />

        <Route exact path='/reports' component={(props) => <Dynamic {...props} load={() => import(`./reports/OrderReportPage.js`)} />} />

        <Route exact path='/contact-list' component={ContactInformation} />

        <Route exact path='/priceList' component={PriceList} />

        <Route exact path='/documents' component={Documents} />
        <Route exact path='/membership' component={MembershipPage}/>
        <Route exact path='/module-ramp-test' component={(props)=><Dynamic {...props} load={() => import(`./module-ramp/ModuleRampPage`)} />}/>
        <Route exact path='/module-spiralStair' component={(props)=><Dynamic {...props} load={() => import(`./module-spiralStair/index`)} />}/>

        <Route exact path='/shipments' component={ShipmentListPage}/>
        <Route exact path='/create-shipment' component={CreateShipmentPage}/>
        <Route exact path='/shipment-detail/:id' component={ShipmentDetailPage}/>

        <Route exact path='/packages' component={PackageListPage}/>
        <Route exact path='/create-package' component={CreatePackagePage}/>
        <Route exact path='/package-detail/:id' component={PackageDetailPage}/>

        <Route exact path={`${InventoryRoute.ORDERS}`} component={InventoryOrderListPage}/>

        <Route exact path='/deviation-report' component={DeviationReportPage}/>

        <Route exact path='/straight-stair-pricing' component={StraightStairPricingPage}/>

        <Route exact path='/image-gallery' component={ImageGalleryPage}/>

        <ApolloProvider client={inventoryClient}>
            <Switch>
                <Route exact path={InventoryRoute.INDEX} component={InventoryArticleListPage}/>

                <Route exact path={InventoryRoute.ARTICLES} component={InventoryArticleListPage}/>
                <Route exact path={`${InventoryRoute.ARTICLE_DETAIL}/:id`} component={InventoryArticleDetailPage}/>

                <Route exact path={InventoryRoute.SUPPLIERS} component={InventorySupplierListPage}/>
                <Route exact path={`${InventoryRoute.SUPPLIER_DETAIL}/:id`} component={InventorySupplierDetailPage}/>

                <Route exact path={InventoryRoute.PURCHASE_ORDERS} component={InventoryPurchaseOrderListPage}/>
                <Route exact path={`${InventoryRoute.PURCHASE_DETAIL}/:id`} component={InventoryPurchaseOrderDetailPage}/>

                <Route exact path={InventoryRoute.REPORTS} component={InventoryReportsPage}/>
                <Route exact path={InventoryRoute.STOCK_TAKING} component={InventoryStockTakingPage}/>

                <Route exact path={InventoryRoute.CODE_SCANNER} component={InventoryCodeScannerPage}/>

                <Route exact path='/stockArticles' component={StockArticleListPage} />

                <Route component={NotFound}/>
            </Switch>
        </ApolloProvider>
    </Switch>
}
