export const ConsigneeAccountList = [
    {id: "NCC", name: "32016222: NCC"},
    {id: "Dynawind", name: "93204725: Dynawind"},
    {id: "BE Group", name: "13683610: BE Group"},
    {id: "Konstruktionssvets AB", name: "10968675: Konstruktionssvets AB"},
    {id: "ETP Transmission AB", name: "28832491: ETP Transmission AB"},
    {id: "Brålanda", name: "30341911: Brålanda"},
    {id: "Vänerply AB", name: "96143045: Vänerply AB"},
    {id: "Stora Enso", name: "96002514: Stora Enso"},
    {id: "Tornum", name: "32611667: Tornum"},
    {id: "Wallens Sport", name: "61622908: Wallens Sport"},
    {id: "Vänerply AB", name: "8505547: Vänerply AB"},
    {id: "Lademetall", name: "21916473: Lademetall"},
    {id: "Wenko", name: "10053254: Wenko"},
    {id: "Bröderna Edstrand", name: "10300184: Bröderna Edstrand"},
    {id: "Frimo", name: "20489779: Frimo"},
    {id: "Dalslands Gris", name: "29060134: Dalslands Gris"},
    {id: "Per Eriksson", name: "29060134: Per Eriksson"},
    {id: "Tornum", name: "29060134: Tornum"},
    {id: "Fredrik", name: "29060134: Fredrik"},
    {id: "Aros Industri Partner", name: "68047042: Aros Industri Partner"},
    {id: "Bröderna E, Sundsvall", name: "10126241: Bröderna E, Sundsvall"},
    {id: "Tveten", name: "29060134: Tveten"},
    {id: "Wenko", name: "22406656: Wenko"},
];
