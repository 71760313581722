import React, {memo} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ImageFragmentFragment} from "../../generated/graphql";

type Props = {
    image: ImageFragmentFragment | null
    toggle: () => void
}

const ImageModal = memo(({image, toggle}: Props) =>
{
    if(!image){
        return null
    }

    return <Modal isOpen={true} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>{image.title}</ModalHeader>
        <ModalBody>
            <img
                alt={image.title}
                src={`${image.url}`}
                style={{width: '100%'}}/>
        </ModalBody>
    </Modal>
})

export default ImageModal;