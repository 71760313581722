import React from "react";
import BackButtons from "../common/BackButtons";
import {Button, ButtonGroup, UncontrolledTooltip} from "reactstrap";
import {CameraType} from "./hooks/useCameraHook";

type Props = {
    articleRowId: string
    canUndo: boolean
    canRedo: boolean
    undo: () => void
    redo: () => void
    save: () => void
    unsavedChanges: boolean
    camera3DView: () => void
    cameraThreeView: (type: CameraType) => void
    downloadDrawing: () => Promise<void>
    downloadScreenshot: () => void
}

export default (props: Props) =>
{
    const {
        articleRowId,
        canUndo,
        canRedo,
        undo,
        redo,
        save,
        unsavedChanges,
        camera3DView,
        cameraThreeView,
        downloadDrawing,
        downloadScreenshot,
    } = props;

    return <div className="controlBar">
        <BackButtons style={{width: "90px"}} articleRowId={articleRowId}/>
        <span className="space"/>
        <ButtonGroup>
            <Button size="sm" color="secondary" disabled={!canUndo} onClick={undo}>undo</Button>
            <Button size="sm" color="secondary" disabled={!canRedo} onClick={redo}>redo</Button>
        </ButtonGroup>

        <span className="space"/>
        <Button size="sm" color="primary" onClick={save} disabled={!unsavedChanges}>
            {unsavedChanges ? "save" : "saved"}
        </Button>
        <span className="space"/>
        <ButtonGroup>
            <Button size="sm" color="secondary" onClick={camera3DView}>3D view</Button>
            <Button size="sm" color="secondary"
                    onClick={() => cameraThreeView(CameraType.top)}>Top view</Button>
        </ButtonGroup>
        <span className="space"/>
        <Button size="sm" color="secondary" onClick={downloadScreenshot}>Download Screenshot</Button>
        <span className="space"/>
        <Button size="sm" color="secondary"
                id={"button-download-drawing"}
                onClick={downloadDrawing}
                disabled={unsavedChanges}>
            Download drawing
        </Button>
        {
            unsavedChanges && <UncontrolledTooltip
                style={{backgroundColor: "white", color: "black"}}
                placement="top"
                target="button-download-drawing"
                autohide
                flip
                trigger="hover">
                To download the PDF, you must save it first.
            </UncontrolledTooltip>
        }
        <span className="space"/>
    </div>
}
