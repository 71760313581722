import {State} from "./ModuleRampPage";
import {
    getAllNodes,
    isNodesCanJointWithDegreeAnd2Length
} from "./utils";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Node} from "./ShpaeObject";
import ModuleTemplate, {
    getModuleTemplate,
    store,
    scaleRatio, childSafePole, ModuleData, pairNodeDistance,
} from "./ModuleTemplate";
import {ChildSafeRailingPiece, ChildSafeSection} from "./Module/ChildSafeSection";
import SceneManager, {SceneName} from "./SceneManager";
import {Tools} from "@babylonjs/core";
import {ChildSafeRailingPieceType, ModuleRampType} from "generated/graphql";

import {Modes} from "./Modes";

export const makeChildSafeRailingPiece = (selectedModule:ModuleTemplate, node) => {
    const allNodes = getAllNodes();
    const {railingPadding} = childSafePole;
    selectedModule.slopeDegree = node.parent.moduleTemplate.slopeDegree;
    if(node===node.parent.startNode){
        selectedModule.slopeDegree *= -1;
    }

    let filterFunction = (anotherNode) => false;
    let reachableNodes:Node[] = [];
    switch (selectedModule.otherProps.pieceType){
        case ChildSafeRailingPieceType.StraightJoint:
            filterFunction = (anotherNode)=>{
                if(!(anotherNode.parent instanceof ChildSafeSection)) return false;
                return Math.abs(Vector3.Distance(anotherNode.origin, node.origin) - railingPadding*2) < pairNodeDistance;
            }
            reachableNodes = allNodes.filter(filterFunction);
            if(reachableNodes.length===0){
                State.getInstance().mode = Modes.noAvailableNode;
                return;
            }
            selectedModule.slopeDegree *= -1;
            selectedModule.otherProps.jointTargetSlopeDegree = (reachableNodes[0].parent as ChildSafeSection).moduleTemplate.slopeDegree;
            if((reachableNodes[0].parent as ChildSafeSection).startNode === reachableNodes[0]){
                selectedModule.otherProps.jointTargetSlopeDegree *= -1;
            }

            break;
        case ChildSafeRailingPieceType.Joint:
            const {length} = selectedModule;
            const halfLength = length/2;
            const railingSection = node.parent as ChildSafeSection;
            const forwardVector = node === railingSection.endNode ? railingSection.vector.normalize(): railingSection.vector.multiplyByFloats(-1,-1,-1).normalize();
            const jointCenterPoint = node.origin.add(forwardVector.multiplyByFloats(halfLength, halfLength, halfLength));
            filterFunction = (anotherNode)=>{
                if(!(anotherNode.parent instanceof ChildSafeSection)) return false;
                return Math.abs(Vector3.Distance(anotherNode.origin, jointCenterPoint) - halfLength) < pairNodeDistance;
            }
            reachableNodes = allNodes.filter(filterFunction);
            if(reachableNodes.length===0){
                State.getInstance().mode = Modes.noAvailableNode;
                return;
            }
            selectedModule.slopeDegree *= -1;
            selectedModule.otherProps.jointTargetSlopeDegree = (reachableNodes[0].parent as ChildSafeSection).moduleTemplate.slopeDegree;
            if((reachableNodes[0].parent as ChildSafeSection).startNode === reachableNodes[0]){
                selectedModule.otherProps.jointTargetSlopeDegree *= -1;
            }

            break;
    }
    selectedModule.origin = new Vector3(-selectedModule.width-2, 0, 0);
    let selectedModuleModel = new ChildSafeRailingPiece(selectedModule, SceneManager.getInstance()[SceneName.main]);
    let selectedNode = selectedModuleModel.startNode;

    // attach selected module to another with their nodes
    if(!selectedNode){ return; }
    selectedNode.attachTo(node);

    return selectedModuleModel;
}


export const childSafeRailingPieceAutoComplete = () => {
    const {railingPadding} = childSafePole;
    let childSafeRailingNodes:(Node|null)[] = getAllNodes().filter((node)=>{
        if(node.attach.length>0) return false;
        return node.parent instanceof ChildSafeSection;
    });
    /*
    * 1. innerJoint90/outerJoint90
    * 2. straightJoint
    * 3. end
    * */
    const generatedPieces:ChildSafeRailingPiece[] = [];
    for (let index=0; index<childSafeRailingNodes.length; index++) {
        const nodeA = childSafeRailingNodes[index];
        if(nodeA===null) continue;

        childSafeRailingNodes.some((nodeB)=>{
            if(!nodeB) return false;
            const childSafeSectionA = nodeA.parent as ChildSafeSection;
            const ChildSafeSectionB = nodeB.parent as ChildSafeSection;
            if(childSafeSectionA===ChildSafeSectionB) return false;

            const nodeAVector = childSafeSectionA.startNode === nodeA ? childSafeSectionA.vector : childSafeSectionA.vector.multiplyByFloats(-1,-1,-1);
            const nodeBNegativeVector = ChildSafeSectionB.endNode === nodeB ? ChildSafeSectionB.vector : ChildSafeSectionB.vector.multiplyByFloats(-1,-1,-1);

            const turnDegree = Tools.ToDegrees(Vector3.GetAngleBetweenVectors(nodeAVector, nodeBNegativeVector, Vector3.Up()));
            const jointModules = store[ModuleRampType.ChildSafeRailingPiece].filter(e=>e.pieceType === ChildSafeRailingPieceType.Joint);
            const matchJoint = jointModules.find((joint) => {
                if(Math.abs(joint.angle-turnDegree)<1){
                    const halfLength = joint.lengthMM!*scaleRatio/2;
                    return isNodesCanJointWithDegreeAnd2Length(nodeB.origin, nodeA.origin, turnDegree, halfLength);
                }
                return false;
            });

            if(matchJoint){
                const index = jointModules.indexOf(matchJoint);
                const newPiece = makeChildSafeRailingPiece(getModuleTemplate({type:ModuleRampType.ChildSafeRailingPiece, id:index}), nodeA);
                if(newPiece) {
                    generatedPieces.push(newPiece);
                }

                childSafeRailingNodes[childSafeRailingNodes.indexOf(nodeA)] = null;
                childSafeRailingNodes[childSafeRailingNodes.indexOf(nodeB)] = null;
                return true;
            }
            return false;
        });
    }

    for (let index=0; index<childSafeRailingNodes.length; index++) {
        const nodeA = childSafeRailingNodes[index];
        if(nodeA===null) continue;

        const pairNode = childSafeRailingNodes.find((nodeB)=>{
            if(!nodeB) return false;
            return Math.abs(Vector3.Distance(nodeB.origin, nodeA.origin) - railingPadding*2) < pairNodeDistance;
        });

        if(pairNode){
            const moduleType = store[ModuleRampType.ChildSafeRailingPiece].find(e=>e.pieceType ===ChildSafeRailingPieceType.StraightJoint);
            const index = store[ModuleRampType.ChildSafeRailingPiece].indexOf(moduleType as ModuleData);

            const newPiece = makeChildSafeRailingPiece(getModuleTemplate({type:ModuleRampType.ChildSafeRailingPiece, id:index}), nodeA);
            if(newPiece) {
                generatedPieces.push(newPiece);
            }
            childSafeRailingNodes[childSafeRailingNodes.indexOf(pairNode)] = null;
            childSafeRailingNodes[childSafeRailingNodes.indexOf(nodeA)] = null;
        }
    }

    childSafeRailingNodes.forEach((node)=>{
        if(node===null) return;
        const endPieceModuleType = store[ModuleRampType.ChildSafeRailingPiece].find(e=>e.pieceType ===ChildSafeRailingPieceType.End);
        if(endPieceModuleType){
            const index = store[ModuleRampType.ChildSafeRailingPiece].indexOf(endPieceModuleType);
            const newPiece = makeChildSafeRailingPiece(getModuleTemplate({type:ModuleRampType.ChildSafeRailingPiece, id:index}), node);
            if(newPiece) {
                generatedPieces.push(newPiece);
            }
        }
    })
    return generatedPieces;
}
