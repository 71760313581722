import {Modal, ModalFooter, ModalHeader, ModalBody, Button} from "reactstrap";
import React from "react";
import {ArticleRow, PurchaseOrder} from "../../generated/graphql";


export type UpdatePurchaseOrderPriceData = {
    newPrice: number
    purchaseOrder: Partial<Pick<PurchaseOrder, 'number' | 'currency'>>
    articleRow: Partial<Pick<ArticleRow, 'id' | 'price'>>
}

type Props = {
    toggle: () => void
    onYesClicked: () => void
    data: UpdatePurchaseOrderPriceData | null
}

const UpdatePurchaseOrderPriceModal = ({toggle, onYesClicked, data}: Props) =>
{
    if (!data)
    {
        return null
    }

    const {purchaseOrder, articleRow, newPrice} = data

    return <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update purchase order price</ModalHeader>
        <ModalBody>
            <p>This stair is linked to PO {purchaseOrder.number} with
                price: {articleRow.price} {purchaseOrder.currency}</p>
            <p>Do you want to update the stair price on the PO to: {newPrice} {purchaseOrder.currency}?</p>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle}>
                No
            </Button>
            <Button color="primary" onClick={onYesClicked}>
                Yes
            </Button>{" "}
        </ModalFooter>
    </Modal>
}

export default UpdatePurchaseOrderPriceModal
