import NewFormRenderer from "../common/NewFormRenderer";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button} from "reactstrap";
import {State} from "./ModuleRampPage";
import {getModuleTemplate, store} from "./ModuleTemplate";
import {ModuleObject} from "./ShpaeObject";
import {industrialRailingPieceAutoComplete} from "./AutoCompleteIndustrailPiece";
import {childSafeRailingPieceAutoComplete} from "./AutoCompleteChildSafePiece";
import {Tools} from "@babylonjs/core";
import { ModuleRampType } from "generated/graphql";

export enum SelectedModuleType {
    startRamp,
    ramp,
    trianglePlatform,
    rampToPlatform,
    industrial,
    industrialParts,
    childSafe,
    childSafeParts,
    wood,
    kickPlate,
    supportLeg,
    stair,
}

const ModuleSettingBar = ({selectedModuleType, newStep, canvasRef, shouldShow}) => {
    const [autoGenAction, setAutoGenAction] = useState<(()=>ModuleObject[])|null>(null);
    const [moduleData, setModuleData] = useState<any>({});

    useEffect(()=>{
        let degree = 0;
        if(!moduleData.isLeaning){
            setModuleData(old=>({...old, degree}));
            return;
        }
        if(moduleData.heightForCalculate > 0){
            degree = 90 - Tools.ToDegrees(Math.atan(moduleData.lengthForCalculate/moduleData.heightForCalculate));
        }
        if(!isNaN(degree)){
            degree = Math.round(degree*100)/100;
            setModuleData(old=>({...old, degree}));
        }

    }, [moduleData.isLeaning, moduleData.heightForCalculate, moduleData.lengthForCalculate, setModuleData]);

    const formDefinition = useMemo(() => {
        let newFormDefinition:any = [];
        let newModuleData:any = {};
        setAutoGenAction(null);

        switch (selectedModuleType){
            case SelectedModuleType.startRamp:
                newModuleData.type = ModuleRampType.StartRamp;
                newFormDefinition = [
                    {
                        name:"id", label:"type", type:'select', style:{width: "300px"},
                        options: store[ModuleRampType.StartRamp].map((m, index) => ({id:index, name:m.name}))
                    },
                ];
                break;
            case SelectedModuleType.ramp:
                newModuleData.type = ModuleRampType.Ramp;
                newFormDefinition = [
                    {
                        name:"id", label:"type", type:'select', style:{width: "220px"},
                        options: store[ModuleRampType.Ramp].map((m, index) => ({id:index, name:m.name}))
                    },
                    {name:"isLeaning", label:"leaning",type:'checkbox', defaultValue:true},
                    {name:"heightForCalculate", label:"height",type:'number', defaultValue:0, hideField:model=>!model.isLeaning},
                    {name:"lengthForCalculate",label:"length",type:'number', defaultValue:0, hideField:model=>!model.isLeaning},
                    {name:"degree",label:"degree",type:'number', defaultValue:0, disabled: true},
                ];
                break;
            case SelectedModuleType.trianglePlatform:
                newModuleData.type = ModuleRampType.TrianglePlatform;
                newFormDefinition = [
                    {
                        name:"id", label:"type", type:'select', style:{width: "300px"},
                        options: store[ModuleRampType.TrianglePlatform].map((m, index) => ({id:index, name:m.name}))
                    },
                ];
                break;
            case SelectedModuleType.rampToPlatform:
                newModuleData.type = ModuleRampType.RampToPlatform;
                newFormDefinition = [
                    {
                        name: "id", label: "type", type: 'select', style: {width: "300px"},
                        options: store[ModuleRampType.RampToPlatform].map((m, index) => ({id: index, name: m.name}))
                    },
                ];
                break;
            case SelectedModuleType.childSafe:
                newModuleData.id = 0;
                newModuleData.type = ModuleRampType.ChildSafeSection;
                newFormDefinition = [
                    {name:" ", label:" ", style:{width: "fit-content"},type:'custom',input:()=> <span>Press Create to build a child safe railing section.</span>},
                ];
                break;
            case SelectedModuleType.industrial:
                newModuleData.id = 0;
                setModuleData(old=>({...old, id:0}));
                newFormDefinition = [
                    {name:"type",label:"type",type:'select', style:{width: "220px"},
                        options:[
                            {id:ModuleRampType.IndustrialPole, name:"Pole"},
                            {id:ModuleRampType.IndustrialRailing, name:"Handrail"},
                        ]
                    },
                ];
                break;
            case SelectedModuleType.industrialParts:
                newModuleData.type = ModuleRampType.IndustrialRailingPiece;
                newFormDefinition = [
                    {name:"id", label:"type", type:'select', style:{width: "220px"},
                        options: store[ModuleRampType.IndustrialRailingPiece].map((m, index) => ({id:index, name:m.name}))
                    },
                ];
                setAutoGenAction(()=>industrialRailingPieceAutoComplete);
                break;
            case SelectedModuleType.childSafeParts:
                newModuleData.type = ModuleRampType.ChildSafeRailingPiece;
                newFormDefinition = [
                    {name:"id", label:"type", type:'select', style:{width: "260px"},
                        options: store[ModuleRampType.ChildSafeRailingPiece].map((m, index) => ({id:index, name:m.name}))
                    },
                ];
                setAutoGenAction(()=>childSafeRailingPieceAutoComplete);
                break;
            case SelectedModuleType.stair:
                newModuleData.id = 0;
                newModuleData.type = ModuleRampType.StairStep;
                newFormDefinition = [
                    {name:"step", label:"step", type:'select', style:{width: "260px"},
                        options: ['auto',1,2,3,4,5,6,7], defaultValue: 'auto'
                    },
                    {name:"railing", label:"railing", type:'select', style:{width: "260px"},
                        options: [
                            {id: "none", name: "None"},
                            {id: "industrial", name: "Industrial"},
                            {id: "childSafe", name: "Child Safe"}
                        ],
                    },
                ];
                break;
            // case SelectedModuleType.stairStringer:
            //     newModuleData.type = ModuleRampType.StairStringer;
            //     newFormDefinition = [
            //         {name:"id", label:"type", type:'select', style:{width: "460px"},
            //             options: store[ModuleRampType.StairStringer].map((m, index) => ({id:index, name:m.name}))
            //         },
            //     ];
            //     break;
            case SelectedModuleType.wood:
                newModuleData.id = 0;
                setModuleData(old => ({...old, id: 0}));
                newFormDefinition = [
                    {
                        name: "type", label: "type", type: 'select', style: {width: "220px"},
                        options: [
                            {id: ModuleRampType.WoodPole, name: "Pole"},
                        ]
                    },
                ];
                break;
            case SelectedModuleType.kickPlate:
                newModuleData.id = 0;
                newModuleData.type = ModuleRampType.KickPlate;
                newFormDefinition = [
                    {name:" ", label:" ", style:{width: "fit-content"},type:'custom',input:()=> <span>Press Create to build a kick plate.</span>},
                ];
                break;
            case SelectedModuleType.supportLeg:
                newModuleData.id = 0;
                setModuleData(old=>({...old, id:0}));
                // newModuleData.type = ModuleType.supportLeg;
                newFormDefinition = [
                    {name:"type",label:"type",type:'select', style:{width: "220px"},
                        options:[
                            {id:ModuleRampType.SupportLeg, name:"Support Leg"},
                            {id:ModuleRampType.ScrewLeg, name:"Screw Leg"},
                        ]
                    },
                    {name:"isCustomHeight", label:"custom height",type:'checkbox', style:{width: "150px"}, defaultValue:false},
                    {name:"customHeightMM", label:"height (MM)",type:'number', defaultValue:0, style:{width: "220px"}, hideField:model=>!model.isCustomHeight},
                ];
                break;
        }

        newFormDefinition.forEach(def => {
            if(def.defaultValue != null) {
                newModuleData[def.name] = def.defaultValue;
            } else {
                newModuleData[def.name] = null;
            }
        });

        setModuleData(newModuleData);

        return newFormDefinition;
    }, [selectedModuleType]);


    const createDisabled = useMemo(()=>{
        if(moduleData.type === ModuleRampType.StairStep){
            return moduleData.step==null || moduleData.railing==null;
        }

        return moduleData.id==null || moduleData.type==null;
    }, [moduleData]);

    const _handleAutoGenButtonClick = useCallback(()=>{
        if(autoGenAction){
            const generatedPieces = autoGenAction();
            newStep(State.getInstance().models.concat(...generatedPieces));
        }
    }, [autoGenAction, newStep]);

    const _handleCreateButtonClick = useCallback(() => {
        if(createDisabled) return;
        const {
            type,
            id,
            degree: slopeDegree,
            ...otherProps
        } = moduleData;
        console.log("moduleData", moduleData)
        State.getInstance().setSelectedModule(getModuleTemplate({type, id, slopeDegree, ...otherProps}));
    }, [moduleData, createDisabled]);

    const _handleCancelButtonClick = useCallback(() => {
        State.getInstance().setSelectedModule(null);
    }, []);

    const _handleHotKeyPress = useCallback((e) => {
        if(e.target.tagName === 'INPUT') return;

        if(e.key.toLowerCase()==='c'){
            if(State.getInstance().selectedModule){
                _handleCancelButtonClick();
            }else {
                _handleCreateButtonClick();
            }

        }
        if(e.key.toLowerCase()==='g' && autoGenAction){
            _handleAutoGenButtonClick();
        }
    }, [_handleCreateButtonClick, _handleCancelButtonClick, _handleAutoGenButtonClick, autoGenAction]);

    useEffect(()=>{
        if(formDefinition.length>0){
            window.addEventListener('keypress', _handleHotKeyPress);
        }
        return ()=>{
            window.removeEventListener('keypress', _handleHotKeyPress);
        }
    }, [formDefinition, _handleHotKeyPress, moduleData]);

    const className = shouldShow ? 'moduleSettingBar' : 'moduleSettingBar hide';
    return <div className={className}>
        <NewFormRenderer
            formDefinition={formDefinition}
            object={moduleData}
            onChange={(name,value)=> {
                setModuleData(old=>({...old,[name]:value}));

                if(name==="id" || name==="type"){ // To enable hotkey after selection
                    canvasRef.current.focus({preventScroll: true});
                }
            }}
            columnClassName={"inputField"}
        />

        {State.getInstance().selectedModule ?
            <Button
                color="danger"
                style={{float:"right"}}
                onClick={_handleCancelButtonClick}
            >
                (C)ancel
            </Button> :
            formDefinition.length>0 && <Button
                color="success"
                style={{float:"right"}}
                onClick={_handleCreateButtonClick}
                disabled={createDisabled}
            >
                (C)reate
            </Button>
        }
        {autoGenAction && <Button
            color="success"
            style={{float:"right"}}
            onClick={_handleAutoGenButtonClick}
        >
            Auto (G)enerate
        </Button>
        }
    </div>
}

export default ModuleSettingBar;