import {Modal, ModalHeader, Spinner} from "reactstrap";
import {useGetSpiralStairBomQuery} from "../../generated/graphql";
import React from "react";
import {ok} from "assert";
import { SpiralStairsInProductionType } from "logistics/SpiralStairsListPage";
import BomWithArticleModalBody from "./component/BomWithArticleModalBody";

export default ({
                    spiralStair,
                    toggle,
                    setSpiralStairs
                }: {
    spiralStair: SpiralStairsInProductionType,
    toggle: () => void,
    setSpiralStairs:any
}) =>
{
    const orderId = spiralStair.purchaseOrder?.order?.id
    ok(orderId, `Unable to retrieve OrderId.`)
    const articleId = spiralStair.articleId

    const {data} = useGetSpiralStairBomQuery({variables: {orderId, articleId}})

    return <Modal size="md" style={{maxWidth: "800px"}} toggle={toggle} isOpen={true} backdrop={false}>
        <ModalHeader toggle={toggle}>Bill of material {spiralStair.purchaseOrder?.order?.number}</ModalHeader>
        {
            data == null ? <Spinner/>
                : <BomWithArticleModalBody orderId={orderId} articleId={articleId}
                                           spiralStair={spiralStair}
                                           boms={data?.getSpiralStairBom ?? []}
                                           setSpiralStairs={setSpiralStairs}
                                           isEditable={true}
                                           orderNumber={spiralStair.purchaseOrder?.order?.number}
                                           toggle={toggle}/>
        }
    </Modal>
}
