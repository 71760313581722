import {PackageFragmentFragment} from "../../../generated/graphql";
import Collapser from "../../../common/Collapser";
import React from "react";
import {getPackageColumns} from "../../../package/PackageListPage";
import NonPaginatedDataGrid from "../../../common/data-grid/NonPaginatedDataGrid";

const TABLE_KEY = "TABLE_KEY_SPIRAL_STAIR_PACKAGE"

const PackageTablePanel = ({packages, artNo}: { packages?: PackageFragmentFragment[], artNo?: string | null }) =>
{
    return <>
        <Collapser label={`Show packages (${packages?.length ?? 0})`} open={false}>
            <h5>Packages with {artNo}</h5>
            {packages && packages.length > 0
                ? <NonPaginatedDataGrid
                    definition={{
                        tableKey: TABLE_KEY,
                        columns: getPackageColumns(true),
                        pageSize: 20,
                        initState: {
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                }
                            }
                        }
                    }}
                    data={packages}
                />
                : <p>no package</p>
            }
        </Collapser>
    </>
}

export default PackageTablePanel