import React, {memo, useCallback, useMemo, useState} from "react";
import {ArticleRowVo} from "../StraightStairV2Types";
import {
    PriceSuggestionFragmentFragment,
    StraightStairV2FragmentFragment
} from "../../generated/graphql";
import {last, sortBy} from "lodash";
import NewFormRenderer from "../../common/NewFormRenderer";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import Paragraph from "../../common/Paragraph";
import ShortenText from "../../common/ShortenText";
import Pen from "../../common/icons/Pen";
import {formatCurrency} from "../../UTIL";
import StraightStairSummaryPanel from "./StraightStairSummaryPanel";

type OnVersionChangeListener = (key: string, value: number) => void

type Props = {
    articleRow: ArticleRowVo,
    straightStair: StraightStairV2FragmentFragment,
    setArticleRow: (ar: ArticleRowVo) => void,
    hasUnsavedChanged: boolean,
    onSaveClick: () => void,
    validationErrors: string[],
    versionList: number[]
    onVersionChange: OnVersionChangeListener,
    onUpdatePOClick: () => void,
    priceSuggestion: PriceSuggestionFragmentFragment | null
    getPriceError: any
}

const PriceListPanel = memo(({currentVersion, versionList, onVersionChange}:
                                 {
                                     currentVersion: number,
                                     versionList: number[],
                                     onVersionChange: OnVersionChangeListener
                                 }) =>
{
    const [editing, setEditing] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(currentVersion);

    const onChange = useCallback((e) =>
    {
        const value = parseInt(e.target.value)
        onVersionChange("version", value)
        setSelectedVersion(value)
    }, [onVersionChange])

    const latestVersion = useMemo(() =>
    {
        return last(sortBy(versionList)) ?? 0;
    }, [versionList])

    const isNewerExists = useMemo(() =>
    {
        return latestVersion > currentVersion;
    }, [latestVersion, currentVersion])

    return <>
        {isNewerExists && <p style={{color: "red"}}>A newer list exists. New Version: {latestVersion}</p>}
        {
            !editing
                ? <p>Pricelist version: {currentVersion}
                    <span className={"link"} onClick={() => setEditing(true)}>
                        <Pen/>
                    </span>
                </p>
                : <>
                    <p>Pricelist version: {currentVersion} →
                        <select value={selectedVersion} onChange={onChange}>
                            {
                                versionList.map((e) => <option key={`priceList${e}`} value={e}>{e}</option>)
                            }
                        </select>
                    </p>
                </>
        }
    </>
})

const ArticleRowEditBlock = memo(({articleRow, onChange}: {
    articleRow: ArticleRowVo,
    onChange: (ar: ArticleRowVo) => void
}) =>
{
    const formDefinition = useMemo(() =>
    {
        const formDefinition: any = [{name: "name"},
            {name: "price", type: "currency"}
        ]
        return formDefinition
    }, [])

    const handleOnChange = useCallback((key, value) =>
    {
        onChange(({...articleRow, [key]: value}))
    }, [onChange, articleRow])

    return <NewFormRenderer formDefinition={formDefinition}
                            object={articleRow}
                            onChange={handleOnChange}/>
})

const StraightStairPriceCalculationPanel = memo(({
                                                     articleRow, setArticleRow, hasUnsavedChanged,
                                                     onSaveClick, straightStair, validationErrors,
                                                     versionList, onVersionChange, onUpdatePOClick,
                                                     priceSuggestion, getPriceError
                                                 }: Props) =>
{
    const isDisplayPrice = validationErrors.length === 0 && !hasUnsavedChanged

    return <Row>
        <Col sm={12} md={6}>
            <Card>
                <CardHeader>
                    <Paragraph style={{fontSize: "18px"}}>Price Calculation</Paragraph>
                </CardHeader>
                <CardBody>
                    <ArticleRowEditBlock articleRow={articleRow} onChange={setArticleRow}/>

                    <PriceListPanel currentVersion={straightStair.version} versionList={versionList}
                                    onVersionChange={onVersionChange}/>

                    <p style={{
                        color: "red",
                        fontSize: "12px"
                    }}>{hasUnsavedChanged ? 'Save before calculating price' : ''}</p>

                    <p>Price: {(isDisplayPrice && priceSuggestion?.price) ? formatCurrency(priceSuggestion.price) : 'N/A'} {isDisplayPrice ? priceSuggestion?.currency : ''}</p>
                    <p>InPrice: {(isDisplayPrice && priceSuggestion?.inPrice) ? `${formatCurrency(priceSuggestion?.inPrice)} EUR` : 'N/A'} </p>

                    {isDisplayPrice && <StraightStairSummaryPanel id={straightStair.id}/>}
                    <br/>
                    <Button color="success" onClick={onSaveClick}
                            style={{marginTop: "16px"}}>
                        Save and update price
                    </Button>
                    <p>Unsaved changes: {hasUnsavedChanged ? <strong style={{color: "red"}}>Yes</strong> : "No"}</p>

                    {
                        straightStair.purchaseOrder &&
                        <Button onClick={() => onUpdatePOClick()}>Update Purchase Order </Button>
                    }
                </CardBody>
            </Card>
        </Col>

        <Col sm={12} md={6}>
            <Card>
                <CardHeader>
                    <Paragraph style={{fontSize: "18px"}}>Validations</Paragraph>
                </CardHeader>
                <CardBody>
                    {
                        validationErrors.length > 0 && <>
                            <p style={{fontSize: "12px", color: "red"}}>Fix validation errors before calculating price</p>

                            <ul className={"validation-list"}>
                                {validationErrors.map((message, index) =>
                                    <li key={index} style={{marginBottom: "10px", borderBottom: "2px solid #ddd"}}>
                                        <ShortenText text={message} lengthToCollapse={10}/>
                                    </li>
                                )}
                            </ul>
                        </>
                    }
                    {getPriceError && <p style={{color: "red"}}>{getPriceError.message}</p>}
                </CardBody>
            </Card>
        </Col>
    </Row>
})

export default StraightStairPriceCalculationPanel